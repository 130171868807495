import React, { useCallback, useContext, useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Typography } from "@mui/joy";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import { MenuItem, Select, Avatar, Button, Divider } from "@mui/material";
import { AuthContext } from "../authentification/Authentification";
import SidebarECommerce from "./eCommerce/SidebarECommerce";
import SettingsIcon from "@mui/icons-material/Settings";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DnsIcon from "@mui/icons-material/Dns";
import AccueilIcon from "@mui/icons-material/Home";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import OperationStatusChecker from "../../shared/operation/OperationStatusChecker";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import axios from "axios";
import { useTranslation } from "react-i18next";

const SidebarGestionCompte = ({
  changeActiveComponent,
  changeSite,
  sitesUtilisateur,
  siteConsulte,
  getUtilisateurSite,
  loading,
  error,
}) => {
  const { t } = useTranslation();
  const { setUserAuthenticated } = useContext(AuthContext);
  const [selectedIndex, setSelectedIndex] = useState("accueil");
  const navigate = useNavigate();
  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const customerName = profil?.customerName;
  const [isBoutique, setIsBoutique] = useState(false);

  const [operationIdInstallation, setOperationIdInstallation] = useState(
    sessionStorage.getItem("operationIdInstallation")
  );
  const [operationIdDomaine, setOperationIdDomaine] = useState(
    sessionStorage.getItem("operationIdDomaine")
  );
  const [operationIdReouverture, setOperationIdReouverture] = useState(
    sessionStorage.getItem("operationIdReouverture")
  );

  const selectedStyle = {
    backgroundColor: "#0042ff !important",
    borderRadius: "8px",
    width: "90%",
    "& .MuiTypography-root": {
      color: "white",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  };

  const handleError = useCallback(
    (message) => {
      error(message);
      setTimeout(() => {
        error(null);
      }, 3000);
    },
    [error]
  );

  useEffect(() => {
    if (siteConsulte) {
      setIsBoutique(siteConsulte.consumerKey != null);
    }
  }, [siteConsulte]);

  const handleListItemClick = (componentName) => {
    setSelectedIndex(componentName);
    if (typeof changeActiveComponent === "function") {
      changeActiveComponent(componentName);
    }
  };

  const handleLogout = async () => {
    try {
      await axios.get(`${API_SERVER_ENDPOINT}/logout`, {
        withCredentials: true,
      });

      setUserAuthenticated(false);
      sessionStorage.removeItem("profil");
      redirectionAccueil();
    } catch (error) {
      handleError(error.message);
    }
  };

  const redirectionAccueil = () => {
    navigate("/");
  };

  const redirectionInformations = () => {
    navigate("/informations");
  };

  const handleChangeSite = (event) => {
    const url = event.target.value;
    const selectedSite = sitesUtilisateur.find((site) => site.url === url);

    if (selectedSite) {
      const isBoutique = selectedSite.consumerKey != null;
      setIsBoutique(isBoutique);

      if (!isBoutique || selectedSite.etat !== "actif") {
        handleListItemClick("accueil");
      }

      changeSite(selectedSite);
      sessionStorage.setItem("selectedSite", JSON.stringify(selectedSite));
    }
  };

  const handleCompleteChecker = useCallback(
    (type) => {
      if (type === "installation") {
        sessionStorage.removeItem("operationIdInstallation");
        setOperationIdInstallation(null);
      } else if (type === "domainUpdate") {
        sessionStorage.removeItem("operationIdDomaine");
        setOperationIdDomaine(null);
      } else if (type === "reouverture") {
        sessionStorage.removeItem("operationIdReouverture");
        setOperationIdReouverture(null);
      }
      getUtilisateurSite();
    },
    [getUtilisateurSite]
  );

  const handleErrorStatusChecker = useCallback(
    (type) => {
      if (type === "installation") {
        sessionStorage.removeItem("operationIdInstallation");
        setOperationIdInstallation(null);
      } else if (type === "domainUpdate") {
        sessionStorage.removeItem("operationIdDomaine");
        setOperationIdDomaine(null);
      } else if (type === "reouverture") {
        handleError(t("error.reopenSite"));
        sessionStorage.removeItem("operationIdReouverture");
        setOperationIdReouverture(null);
      }
      getUtilisateurSite();
    },
    [getUtilisateurSite, handleError, t]
  );

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <Box
        sx={{
          width: "300px",
          backgroundColor: "#02010e",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "100vh",
          overflowY: "auto",
        }}
      >
        <Box>
          <Box
            sx={{
              padding: "5%",
              justifyContent: "left",
              display: "flex",
              alignItems: "left",
            }}
          >
            <Link to="/">
              <img
                src={process.env.PUBLIC_URL + "/logo/onesite.png"}
                alt="Presentation"
                style={{ maxWidth: "7rem", margin: "0 auto" }}
              />
            </Link>
          </Box>

          {!loading && (
            <div>
              {sitesUtilisateur && sitesUtilisateur.length > 0 ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      flexDirection: "column",
                      alignItems: "left",
                      mb: 1,
                      pl: "5%",
                      mt: "-3%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: {
                          xs: "12px",
                          md: "12px",
                          lg: "14px",
                          xl: "14px",
                        },
                        fontFamily: "Inter Variable",
                        fontWeight: "500",
                        mb: 1,
                        ml: "3%",
                      }}
                    >
                      Vous modifiez le site :
                    </Typography>

                    <Select
                      value={siteConsulte?.url}
                      onChange={handleChangeSite}
                      sx={{
                        width: "80%",
                        bgcolor: "#0042ff",
                        fontFamily: "Inter Variable",
                        fontWeight: "500",
                        textAlign: "left",
                        fontSize: { xs: "14px", md: "14px" },
                        letterSpacing: "-0px",
                        color: "white",
                        borderRadius: "10px",
                        marginTop: { xs: "5%", md: "1%" },

                        ".MuiSelect-icon": {
                          color: "white",
                        },
                        "& .MuiSelect-select": {
                          textAlign: "left",
                          padding: { xs: "10px", sm: "10px", md: "10px" },
                        },
                        "& .MuiOutlinedInput-root": {
                          fontSize: { xs: "14px", sm: "16px", md: "18px" },
                        },
                        "& .MuiMenuItem-root": {
                          fontSize: { xs: "14px", sm: "16px", md: "18px" },
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            mt: "-0.5%",
                            bgcolor: "#0042ff",
                            color: "white",
                            boxShadow: "none",
                            borderBottomRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            borderTopLeftRadius: "0px",
                            borderTopRightRadius: "0px",
                            "& .MuiMenuItem-root": {
                              fontFamily: "Inter Variable",
                              fontWeight: "500",
                              fontSize: {
                                lg: "14px",
                                md: "14px",
                                sm: "14px",
                                xs: "14px",
                              },
                              justifyContent: "left",
                              width: "100%",
                              textAlign: "left",
                              "&:hover": {
                                background: "#0033c1",
                                fontWeight: "500",
                                color: "white",
                              },
                              "&.Mui-selected": {
                                bgcolor: "#0033c1",
                                "&:hover": {
                                  bgcolor: "#0042ff",
                                },
                              },
                            },
                          },
                        },
                      }}
                    >
                      {sitesUtilisateur.map((site, index) => (
                        <MenuItem
                          key={index}
                          value={site.url}
                          sx={{
                            justifyContent: "center",
                          }}
                        >
                          {site.url.split("/").pop()}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>

                  <Box sx={{ width: "100%", px: 2 }}>
                    <Divider
                      sx={{
                        my: 2,
                        borderColor: "#ffffff",
                        borderStyle: "dashed",
                        borderWidth: "1px",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: {
                          xs: "12px",
                          md: "12px",
                          lg: "12px",
                          xl: "12px",
                        },
                        fontFamily: "Inter Variable",
                        fontWeight: "500",
                        textAlign: "left",
                        pl: "2%",
                        mb: "2%",
                      }}
                    >
                      Menu
                    </Typography>
                    <List component="nav">
                      <ListItemButton
                        selected={selectedIndex === "accueil"}
                        onClick={() => handleListItemClick("accueil")}
                        sx={
                          selectedIndex === "accueil"
                            ? selectedStyle
                            : { marginBottom: "0px" }
                        }
                      >
                        <ListItemIcon>
                          <AccueilIcon
                            sx={{
                              color: "white",
                              fontSize: {
                                xs: "20px",
                                md: "20px",
                                lg: "20px",
                                xl: "20px",
                              },
                            }}
                          />
                          <Typography
                            sx={{
                              color: "white",
                              fontSize: {
                                xs: "14px",
                                md: "14px",
                                lg: "14px",
                                xl: "14px",
                              },
                              fontFamily: "Inter Variable",
                              fontWeight: "500",
                              letterSpacing: "-0.5px",
                              ml: "10px",
                              mt: "-1%",
                            }}
                          >
                            {t("sidebarGestionCompte.home")}
                          </Typography>
                        </ListItemIcon>
                      </ListItemButton>
                    </List>
                    <SidebarECommerce
                      selectedIndex={selectedIndex}
                      onListItemClick={handleListItemClick}
                      site={siteConsulte}
                    />
                  </Box>
                </>
              ) : (
                <Box
                  sx={{ display: "flex", justifyContent: "center", mt: "10px" }}
                >
                  <Button
                    variant="contained"
                    onClick={redirectionInformations}
                    sx={{
                      backgroundColor: "#0042ff",
                      color: "white",
                      fontSize: "18px",
                      fontFamily: "Inter Variable",
                      letterSpacing: "-0.5px",
                      fontWeight: "700",
                      textTransform: "none",
                      boxShadow: "none",
                      borderRadius: "10px",
                      padding: "10px 20px",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#0042ff",
                        boxShadow: "none",
                      },
                    }}
                  >
                    {t("sidebarGestionCompte.startTrial")}
                  </Button>
                </Box>
              )}
            </div>
          )}
        </Box>

        <Box
          sx={{
            width: "100%",
            px: 2,
            pb: 2,
          }}
        >
          <Divider
            sx={{
              my: 2,
              borderColor: "#ffffff",
              borderStyle: "dashed",
              borderWidth: "1px",
            }}
          />
          <Typography
            sx={{
              color: "white",
              fontSize: {
                xs: "12px",
                md: "12px",
                lg: "12px",
                xl: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              textAlign: "left",
              pl: "2%",
              mb: "2%",
            }}
          >
            {t("sidebarGestionCompte.home")}
          </Typography>
          <List component="nav">
            <ListItemButton
              selected={selectedIndex === "mesInformations"}
              onClick={() => handleListItemClick("mesInformations")}
              sx={
                selectedIndex === "mesInformations"
                  ? selectedStyle
                  : { marginBottom: "2px" }
              }
            >
              <ListItemIcon>
                <SettingsIcon
                  sx={{
                    color: "white",
                    fontSize: {
                      xs: "18px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                  }}
                />
                <Typography
                  sx={{
                    color: "white",
                    fontSize: {
                      xs: "14px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontFamily: "Inter Variable",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                    ml: "10px",
                    mt: "-1.5%",
                  }}
                >
                  {t("sidebarGestionCompte.settings")}
                </Typography>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === "mesAbonnements"}
              onClick={() => handleListItemClick("mesAbonnements")}
              sx={
                selectedIndex === "mesAbonnements"
                  ? selectedStyle
                  : { marginBottom: "2px" }
              }
            >
              <ListItemIcon>
                <ReceiptIcon
                  sx={{
                    color: "white",
                    fontSize: {
                      xs: "18px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                  }}
                />
                <Typography
                  sx={{
                    color: "white",
                    fontSize: {
                      xs: "14px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontFamily: "Inter Variable",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                    ml: "10px",
                    mt: "-1.5%",
                  }}
                >
                  {t("sidebarGestionCompte.subscriptions")}
                </Typography>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === "gestionDomaine"}
              onClick={() => handleListItemClick("gestionDomaine")}
              sx={
                selectedIndex === "gestionDomaine"
                  ? selectedStyle
                  : { marginBottom: "2px" }
              }
            >
              <ListItemIcon>
                <DnsIcon
                  sx={{
                    color: "white",
                    fontSize: {
                      xs: "18px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                  }}
                />
                <Typography
                  sx={{
                    color: "white",
                    fontSize: {
                      xs: "14px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontFamily: "Inter Variable",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                    ml: "10px",
                    mt: "-1.5%",
                  }}
                >
                  {t("sidebarGestionCompte.domains")}
                </Typography>
              </ListItemIcon>
            </ListItemButton>
          </List>
          <List component="nav">
            <ListItemButton
              selected={selectedIndex === "deconnexion"}
              onClick={handleLogout}
              sx={{
                backgroundColor: "#0042ff",
                borderRadius: "8px",
                color: "white",
                mt: 2,
                "&:hover": {
                  backgroundColor: "#0033cc",
                },
              }}
            >
              <Avatar
                alt={customerName}
                src=""
                sx={{
                  width: { xs: 24, sm: 28, md: 30 },
                  height: { xs: 24, sm: 28, md: 30 },
                  mr: { xs: 1, sm: 1.5, md: 2 },
                }}
              />
              <Typography
                sx={{
                  flexGrow: 1,
                  color: "white",
                  fontSize: { xs: "16px", sm: "18px", md: "20px" },
                  fontFamily: "Inter Variable",
                  fontWeight: "500",
                  letterSpacing: "-0.5px",
                  ml: { xs: "5px", sm: "8px", md: "10px" },
                }}
              >
                {customerName}
              </Typography>
              <PowerSettingsNewIcon
                sx={{
                  color: "white",
                  fontSize: { xs: "20px", sm: "25px", md: "30px" },
                }}
              />
            </ListItemButton>
          </List>
        </Box>
      </Box>

      {operationIdInstallation && (
        <OperationStatusChecker
          operationId={operationIdInstallation}
          type="installation"
          onComplete={handleCompleteChecker}
          onError={handleErrorStatusChecker}
        />
      )}
      {operationIdDomaine && (
        <OperationStatusChecker
          operationId={operationIdDomaine}
          type="domainUpdate"
          onComplete={handleCompleteChecker}
          onError={handleErrorStatusChecker}
        />
      )}
      {operationIdReouverture && (
        <OperationStatusChecker
          operationId={operationIdReouverture}
          type="reouverture"
          onComplete={handleCompleteChecker}
          onError={handleErrorStatusChecker}
        />
      )}
    </Box>
  );
};

export default SidebarGestionCompte;
