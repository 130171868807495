import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const ConfirmationDialog = ({ open, onClose, onConfirm, message }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "20px",
          width: "90%",
          maxWidth: "600px",
          height: "auto",
          maxHeight: "90vh",
          backgroundColor: "#f9f9f9",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: { lg: "20px", md: "16px", sm: "14px", xs: "12px" },
            color: "black",
            fontFamily: "Space Grotesk",
            fontWeight: "700",
            letterSpacing: "-1px",
          }}
        >
          {t("confirmationDialog.title")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            fontSize: { lg: "18px", md: "16px", sm: "14px", xs: "12px" },
            color: "black",
            fontFamily: "Inter Variable",
            fontWeight: "500",
            letterSpacing: "-0.5px",
            textAlign: "center",
            mt: 2,
            mb: 2,
          }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", width: "100%" }}>
        <Button
          onClick={onClose}
          sx={{
            color: "white",
            fontSize: {
              xl: "20px",
              lg: "20px",
              md: "18px",
              sm: "16px",
              xs: "14px",
            },
            px: "6%",
            mt: "5%",
            ml: "2%",
            fontFamily: "Space Grotesk",
            fontWeight: "700",
            background: "#878ca7",
            letterSpacing: "-1px",
            textTransform: "none",
            boxShadow: "none",
            borderRadius: "10px",
            "&:hover": {
              background: "#5e6172",
              boxShadow: "none",
              color: "white",
            },
          }}
        >
          {t("confirmationDialog.cancel")}
        </Button>
        <Button
          onClick={onConfirm}
          autoFocus
          sx={{
            color: "white",
            mt: "5%",
            fontSize: {
              xl: "20px",
              lg: "20px",
              md: "18px",
              sm: "16px",
              xs: "14px",
            },
            ml: "2%",
            px: "6%",
            fontFamily: "Space Grotesk",
            fontWeight: "700",
            background: "#0042ff",
            textTransform: "none",
            letterSpacing: "-1px",
            boxShadow: "none",
            borderRadius: "10px",
            "&:hover": {
              color: "white",
              boxShadow: "none",
              background: "#0033c1",
            },
          }}
        >
          {t("confirmationDialog.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
