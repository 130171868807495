import { Chip } from "@mui/material";

export const getStatutAbonnement = (status, t) => {
  const statusLowerCase = status?.toLowerCase();
  let color;

  switch (statusLowerCase) {
    case "active":
      color = "success";
      break;
    case "trialing":
      color = "primary";
      break;
    case "canceled":
      color = "error";
      break;
    case "incomplete":
    case "incomplete_expired":
      color = "default";
      break;
    case "past_due":
      color = "warning";
      break;
    default:
      color = "default";
  }

  const label = t(`statutAbonnement.${statusLowerCase}`, {
    defaultValue: t("statutAbonnement.unknown"),
  });

  return (
    <Chip
      label={label}
      color={color}
      sx={{
        fontSize: { xl: "14px", lg: "14px", md: "14px" },
        fontFamily: "Inter Variable",
        letterSpacing: "-0.5px",
        fontWeight: "500",
        borderRadius: "8px",
        boxShadow: "none",
      }}
    />
  );
};
