import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { addHttpsPrefix } from "../html/HtmlUtils";
import StatutSite from "../statut/StatutSite";
import { useTranslation } from "react-i18next";

const SiteStatsCard = ({ sites }) => {
  const { t } = useTranslation();

  const totalSites = sites?.length || 0;
  const activeSites =
    sites?.filter((site) => site.etat === "actif").length || 0;
  const installationSites =
    sites?.filter((site) => site.etat === "installation").length || 0;
  const inactiveSites = totalSites - activeSites - installationSites;
  const activePercentage = ((activeSites / totalSites) * 100).toFixed(0);
  const installationPercentage = (
    (installationSites / totalSites) *
    100
  ).toFixed(0);
  const inactivePercentage = ((inactiveSites / totalSites) * 100).toFixed(0);

  const data = [
    {
      name: t("siteStatsCard.active"),
      value: parseFloat(activePercentage),
      color: "#00C49F",
    },
    {
      name: t("siteStatsCard.installation"),
      value: parseFloat(installationPercentage),
      color: "#FFA500",
    },
    {
      name: t("siteStatsCard.inactive"),
      value: parseFloat(inactivePercentage),
      color: "#FF4848",
    },
  ];

  return (
    <Card
      raised
      sx={{
        m: 2,
        p: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        boxShadow: "none",
        border: "2px solid #c6c7ce",
      }}
    >
      <CardContent sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              flexGrow: 1,
              ml: 1,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                fontFamily: "Space Grotesk, sans-serif",
                fontWeight: "1000",
                letterSpacing: {
                  xs: "-1px",
                  sm: "-1px",
                  md: "-2px",
                  lg: "-3px",
                },
                fontSize: { xs: "25px", sm: "25px", md: "30px", lg: "40px" },
              }}
            >
              {t("siteStatsCard.userSites")}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Inter Variable, sans-serif",
                fontWeight: "600",
                letterSpacing: {
                  xs: "-1px",
                  sm: "-1px",
                  md: "-2px",
                  lg: "-2px",
                },
                fontSize: { xs: "20px", sm: "20px", md: "25px", lg: "30px" },
              }}
            >
              {totalSites}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "300px",
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={70}
                outerRadius={100}
                startAngle={90}
                endAngle={450}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                labelLine={false}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Legend
                verticalAlign="bottom"
                height={36}
                iconType="circle"
                content={({ payload }) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      mt: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "50%",
                      }}
                    >
                      {payload.map((entry, index) => (
                        <Box
                          key={`item-${index}`}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: 12,
                              height: 12,
                              backgroundColor: entry.color,
                              borderRadius: "50%",
                              marginRight: 1,
                            }}
                          />
                          <Typography
                            variant="body2"
                            sx={{ color: entry.color }}
                          >
                            {entry.value}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              />
            </PieChart>
          </ResponsiveContainer>
        </Box>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table aria-label="domain names table">
            <TableHead>
              <TableRow>
                <TableCell>{t("siteStatsCard.domainName")}</TableCell>
                <TableCell>{t("siteStatsCard.status")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sites.map((site) => (
                <TableRow key={site._id}>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "black",
                          marginRight: "8px",
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "500",
                          textDecoration: "none",
                        }}
                      >
                        {site.url}
                      </Typography>
                      <IconButton
                        component="a"
                        href={addHttpsPrefix(site.url)}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ padding: "0" }}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <StatutSite site={site} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default SiteStatsCard;
