import axios from "axios";
import { API_SERVER_ENDPOINT } from "../shared/api/ConstantesEndpoints";

const sendToAnalytics = (metric) => {
  const { id, name, value } = metric;
  const payload = { id, name, value };
  axios
    .post(`${API_SERVER_ENDPOINT}/frontend-metrics`, payload, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((error) => {
      console.error("Error sending metrics to backend", error);
    });
};

export default sendToAnalytics;
