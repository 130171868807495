import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  InputAdornment,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import ProductVariations from "./ProductVariations";
import HelpIconButton from "../../../../shared/tooltip/HelpIconButton";

const ProductDetails = ({
  product,
  onProductDetailsChange,
  onVariationsChange,
  imageScrape,
  onScrapedImageRemoved,
}) => {
  const { t } = useTranslation();
  const [selectedUrls, setSelectedUrls] = useState(product.images || []);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);
  const [variations, setVariations] = useState(product.variations || []);
  const [scrapedImage, setScrapedImage] = useState(imageScrape);

  useEffect(() => {
    if (imageScrape) {
      setSelectedUrls([imageScrape]);
      setScrapedImage(imageScrape);
    }
  }, [imageScrape]);

  useEffect(() => {
    return () => {
      fileUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [fileUrls]);

  const handleImageUpload = (acceptedFiles) => {
    const validMimeTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/webp",
      "image/heif",
      "image/heic",
    ];
    const validFiles = acceptedFiles.filter((file) =>
      validMimeTypes.includes(file.type)
    );

    if (validFiles.length > 0) {
      const updatedFiles = [...selectedFiles, ...validFiles];
      setSelectedFiles(updatedFiles);

      const newFileUrls = validFiles.map((file) => URL.createObjectURL(file));
      setFileUrls((prevUrls) => [...prevUrls, ...newFileUrls]);

      onProductDetailsChange("images", [...selectedUrls, ...updatedFiles]);
    }
  };

  const handleRemoveImage = (event, index, type) => {
    event.stopPropagation();
    if (type === "url") {
      const updatedUrls = selectedUrls.filter((_, i) => i !== index);

      if (selectedUrls[index] === scrapedImage) {
        setScrapedImage(null);
        onScrapedImageRemoved();
      }

      setSelectedUrls(updatedUrls);
      onProductDetailsChange("images", [...updatedUrls, ...selectedFiles]);
    } else if (type === "file") {
      const updatedFiles = selectedFiles.filter((_, i) => i !== index);
      setSelectedFiles(updatedFiles);

      URL.revokeObjectURL(fileUrls[index]);
      const updatedFileUrls = fileUrls.filter((_, i) => i !== index);
      setFileUrls(updatedFileUrls);

      onProductDetailsChange("images", [...selectedUrls, ...updatedFiles]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleImageUpload,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
      "image/webp": [],
      "image/heif": [],
      "image/heic": [],
    },
  });

  const handleVariationsChange = (newVariations) => {
    setVariations(newVariations);
    onVariationsChange(newVariations);
  };

  return (
    <Card
      sx={{
        marginBottom: 2,
        width: "100%",
        borderColor: "grey.300",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "16px",
        padding: "16px",
        boxShadow: "none",
        border: "2px solid #c6c7ce",
        mt: "-5%",
        ml: "-12%",
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "600",
            fontSize: "14px",
            fontFamily: "Inter Variable",
            marginBottom: "8px",
            letterSpacing: "-0.5px",
          }}
        >
          {t("produits.product_name")}
        </Typography>
        <TextField
          id="name"
          type="text"
          fullWidth
          variant="outlined"
          value={product.name}
          onChange={(e) => onProductDetailsChange("name", e.target.value)}
          InputProps={{
            sx: {
              width: "100%",
              height: "50px",
              borderRadius: "12px",
              backgroundColor: "white",
              border: "1px solid #E3E3E3",
              fontWeight: "500",
              fontFamily: "Inter Variable, sans-serif",
              fontSize: "14px",
              padding: "0%",
              letterSpacing: "-0.25px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              input: {
                color: "text.primary",
              },
              "&::placeholder": {
                color: "#000000",
                opacity: 0.8,
              },
            },
          }}
        />
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                fontFamily: "Inter Variable",
                marginBottom: "8px",
                letterSpacing: "-0.5px",
              }}
            >
              {t("produits.price")}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                id="price"
                type="number"
                fullWidth
                variant="outlined"
                value={product.price}
                onChange={(e) =>
                  onProductDetailsChange("price", e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment sx={{ ml: 1 }} position="start">
                      €
                    </InputAdornment>
                  ),
                  sx: {
                    width: "100%",
                    height: "50px",
                    borderRadius: "12px",
                    backgroundColor: "white",
                    border: "1px solid #E3E3E3",
                    fontWeight: "500",
                    fontFamily: "Space Grotesk, sans-serif",
                    fontSize: "14px",
                    padding: "0%",
                    letterSpacing: "-0.25px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    input: {
                      color: "text.primary",
                    },
                    "&::placeholder": {
                      color: "#000000",
                      opacity: 0.8,
                    },
                  },
                }}
              />
              <HelpIconButton helpText={t("produits.help_price_principal")} />
            </Box>
          </Grid>
        </Grid>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "600",
            fontSize: "14px",
            fontFamily: "Inter Variable",
            marginBottom: "8px",
            letterSpacing: "-0.5px",
            marginTop: "16px",
          }}
        >
          {t("produits.description")}
        </Typography>
        <TextField
          id="description"
          type="text"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={product.description}
          onChange={(e) =>
            onProductDetailsChange("description", e.target.value)
          }
          InputProps={{
            sx: {
              borderRadius: "12px",
              backgroundColor: "white",
              border: "1px solid #E3E3E3",
              fontWeight: "500",
              fontFamily: "Inter Variable, sans-serif",
              fontSize: "14px",
              padding: "3%",
              letterSpacing: "-0.25px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              input: {
                color: "text.primary",
              },
            },
          }}
        />
        <Typography
          variant="h6"
          sx={{
            fontWeight: "600",
            fontSize: "14px",
            fontFamily: "Inter Variable",
            marginBottom: "8px",
            letterSpacing: "-0.5px",
            marginTop: "25px",
          }}
        >
          {t("produits.images")}
        </Typography>
        <Box
          {...getRootProps()}
          sx={{
            border: "2px dashed #9a9b9f",
            borderRadius: "16px",
            padding: "40px",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            mt: 2,
            mb: 2,
            width: "100%",
            backgroundColor: "#fcfcfc",
          }}
        >
          <input {...getInputProps()} />
          {selectedUrls.length > 0 || fileUrls.length > 0 ? (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {selectedUrls.map((url, index) => (
                <Box
                  key={`url-${index}`}
                  sx={{ position: "relative", display: "inline-block" }}
                >
                  <img
                    src={url}
                    alt="Preview"
                    style={{
                      width: "120px",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                  <IconButton
                    onClick={(event) => handleRemoveImage(event, index, "url")}
                    sx={{
                      position: "absolute",
                      top: -10,
                      right: -10,
                      backgroundColor: "white",
                      "&:hover": { backgroundColor: "white" },
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Box>
              ))}
              {fileUrls.map((url, index) => (
                <Box
                  key={`file-${index}`}
                  sx={{ position: "relative", display: "inline-block" }}
                >
                  <img
                    src={url}
                    alt="Preview"
                    style={{
                      width: "120px",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                  <IconButton
                    onClick={(event) => handleRemoveImage(event, index, "file")}
                    sx={{
                      position: "absolute",
                      top: -10,
                      right: -10,
                      backgroundColor: "white",
                      "&:hover": { backgroundColor: "white" },
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography
              sx={{
                fontFamily: "Inter Variable, sans-serif",
                fontWeight: 500,
                letterSpacing: "-0.25px",
                fontSize: { xs: "16px", md: "20px" },
                textAlign: "center",
                color: "#43444c",
              }}
            >
              {t("produits.drop_or_click_upload")}
            </Typography>
          )}
        </Box>

        <ProductVariations
          variations={variations}
          onVariationsChange={handleVariationsChange}
        />
      </CardContent>
    </Card>
  );
};

export default ProductDetails;
