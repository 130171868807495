import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaiementStripeFormulaire from "./PaiementStripeFormulaire";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const PaymentSubscribeAbonnement = ({
  selectedPriceId,
  montant,
  promoCode,
}) => {
  const [selectedAmount, setSelectedAmount] = useState(montant);

  useEffect(() => {
    setSelectedAmount(montant);
  }, [montant]);

  const options = {
    mode: "subscription",
    amount: selectedAmount,
    currency: "eur",
    appearance: {
      variables: {
        colorText: "#000000",
        borderRadius: "10px",
      },
      labels: "floating",
      rules: {
        ".Input": {
          border: "1px solid #ccc",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        },
      },
    },
  };

  return (
    <Box>
      <Elements options={options} stripe={stripePromise}>
        <PaiementStripeFormulaire
          selectedPriceId={selectedPriceId}
          promoCode={promoCode}
        />
      </Elements>
    </Box>
  );
};

export default PaymentSubscribeAbonnement;
