import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Typography,
  Menu,
  MenuItem,
  Modal,
  TableBody,
  TableHead,
  Table,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import DiscountDialog from "./DiscountDialog";
import DeleteDialog from "./DeleteDialog";

const RowProduit = ({ product, handleEdit, siteNom, loadProducts }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [variationAnchorEl, setVariationAnchorEl] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [discountDialogOpen, setDiscountDialogOpen] = useState(false);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemType, setItemType] = useState(null);

  const originalPrice = parseFloat(product.regular_price || product.price);
  const salePrice = parseFloat(product.sale_price);
  const hasDiscount =
    !isNaN(originalPrice) && !isNaN(salePrice) && salePrice < originalPrice;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleVariationMenuClick = (event, variationId) => {
    setVariationAnchorEl((prev) => ({
      ...prev,
      [variationId]: event.currentTarget,
    }));
  };

  const handleVariationMenuClose = (variationId) => {
    setVariationAnchorEl((prev) => ({ ...prev, [variationId]: null }));
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleOpenDiscountDialog = (variation) => {
    setSelectedVariation(variation);
    setDiscountDialogOpen(true);
    handleClose();
  };

  const handleOpenDiscountDialogForProduct = () => {
    setSelectedVariation(null);
    setDiscountDialogOpen(true);
    handleClose();
  };

  const handleCloseDiscountDialog = () => {
    setDiscountDialogOpen(false);
    setSelectedVariation(null);
  };

  const handleOpenDeleteDialog = (type, item) => {
    setItemType(type);
    setSelectedVariation(item);
    setDeleteDialogOpen(true);
    handleClose();
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setItemType(null);
    setSelectedVariation(null);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          {product.variations.length > 0 && (
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              maxWidth: "500px",
            }}
          >
            {product.images.map((image, index) => (
              <Box
                key={index}
                sx={{
                  position: "relative",
                  width: "100px",
                  height: "100px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={image.src}
                  alt={product.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  onClick={() => handleImageClick(image.src)}
                />
              </Box>
            ))}
          </Box>
        </TableCell>
        <TableCell>
          <Typography
            sx={{
              fontSize: { xl: "14px", lg: "14px", md: "14px" },
              fontFamily: "Inter Variable",
              letterSpacing: "-0.5px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            {product.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            sx={{
              fontSize: { xl: "14px", lg: "14px", md: "14px" },
              fontFamily: "Inter Variable",
              letterSpacing: "-0.5px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            {hasDiscount ? (
              <>
                <span
                  style={{ textDecoration: "line-through", marginRight: "8px" }}
                >
                  {originalPrice.toFixed(2)}€
                </span>
                <span style={{ color: "red" }}>{salePrice.toFixed(2)}€</span>
              </>
            ) : (
              `${originalPrice.toFixed(2)}€`
            )}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <div
            inert={discountDialogOpen || deleteDialogOpen ? "true" : undefined}
          >
            <IconButton onClick={handleClick} aria-label="actions menu">
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "10px",
                  textAlign: "center",
                },
                "& .MuiMenuItem-root": {
                  fontFamily: "Inter Variable, sans-serif",
                },
              }}
            >
              <MenuItem onClick={() => handleEdit(product)}>
                {t("produits.edit")}
              </MenuItem>
              <MenuItem
                onClick={() => handleOpenDeleteDialog("product", product)}
              >
                {t("produits.delete")}
              </MenuItem>
              {product.variations.length === 0 && (
                <MenuItem onClick={handleOpenDiscountDialogForProduct}>
                  {t("produits.add_discount")}
                </MenuItem>
              )}
            </Menu>
          </div>
        </TableCell>
      </TableRow>
      {product.variations.length > 0 && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="variations">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        {t("produits.attribute")}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        {t("produits.option")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        {t("produits.price")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        {t("produits.image")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        {t("produits.actions")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {product.variations.map((variation) => {
                      const variationHasDiscount =
                        parseFloat(variation.sale_price) <
                        parseFloat(variation.regular_price);
                      return (
                        <TableRow key={variation.id}>
                          <TableCell
                            sx={{
                              fontSize: { xl: "14px", lg: "14px", md: "14px" },
                              fontFamily: "Inter Variable",
                              letterSpacing: "-0.5px",
                              fontWeight: "500",
                              textAlign: "center",
                            }}
                          >
                            {variation.attributes.map((attr) => (
                              <div key={attr.id}>{attr.name}</div>
                            ))}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: { xl: "14px", lg: "14px", md: "14px" },
                              fontFamily: "Inter Variable",
                              letterSpacing: "-0.5px",
                              fontWeight: "500",
                              textAlign: "center",
                            }}
                          >
                            {variation.attributes.map((attr) => (
                              <div key={attr.id}>{attr.option}</div>
                            ))}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: { xl: "14px", lg: "14px", md: "14px" },
                              fontFamily: "Inter Variable",
                              letterSpacing: "-0.5px",
                              fontWeight: "500",
                              textAlign: "center",
                            }}
                          >
                            {variationHasDiscount ? (
                              <>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    marginRight: "8px",
                                  }}
                                >
                                  {parseFloat(variation.regular_price).toFixed(
                                    2
                                  )}
                                  €
                                </span>
                                <span style={{ color: "red" }}>
                                  {parseFloat(variation.sale_price).toFixed(2)}€
                                </span>
                              </>
                            ) : (
                              `${parseFloat(variation.price).toFixed(2)}€`
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: { xl: "14px", lg: "14px", md: "14px" },
                              fontFamily: "Inter Variable",
                              letterSpacing: "-0.5px",
                              fontWeight: "500",
                              textAlign: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100px",
                                cursor: "pointer",
                              }}
                            >
                              {variation.image && (
                                <img
                                  src={variation.image.src}
                                  alt={variation.name}
                                  style={{
                                    width: "50%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                  onClick={() =>
                                    handleImageClick(variation.image.src)
                                  }
                                />
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <div
                              inert={
                                discountDialogOpen || deleteDialogOpen
                                  ? "true"
                                  : undefined
                              }
                            >
                              <IconButton
                                aria-label="more options"
                                aria-controls={`variation-menu-${variation.id}`}
                                aria-haspopup="true"
                                onClick={(event) =>
                                  handleVariationMenuClick(event, variation.id)
                                }
                              >
                                <MoreVertIcon />
                              </IconButton>

                              <Menu
                                id={`variation-menu-${variation.id}`}
                                anchorEl={
                                  variationAnchorEl[variation.id] || null
                                }
                                keepMounted
                                open={Boolean(variationAnchorEl[variation.id])}
                                onClose={() =>
                                  handleVariationMenuClose(variation.id)
                                }
                                sx={{
                                  "& .MuiPaper-root": {
                                    borderRadius: "10px",
                                    textAlign: "center",
                                  },
                                  "& .MuiMenuItem-root": {
                                    fontFamily: "Inter Variable, sans-serif",
                                  },
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleEdit({
                                      ...product,
                                      mode: "edit-variation",
                                      variation,
                                    });
                                    handleVariationMenuClose(variation.id);
                                  }}
                                >
                                  {t("produits.edit")}
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleOpenDeleteDialog(
                                      "variation",
                                      variation
                                    );
                                    handleVariationMenuClose(variation.id);
                                  }}
                                >
                                  {t("produits.delete")}
                                </MenuItem>
                                {!variationHasDiscount && (
                                  <MenuItem
                                    onClick={() => {
                                      handleOpenDiscountDialog(variation);
                                      handleVariationMenuClose(variation.id);
                                    }}
                                  >
                                    {t("produits.add_discount")}
                                  </MenuItem>
                                )}
                                {variationHasDiscount && (
                                  <MenuItem
                                    onClick={() => {
                                      handleOpenDeleteDialog(
                                        "variation-discount",
                                        variation
                                      );
                                      handleVariationMenuClose(variation.id);
                                    }}
                                  >
                                    {t("produits.remove_discount")}
                                  </MenuItem>
                                )}
                              </Menu>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      <Modal open={!!selectedImage} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 300, md: 600 },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <img
            src={selectedImage}
            alt="Selected"
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
      </Modal>
      <DiscountDialog
        open={discountDialogOpen}
        onClose={() => {
          handleCloseDiscountDialog();
          setAnchorEl(null);
          setVariationAnchorEl({});
        }}
        siteNom={siteNom}
        selectedProduct={product}
        currentVariation={selectedVariation}
        loadProducts={loadProducts}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => {
          handleCloseDeleteDialog();
          setAnchorEl(null);
          setVariationAnchorEl({});
        }}
        itemType={itemType}
        siteNom={siteNom}
        selectedProduct={product}
        selectedItem={selectedVariation}
        loadProducts={loadProducts}
      />
    </>
  );
};

export default RowProduit;
