import React from "react";
import { Box, Typography } from "@mui/material";

const ScrapeResult = ({ scrapedData, onBack, onConfirm, t }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        mt: 4,
      }}
    >
      <Box sx={{ flex: 1, pr: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Inter Variable",
            fontWeight: "600",
            mb: 2,
          }}
        >
          {t("scrapePopup.productTitle")}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter Variable",
            fontWeight: "400",
            mb: 2,
          }}
        >
          {scrapedData.title}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Inter Variable",
            fontWeight: "600",
            mb: 2,
          }}
        >
          {t("scrapePopup.price")}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter Variable",
            fontWeight: "400",
            mb: 2,
          }}
        >
          {scrapedData.price}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Inter Variable",
            fontWeight: "600",
            mb: 2,
          }}
        >
          {t("scrapePopup.description")}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter Variable",
            fontWeight: "400",
            mb: 2,
          }}
        >
          {scrapedData.description}
        </Typography>
      </Box>
      <Box sx={{ flex: 1, pl: 2 }}>
        <img
          src={scrapedData.image}
          alt={t("scrapePopup.productAlt")}
          style={{ width: "100%", height: "auto", borderRadius: "10px" }}
        />
      </Box>
    </Box>
  );
};

export default ScrapeResult;
