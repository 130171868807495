import React from "react";
import { Box, LinearProgress } from "@mui/material";

function Loader({ loading }) {
  return (
    loading && (
      <Box
        sx={{ width: "100%", position: "fixed", top: 0, left: 0, zIndex: 9999 }}
      >
        <LinearProgress sx={{ backgroundColor: "#0042FF", height: "4px" }} />
      </Box>
    )
  );
}

export default Loader;
