import React from "react";
import { Card, Box, Typography, useTheme } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { format, parseISO } from "date-fns";

const StatCard = ({
  title,
  value,
  percentageChange,
  data,
  dateRange,
  tooltipContentLine,
  granularity,
}) => {
  const theme = useTheme();

  const percentageColor =
    percentageChange >= 0
      ? theme.palette.success.main
      : theme.palette.error.main;

  const formatXAxis = (tickItem) => {
    if (granularity === "annee") {
      return format(parseISO(tickItem), "MMM yyyy");
    } else {
      return format(parseISO(tickItem), "MMM dd");
    }
  };

  const formatTooltipLabel = (label) => {
    if (granularity === "annee") {
      return format(parseISO(label), "MMMM yyyy");
    } else {
      return format(parseISO(label), "MMMM dd, yyyy");
    }
  };

  return (
    <Card
      raised
      sx={{
        m: 2,
        p: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        boxShadow: "none",
        border: "2px solid #c6c7ce",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
            ml: 1,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontFamily: "Space Grotesk, sans serif",
              fontWeight: "1000",
              letterSpacing: { xs: "-1px", sm: "-1px", md: "-2px", lg: "-3px" },
              fontSize: { xs: "25px", sm: "25px", md: "30px", lg: "40px" },
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Inter Variable, sans-serif",
              fontWeight: "600",
              letterSpacing: { xs: "-1px", sm: "-1px", md: "-2px", lg: "-2px" },
              fontSize: { xs: "20px", sm: "20px", md: "25px", lg: "30px" },
            }}
          >
            {value}
          </Typography>
        </Box>
        <Typography
          variant="h4"
          sx={{
            color: percentageColor,
            fontWeight: "medium",
            fontFamily: "Inter Variable",
            letterSpacing: { xs: "-1px", sm: "-1px", md: "-2px", lg: "-2px" },
            fontSize: { xs: "20px", sm: "20px", md: "22px", lg: "28px" },
          }}
        >
          {percentageChange > 0
            ? `↑${percentageChange}%`
            : `↓${Math.abs(percentageChange)}%`}
        </Typography>
      </Box>
      <ResponsiveContainer height={250} width="100%">
        <LineChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid vertical={false} stroke={theme.palette.grey[300]} />
          <XAxis
            dataKey="date"
            tickFormatter={formatXAxis}
            tick={{
              fontSize: 14,
              fontFamily: "Inter Variable",
              fill: theme.palette.text.primary,
            }}
            axisLine={{ stroke: "#0042FF" }}
            tickLine={false}
            interval="preserveEnd"
            padding={{ right: 20 }}
          />
          <YAxis
            tickFormatter={(tick) =>
              tick.toLocaleString("fr-FR", { maximumFractionDigits: 0 })
            }
            tick={{
              fontSize: 14,
              fontFamily: "Inter Variable",
              fill: theme.palette.text.primary,
            }}
            axisLine={{ stroke: "transparent" }}
            tickLine={false}
            domain={[0, "dataMax + 10"]}
          />
          <Tooltip
            labelFormatter={formatTooltipLabel}
            contentStyle={{
              backgroundColor: theme.palette.background.default,
              borderRadius: "8px",
              fontFamily: "Inter Variable",
            }}
          />
          <Line
            type="monotone"
            dataKey={tooltipContentLine}
            stroke="#0042FF"
            strokeWidth={3}
            dot={false}
            activeDot={{ r: 2, fill: "#0042FF" }}
          />
        </LineChart>
      </ResponsiveContainer>
      <Box sx={{ marginTop: { xs: "1rem", md: "1rem" } }}>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          sx={{ mb: 1, fontFamily: "Inter Variable", textAlign: "center" }}
        >
          {dateRange}
        </Typography>
      </Box>
    </Card>
  );
};

export default StatCard;
