import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  Button,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  Card,
  CardContent,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";
import ReceiptIcon from "@mui/icons-material/Receipt";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { addHttpsPrefix } from "../../../shared/html/HtmlUtils";
import StatutSite from "../../../shared/statut/StatutSite";
import { useTranslation } from "react-i18next";
import {
  LITE_PLAN,
  PLANS,
  PREMIUM_PLAN,
} from "../../../shared/enumeration/PriceEnum";
import { getStatutAbonnement } from "../../../shared/statut/StatutAbonnement";

const AbonnementCard = ({
  abonnement,
  requestAnnulation,
  handleReouvrirAbonnement,
  generateInvoice,
  rerollSite,
  ajouterSite,
  supprimerSite,
}) => {
  const { t } = useTranslation();
  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const [customerId] = useState(profil?.customerId);

  const getAbonnementLabel = (priceId) => {
    return PLANS[priceId]?.label || "Abonnement";
  };

  const canAddSite = (abonnement) => {
    const plan = PLANS[abonnement.priceId];
    return plan ? abonnement.sites.length < plan.maxSites : false;
  };

  const isPremiumPlan = (priceId) => {
    return priceId === PREMIUM_PLAN.priceId;
  };

  const canDeleteSite = (abonnement) => {
    return abonnement.sites.length > 1;
  };

  return (
    <Card
      sx={{
        marginBottom: 2,
        boxShadow: 3,
        borderColor: "grey.300",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "16px",
      }}
    >
      <CardContent>
        <Box sx={{ pl: 1 }}>
          <Box sx={{ marginBottom: 1 }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "600",
                fontFamily: "Inter Variable",
                fontSize: "16px",
              }}
            >
              {t("abonnement.type")}
            </Typography>
            <Typography sx={{ marginBottom: 1, fontFamily: "Inter Variable" }}>
              {getAbonnementLabel(abonnement.priceId)}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 1 }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "600",
                fontFamily: "Inter Variable",
                fontSize: "16px",
              }}
            >
              {t("abonnement.debut")}
            </Typography>
            <Typography sx={{ marginBottom: 1, fontFamily: "Inter Variable" }}>
              {new Date(abonnement.periodeDebut).toLocaleDateString("fr-FR")}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 1 }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "600",
                fontFamily: "Inter Variable",
                fontSize: "16px",
              }}
            >
              {t("abonnement.fin")}
            </Typography>
            <Typography sx={{ marginBottom: 1, fontFamily: "Inter Variable" }}>
              {new Date(abonnement.periodeFin).toLocaleDateString("fr-FR")}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 1 }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "600",
                fontFamily: "Inter Variable",
                fontSize: "16px",
              }}
            >
              {t("abonnement.montant")}
            </Typography>
            <Typography sx={{ marginBottom: 1, fontFamily: "Inter Variable" }}>
              {abonnement.prix / 100} {abonnement.monnaie}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 1 }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "600",
                fontFamily: "Inter Variable",
                fontSize: "16px",
                marginBottom: 1,
              }}
            >
              {t("abonnement.statutabonnement")}
            </Typography>
            {getStatutAbonnement(abonnement.status, t)}
          </Box>
        </Box>
        <Box align="center">
          {abonnement.status === "canceled" ? (
            <IconButton
              onClick={() =>
                handleReouvrirAbonnement(abonnement.abonnementId, customerId)
              }
              color="primary"
            >
              <ReplayIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => requestAnnulation(abonnement.abonnementId)}
              color="secondary"
            >
              <DeleteIcon />
            </IconButton>
          )}
          <IconButton
            onClick={() => generateInvoice(abonnement.abonnementId)}
            color="default"
          >
            <ReceiptIcon />
          </IconButton>
        </Box>

        {(abonnement.status === "active" || abonnement.status === "trialing") &&
          canAddSite(abonnement) && (
            <Tooltip
              title={t("abonnement.ajouterSite")}
              enterDelay={500}
              PopperProps={{
                sx: {
                  "& .MuiTooltip-tooltip": {
                    fontSize: "1rem",
                    fontFamily: "Inter Variable",
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => ajouterSite(abonnement.abonnementId)}
                  sx={{
                    width: "50%",
                    background: "#0042ff",
                    color: "white",
                    fontSize: {
                      lg: "16px",
                      md: "16px",
                      sm: "14px",
                      xs: "12px",
                    },
                    fontFamily: "Space Grotesk, sans serif",
                    fontWeight: "600",
                    textTransform: "none",
                    boxShadow: "none",
                    borderRadius: "10px",
                    "&:hover": {
                      background: "#0033c1",
                      color: "white",
                      boxShadow: "none",
                    },
                  }}
                >
                  {t("abonnement.ajouterSite")}
                </Button>
              </Box>
            </Tooltip>
          )}

        {abonnement.sites.length > 0 && (
          <Box margin={3}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "600",
                fontFamily: "Inter Variable",
                fontSize: "16px",
                marginBottom: 1,
              }}
            >
              {t("abonnement.sites")}
            </Typography>
            <Table size="small" aria-label="sites">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: {
                        xl: "14px",
                        lg: "14px",
                        md: "14px",
                      },
                      fontFamily: "Inter Variable",
                      letterSpacing: "-0.5px",
                      fontWeight: "600",
                    }}
                  >
                    {t("abonnement.siteUrl")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontSize: {
                        xl: "14px",
                        lg: "14px",
                        md: "14px",
                      },
                      fontFamily: "Inter Variable",
                      letterSpacing: "-0.5px",
                      fontWeight: "600",
                    }}
                  >
                    {t("abonnement.siteName")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontSize: {
                        xl: "14px",
                        lg: "14px",
                        md: "14px",
                      },
                      fontFamily: "Inter Variable",
                      letterSpacing: "-0.5px",
                      fontWeight: "600",
                    }}
                  >
                    {t("abonnement.statutduSite")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontSize: {
                        xl: "14px",
                        lg: "14px",
                        md: "14px",
                      },
                      fontFamily: "Inter Variable",
                      letterSpacing: "-0.5px",
                      fontWeight: "600",
                    }}
                  >
                    {t("abonnement.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {abonnement.sites.map((site) => (
                  <TableRow key={site._id}>
                    <TableCell>
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "black",
                            marginRight: "8px",
                            fontSize: {
                              xl: "14px",
                              lg: "14px",
                              md: "14px",
                            },
                            fontFamily: "Inter Variable",
                            letterSpacing: "-0.5px",
                            fontWeight: "500",
                            textDecoration: "none",
                          }}
                        >
                          {site?.url}
                        </Typography>
                        <IconButton
                          component="a"
                          href={addHttpsPrefix(site?.url)}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{ padding: "0" }}
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body2"
                        sx={{
                          color: "black",
                          fontSize: {
                            xl: "14px",
                            lg: "14px",
                            md: "14px",
                          },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "500",
                        }}
                      >
                        {site.nom}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <StatutSite site={site} />
                    </TableCell>
                    <TableCell align="center">
                      {(abonnement.status === "active" ||
                        abonnement.status === "trialing") &&
                        site.etat === "actif" && (
                          <Tooltip
                            title={
                              abonnement?.priceId === LITE_PLAN.priceId &&
                              site.nombreReroll >= 2
                                ? t("abonnement.rerollDisabledTooltip")
                                : t("abonnement.rerollTooltip")
                            }
                            enterDelay={500}
                            PopperProps={{
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  fontSize: "1rem",
                                  fontFamily: "Inter Variable",
                                },
                              },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                            >
                              <span
                                style={{
                                  display: "inline-block",
                                  width: "100%",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    rerollSite(
                                      abonnement.abonnementId,
                                      site.nom
                                    )
                                  }
                                  disabled={
                                    abonnement.priceId === LITE_PLAN.priceId &&
                                    site.nombreReroll >= 2
                                  }
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "70%",
                                    background: "#0042ff",
                                    color: "white",
                                    fontSize: {
                                      lg: "14px",
                                      md: "14px",
                                      sm: "12px",
                                      xs: "12px",
                                    },
                                    fontFamily: "Inter Variable",
                                    fontWeight: "500",
                                    letterSpacing: "-0.25px",
                                    textTransform: "none",
                                    borderRadius: "10px",
                                    border: "1px solid #276CFA",
                                    outline: "1px solid #0646C6",
                                    "&:hover": {
                                      backgroundColor: "#1459e2",
                                      boxShadow: "none",
                                    },
                                  }}
                                >
                                  {t("abonnement.reroll")}
                                  <ReplayIcon
                                    sx={{ fontSize: "20px", ml: "3px" }}
                                  />
                                </Button>
                              </span>
                            </Box>
                          </Tooltip>
                        )}

                      {isPremiumPlan(abonnement.priceId) &&
                        canDeleteSite(abonnement) && (
                          <Tooltip
                            title={t("abonnement.supprimerSite")}
                            enterDelay={500}
                            PopperProps={{
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  fontSize: "1rem",
                                  fontFamily: "Inter Variable",
                                },
                              },
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={() => supprimerSite(site.nom)}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "50%",
                                background: "#878CA7",
                                color: "white",
                                mt: 2,
                                mb: 2,
                                fontSize: {
                                  lg: "14px",
                                  md: "14px",
                                  sm: "12px",
                                  xs: "12px",
                                },
                                fontFamily: "Inter Variable",
                                fontWeight: "500",
                                letterSpacing: "-0.25px",
                                textTransform: "none",
                                borderRadius: "10px",
                                border: "1px solid #878CA7",
                                outline: "1px solid #878CA7",
                                "&:hover": {
                                  backgroundColor: "#6c6e80",
                                  boxShadow: "none",
                                },
                              }}
                            >
                              {t("abonnement.supprimerSite")}
                              <DeleteIcon
                                sx={{
                                  fontSize: "20px",
                                  ml: "3px",
                                }}
                              />
                            </Button>
                          </Tooltip>
                        )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default AbonnementCard;
