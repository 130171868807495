import React, { useState } from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import { Typography, CircularProgress, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import { addHttpsPrefix } from "../../shared/html/HtmlUtils";

const PageSelector = ({
  pages,
  selectedPage,
  onPageChange,
  onNewPage,
  onDeletePage,
  onAddMenuItem,
  loadingPopover,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedType, setSelectedType] = useState("general");

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handlePageSelect = (pageId) => {
    const newPage = pages.find((page) => page._id === pageId);
    onPageChange(newPage);
    handlePopoverClose();
  };

  const filterPagesByType = (type) => {
    return pages.filter((page) => page.type === type);
  };

  const open = Boolean(anchorEl);
  const buttonWidth = anchorEl ? anchorEl.clientWidth : undefined;

  const filteredPages = filterPagesByType(selectedType);

  return (
    <>
      <Button
        onClick={handlePopoverOpen}
        variant="outlined"
        endIcon={<AddIcon />}
        sx={{
          background: "white",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          color: "#000",
          fontSize: {
            lg: "14px",
            md: "14px",
            sm: "12px",
            xs: "12px",
          },
          fontFamily: "Inter Variable",
          fontWeight: "500",
          letterSpacing: "-0.25px",
          textTransform: "none",
          borderRadius: "10px",
          border: "1px solid #276CFA",
          outline: "1px solid #0646C6",
          "&:hover": {
            backgroundColor: "#f0f0f0",
            boxShadow: "none",
          },
        }}
      >
        {selectedPage?.nom || t("headerVisualBuilder.selectPage")}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            mt: "8px",
            width: buttonWidth,
            display: "flex",
            flexDirection: "row",
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
            borderRadius: "15px",
            overflow: "hidden",
          },
        }}
      >
        {loadingPopover ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {/* Liste des types de pages à gauche */}
            <List
              sx={{
                width: "30%",
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                maxHeight: "300px",
                overflow: "auto",
              }}
            >
              <ListItemButton
                selected={selectedType === "general"}
                onClick={() => setSelectedType("general")}
              >
                <ListItemText primary={t("headerVisualBuilder.generalPage")} />
              </ListItemButton>
              <ListItemButton
                selected={selectedType === "produit"}
                onClick={() => setSelectedType("produit")}
              >
                <ListItemText primary={t("headerVisualBuilder.productPage")} />
              </ListItemButton>
            </List>

            {/* Liste des pages associées à droite */}
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "70%" }}
            >
              {/* Bouton pour ajouter une page (désactivé pour les pages produit) */}
              {selectedType === "general" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "8px",
                    borderBottom: "1px solid #d8d8d8",
                  }}
                >
                  <Button
                    onClick={() => {
                      handlePopoverClose();
                      onNewPage();
                    }}
                    variant="text"
                    startIcon={<AddIcon />}
                    sx={{
                      fontSize: "12px",
                      textTransform: "none",
                      color: "#1460F9",
                      "&:hover": {
                        backgroundColor: "transparent",
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {t("headerVisualBuilder.addPage")}
                  </Button>
                </Box>
              )}

              {/* Liste des pages */}
              {filteredPages.length > 0 ? (
                <List
                  sx={{
                    width: "100%",
                    maxHeight: "250px",
                    overflow: "auto",
                    bgcolor: "#ECEDF5",
                  }}
                >
                  {filteredPages.map((page) => (
                    <ListItemButton
                      key={page._id}
                      onClick={() => handlePageSelect(page._id)}
                      sx={{
                        borderBottom: "1px solid #d8d8d8",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        "&:hover": {
                          backgroundColor: "#E0E0E0",
                          color: "#000",
                        },
                        "&.Mui-selected": {
                          bgcolor: "#ECEDF5",
                          "&:hover": {
                            bgcolor: "#D0D0D0",
                            color: "black",
                          },
                        },
                      }}
                    >
                      <Box>
                        <ListItemText
                          primary={page.nom}
                          primaryTypographyProps={{
                            sx: {
                              fontFamily: "Inter Variable",
                              fontWeight: "500",
                              letterSpacing: "-0.5px",
                              fontSize: {
                                lg: "14px",
                                md: "14px",
                                sm: "12px",
                                xs: "12px",
                              },
                            },
                          }}
                        />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: "Inter Variable",
                            fontSize: {
                              lg: "12px",
                              md: "12px",
                              sm: "10px",
                              xs: "10px",
                            },
                            ml: 2,
                          }}
                        >
                          {page.url}
                        </Typography>
                      </Box>
                      <Box>
                        <Tooltip title={t("headerVisualBuilder.addToMenu")}>
                          <span>
                            <IconButton
                              edge="end"
                              color="primary"
                              aria-label="add-to-menu"
                              onClick={(e) => {
                                e.stopPropagation();
                                onAddMenuItem(
                                  page.nom,
                                  addHttpsPrefix(page.url)
                                );
                              }}
                              size="small"
                            >
                              <MenuIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title={t("headerVisualBuilder.deletePage")}>
                          <span>
                            <IconButton
                              edge="end"
                              color="secondary"
                              aria-label="delete"
                              onClick={(e) => {
                                e.stopPropagation();
                                onDeletePage(page._id);
                              }}
                              size="small"
                              disabled={!page.supprimable}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                    </ListItemButton>
                  ))}
                </List>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    padding: "16px",
                  }}
                >
                  <em>{t("headerVisualBuilder.noPagesAvailable")}</em>
                </Box>
              )}
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default PageSelector;
