import { useCallback, useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import PasswordPopup from "../../../shared/popup/PasswordPopup";
import { API_SERVER_ENDPOINT } from "../../../shared/api/ConstantesEndpoints";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";
import Loader from "../../../shared/loader/Loader";
import ToastError from "../../../shared/toast/ToastError";

function DetailsInformations() {
  const { t } = useTranslation();
  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [customerId] = useState(profil?.customerId);
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const [loadingInformationsPersonnelles, setLoadingInformationsPersonnelles] =
    useState(false);
  const [loadingMail, setLoadingMail] = useState(false);

  const [error, setError] = useState(null);
  const [showSuccessUtilisateur, setShowSuccessUtilisateur] = useState(false);
  const [showSuccessMail, setShowSuccessMail] = useState(false);
  const [passwordUpdateSuccess, setPasswordUpdateSuccess] = useState(false);

  const [isPasswordPopupOpen, setIsPasswordPopupOpen] = useState(false);
  const handleOpenPasswordPopup = () => {
    setIsPasswordPopupOpen(true);
  };
  const handleClosePasswordPopup = () => {
    setIsPasswordPopupOpen(false);
  };

  const [utilisateurInfo, setUtilisateurInfo] = useState({
    prenom: "",
    nom: "",
    dateNaissance: "",
    telephone: "",
    email: "",
  });

  const [errorFields, setErrorFields] = useState({
    prenom: "",
    nom: "",
    dateNaissance: "",
    telephone: "",
    email: "",
  });

  const handleChange = (value, nomChamp) => {
    setError(null);

    setErrorFields((prev) => ({ ...prev, [nomChamp]: "" }));

    setUtilisateurInfo({
      ...utilisateurInfo,
      [nomChamp]: value,
    });
  };

  const handleSaveInformationsPersonelles = async () => {
    setLoadingInformationsPersonnelles(true);

    if (validateInformationsPersonnelles()) {
      setShowSuccessUtilisateur(false);
      resetErrorFields();
      updateInformationsUtilisateur(
        {
          prenom: utilisateurInfo.prenom,
          nom: utilisateurInfo.nom,
          dateNaissance: utilisateurInfo.dateNaissance,
          telephone: utilisateurInfo.telephone,
        },
        "utilisateur"
      );
    }

    setLoadingInformationsPersonnelles(false);
  };

  const validateInformationsPersonnelles = () => {
    let isValid = true;
    let newErrorMessages = {
      prenom: "",
      nom: "",
      dateNaissance: "",
      telephone: "",
    };

    // Validation du téléphone
    const telephoneRegex =
      /^(?:(?:\+|00)33[\s.-]?(?:\(0\)[\s.-]?)?|(?:0[\s.-]?))(?:(?:(?:[1-9]\d?)|(?:[2689]\d{2})|(?:[37]\d{3})|(?:[45]\d{4})|(?:[123456789](?:\d{0,5}))))(?:[\s.-]?\d{2}){4}$/;
    if (
      utilisateurInfo.telephone &&
      !telephoneRegex.test(utilisateurInfo.telephone.trim())
    ) {
      newErrorMessages.telephone = t("parametre.phone_invalid");
      isValid = false;
    }

    setErrorFields(newErrorMessages);
    return isValid;
  };

  const handleSaveMail = async () => {
    setLoadingMail(true);

    if (validateEmail()) {
      setShowSuccessMail(false);
      resetErrorFields();
      updateInformationsUtilisateur({ email: utilisateurInfo.email }, "email");
    }

    setLoadingMail(false);
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(utilisateurInfo.email)) {
      setErrorFields((prev) => ({
        ...prev,
        email: t("parametre.email_invalid"),
      }));
      return false;
    }
    return true;
  };

  const resetErrorFields = () => {
    setErrorFields({
      prenom: "",
      nom: "",
      dateNaissance: "",
      telephone: "",
      email: "",
    });
  };

  const getUtilisateurInformations = useCallback(async () => {
    setLoadingGlobal(true);
    try {
      const response = await axios.get(`${API_SERVER_ENDPOINT}/utilisateur`, {
        params: {
          customerId: customerId,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      setUtilisateurInfo(response.data);
    } catch (error) {
      handleError(error.message);
    } finally {
      setLoadingGlobal(false);
    }
  }, [customerId, setLoadingGlobal, setUtilisateurInfo]);

  useEffect(() => {
    if (customerId) {
      getUtilisateurInformations();
    }
  }, [customerId, getUtilisateurInformations]);

  async function updateInformationsUtilisateur(champs, operation) {
    try {
      await axios.put(
        `${API_SERVER_ENDPOINT}/utilisateur/${customerId}`,
        champs,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (operation === "utilisateur") {
        setShowSuccessUtilisateur(true);
      } else if (operation === "email") {
        setShowSuccessMail(true);
      }

      setTimeout(() => {
        if (operation === "utilisateur") {
          setShowSuccessUtilisateur(false);
        } else if (operation === "email") {
          setShowSuccessMail(false);
        }
      }, 3000);
    } catch (error) {
      const message = error.response?.data?.error || error.message;
      handleError(message);
    }
  }

  const handlePasswordUpdateSuccess = () => {
    setPasswordUpdateSuccess(true);

    setTimeout(() => {
      setPasswordUpdateSuccess(false);
    }, 3000);
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const formatDateNaissance = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const textFieldStyles = {
    height: "50px",
    borderRadius: "12px",
    backgroundColor: "white",
    border: "1px solid #E3E3E3",
    fontWeight: "500",
    fontFamily: "Inter Variable",
    fontSize: "14px",
    padding: "0%",
    letterSpacing: "-0.25px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    input: {
      color: "text.primary",
    },
    "&::placeholder": {
      color: "#000000",
      opacity: 0.8,
    },
  };

  return (
    <div
      style={{
        flexGrow: 1,
        padding: "2%",
        backgroundColor: "#f5f8fa",
        minHeight: "100vh",
      }}
    >
      <Paper
        sx={{
          margin: "0px",
          overflowX: "auto",
          boxShadow: "none",
          backgroundColor: "#f5f8fa",
        }}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: { xs: "26px", sm: "60px" },
            fontFamily: "Space Grotesk, sans serif",
            fontWeight: "1000",
            letterSpacing: { xs: "-2px", sm: "-5px", md: "-5px", lg: "-5px" },
            ml: "2%",
            mt: { xs: "5%", sm: 0 },
          }}
        >
          {t("parametre.settings")}
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: "16px",
            fontFamily: "Inter Variable, sans-serif",
            fontWeight: "500",
            letterSpacing: "-0.75px",
            mb: "3%",
            mt: "0%",
            ml: "2%",
          }}
        >
          {t("parametre.manage_your_info")}
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: { xs: "26px", sm: "40px" },
            fontFamily: "Space Grotesk, sans serif",
            fontWeight: "1000",
            letterSpacing: { xs: "-2px", sm: "-5px", md: "-3px", lg: "-3px" },
            ml: "2%",
            mt: { xs: "5%", sm: 0 },
            mb: 3,
          }}
        >
          {t("parametre.personal_info")}
        </Typography>
        <Typography
          sx={{
            display: { xs: "none", sm: "block" },
            color: "black",
            fontSize: "15px",
            fontFamily: "Inter Variable",
            fontWeight: "400",
            mb: "2%",
            mt: "1%",
            ml: "2%",
          }}
        >
          {t("parametre.personal_info_desc")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            gap: 2,
            width: isMobile ? "90%" : "20%",
            ml: "2%",
            "& .MuiTextField-root": {
              marginBottom: { xs: 3, sm: 0 },
            },
          }}
        >
          <TextField
            placeholder={t("parametre.first_name")}
            variant="outlined"
            value={utilisateurInfo.prenom ? utilisateurInfo.prenom : ""}
            autoComplete="off"
            onChange={(event) => {
              handleChange(event.target.value, "prenom");
            }}
            fullWidth={isMobile}
            InputProps={{
              sx: textFieldStyles,
            }}
          />
          <TextField
            placeholder={t("parametre.last_name")}
            variant="outlined"
            value={utilisateurInfo.nom ? utilisateurInfo.nom : ""}
            autoComplete="off"
            onChange={(event) => {
              handleChange(event.target.value, "nom");
            }}
            fullWidth={isMobile}
            InputProps={{
              sx: textFieldStyles,
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              placeholder={t("parametre.birth_date")}
              value={
                utilisateurInfo.dateNaissance
                  ? dayjs(formatDateNaissance(utilisateurInfo.dateNaissance))
                  : null
              }
              onChange={(newValue) => {
                handleChange(newValue, "dateNaissance");
              }}
              sx={{
                width: isMobile ? "100%" : "auto",
                "& .MuiInputBase-root": {
                  height: "50px",
                  borderRadius: "12px",
                  backgroundColor: "white",
                  border: "1px solid #E3E3E3",
                  fontWeight: "500",
                  fontFamily: "Inter Variable",
                  fontSize: "14px",
                  letterSpacing: "-0.25px",
                  padding: "0",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiInputBase-input": {
                  color: "text.primary",
                  padding: "0 14px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                },
                "& .MuiInputAdornment-root": {
                  height: "100%",
                  maxHeight: "none",
                  "& .MuiButtonBase-root": {
                    color: "#000000",
                    mr: "3%",
                  },
                },
                "& .MuiFormLabel-root": {
                  color: "#0042ff",
                  fontFamily: "Inter Variable",
                  fontSize: "14px",
                  fontWeight: 500,
                  transform: "translate(14px, 16px) scale(1)",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000000",
                },
                "& .MuiFormLabel-root.MuiFormLabel-shrink": {
                  transform: "translate(14px, -6px) scale(0.75)",
                  backgroundColor: "white",
                  padding: "0 4px",
                },
              }}
            />
          </LocalizationProvider>

          <TextField
            error={!!errorFields.telephone}
            placeholder={t("parametre.phone")}
            variant="outlined"
            value={utilisateurInfo.telephone ? utilisateurInfo.telephone : ""}
            autoComplete="off"
            onChange={(event) => {
              handleChange(event.target.value, "telephone");
            }}
            fullWidth={isMobile}
            InputProps={{
              sx: textFieldStyles,
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            mt: { xs: 0, sm: 5 },
          }}
        >
          <LoadingButton
            onClick={handleSaveInformationsPersonelles}
            endIcon={
              showSuccessUtilisateur ? <CheckIcon /> : <ArrowForwardIosIcon />
            }
            loading={loadingInformationsPersonnelles}
            loadingPosition="end"
            variant="contained"
            color={showSuccessUtilisateur ? "success" : "primary"}
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: {
                lg: "16px",
                md: "16px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              ml: "2%",
              mb: "2%",
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
            }}
          >
            <span>{t("parametre.save")}</span>
          </LoadingButton>
        </Box>
        {/* Bloc mail */}
        <Typography
          sx={{
            color: "black",
            fontSize: { xs: "26px", sm: "40px" },
            fontFamily: "Space Grotesk, sans serif",
            fontWeight: "1000",
            letterSpacing: { xs: "-2px", sm: "-5px", md: "-3px", lg: "-3px" },
            ml: "2%",
            mt: { xs: "5%", sm: 0 },
            mb: 3,
          }}
        >
          {t("parametre.mail")}
        </Typography>
        <Typography
          sx={{
            display: { xs: "none", sm: "block" },
            color: "black",
            fontSize: "15px",
            fontFamily: "Inter Variable",
            fontWeight: "400",
            mb: "1.5%",
            mt: "1%",
            ml: "2%",
          }}
        >
          {t("parametre.mail_desc")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "left",
            ml: "2%",
            gap: 2,
            "& .MuiTextField-root": {
              marginBottom: { xs: 3, sm: 0 },
            },
          }}
        >
          <TextField
            error={!!errorFields.email}
            placeholder={t("parametre.mail")}
            variant="outlined"
            value={utilisateurInfo.email ? utilisateurInfo.email : ""}
            autoComplete="off"
            onChange={(event) => {
              handleChange(event.target.value, "email");
            }}
            fullWidth={isMobile}
            sx={{ width: isMobile ? "100%" : "20%" }}
            InputProps={{
              sx: textFieldStyles,
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            mt: { xs: 0, sm: 5 },
          }}
        >
          <LoadingButton
            onClick={handleSaveMail}
            endIcon={showSuccessMail ? <CheckIcon /> : <ArrowForwardIosIcon />}
            loading={loadingMail}
            loadingPosition="end"
            variant="contained"
            color={showSuccessMail ? "success" : "primary"}
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: {
                lg: "16px",
                md: "16px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              ml: "2%",
              mb: "2%",
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
            }}
          >
            <span>{t("parametre.save")}</span>
          </LoadingButton>
        </Box>
        {/* Bloc mot de passe */}
        <Typography
          sx={{
            color: "black",
            fontSize: { xs: "26px", sm: "40px" },
            fontFamily: "Space Grotesk, sans serif",
            fontWeight: "1000",
            letterSpacing: { xs: "-2px", sm: "-5px", md: "-3px", lg: "-3px" },
            ml: "2%",
            mt: { xs: "5%", sm: 0 },
            mb: 3,
          }}
        >
          {t("parametre.password")}
        </Typography>
        <Typography
          sx={{
            display: { xs: "none", sm: "block" },
            color: "black",
            fontSize: "15px",
            fontFamily: "Inter Variable",
            fontWeight: "400",
            mb: "3%",
            mt: "1%",
            ml: "2%",
          }}
        >
          {t("parametre.password_desc")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "left",
            gap: 2,
            "& .MuiTextField-root": {
              marginBottom: { xs: 3, sm: 0 },
            },
          }}
        >
          <Button
            variant={passwordUpdateSuccess ? "contained" : "outlined"}
            onClick={handleOpenPasswordPopup}
            color={passwordUpdateSuccess ? "success" : "primary"}
            startIcon={passwordUpdateSuccess ? <CheckIcon /> : null}
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: {
                lg: "16px",
                md: "16px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              ml: "2%",
              mb: "2%",
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
            }}
          >
            {t("parametre.change_password")}
          </Button>
          <Dialog
            sx={{
              "& .MuiDialog-paper": {
                padding: "24px",
                width: "100%",
                maxWidth: "500px",
              },
            }}
            open={isPasswordPopupOpen}
            onClose={handleClosePasswordPopup}
          >
            <PasswordPopup
              customerId={customerId}
              handleClosePasswordPopup={handleClosePasswordPopup}
              handleSuccess={handlePasswordUpdateSuccess}
            />
          </Dialog>
        </Box>

        <ToastError
          open={error !== null}
          onClose={() => setError(null)}
          message={error}
        />
      </Paper>
      <Loader loading={loadingGlobal}></Loader>
    </div>
  );
}

export default DetailsInformations;
