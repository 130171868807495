import React from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Banner() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "20px",
        color: "#fff",
        textAlign: "center",
        minHeight: "800px",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          minHeight: {
            xl: "800px",
            lg: "800px",
            md: "600px",
            sm: "500px",
            xs: "500px",
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            textAlign: { xs: "center", md: "left" },
            ml: { xl: "15%", lg: "10%", md: "10%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Box
              sx={{
                padding: "5px 10px",
                backgroundColor: "#022aa9",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  mr: 1,
                  fontFamily: "Space Grotesk, sans-serif",
                }}
              >
                {t("banner.poweredBy")}
              </Typography>
              <img
                src="/images/open-ai-logo.png"
                alt="OpenAI logo"
                style={{ height: "20px" }}
              />
            </Box>
          </Box>
          <Typography
            sx={{
              color: "white",
              fontSize: {
                xl: "60px",
                lg: "50px",
                md: "40px",
                sm: "30px",
                xs: "30px",
              },
              fontFamily: "Space Grotesk, sans-serif",
              letterSpacing: {
                lg: "-3px",
                md: "-2.5px",
                sm: "-2.5px",
                xs: "-2.5px",
              },
              fontWeight: "600",
            }}
          >
            {t("banner.yourEcommerceSite")}
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: {
                xl: "60px",
                lg: "50px",
                md: "40px",
                sm: "30px",
                xs: "30px",
              },
              fontFamily: "Space Grotesk, sans-serif",
              letterSpacing: {
                lg: "-3px",
                md: "-2.5px",
                sm: "-2.5px",
                xs: "-2.5px",
              },
              fontWeight: "600",
              mt: { xs: "0", md: "-20px" },
            }}
          >
            {t("banner.in10Minutes")}
          </Typography>
          <Button
            component={RouterLink}
            to="/informations"
            sx={{
              mt: 2,
              bgcolor: "#124ae5",
              color: "white",
              fontSize: { xs: "15px", md: "20px" },
              fontFamily: "Inter Variable",
              letterSpacing: "-0.5px",
              fontWeight: "700",
              textTransform: "none",
              boxShadow: "none",
              borderRadius: "15px",
              width: { xs: "280px", md: "380px", lg: "500px" },
              height: { xs: "40px", md: "60px" },
              "&:hover": {
                bgcolor: "#03247f",
                boxShadow: "none",
              },
            }}
            type="button"
          >
            {t("banner.startFreeTrial")}
          </Button>
          <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
            <StarIcon sx={{ color: "gold" }} />
            <StarIcon sx={{ color: "gold" }} />
            <StarIcon sx={{ color: "gold" }} />
            <StarIcon sx={{ color: "gold" }} />
            <StarIcon sx={{ color: "gold" }} />
            <Typography
              variant="h6"
              component="span"
              sx={{ ml: 1, fontFamily: "Inter Variable" }}
            >
              {t("banner.rating")}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <Box
            component="img"
            src="/images/phone_banner.png"
            alt="Phone preview"
            sx={{
              width: "100%",
              maxWidth: "500px",
              height: "auto",
              marginBottom: {
                xs: "-6%",
                sm: "-3%",
                md: "-27.5%",
                lg: "-3.5%",
                xl: "-2%",
              },
              display: "block",
              verticalAlign: "bottom",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Banner;
