import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "@fontsource-variable/inter";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { HexColorPicker } from "react-colorful";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";

const AttributeEditor = ({
  selectedModule,
  instancesData,
  instanceId,
  attributeModifications,
  onBack,
  onUpdateAttributes,
  products,
  templateId,
}) => {
  const { t } = useTranslation();
  const [attributeValues, setAttributeValues] = useState({});
  const [colorPickersVisible, setColorPickersVisible] = useState({});
  const [isLoading, setLoading] = useState(false);

  const calculateInitialAttributeValues = useCallback(
    (module, instance, modifications) => {
      if (!module?.selectedElement || !instance) return {};

      return module.selectedElement.attributes.reduce((acc, attr) => {
        const templateAttr = instance.elements
          .find((el) => el.nom === module.selectedElement.nom)
          ?.attributes.find((a) => a.nom === attr.nom);
        const key = `${module.moduleId}_${instanceId}_${module.selectedElement.nom}_${attr.nom}`;

        acc[attr.nom] =
          modifications[key] ??
          templateAttr?.valeurDefaut ??
          attr.valeurBase ??
          "";
        return acc;
      }, {});
    },
    [instanceId]
  );

  useEffect(() => {
    const instance = instancesData.find(
      (instance) =>
        instance.moduleId === selectedModule?.moduleId &&
        instance._id === instanceId
    );

    const initialAttributeValues = calculateInitialAttributeValues(
      selectedModule,
      instance,
      attributeModifications
    );

    setAttributeValues(initialAttributeValues);
  }, [
    selectedModule,
    instancesData,
    attributeModifications,
    instanceId,
    calculateInitialAttributeValues,
  ]);

  const handleValueChange = (name, value) => {
    const newAttributeValues = { ...attributeValues, [name]: value };
    setAttributeValues(newAttributeValues);
    onUpdateAttributes(
      selectedModule.moduleId,
      instanceId,
      selectedModule.selectedElement.nom,
      name,
      value
    );
  };

  const toggleColorPicker = (attrName) => {
    setColorPickersVisible((prev) => ({
      ...prev,
      [attrName]: !prev[attrName],
    }));
  };

  const handleGenerateText = async (attributeName) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/generer-text-ia/module/${selectedModule.moduleId}`,
        {
          templateId: templateId,
          attributeName,
        }
      );

      const generatedText = response.data.reponse;
      handleValueChange(attributeName, generatedText);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const renderInputField = (attr) => {
    switch (attr.type) {
      case "product":
        return (
          <Select
            label={attr.displayName}
            value={attributeValues[attr.nom] || ""}
            onChange={(e) => handleValueChange(attr.nom, e.target.value)}
            fullWidth
            sx={{
              display: "flex",
              mb: "3%",
              justifyContent: "center",
              width: "100%",
              bgcolor: "#ECEDF5",
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.55px",
              fontSize: {
                lg: "14px",
                md: "14px",
                sm: "12px",
                xs: "12px",
              },
              color: "black",
              borderRadius: "10px",
              "& .MuiSelect-select": {
                borderRadius: "20px",
                textAlign: "center",
                padding: "10px 0",
                fontStyle: "normal !important",
                border: "none",
              },
              "&:before, &:after": {
                border: "none",
              },
              "& .MuiSvgIcon-root": {
                color: "black",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiPaper-root": {
                bgcolor: "#ECEDF5",
                borderRadius: "10px",
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  mt: "-0.5%",
                  bgcolor: "#ECEDF5",
                  boxShadow: "none",
                  borderBottomRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  "& .MuiMenuItem-root": {
                    fontFamily: "Inter Variable",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                    justifyContent: "center",
                    width: "100%",
                    textAlign: "center",
                    fontStyle: "normal !important",
                    "&:hover": {
                      background: "#0033c1",
                      fontWeight: "500",
                      color: "white",
                    },
                    "&.Mui-selected": {
                      bgcolor: "#ECEDF5",
                      "&:hover": {
                        bgcolor: "#D0D0D0",
                        color: "black",
                      },
                    },
                  },
                },
              },
            }}
          >
            {products?.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        );
      case "image":
        return (
          <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
            <Typography
              sx={{
                color: "text.primary",
                fontSize: {
                  lg: "12px",
                  md: "12px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                letterSpacing: "-0.25px",
                fontWeight: "600",
                textAlign: "center",
                marginTop: "2%",
                ml: "2%",
                marginBottom: "3%",
              }}
            >
              {attr.displayName}
            </Typography>
            <TextField
              value={attributeValues[attr.nom] || ""}
              onChange={(e) => handleValueChange(attr.nom, e.target.value)}
              variant="outlined"
              fullWidth
              multiline
              minRows={1}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  backgroundColor: "white",
                  border: "1px solid #E3E3E3",
                  fontWeight: "500",
                  fontFamily: "Inter Variable",
                  fontSize: "14px",
                  padding: "3%",
                  letterSpacing: "-0.25px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  input: {
                    color: "text.primary",
                  },
                },
              }}
            />
          </Box>
        );
      case "text":
        return (
          <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
            <Typography
              sx={{
                color: "text.primary",
                fontSize: {
                  lg: "12px",
                  md: "12px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                letterSpacing: "-0.25px",
                fontWeight: "600",
                textAlign: "center",
                marginTop: "2%",
                ml: "2%",
                marginBottom: "3%",
              }}
            >
              {attr.displayName}
            </Typography>
            <TextField
              value={attributeValues[attr.nom] || ""}
              onChange={(e) => handleValueChange(attr.nom, e.target.value)}
              variant="outlined"
              fullWidth
              multiline
              minRows={1}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  backgroundColor: "white",
                  border: "1px solid #E3E3E3",
                  fontWeight: "500",
                  fontFamily: "Inter Variable",
                  fontSize: "14px",
                  padding: "3%",
                  letterSpacing: "-0.25px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  input: {
                    color: "text.primary",
                  },
                },
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                onClick={() => handleGenerateText(attr.nom)}
                sx={{
                  mt: 2,
                  bgcolor: "#0042FF",
                  color: "white",
                  fontFamily: "Inter Variable",
                  fontWeight: "500",
                  textTransform: "none",
                  borderRadius: "8px",
                  "&:hover": {
                    bgcolor: "#0032CC",
                  },
                }}
              >
                {t("attributeEditor.generer")}
              </LoadingButton>
            </Box>
          </Box>
        );

      case "color":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              sx={{
                color: "text.primary",
                fontSize: {
                  lg: "12px",
                  md: "12px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                letterSpacing: "-0.25px",
                fontWeight: "600",
                textAlign: "center",
                marginTop: "2%",
                marginBottom: "3%",
              }}
            >
              {attr.displayName}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <TextField
                value={attributeValues[attr.nom] || ""}
                onChange={(e) => handleValueChange(attr.nom, e.target.value)}
                sx={{
                  width: "100px",
                  marginRight: "10px",
                  "& input": {
                    textAlign: "center",
                  },
                }}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    backgroundColor: "white",
                    border: "1px solid #E3E3E3",
                    fontWeight: "500",
                    fontFamily: "Inter Variable",
                    fontSize: "14px",
                    padding: "3%",
                    letterSpacing: "-0.25px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    input: {
                      color: "text.primary",
                    },
                  },
                }}
              />
              <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "5px",
                  backgroundColor: attributeValues[attr.nom],
                  cursor: "pointer",
                  border: "2px solid #ccc",
                }}
                onClick={() => toggleColorPicker(attr.nom)}
              />
            </Box>
            {colorPickersVisible[attr.nom] && (
              <Box sx={{ mt: 1 }}>
                <HexColorPicker
                  color={attributeValues[attr.nom]}
                  onChange={(color) => handleValueChange(attr.nom, color)}
                />
              </Box>
            )}
          </Box>
        );
      case "font":
        return (
          <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
            <Typography
              sx={{
                color: "text.primary",
                fontSize: {
                  lg: "12px",
                  md: "12px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                letterSpacing: "-0.25px",
                fontWeight: "600",
                textAlign: "center",
                marginTop: "5%",
                marginBottom: "2%",
              }}
            >
              {attr.displayName}
            </Typography>
            <Select
              value={attributeValues[attr.nom] || ""}
              onChange={(e) => handleValueChange(attr.nom, e.target.value)}
              variant="outlined"
              fullWidth
              sx={{
                height: "40%",
                borderRadius: "10px",
                backgroundColor: "#ECEDF5",
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                letterSpacing: "-0.25px",
                fontWeight: "600",
                textAlign: "center",
                pl: "6%",
                mt: 1,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    mt: "-0.5%",
                    bgcolor: "#ECEDF5",
                    boxShadow: "none",
                    borderBottomRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                    "& .MuiMenuItem-root": {
                      fontSize: {
                        lg: "14px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontFamily: "Inter Variable",
                      letterSpacing: "-0.25px",
                      fontWeight: "600",
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                      "&:hover": {
                        background: "#0033c1",
                        fontWeight: "500",
                        color: "white",
                      },
                      "&.Mui-selected": {
                        bgcolor: "#ECEDF5",
                        "&:hover": {
                          bgcolor: "#D0D0D0",
                          color: "black",
                        },
                      },
                    },
                  },
                },
              }}
            >
              {[
                "Montserrat",
                "Roboto",
                "Actor",
                "Poppins",
                "AbeeZee",
                "Open Sans",
                "Lato",
                "Playfair Display",
                "Source Sans Pro",
                "Nunito",
                "Raleway",
                "Quicksand",
                "Merriweather",
                "Cormorant Garamond",
                "Bebas Neue",
                "Futura",
                "Didot",
                "Oswald",
                "Cinzel",
                "Bodoni",
                "Roboto Slab",
                "Orbitron",
                "Rajdhani",
                "Nunito",
                "Julius Sans One",
                "Amatic SC",
                "Impact",
                "Cabin",
                "Fredoka One",
                "Comfortaa",
                "Pacifico",
                "Bubblegum Sans",
                "Zen Maru Gothic",
                "Exo 2",
                "Ubuntu",
                "Chewy",
                "Bangers",
              ].map((font) => (
                <MenuItem key={font} value={font} sx={{ fontFamily: font }}>
                  {font}
                </MenuItem>
              ))}
            </Select>
          </Box>
        );
      case "fontWeight":
        return (
          <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
            <Typography
              sx={{
                color: "text.primary",
                fontSize: {
                  lg: "12px",
                  md: "12px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                letterSpacing: "-0.25px",
                fontWeight: "600",
                textAlign: "center",
                marginTop: "5%",
                marginBottom: "3%",
              }}
            >
              {attr.displayName}
            </Typography>
            <Select
              value={attributeValues[attr.nom] || ""}
              onChange={(e) => handleValueChange(attr.nom, e.target.value)}
              variant="outlined"
              fullWidth
              sx={{
                height: "40%",
                borderRadius: "10px",
                backgroundColor: "#ECEDF5",
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                letterSpacing: "-0.25px",
                fontWeight: "600",
                textAlign: "center",
                pl: "3%",
                mt: 1,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: "#ECEDF5",
                    boxShadow: "none",
                    borderBottomRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                    "& .MuiMenuItem-root": {
                      fontSize: {
                        lg: "14px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontFamily: "Inter Variable",
                      letterSpacing: "-0.25px",
                      fontWeight: "500",
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                      "&:hover": {
                        background: "#0033c1",
                        fontWeight: "500",
                        color: "white",
                      },
                      "&.Mui-selected": {
                        bgcolor: "#ECEDF5",
                        "&:hover": {
                          bgcolor: "#D0D0D0",
                          color: "black",
                        },
                      },
                    },
                  },
                },
              }}
            >
              {[
                "100",
                "200",
                "300",
                "400",
                "500",
                "600",
                "700",
                "800",
                "900",
              ].map((weight) => (
                <MenuItem
                  key={weight}
                  value={weight}
                  sx={{ fontWeight: weight }}
                >
                  {weight}
                </MenuItem>
              ))}
            </Select>
          </Box>
        );
      case "range":
        return (
          <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
            <Typography
              sx={{
                color: "text.primary",
                fontSize: {
                  lg: "12px",
                  md: "12px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                letterSpacing: "-0.25px",
                fontWeight: "600",
                textAlign: "center",
                marginTop: "2%",
                marginLeft: "-6%",
                marginBottom: "3%",
              }}
            >
              {attr.displayName}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Slider
                value={
                  typeof attributeValues[attr.nom] === "number"
                    ? attributeValues[attr.nom]
                    : 0
                }
                onChange={(e, value) => handleValueChange(attr.nom, value)}
                aria-labelledby="input-slider"
                min={10}
                max={100}
                sx={{
                  width: "60%",
                  mr: 2,
                  color: "#1460f9",
                  "& .MuiSlider-thumb": {
                    backgroundColor: "#1460f9",
                    border: "2px solid white",
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "0px 0px 0px 8px rgba(0, 66, 255, 0.16)",
                    },
                    "&.Mui-active": {
                      boxShadow: "0px 0px 0px 14px rgba(0, 66, 255, 0.16)",
                    },
                  },
                  "& .MuiSlider-track": {
                    backgroundColor: "#0042FF",
                  },
                  "& .MuiSlider-rail": {
                    backgroundColor: "#ededf5",
                  },
                }}
              />
              <Typography
                sx={{
                  color: "text.primary",
                  fontSize: {
                    lg: "14px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  fontFamily: "Inter Variable",
                  letterSpacing: "-0.25px",
                  fontWeight: "600",
                }}
              >
                {`${attributeValues[attr.nom]}`}
              </Typography>
            </Box>
          </Box>
        );
      case "alignment":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              sx={{
                color: "text.primary",
                fontSize: {
                  lg: "12px",
                  md: "12px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                letterSpacing: "-0.25px",
                fontWeight: "600",
                textAlign: "center",
                marginTop: "2%",
                marginBottom: "3%",
              }}
            >
              {attr.displayName}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
              <Button
                variant={
                  attributeValues[attr.nom] === "left"
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleValueChange(attr.nom, "left")}
                sx={{
                  borderRadius: "8px",
                  fontFamily: "Inter Variable",
                  textAlign: "center",
                  letterSpacing: "-0.25px",
                  fontWeight:
                    attributeValues[attr.nom] === "left" ? "700" : "500",
                  fontSize: "12px",
                  textTransform: "none",
                  backgroundColor:
                    attributeValues[attr.nom] === "left"
                      ? "#0042FF"
                      : "#ededf5",
                  color:
                    attributeValues[attr.nom] === "left" ? "white" : "#000000",
                  border: "none",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor:
                      attributeValues[attr.nom] === "left"
                        ? "#0032CC"
                        : "#b7b7be",
                    boxShadow: "none",
                    border: "none",
                  },
                }}
              >
                {t("attributeEditor.left")}
              </Button>
              <Button
                variant={
                  attributeValues[attr.nom] === "center"
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleValueChange(attr.nom, "center")}
                sx={{
                  borderRadius: "8px",
                  fontFamily: "Inter Variable",
                  textAlign: "center",
                  letterSpacing: "-0.25px",
                  fontWeight:
                    attributeValues[attr.nom] === "center" ? "700" : "500",
                  fontSize: "12px",
                  textTransform: "none",
                  backgroundColor:
                    attributeValues[attr.nom] === "center"
                      ? "#0042FF"
                      : "#ededf5",
                  color:
                    attributeValues[attr.nom] === "center"
                      ? "white"
                      : "#000000",
                  border: "none",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor:
                      attributeValues[attr.nom] === "center"
                        ? "#0032CC"
                        : "#b7b7be",
                    boxShadow: "none",
                    border: "none",
                  },
                }}
              >
                {t("attributeEditor.center")}
              </Button>
              <Button
                variant={
                  attributeValues[attr.nom] === "right"
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleValueChange(attr.nom, "right")}
                sx={{
                  borderRadius: "8px",
                  fontFamily: "Inter Variable",
                  textAlign: "center",
                  letterSpacing: "-0.25px",
                  fontWeight:
                    attributeValues[attr.nom] === "right" ? "700" : "500",
                  fontSize: "12px",
                  textTransform: "none",
                  backgroundColor:
                    attributeValues[attr.nom] === "right"
                      ? "#0042FF"
                      : "#ededf5",
                  color:
                    attributeValues[attr.nom] === "right" ? "white" : "#000000",
                  border: "none",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor:
                      attributeValues[attr.nom] === "right"
                        ? "#0032CC"
                        : "#b7b7be",
                    boxShadow: "none",
                    border: "none",
                  },
                }}
              >
                {t("attributeEditor.right")}
              </Button>
            </Box>
          </Box>
        );
      case "switch":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              sx={{
                color: "text.primary",
                fontSize: {
                  lg: "12px",
                  md: "12px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                letterSpacing: "-0.25px",
                fontWeight: "600",
                textAlign: "center",
                marginTop: "2%",
                marginBottom: "3%",
              }}
            >
              {attr.displayName}
            </Typography>
            <Switch
              checked={attributeValues[attr.nom] === "on"}
              onChange={(e) =>
                handleValueChange(attr.nom, e.target.checked ? "on" : "off")
              }
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return selectedModule ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "95%", xl: "20%" },
        height: "100%",
        overflowY: "auto",
        bgcolor: "background.paper",
        p: 2,
        borderRadius: "10px",
        mt: "10px",
        ml: "5px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          onClick={onBack}
          variant="contained"
          sx={{
            bgcolor: "white",
            color: "black",
            marginRight: "10px",
            fontSize: {
              lg: "14px",
              md: "14px",
              sm: "12px",
              xs: "12px",
            },
            fontFamily: "Inter Variable",
            letterSpacing: "-0.25px",
            fontWeight: "600",
            textTransform: "none",
            p: "5px",
            mr: "5px",
            boxShadow: "none",
            borderRadius: "10px",

            "&:hover": {
              bgcolor: "grey.300",
              boxShadow: "none",
              color: "black",
            },
            mb: 2,
          }}
        >
          <ChevronLeftIcon
            sx={{
              color: "black",
              fontSize: {
                lg: "14px",
                md: "14px",
                sm: "12px",
                xs: "12px",
              },
            }}
          />{" "}
          {t("attributeEditor.back")}
        </Button>
      </Box>
      <Typography
        align="center"
        variant="h6"
        sx={{
          mt: "5%",
          fontSize: {
            lg: "12px",
            md: "12px",
            sm: "12px",
            xs: "12px",
          },
          fontFamily: "Inter Variable",
          letterSpacing: "-0.25px",
          fontWeight: "500",
        }}
      >
        {t("attributeEditor.editingModule")}
      </Typography>
      <Box>
        <Typography
          align="center"
          variant="h6"
          sx={{
            fontSize: {
              lg: "16px",
              md: "16px",
              sm: "14px",
              xs: "12px",
            },
            fontFamily: "Inter Variable",
            letterSpacing: "-0.25px",
            fontWeight: "600",
            color: "black",
          }}
        >
          {selectedModule?.selectedElement?.section}
        </Typography>
      </Box>
      <Divider sx={{ my: 2, borderWidth: 1, borderColor: "divider" }} />
      {selectedModule.selectedElement.attributes.map((attr, index) => (
        <React.Fragment key={attr.nom}>
          {index > 0 && (
            <Divider sx={{ my: 1, borderWidth: 1, borderColor: "divider" }} />
          )}
          {renderInputField(attr)}
        </React.Fragment>
      ))}
    </Box>
  ) : null;
};

export default AttributeEditor;
