import React, { useState, useEffect, useCallback, Fragment } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Paper,
  Grid,
  Typography,
  Box,
  Divider,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { API_SERVER_ENDPOINT } from "../../../../shared/api/ConstantesEndpoints";
import { useTranslation } from "react-i18next";
import Loader from "../../../../shared/loader/Loader";
import AddShippingMethodDialog from "./AddShippingMethodDialog";
import AddZoneLivraisonDialog from "./AddZoneLivraisonDialog";
import ToastError from "../../../../shared/toast/ToastError";

const GestionLivraison = ({ siteConsulte }) => {
  const { t } = useTranslation();

  const [shippingZones, setShippingZones] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [dialogZoneOpen, setDialogZoneOpen] = useState(false);
  const [dialogMethodOpen, setDialogMethodOpen] = useState(false);

  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [editMode, setEditMode] = useState({});

  const [methodDetails, setMethodDetails] = useState({});
  const [error, setError] = useState(null);

  const handleDialogZoneOpen = () => {
    setDialogZoneOpen(true);
  };

  const handleDialogZoneClose = () => {
    setDialogZoneOpen(false);
    fetchShippingZones();
  };

  const handleOpenMethodDialog = (zoneId) => {
    setSelectedZoneId(zoneId);
    setDialogMethodOpen(true);
  };

  const handleCloseMethodDialog = () => {
    setDialogMethodOpen(false);
    fetchShippingZones();
  };

  const fetchShippingZones = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${API_SERVER_ENDPOINT}/livraison/zones`,
        {
          params: { siteNom: encodeURIComponent(siteConsulte.nom) },
        }
      );

      let zones = response.data;

      // Trier les zones pour que "Locations not covered by your other zones" soit toujours à la fin
      zones.sort((a, b) => {
        if (a.name === "Locations not covered by your other zones") return 1;
        if (b.name === "Locations not covered by your other zones") return -1;
        return 0; // Maintenir l'ordre initial pour toutes les autres zones
      });

      setShippingZones(zones);
      setEditMode(false);
    } catch (error) {
      handleError(error.message);
    } finally {
      setIsLoading(false);
      setEditMode(false);
    }
  }, [siteConsulte]);

  useEffect(() => {
    fetchShippingZones();
  }, [fetchShippingZones]);

  useEffect(() => {
    if (!shippingZones || shippingZones.length === 0) return;

    const initialMethodDetails = shippingZones.reduce((zoneDetails, zone) => {
      const methodsDetails = zone.methods.reduce((details, method) => {
        const methodInfo = {
          title: method.title,
          minAmount:
            method.settings && method.settings.min_amount
              ? method.settings.min_amount.value
              : undefined,
          cost:
            method.settings && method.settings.cost
              ? method.settings.cost.value
              : undefined,
        };
        return { ...details, [method.id]: methodInfo };
      }, {});

      return { ...zoneDetails, [zone.id]: methodsDetails };
    }, {});

    setMethodDetails(initialMethodDetails);
  }, [shippingZones]);

  const updateMethodLivraison = useCallback(
    async (zoneId, methodId, methodDetails) => {
      setIsLoading(true);

      const settings = {
        title: methodDetails.title,
        min_amount: methodDetails.minAmount,
        cost: methodDetails.cost,
      };

      axios
        .put(
          `${API_SERVER_ENDPOINT}/livraison/zones/${zoneId}/methodes/${methodId}`,
          { settings },
          {
            params: { siteNom: encodeURIComponent(siteConsulte.nom) },
          }
        )
        .then(() => {
          fetchShippingZones();
        })
        .catch((error) => {
          handleError(error.message);
          setIsLoading(false);
        });
    },
    [fetchShippingZones, siteConsulte.nom]
  );

  const handleMethodChange = (value, zoneId, methodId, field) => {
    const updatedMethodDetails = {
      ...methodDetails[zoneId],
      [methodId]: { ...methodDetails[zoneId][methodId], [field]: value },
    };
    const updatedDetails = { ...methodDetails, [zoneId]: updatedMethodDetails };
    setMethodDetails(updatedDetails);
  };

  const handleSubmit = (e, zoneId, methodId) => {
    e.preventDefault();
    const methodInfo = methodDetails[zoneId][methodId];
    updateMethodLivraison(zoneId, methodId, {
      title: methodInfo.title,
      minAmount: methodInfo.minAmount,
      cost: methodInfo.cost,
    });
    toggleEditMode(methodId, false);
  };

  const handleDeleteZone = async (zoneId) => {
    setIsLoading(true);

    try {
      await axios.delete(
        `${API_SERVER_ENDPOINT}/livraison/zones/${zoneId}?siteNom=${encodeURIComponent(
          siteConsulte.nom
        )}`
      );
      fetchShippingZones();
    } catch (error) {
      handleError(error.message);
      setIsLoading(false);
    }
  };

  const handleDeleteMethod = async (zoneId, methodId) => {
    setIsLoading(true);

    try {
      await axios.delete(
        `${API_SERVER_ENDPOINT}/livraison/zones/${zoneId}/methodes/${methodId}?siteNom=${encodeURIComponent(
          siteConsulte.nom
        )}`
      );
      fetchShippingZones();
    } catch (error) {
      handleError(error.message);
      setIsLoading(false);
    }
  };

  const toggleEditMode = (zoneId) => {
    setEditMode((prev) => ({ ...prev, [zoneId]: !prev[zoneId] }));
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  return (
    <div
      style={{
        flexGrow: 1,
        padding: "2%",
        backgroundColor: "#f5f8fa",
        minHeight: "100vh",
      }}
    >
      <Paper
        sx={{
          margin: 0,
          overflowX: "auto",
          boxShadow: "none",
          backgroundColor: "#f5f8fa",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontSize: { xs: "26px", sm: "60px" },
              fontFamily: "Space Grotesk, sans serif",
              fontWeight: "1000",
              letterSpacing: { xs: "-2px", sm: "-5px", md: "-5px", lg: "-5px" },
              ml: "2%",
              mt: { xs: "5%", sm: 0 },
            }}
          >
            {t("livraison.livraisons")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogZoneOpen}
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: {
                lg: "14px",
                md: "14px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              mr: 2,
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
            }}
          >
            {t("livraison.ajouterZone")}
            <AddIcon sx={{ fontSize: "20px", ml: "3px" }} />
          </Button>
          <AddZoneLivraisonDialog
            open={dialogZoneOpen}
            handleClose={handleDialogZoneClose}
            siteConsulte={siteConsulte}
          />
        </div>
        <Typography
          sx={{
            color: "black",
            fontSize: "16px",
            fontFamily: "Inter Variable, sans-serif",
            fontWeight: "500",
            letterSpacing: "-0.75px",
            mb: "5%",
            mt: "0%",
            ml: "2%",
          }}
        >
          {t("livraison.ajouterAfficherGererZones")}
        </Typography>

        {shippingZones.length > 0 ? (
          shippingZones.map((zone) => (
            <Paper
              key={zone.id}
              sx={{
                padding: 3,
                width: "97%",
                ml: "2%",
                marginBottom: 2,
                bgcolor: "white",
                borderRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                border: "1px solid #E3E3E3",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  color: "black",
                  fontSize: "20px",
                  fontFamily: "Inter Variable, sans-serif",
                  fontWeight: "700",
                  letterSpacing: "-0.75px",
                }}
              >
                {t("livraison.nomDeLaZone")}: {zone.name}
              </Typography>

              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  color: "black",
                  fontSize: "16px",
                  fontFamily: "Inter Variable, sans-serif",
                  fontWeight: "500",
                  letterSpacing: "-0.75px",
                }}
              >
                {t("livraison.paysDeLivraison")}: {zone.countries.join(", ")}
              </Typography>

              <Divider sx={{ my: 2, height: "1px", bgcolor: "black" }} />

              {zone.methods.length > 0 ? (
                zone.methods.map((method, index) => (
                  <Fragment key={method.id}>
                    <form onSubmit={(e) => handleSubmit(e, zone.id, method.id)}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={10}>
                          {editMode[method.id] ? (
                            <>
                              <Typography
                                variant="h6"
                                sx={{
                                  color: "black",
                                  fontSize: "16px",
                                  fontFamily: "Inter Variable, sans-serif",
                                  fontWeight: "500",
                                  letterSpacing: "-0.75px",
                                }}
                              >
                                {t("livraison.typeDeLivraison")}:{" "}
                                {method.method_title}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "text.primary",
                                  fontSize: {
                                    lg: "16px",
                                    md: "16px",
                                    sm: "14px",
                                    xs: "12px",
                                  },
                                  fontFamily: "Inter Variable",
                                  fontWeight: "500",
                                  letterSpacing: "-0.5px",
                                  mb: "2%",
                                  mt: "2%",
                                }}
                              >
                                {t("livraison.nomDeLaMethodeDeLivraison")}
                              </Typography>
                              <TextField
                                fullWidth
                                variant="outlined"
                                value={
                                  methodDetails[zone.id] &&
                                  methodDetails[zone.id][method.id]
                                    ? methodDetails[zone.id][method.id].title
                                    : method.title
                                }
                                onChange={(e) =>
                                  handleMethodChange(
                                    e.target.value,
                                    zone.id,
                                    method.id,
                                    "title"
                                  )
                                }
                                InputProps={{
                                  sx: {
                                    width: "100%",
                                    height: "50px",
                                    borderRadius: "12px",
                                    backgroundColor: "white",
                                    border: "1px solid #E3E3E3",
                                    fontWeight: "500",
                                    fontFamily: "Inter Variable",
                                    fontSize: "14px",
                                    padding: "0%",
                                    mb: "2%",
                                    letterSpacing: "-0.25px",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "transparent",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "transparent",
                                      },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "transparent",
                                      },
                                    input: {
                                      color: "text.primary",
                                    },
                                    "&::placeholder": {
                                      color: "#000000",
                                      opacity: 0.8,
                                    },
                                  },
                                }}
                              />
                              {method.settings &&
                                method.settings.min_amount && (
                                  <Box>
                                    <Typography
                                      sx={{
                                        color: "text.primary",
                                        fontSize: {
                                          lg: "16px",
                                          md: "16px",
                                          sm: "14px",
                                          xs: "12px",
                                        },
                                        fontFamily: "Inter Variable",
                                        fontWeight: "500",
                                        letterSpacing: "-0.5px",
                                        mb: 1,
                                      }}
                                    >
                                      {t("livraison.montantMinimumDeCommande")}
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      type="number"
                                      value={
                                        methodDetails[zone.id] &&
                                        methodDetails[zone.id][method.id] &&
                                        methodDetails[zone.id][method.id]
                                          .minAmount
                                          ? methodDetails[zone.id][method.id]
                                              .minAmount
                                          : method.settings.min_amount?.value
                                      }
                                      onChange={(e) =>
                                        handleMethodChange(
                                          e.target.value,
                                          zone.id,
                                          method.id,
                                          "minAmount"
                                        )
                                      }
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment
                                            sx={{ ml: 1 }}
                                            position="start"
                                          >
                                            €
                                          </InputAdornment>
                                        ),
                                        sx: {
                                          width: "100%",
                                          height: "50px",
                                          borderRadius: "12px",
                                          backgroundColor: "white",
                                          border: "1px solid #E3E3E3",
                                          fontWeight: "500",
                                          fontFamily: "Inter Variable",
                                          fontSize: "14px",
                                          padding: "0%",
                                          letterSpacing: "-0.25px",
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              borderColor: "transparent",
                                            },
                                          "&:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                              borderColor: "transparent",
                                            },
                                          "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                              borderColor: "transparent",
                                            },
                                          input: {
                                            color: "text.primary",
                                          },
                                          "&::placeholder": {
                                            color: "#000000",
                                            opacity: 0.8,
                                          },
                                        },
                                      }}
                                    />
                                  </Box>
                                )}
                              {method.settings && method.settings.cost && (
                                <Box>
                                  <Typography
                                    sx={{
                                      color: "text.primary",
                                      fontSize: {
                                        lg: "16px",
                                        md: "16px",
                                        sm: "14px",
                                        xs: "12px",
                                      },
                                      fontFamily: "Space Grotesk",
                                      fontWeight: "700",
                                      letterSpacing: "-1.5px",
                                      mb: 1,
                                    }}
                                  >
                                    {t("livraison.fraisDeLivraison")}
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="number"
                                    value={
                                      methodDetails[zone.id] &&
                                      methodDetails[zone.id][method.id] &&
                                      methodDetails[zone.id][method.id].cost
                                        ? methodDetails[zone.id][method.id].cost
                                        : method.settings.cost?.value
                                    }
                                    onChange={(e) =>
                                      handleMethodChange(
                                        e.target.value,
                                        zone.id,
                                        method.id,
                                        "cost"
                                      )
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment
                                          sx={{ ml: 1 }}
                                          position="start"
                                        >
                                          €
                                        </InputAdornment>
                                      ),
                                      sx: {
                                        width: "100%",
                                        height: "50px",
                                        borderRadius: "12px",
                                        backgroundColor: "white",
                                        border: "1px solid #E3E3E3",
                                        fontWeight: "500",
                                        fontFamily: "Inter Variable",
                                        fontSize: "14px",
                                        padding: "0%",
                                        letterSpacing: "-0.25px",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          borderColor: "transparent",
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline":
                                          {
                                            borderColor: "transparent",
                                          },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                          {
                                            borderColor: "transparent",
                                          },
                                        input: {
                                          color: "text.primary",
                                        },
                                        "&::placeholder": {
                                          color: "#000000",
                                          opacity: 0.8,
                                        },
                                      },
                                    }}
                                  />
                                </Box>
                              )}
                            </>
                          ) : (
                            <>
                              <Typography
                                variant="h6"
                                sx={{
                                  backgroundColor: "white",
                                  borderRadius: "16px",
                                  fontFamily: "Space Grotesk",
                                  fontWeight: "700",
                                  letterSpacing: "-1.5px",
                                  color: "text.primary",
                                  mb: 3,
                                }}
                              >
                                {t("livraison.typeDeLivraison")}:{" "}
                                {method.method_title}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  backgroundColor: "white",
                                  borderRadius: "16px",
                                  fontFamily: "Inter Variable",
                                  fontWeight: "500",
                                  letterSpacing: "-0.5px",
                                  color: "text.primary",
                                }}
                              >
                                {t("livraison.nomDeLaMethodeDeLivraison")}:{" "}
                                {methodDetails[zone.id] &&
                                methodDetails[zone.id][method.id]
                                  ? methodDetails[zone.id][method.id].title
                                  : method.title}
                              </Typography>
                              {method.settings &&
                                method.settings.min_amount && (
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      backgroundColor: "white",
                                      borderRadius: "16px",
                                      fontFamily: "Inter Variable",
                                      fontWeight: "500",
                                      letterSpacing: "-0.5px",
                                      color: "text.primary",
                                    }}
                                  >
                                    {t("livraison.montantMinimumDeCommande")}:{" "}
                                    {methodDetails[zone.id] &&
                                    methodDetails[zone.id][method.id]
                                      ? methodDetails[zone.id][method.id]
                                          .minAmount
                                      : method.settings.min_amount.value}{" "}
                                    €
                                  </Typography>
                                )}

                              {method.settings && method.settings.cost && (
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    backgroundColor: "white",
                                    borderRadius: "16px",
                                    fontFamily: "Space Grotesk",
                                    fontWeight: "600",
                                    letterSpacing: "-1.5px",
                                    color: "text.primary",
                                  }}
                                >
                                  {t("livraison.fraisDeLivraison")}:{" "}
                                  {methodDetails[zone.id] &&
                                  methodDetails[zone.id][method.id]
                                    ? methodDetails[zone.id][method.id].cost
                                    : method.settings.cost.value}{" "}
                                  €
                                </Typography>
                              )}
                            </>
                          )}
                        </Grid>
                        <Grid
                          item
                          sx={{
                            ml: 0,
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                          }}
                        >
                          {editMode[method.id] ? (
                            <>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{
                                  background: "#0042ff",
                                  color: "white",
                                  fontSize: {
                                    lg: "16px",
                                    md: "16px",
                                    sm: "12px",
                                    xs: "12px",
                                  },
                                  fontFamily: "Inter Variable",
                                  fontWeight: "500",
                                  letterSpacing: "-0.25px",
                                  textTransform: "none",
                                  mr: 2,
                                  borderRadius: "10px",
                                  border: "1px solid #276CFA",
                                  outline: "1px solid #0646C6",
                                  "&:hover": {
                                    backgroundColor: "#1459e2",
                                    boxShadow: "none",
                                  },
                                }}
                              >
                                {t("livraison.enregistrer")}
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => toggleEditMode(method.id, false)}
                                sx={{
                                  bgcolor: "#F3F4F6",
                                  color: "#303030",
                                  fontSize: {
                                    lg: "16px",
                                    md: "16px",
                                    sm: "12px",
                                    xs: "12px",
                                  },
                                  fontFamily: "Inter Variable",
                                  fontWeight: "500",
                                  letterSpacing: "-0.25px",
                                  textTransform: "none",
                                  mr: 1,
                                  pl: "3%",
                                  pr: "3%",
                                  boxShadow: "none",
                                  borderRadius: "10px",
                                  border: "1px solid #ECEFF2",
                                  outline: "1px solid #303030",
                                  "&:hover": {
                                    bgcolor: "#e2e2e2",
                                    color: "#303030",
                                    boxShadow: "none",
                                  },
                                }}
                              >
                                {t("livraison.annuler")}
                              </Button>
                            </>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                toggleEditMode(method.id, true);
                              }}
                              sx={{
                                background: "#0042ff",
                                color: "white",
                                fontSize: {
                                  lg: "16px",
                                  md: "16px",
                                  sm: "12px",
                                  xs: "12px",
                                },
                                fontFamily: "Inter Variable",
                                fontWeight: "500",
                                letterSpacing: "-0.25px",
                                textTransform: "none",
                                mr: 2,
                                borderRadius: "10px",
                                border: "1px solid #276CFA",
                                outline: "1px solid #0646C6",
                                "&:hover": {
                                  backgroundColor: "#1459e2",
                                  boxShadow: "none",
                                },
                              }}
                            >
                              {t("livraison.modifier")}
                            </Button>
                          )}

                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() =>
                              handleDeleteMethod(zone.id, method.id)
                            }
                            sx={{
                              background: "#e6332d",
                              color: "white",
                              fontSize: {
                                lg: "14px",
                                md: "14px",
                                sm: "12px",
                                xs: "12px",
                              },
                              fontFamily: "Inter Variable",
                              letterSpacing: "-0.25px",
                              fontWeight: "600",
                              textTransform: "none",
                              boxShadow: "none",
                              mr: 1,
                              outline: "1px solid #c12724",
                              border: "1px solid #f74a47",
                              borderRadius: "10px",
                              "&:hover": {
                                background: "#c12724",
                                boxShadow: "none",
                                color: "white",
                              },
                            }}
                          >
                            {t("livraison.supprimerLaMethode")}
                          </Button>
                        </Grid>
                      </Grid>
                      <Divider
                        sx={{ my: 2, height: "1px", bgcolor: "black" }}
                      />
                    </form>
                  </Fragment>
                ))
              ) : (
                <Typography
                  sx={{
                    mb: 2,
                    mt: 2,
                    fontFamily: "Space Grotesk",
                    fontWeight: "500",
                    letterSpacing: "-1px",
                  }}
                >
                  {t("livraison.aucuneMethodeDeLivraisonDefinie")}
                </Typography>
              )}

              <Box sx={{ mt: 3, display: "flex" }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    background: "#0042ff",
                    color: "white",
                    fontSize: {
                      lg: "16px",
                      md: "16px",
                      sm: "12px",
                      xs: "12px",
                    },
                    fontFamily: "Inter Variable",
                    fontWeight: "500",
                    letterSpacing: "-0.25px",
                    textTransform: "none",
                    mr: 2,
                    borderRadius: "10px",
                    border: "1px solid #276CFA",
                    outline: "1px solid #0646C6",
                    "&:hover": {
                      backgroundColor: "#1459e2",
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => handleOpenMethodDialog(zone.id)}
                >
                  {t("livraison.ajouterUneMethodeDeLivraison")}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#F3F4F6",
                    color: "#303030",
                    fontSize: {
                      lg: "16px",
                      md: "16px",
                      sm: "12px",
                      xs: "12px",
                    },
                    fontFamily: "Inter Variable",
                    fontWeight: "500",
                    letterSpacing: "-0.25px",
                    textTransform: "none",
                    mr: 1,
                    pl: "3%",
                    pr: "3%",
                    boxShadow: "none",
                    borderRadius: "10px",
                    border: "1px solid #ECEFF2",
                    outline: "1px solid #303030",
                    "&:hover": {
                      bgcolor: "#e2e2e2",
                      color: "#303030",
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => handleDeleteZone(zone.id)}
                >
                  {t("livraison.supprimerLaZone")}
                </Button>
                <AddShippingMethodDialog
                  open={dialogMethodOpen}
                  onClose={handleCloseMethodDialog}
                  zoneId={selectedZoneId}
                  siteConsulte={siteConsulte}
                />
              </Box>
            </Paper>
          ))
        ) : (
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              color: "grey",
              fontSize: "20px",
              fontFamily: "Inter Variable, sans serif",
              letterSpacing: "-1px",
              fontWeight: "500",
              mt: "10%",
              textAlign: "center",
            }}
          >
            {t("livraison.aucuneZoneDeLivraison")}
          </Typography>
        )}
      </Paper>
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
      <Loader loading={isloading}></Loader>
    </div>
  );
};

export default GestionLivraison;
