import React, { useState, useEffect, useCallback } from "react";
import { Box, Grid } from "@mui/material";
import StatCard from "../../../../shared/statistique/StatCard";
import ActiveUsersChart from "../../../../shared/statistique/ActiveUsersChart";
import PageViewsTable from "../../../../shared/statistique/PageViewsTable";
import ConversionRateStatCard from "../../../../shared/statistique/ConversionRateStatCard";
import { format, parseISO } from "date-fns";
import { API_SERVER_ENDPOINT } from "../../../../shared/api/ConstantesEndpoints";
import io from "socket.io-client";
import { useTranslation } from "react-i18next";
import LiveView from "../../../../shared/statistique/LiveView";

const AnalyticsStats = ({ siteConsulte, startDate, endDate, granularity }) => {
  const { t } = useTranslation();
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);
  const [totalVisitorsData, setTotalVisitorsData] = useState([]);
  const [activeUsersData, setActiveUsersData] = useState([]);
  const [pageViewsData, setPageViewsData] = useState([]);
  const [conversionRates, setConversionRates] = useState({
    cartConversionRate: 0,
    checkoutConversionRate: 0,
    orderReceivedConversionRate: 0,
  });
  const [realTimeEventTotals, setRealTimeEventTotals] = useState({
    cart_view: 0,
    checkout_view: 0,
    thank_you_visit: 0,
  });

  const calculatePercentageChange = (current, previous) => {
    if (!previous || previous === "0.00") return 0;
    return (((current - previous) / previous) * 100).toFixed(2);
  };

  const calculatePercentageChangeVisiteur = useCallback((data) => {
    if (data.length < 2) return 0;
    const previous = data[data.length - 2]?.totalUsers || 0;
    const current = data[data.length - 1]?.totalUsers || 0;
    return calculatePercentageChange(current, previous);
  }, []);

  const calculateTotalVisiteur = (data) => {
    return data.reduce((acc, item) => acc + item.totalUsers, 0);
  };

  useEffect(() => {
    if (siteConsulte && startDate && endDate) {
      const socket = io(API_SERVER_ENDPOINT, {
        withCredentials: true,
        transports: ["websocket"],
      });

      socket.emit("subscribeToAnalytics", {
        siteNom: siteConsulte.nom,
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        granularity: granularity,
      });

      socket.on("analyticsData", (data) => {
        // Utilisateurs actifs (granularité de 5 minutes)
        const activeUsersData = data.activeUsers.map((row) => ({
          time: format(parseISO(row.interval), "HH:mm"),
          activeUsers: row.activeUsers,
        }));

        setActiveUsersData(activeUsersData);

        // Visiteurs totaux (granularité quotidienne)
        const totalVisitorsData = data.visitors.map((row) => ({
          date: row.date,
          totalUsers: row.totalUsers,
        }));

        // Visites par page (granularité quotidienne)
        const pageViewsData = data.pageViews.map((row) => ({
          pagePath: row.pagePath,
          screenPageViews: row.screenPageViews,
        }));

        setTotalVisitorsData(totalVisitorsData);
        setTotalVisitors(calculateTotalVisiteur(totalVisitorsData));
        setPageViewsData(pageViewsData);

        setConversionRates(data.conversionRates);

        setRealTimeEventTotals(data.realTimeEventTotals);

        setPercentageChange(
          calculatePercentageChangeVisiteur(totalVisitorsData)
        );
      });

      socket.on("error", (error) => {
        console.error(error.message);
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [
    siteConsulte,
    startDate,
    endDate,
    granularity,
    calculatePercentageChangeVisiteur,
  ]);

  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        spacing={0}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          margin: 0,
          width: "100%",
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            margin: 0,
            width: "100%",
          }}
        >
          <Grid item xs={12} md={4} sx={{ padding: "0 2px" }}>
            <ConversionRateStatCard
              title={t("statistique.cart_conversion")}
              value={conversionRates.cartConversionRate}
              dateRange={`${t("statistique.analyzed_period")} ${format(
                startDate,
                "MMM dd"
              )} à ${format(endDate, "MMM dd, yyyy")}`}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ padding: "0 2px" }}>
            <ConversionRateStatCard
              title={t("statistique.checkout_conversion")}
              value={conversionRates.checkoutConversionRate}
              dateRange={`${t("statistique.analyzed_period")} ${format(
                startDate,
                "MMM dd"
              )} à ${format(endDate, "MMM dd, yyyy")}`}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ padding: "0 2px" }}>
            <ConversionRateStatCard
              title={t("statistique.order_received_conversion")}
              value={conversionRates.orderReceivedConversionRate}
              dateRange={`${t("statistique.analyzed_period")} ${format(
                startDate,
                "MMM dd"
              )} à ${format(endDate, "MMM dd, yyyy")}`}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} sx={{ padding: "0 2px" }}>
          <StatCard
            title={t("statistique.total_visitors")}
            value={totalVisitors}
            percentageChange={percentageChange}
            data={totalVisitorsData}
            dateRange={`${t("statistique.analyzed_period")} ${format(
              startDate,
              "MMM dd"
            )} ${t("statistique.to")} ${format(endDate, "MMM dd, yyyy")}`}
            tooltipContentLine="totalUsers"
            granularity="jour"
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ padding: "0 2px" }}>
          <ActiveUsersChart
            data={activeUsersData}
            dateRange={`${t(
              "statistique.analyzed_period"
            )} 30 dernières minutes`}
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ padding: "0 2px" }}>
          <LiveView
            realTimeData={realTimeEventTotals}
            dateRange={`${t(
              "statistique.analyzed_period"
            )} 10 dernières minutes`}
          ></LiveView>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          margin: 0,
          width: "100%",
        }}
      >
        <Grid item xs={12} md={4} sx={{ padding: "0 2px" }}>
          <PageViewsTable
            data={pageViewsData}
            dateRange={`${t("statistique.analyzed_period")} ${format(
              startDate,
              "MMM dd"
            )} à ${format(endDate, "MMM dd, yyyy")}`}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnalyticsStats;
