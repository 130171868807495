import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ReouvertureStripeFormulaire from "./ReouvertureStripeFormulaire";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const PaymentReouvertureAbonnement = ({
  selectedPriceId,
  montant,
  abonnementIdBdd,
  promoCode,
}) => {
  const [selectedAmount, setSelectedAmount] = useState(montant * 100);

  useEffect(() => {
    const newAmount = montant * 100;
    setSelectedAmount(newAmount);
  }, [montant]);

  const options = {
    mode: "subscription",
    amount: selectedAmount,
    currency: "eur",
    appearance: {
      variables: {
        colorText: "#000000",
        borderRadius: "10px",
      },
      labels: "floating",
      rules: {
        ".Input": {
          border: "1px solid #ccc",
          boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.5)",
        },
      },
    },
  };

  return (
    <Box>
      <Elements options={options} stripe={stripePromise}>
        <ReouvertureStripeFormulaire
          abonnementIdBdd={abonnementIdBdd}
          selectedPriceId={selectedPriceId}
          promoCode={promoCode}
        />
      </Elements>
    </Box>
  );
};

export default PaymentReouvertureAbonnement;
