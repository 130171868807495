import React, { useState } from "react";
import { Box, Typography, Grid, Paper, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomSwitch from "../../shared/switch/Switch";

function Pricing() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAnnual, setIsAnnual] = useState(false);

  const handleClick = () => {
    navigate("/informations");
  };

  const handleToggle = () => {
    setIsAnnual((prev) => !prev);
  };

  const getPrice = (monthly, annually) => {
    return isAnnual ? annually : monthly;
  };

  return (
    <Box
      id="tarifs"
      sx={{
        backgroundColor: "#02010e",
        color: "#fff",
        padding: "40px 20px",
        textAlign: "center",
        minHeight: "800px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontFamily: "Inter Variable",
          fontWeight: "400",
          marginBottom: "10px",
          letterSpacing: "-0.5px",
          textTransform: "uppercase",
          fontSize: {
            xl: "18px",
            lg: "16px",
            md: "14px",
            sm: "14px",
            xs: "14px",
          },
          color: "#634fff",
        }}
      >
        {t("pricing.pricingHead")}
      </Typography>
      <Typography
        variant="h3"
        sx={{
          fontSize: {
            xl: "50px",
            lg: "40px",
            md: "40px",
            sm: "40px",
            xs: "30px",
          },
          fontFamily: "Space Grotesk, sans-serif",
          fontWeight: "600",
          letterSpacing: {
            xl: "-3px",
            lg: "-2.5px",
            md: "-2.5px",
            sm: "-2px",
            xs: "-1.75px",
          },
          marginBottom: "20px",
        }}
      >
        {t("pricing.pricingTitle")}
      </Typography>
      <Box sx={{ textAlign: "center", mb: 2 }}>
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: {
              xl: "20px",
              lg: "18px",
              md: "15px",
              sm: "15px",
              xs: "13px",
            },
            backgroundColor: "#fff",
            color: "#1565c0",
            borderRadius: "20px",
            fontFamily: "Space Grotesk, sans-serif",
            fontWeight: "600",
            letterSpacing: "-1px",
            padding: "5px 15px",
            display: "inline-block",
          }}
        >
          {t("pricing.pricingDiscount")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter Variable",
            fontWeight: "500",
            textTransform: "uppercase",
          }}
        >
          {t("pricing.mensuel")}
        </Typography>
        <CustomSwitch checked={isAnnual} onChange={handleToggle} />
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter Variable",
            fontWeight: "500",
            textTransform: "uppercase",
          }}
        >
          {t("pricing.annuel")}
        </Typography>
      </Box>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={3}>
          <Paper
            sx={{
              background: "linear-gradient(to right bottom, #0042ff, #75b3ff)",
              borderRadius: "20px",
              padding: "20px",
              color: "#fff",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ textAlign: "center", mb: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  backgroundColor: "#fff",
                  color: "#1565c0",
                  borderRadius: "20px",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "600",
                  letterSpacing: "-1px",
                  padding: "5px 15px",
                  display: "inline-block",
                }}
              >
                {t("pricing.pourBienDebuter")}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "600",
                  mb: 1,
                  letterSpacing: "-2px",
                  textAlign: "left",
                }}
              >
                Lite
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  mb: 1,
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "600",
                  letterSpacing: "-3px",
                  textAlign: "left",
                  marginTop: "0",
                  fontSize: {
                    xl: "50px",
                    lg: "40px",
                    md: "40px",
                    sm: "50px",
                    xs: "50px",
                  },
                }}
              >
                {getPrice("19,90€/mois", "190€/an")}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginBottom: "20px",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "500",
                  letterSpacing: "-1px",
                  color: "white",
                  textAlign: "left",
                }}
              >
                {t("pricing.freeTrialPricing")}
              </Typography>
              <Box sx={{ textAlign: "left", ml: 2 }}>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Space Grotesk, sans-serif",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                  }}
                >
                  <CheckIcon sx={{ mr: 1, color: "white" }} />{" "}
                  {t("pricing.aToZ")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Space Grotesk, sans-serif",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                  }}
                >
                  <CheckIcon sx={{ mr: 1, color: "white" }} />{" "}
                  {t("pricing.pricingFees")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Space Grotesk, sans-serif",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                  }}
                >
                  <CheckIcon sx={{ mr: 1, color: "white" }} />{" "}
                  {t("pricing.limitedProducts")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Space Grotesk, sans-serif",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                  }}
                >
                  <CloseIcon sx={{ mr: 1, color: "white" }} />{" "}
                  {t("pricing.oneSiteAcademy")}
                </Typography>
              </Box>
            </Box>
            <Button
              variant="contained"
              onClick={handleClick}
              sx={{
                backgroundColor: "white",
                color: "#0042ff",
                fontFamily: "Inter Variable",
                letterSpacing: "-0.5px",
                fontWeight: "700",
                textTransform: "none",
                boxShadow: "none",
                borderRadius: "10px",
                fontSize: {
                  xl: "20px",
                  lg: "18px",
                  md: "12px",
                  sm: "16px",
                  xs: "16px",
                },
                "&:hover": {
                  backgroundColor: "#0042ff",
                  color: "white",
                  boxShadow: "none",
                },
                marginTop: "20px",
              }}
            >
              {t("pricing.startFreeTrial")}
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper
            sx={{
              background: "linear-gradient(to bottom, #0042ff, #75b3ff)",
              borderRadius: "20px",
              padding: "20px",
              color: "#fff",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ textAlign: "center", mb: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  backgroundColor: "#fff",
                  color: "#1565c0",
                  borderRadius: "20px",
                  padding: "5px 15px",
                  display: "inline-block",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "600",
                  letterSpacing: "-1px",
                }}
              >
                {t("pricing.bestSeller")}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "600",
                  mb: 1,
                  letterSpacing: "-2px",
                  textAlign: "left",
                }}
              >
                Basic
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  mb: 1,
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "600",
                  letterSpacing: "-3px",
                  textAlign: "left",
                  marginTop: "0",
                  fontSize: {
                    xl: "50px",
                    lg: "40px",
                    md: "40px",
                    sm: "50px",
                    xs: "50px",
                  },
                }}
              >
                {getPrice("39,90€/mois", "390€/an")}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginBottom: "20px",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "500",
                  letterSpacing: "-1px",
                  color: "white",
                  textAlign: "left",
                }}
              >
                {t("pricing.freeTrialPricing")}
              </Typography>
              <Box sx={{ textAlign: "left", ml: 2 }}>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Space Grotesk, sans-serif",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                  }}
                >
                  <CheckIcon sx={{ mr: 1, color: "white" }} />{" "}
                  {t("pricing.aToZ")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Space Grotesk, sans-serif",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                  }}
                >
                  <CheckIcon sx={{ mr: 1, color: "white" }} />{" "}
                  {t("pricing.pricingFees")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Space Grotesk, sans-serif",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                  }}
                >
                  <CheckIcon sx={{ mr: 1, color: "white" }} />{" "}
                  {t("pricing.unlimitedProducts")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Space Grotesk, sans-serif",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                  }}
                >
                  <CheckIcon sx={{ mr: 1, color: "white" }} />{" "}
                  {t("pricing.oneSiteAcademy")}
                </Typography>
              </Box>
            </Box>
            <Button
              variant="contained"
              onClick={handleClick}
              sx={{
                backgroundColor: "white",
                color: "#0042ff",
                fontFamily: "Inter Variable",
                letterSpacing: "-0.5px",
                fontWeight: "700",
                textTransform: "none",
                boxShadow: "none",
                borderRadius: "10px",
                fontSize: {
                  xl: "20px",
                  lg: "18px",
                  md: "12px",
                  sm: "16px",
                  xs: "16px",
                },
                "&:hover": {
                  backgroundColor: "#0042ff",
                  color: "white",
                  boxShadow: "none",
                },
                marginTop: "20px",
              }}
            >
              {t("pricing.startFreeTrial")}
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper
            sx={{
              background: "linear-gradient(to left bottom, #0042ff, #75b3ff)",
              borderRadius: "20px",
              padding: "20px",
              color: "#fff",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ textAlign: "center", mb: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  backgroundColor: "#fff",
                  color: "#1565c0",
                  borderRadius: "20px",
                  padding: "5px 15px",
                  display: "inline-block",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "600",
                  letterSpacing: "-1px",
                }}
              >
                {t("pricing.advancedPricing")}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "600",
                  mb: 1,
                  letterSpacing: "-2px",
                  textAlign: "left",
                }}
              >
                Premium
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  mb: 1,
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "600",
                  letterSpacing: "-3px",
                  textAlign: "left",
                  marginTop: "0",
                  fontSize: {
                    xl: "50px",
                    lg: "40px",
                    md: "40px",
                    sm: "50px",
                    xs: "50px",
                  },
                }}
              >
                {getPrice("99,90€/mois", "990€/an")}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginBottom: "20px",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "500",
                  letterSpacing: "-1px",
                  color: "white",
                  textAlign: "left",
                }}
              >
                {t("pricing.freeTrialPricing")}
              </Typography>
              <Box sx={{ textAlign: "left", ml: 2 }}>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Space Grotesk, sans-serif",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                  }}
                >
                  <CheckIcon sx={{ mr: 1, color: "white" }} />{" "}
                  {t("pricing.premiumOffer")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Space Grotesk, sans-serif",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                  }}
                >
                  <CheckIcon sx={{ mr: 1, color: "white" }} />{" "}
                  {t("pricing.pricingFees")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Space Grotesk, sans-serif",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                  }}
                >
                  <CheckIcon sx={{ mr: 1, color: "white" }} />{" "}
                  {t("pricing.unlimitedProducts")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Space Grotesk, sans-serif",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                  }}
                >
                  <CheckIcon sx={{ mr: 1, color: "white" }} />{" "}
                  {t("pricing.oneSiteAcademy")}
                </Typography>
              </Box>
            </Box>
            <Button
              variant="contained"
              onClick={handleClick}
              sx={{
                backgroundColor: "white",
                color: "#0042ff",
                fontSize: {
                  xl: "20px",
                  lg: "18px",
                  md: "12px",
                  sm: "16px",
                  xs: "16px",
                },
                fontFamily: "Inter Variable",
                letterSpacing: "-0.5px",
                fontWeight: "700",
                textTransform: "none",
                boxShadow: "none",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "#0042ff",
                  color: "white",
                  boxShadow: "none",
                },
                marginTop: "20px",
              }}
            >
              {t("pricing.startFreeTrial")}
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Pricing;
