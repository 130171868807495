import React, { useState } from "react";
import {
  TextField,
  Box,
  IconButton,
  Typography,
  Grid,
  InputAdornment,
  Button,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import HelpIconButton from "../../../../shared/tooltip/HelpIconButton";

const ProductVariations = ({ variations, onVariationsChange }) => {
  const { t } = useTranslation();
  const [currentVariation, setCurrentVariation] = useState({
    id: null,
    attributes: [{ id: null, name: "", option: "" }],
    price: "",
    imageUrl: null,
    imageFile: null,
  });
  const [editing, setEditing] = useState(false);

  const handleAddVariation = () => {
    setCurrentVariation({
      id: null,
      attributes: [{ id: null, name: "", option: "" }],
      price: "",
      imageUrl: null,
      imageFile: null,
    });
    setEditing(true);
  };

  const handleEditVariation = (variation) => {
    setCurrentVariation({
      ...variation,
      imageUrl: variation.imageUrl || variation.image?.src || null,
      imageFile: null,
    });
    setEditing(true);
  };

  const handleSaveVariation = () => {
    const variationToSave = {
      ...currentVariation,
      imageUrl: currentVariation.imageUrl,
      imageFile: currentVariation.imageFile,
    };

    if (currentVariation.id) {
      onVariationsChange(
        variations.map((v) =>
          v.id === currentVariation.id ? variationToSave : v
        )
      );
    } else {
      variationToSave.id = `${Date.now()}-${Math.random()}`;
      onVariationsChange([...variations, { ...variationToSave }]);
    }

    setCurrentVariation({
      id: null,
      attributes: [{ id: null, name: "", option: "" }],
      price: "",
      imageUrl: null,
      imageFile: null,
    });
    setEditing(false);
  };

  const handleCancelVariation = () => {
    setCurrentVariation({
      id: null,
      attributes: [{ id: null, name: "", option: "" }],
      price: "",
      imageUrl: null,
      imageFile: null,
    });
    setEditing(false);
  };

  const handleRemoveVariation = (variationId) => {
    const updatedVariations = variations.filter((v) => v.id !== variationId);
    onVariationsChange(updatedVariations);
  };

  const handleChangeVariation = (field, value) => {
    setCurrentVariation((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleAttributeChange = (field, value) => {
    const updatedAttributes = [...currentVariation.attributes];
    updatedAttributes[0] = { ...updatedAttributes[0], [field]: value };
    handleChangeVariation("attributes", updatedAttributes);
  };

  const handleVariationImageUpload = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      handleChangeVariation("imageFile", file);
      handleChangeVariation("imageUrl", URL.createObjectURL(file));
    }
  };

  const handleRemoveVariationImage = (event) => {
    event.stopPropagation();

    if (currentVariation.imageFile) {
      URL.revokeObjectURL(currentVariation.imageUrl);
      handleChangeVariation("imageFile", null);
    }

    handleChangeVariation("image", null);
    handleChangeVariation("imageUrl", null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleVariationImageUpload,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
      "image/webp": [],
      "image/heif": [],
      "image/heic": [],
    },
  });

  return (
    <Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", mb: 2, mt: 5 }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
              fontSize: "14px",
              fontFamily: "Inter Variable",
              marginBottom: "8px",
              letterSpacing: "-0.5px",
            }}
          >
            {t("produits.variantes")}
          </Typography>
          <HelpIconButton helpText={t("produits.help_variantes")} />
        </Box>
        {!editing && (
          <Button
            onClick={handleAddVariation}
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: {
                lg: "16px",
                md: "16px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
            }}
          >
            {t("produits.add_variation")}
          </Button>
        )}
      </Box>
      {variations.map((variation, index) => (
        <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Typography sx={{ fontWeight: "500", fontFamily: "Inter Variable" }}>
            {variation.attributes[0]?.name}: {variation.attributes[0]?.option} -{" "}
            {variation.price}€
          </Typography>
          <Box sx={{ ml: 3 }}>
            <IconButton
              onClick={() => handleEditVariation(variation)}
              disabled={editing}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleRemoveVariation(variation.id)}
              disabled={editing}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      ))}

      {editing && (
        <Box>
          <Divider sx={{ my: 2, height: "1px", bgcolor: "black" }} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ display: "flex" }}>
                <TextField
                  placeholder="Prix"
                  margin="dense"
                  id="variation-price"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={currentVariation.price}
                  onChange={(e) =>
                    handleChangeVariation("price", e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment sx={{ ml: 1 }} position="start">
                        €
                      </InputAdornment>
                    ),
                    sx: {
                      width: "100%",
                      height: "50px",
                      borderRadius: "12px",
                      backgroundColor: "white",
                      border: "1px solid #E3E3E3",
                      fontWeight: "500",
                      fontFamily: "Inter Variable, sans-serif",
                      fontSize: "14px",
                      padding: "0%",
                      letterSpacing: "-0.25px",
                      mb: 2,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                      input: {
                        color: "text.primary",
                      },
                      "&::placeholder": {
                        color: "#000000",
                        opacity: 0.8,
                      },
                    },
                  }}
                />
                <HelpIconButton helpText={t("produits.help_price")} />
              </Box>
              <Box sx={{ mb: 2 }}>
                <Divider sx={{ my: 2, height: "1px", bgcolor: "black" }} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      placeholder={t("produits.attribute")}
                      margin="dense"
                      id="variation-attribute-name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={currentVariation.attributes[0]?.name}
                      onChange={(e) =>
                        handleAttributeChange("name", e.target.value)
                      }
                      InputProps={{
                        sx: {
                          width: "100%",
                          height: "50px",
                          borderRadius: "12px",
                          backgroundColor: "white",
                          border: "1px solid #E3E3E3",
                          fontWeight: "500",
                          fontFamily: "Inter Variable, sans-serif",
                          fontSize: "14px",
                          padding: "0%",
                          letterSpacing: "-0.25px",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                          input: {
                            color: "text.primary",
                          },
                          "&::placeholder": {
                            color: "#000000",
                            opacity: 0.8,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      placeholder={t("produits.option")}
                      margin="dense"
                      id="variation-attribute-option"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={currentVariation.attributes[0].option}
                      onChange={(e) =>
                        handleAttributeChange("option", e.target.value)
                      }
                      InputProps={{
                        sx: {
                          width: "100%",
                          height: "50px",
                          borderRadius: "12px",
                          backgroundColor: "white",
                          border: "1px solid #E3E3E3",
                          fontWeight: "500",
                          fontFamily: "Inter Variable, sans-serif",
                          fontSize: "14px",
                          padding: "0%",
                          letterSpacing: "-0.25px",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                          input: {
                            color: "text.primary",
                          },
                          "&::placeholder": {
                            color: "#000000",
                            opacity: 0.8,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <HelpIconButton helpText={t("produits.help_attribute")} />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                {...getRootProps()}
                sx={{
                  border: "2px dashed #9a9b9f",
                  borderRadius: "16px",
                  padding: "40px",
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  mt: 2,
                  mb: 2,
                  width: "100%",
                  backgroundColor: "#fcfcfc",
                }}
              >
                <input {...getInputProps()} />
                {currentVariation.imageUrl ? (
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      margin: "auto",
                    }}
                  >
                    <img
                      src={currentVariation.imageUrl}
                      alt="Preview"
                      style={{
                        width: "120px",
                        height: "120px",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                    <IconButton
                      onClick={(event) => handleRemoveVariationImage(event)}
                      sx={{
                        position: "absolute",
                        top: -10,
                        right: -10,
                        backgroundColor: "white",
                        "&:hover": {
                          backgroundColor: "white",
                        },
                      }}
                    >
                      <DeleteIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontFamily: "Inter Variable, sans-serif",
                      fontWeight: 500,
                      letterSpacing: "-0.25px",
                      fontSize: { xs: "16px", md: "20px" },
                      textAlign: "center",
                      color: "#43444c",
                    }}
                  >
                    {t("produits.drop_or_click_upload")}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              onClick={handleCancelVariation}
              sx={{
                bgcolor: "#F3F4F6",
                color: "#303030",
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable, sans-serif",
                fontWeight: "500",
                letterSpacing: "-0.25px",
                textTransform: "none",
                ml: "auto",
                pl: "3%",
                pr: "3%",
                boxShadow: "none",
                borderRadius: "10px",
                border: "1px solid #ECEFF2",
                outline: "1px solid #303030",
                "&:hover": {
                  bgcolor: "#e2e2e2",
                  color: "#303030",
                  boxShadow: "none",
                },
              }}
            >
              {t("produits.cancel_variation")}
            </Button>
            <Button
              onClick={handleSaveVariation}
              disabled={
                !currentVariation.price ||
                !currentVariation.attributes[0]?.name ||
                !currentVariation.attributes[0]?.option
              }
              sx={{
                background: "#0042ff",
                color: "white",
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
                ml: 2,
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.25px",
                textTransform: "none",
                borderRadius: "10px",
                border: "1px solid #276CFA",
                outline: "1px solid #0646C6",
                "&:hover": {
                  backgroundColor: "#1459e2",
                  boxShadow: "none",
                },
              }}
            >
              {t("produits.save_variation")}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProductVariations;
