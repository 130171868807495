import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  Paper,
  Box,
  Typography,
  TextField,
  LinearProgress,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import { useTranslation } from "react-i18next";
import MenuConnexion from "../../shared/menuConnexion/MenuConnexion";
import ToastError from "../../shared/toast/ToastError";
import LoadingScreen from "../../shared/loader/LoadingScreen";

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          style: {
            fontFamily: "Inter Variable",
            fontWeight: "400",
            fontSize: "15px",
          },
        },
      },
    },
  },
});

function ConfirmationPasswordReset() {
  const { t } = useTranslation();
  const { token } = useParams();
  const [nouveauMotDePasse, setNouveauMotDePasse] = useState("");
  const [confirmationMotDePasse, setConfirmationMotDePasse] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingBackground, setLoadingBackground] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const navigate = useNavigate();

  const checkPasswordStrength = (password) => {
    let score = 0;

    if (password.length >= 5) score += 1;
    if (/\d/.test(password)) score += 1;
    if (/[a-z]/.test(password) && /[A-Z]/.test(password)) score += 1;
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) score += 1;

    return score;
  };

  const isPasswordValid = (password) => {
    return (
      password.length >= 5 && /\d/.test(password) && !/^[a-z]+$/.test(password)
    );
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNouveauMotDePasse(newPassword);
    setPasswordStrength(checkPasswordStrength(newPassword));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (nouveauMotDePasse !== confirmationMotDePasse) {
      handleError(t("confirmationPasswordReset.passwordMismatch"));
      return;
    }

    if (!isPasswordValid(nouveauMotDePasse)) {
      handleError(t("confirmationPasswordReset.invalidPassword"));
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/reinitialiser-mot-de-passe`,
        {
          token,
          nouveauMotDePasse,
        }
      );
      setMessage(response.data.message);
      setNouveauMotDePasse("");
      setConfirmationMotDePasse("");
      setTimeout(() => {
        setMessage("");
        navigate("/connexion-compte");
      }, 1000);
    } catch (error) {
      handleError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const handleBackgroundLoad = () => {
    setLoadingBackground(false);
  };

  return (
    <ThemeProvider theme={theme}>
      {loadingBackground && <LoadingScreen />}
      <div style={{ display: loadingBackground ? "none" : "block" }}>
        <img
          src={process.env.PUBLIC_URL + "/background/one-site-bg.png"}
          alt="Background"
          onLoad={handleBackgroundLoad}
          style={{ display: "none" }}
        />
        <Paper
          sx={{
            width: "100%",
            height: "100vh",
            overflowX: "auto",
            boxShadow: "none",
            backgroundImage: loadingBackground
              ? "none"
              : `url(${
                  process.env.PUBLIC_URL + "/background/one-site-bg.png"
                })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MenuConnexion />
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "10%",
            }}
          >
            <Box
              sx={{
                width: { xs: "90%", sm: "500px", md: "600px", lg: "700px" },
                minHeight: "450px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: "20px",
                bgcolor: "white",
                boxShadow: "0 0 100px 0 rgba(0, 150, 255, 0.2)",
                padding: { xs: "20px", sm: "30px" },
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: { xs: "24px", sm: "30px" },
                  fontFamily: "Space Grotesk",
                  fontWeight: "600",
                  textAlign: "center",
                  letterSpacing: "-0.5px",
                  mb: 3,
                }}
              >
                {t("confirmationPasswordReset.confirmNewPassword")}
              </Typography>

              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <TextField
                    fullWidth
                    placeholder={t("confirmationPasswordReset.newPassword")}
                    variant="outlined"
                    sx={{
                      mb: 2,
                      width: { xs: "90%", sm: "80%", md: "70%" },
                      ".MuiOutlinedInput-root": {
                        height: "50px",
                        borderRadius: "12px",
                        backgroundColor: "#eef6ff",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 0)",
                          boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1)",
                        },
                      },
                      ".MuiInputBase-input": {
                        fontFamily: "Inter Variable",
                        fontWeight: "500",
                        fontSize: "14px",
                        letterSpacing: "-0.25px",
                      },
                    }}
                    type="password"
                    value={nouveauMotDePasse}
                    onChange={handlePasswordChange}
                    required
                  />

                  <TextField
                    fullWidth
                    placeholder={t(
                      "confirmationPasswordReset.confirmNewPassword"
                    )}
                    variant="outlined"
                    sx={{
                      mb: 2,
                      width: { xs: "90%", sm: "80%", md: "70%" },
                      ".MuiOutlinedInput-root": {
                        height: "50px",
                        borderRadius: "12px",
                        backgroundColor: "#eef6ff",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 0)",
                          boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1)",
                        },
                      },
                      ".MuiInputBase-input": {
                        fontFamily: "Inter Variable",
                        fontWeight: "500",
                        fontSize: "14px",
                        letterSpacing: "-0.25px",
                      },
                    }}
                    type="password"
                    value={confirmationMotDePasse}
                    onChange={(e) => setConfirmationMotDePasse(e.target.value)}
                    required
                  />

                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mb: 3, mt: 3 }}
                  >
                    <LinearProgress
                      variant="determinate"
                      value={(passwordStrength / 4) * 100}
                      sx={{
                        width: { xs: "90%", sm: "80%", md: "70%" },
                        height: "10px",
                        borderRadius: "5px",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor:
                            passwordStrength < 2
                              ? "red"
                              : passwordStrength < 3
                              ? "orange"
                              : "green",
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        mt: 1,
                        color:
                          passwordStrength < 2
                            ? "red"
                            : passwordStrength < 3
                            ? "orange"
                            : "green",
                        fontFamily: "Inter Variable, sans-serif",
                        fontWeight: "600",
                      }}
                    >
                      {passwordStrength < 2
                        ? "Faible"
                        : passwordStrength < 3
                        ? "Moyen"
                        : "Fort"}
                    </Typography>
                  </Box>

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={loading}
                    sx={{
                      mt: 3,
                      bgcolor: "#0042ff",
                      color: "white",
                      fontSize: { xs: "14px", sm: "16px" },
                      fontFamily: "Space Grotesk, sans-serif",
                      fontWeight: "500",
                      letterSpacing: "-0.25px",
                      textTransform: "none",
                      borderRadius: "10px",
                      border: "1px solid #276CFA",
                      outline: "1px solid #0646C6",
                      "&:hover": {
                        backgroundColor: "#1459e2",
                        boxShadow: "none",
                      },
                    }}
                  >
                    {t("confirmationPasswordReset.reset")}
                  </LoadingButton>
                </Box>
              </form>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                  width: "100%",
                }}
              >
                {message && <Alert severity="success">{message}</Alert>}
                <ToastError
                  open={error !== null}
                  onClose={() => setError(null)}
                  message={error}
                />
              </Box>
            </Box>
          </Box>
        </Paper>
      </div>
    </ThemeProvider>
  );
}

export default ConfirmationPasswordReset;
