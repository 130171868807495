import { useParams } from "react-router-dom";
import VisualBuilder from "../../components/visualBuilder/preview/VisualBuilder";

function Preview() {
  const urlPreview = sessionStorage.getItem("urlPreview");
  const postId = sessionStorage.getItem("postId");
  const templateId = sessionStorage.getItem("templateId");

  const { abonnementId, siteNom } = useParams();

  return (
    <VisualBuilder
      urlPreview={urlPreview}
      postId={postId}
      templateId={templateId}
      abonnementId={abonnementId}
      siteNom={siteNom}
    ></VisualBuilder>
  );
}
export default Preview;
