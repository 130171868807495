const { Chip } = require("@mui/material");
const { useTranslation } = require("react-i18next");

const GetStatusChip = (status) => {
  const { t } = useTranslation();

  const orderStatusTranslations = {
    pending: t("commande.enAttente"),
    processing: t("commande.enCours"),
    completed: t("commande.terminee"),
    suspend: t("commande.enSuspens"),
    cancelled: t("commande.annulee"),
    refunded: t("commande.remboursee"),
    failed: t("commande.echouee"),
  };

  const statusLowerCase = status.toLowerCase();
  let color = "default";
  switch (statusLowerCase) {
    case "pending":
      color = "warning";
      break;
    case "processing":
      color = "info";
      break;
    case "completed":
      color = "success";
      break;
    case "suspend":
      color = "secondary";
      break;
    case "cancelled":
      color = "error";
      break;
    default:
      color = "default";
  }

  const label = orderStatusTranslations[statusLowerCase] || status;

  return (
    <Chip
      label={label}
      color={color}
      sx={{
        borderRadius: "10px",
        fontFamily: "Inter Variable",
        fontWeight: "600",
        letterSpacing: "-0.5px",
      }}
    />
  );
};

export default GetStatusChip;
