import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CommandeCard from "./CommandeCard";
import RowCommande from "./RowCommande";
import { API_SERVER_ENDPOINT } from "../../../../shared/api/ConstantesEndpoints";
import { useTranslation } from "react-i18next";
import Loader from "../../../../shared/loader/Loader";
import ExportIcon from "@mui/icons-material/GetApp";
import ExportOrdersDialog from "./ExportOrdersDialog";
import ToastError from "../../../../shared/toast/ToastError";

const GestionCommandes = ({ siteConsulte }) => {
  const { t } = useTranslation();

  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const loadCommandes = useCallback(() => {
    if (siteConsulte && siteConsulte.nom) {
      const url = `${API_SERVER_ENDPOINT}/commandes?siteNom=${encodeURIComponent(
        siteConsulte.nom
      )}`;

      axios
        .get(url)
        .then((response) => {
          setOrders(response.data);
        })
        .catch((error) => {
          handleError(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [siteConsulte]);

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  useEffect(() => {
    loadCommandes();
  }, [loadCommandes]);

  const handleStatusChange = (orderId, status) => {
    setIsLoading(true);
    axios
      .post(`${API_SERVER_ENDPOINT}/commandes/status`, {
        siteNom: siteConsulte.nom,
        orderId,
        status,
      })
      .then(() => {
        loadCommandes();
        setIsLoading(false);
      })
      .catch((error) => {
        handleError(error.message);
        setIsLoading(false);
      });
  };

  const handleExportDialogOpen = () => {
    setIsExportDialogOpen(true);
  };

  const handleExportDialogClose = () => {
    setIsExportDialogOpen(false);
  };

  return (
    <div
      style={{
        flexGrow: 1,
        padding: "2%",
        backgroundColor: "#f5f8fa",
        minHeight: "100vh",
      }}
    >
      <Paper
        sx={{
          margin: "0px",
          overflowX: "auto",
          boxShadow: "none",
          backgroundColor: "#f5f8fa",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#f5f8fa",
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontSize: { xs: "26px", sm: "60px" },
              fontFamily: "Space Grotesk, sans serif",
              fontWeight: "1000",
              letterSpacing: { xs: "-2px", sm: "-5px", md: "-5px", lg: "-5px" },
              ml: "2%",
              mt: { xs: "5%", sm: 0 },
            }}
          >
            {t("commande.commandes")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportDialogOpen}
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: {
                lg: "14px",
                md: "14px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              mr: 2,
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
            }}
          >
            {t("commande.exporterCommandes")}
            <ExportIcon sx={{ fontSize: "20px", ml: "3px" }} />
          </Button>
        </div>
        <Typography
          sx={{
            color: "black",
            fontSize: "16px",
            fontFamily: "Inter Variable, sans-serif",
            fontWeight: "500",
            letterSpacing: "-0.75px",
            mb: "3%",
            mt: "0%",
            ml: "2%",
          }}
        >
          {t("commande.consulterModifierTraiterCommandes")}
        </Typography>

        <Box
          marginTop="2rem"
          sx={{
            borderRadius: "20px",
            boxShadow: "none",
            width: { xl: "90%", lg: "90%", md: "95%", sm: "95%", xs: "95%" },
            mb: "1%",
            ml: "3%",
          }}
        >
          {orders.length > 0 ? (
            isMobile ? (
              orders.map((order) => (
                <CommandeCard
                  key={order.id}
                  order={order}
                  handleStatusChange={handleStatusChange}
                />
              ))
            ) : (
              <TableContainer
                component={Paper}
                style={{ borderRadius: "20px", border: "1px solid #c6c7ce" }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "600",
                        }}
                      >
                        #
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        {t("commande.date")}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        {t("commande.client")}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        {t("commande.montant")}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        {t("commande.statut")}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        {t("commande.actions")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders?.map((order) => (
                      <RowCommande
                        key={order.id}
                        order={order}
                        handleStatusChange={handleStatusChange}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          ) : (
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                color: "grey",
                fontSize: "20px",
                fontFamily: "Inter Variable, sans serif",
                letterSpacing: "-1px",
                fontWeight: "500",
                mt: "10%",
                textAlign: "center",
              }}
            >
              {t("commande.aucuneCommandeAfficher")}
            </Typography>
          )}
        </Box>
      </Paper>
      <Loader loading={isLoading} />
      <ExportOrdersDialog
        open={isExportDialogOpen}
        onClose={handleExportDialogClose}
        siteNom={siteConsulte.nom}
      />

      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </div>
  );
};

export default GestionCommandes;
