import React, { useCallback, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import { useTranslation } from "react-i18next";
import ToastError from "../../shared/toast/ToastError";

const DeletePageDialog = ({
  open,
  onClose,
  page,
  siteNom,
  fetchPages,
  onDeleteSuccess,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleError = useCallback((message) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 3000);
  }, []);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await axios.delete(`${API_SERVER_ENDPOINT}/page`, {
        data: { siteNom, pageId: page.post_id, pageUrl: page.url },
      });
      fetchPages();
      onDeleteSuccess();
      onClose();
    } catch (error) {
      const erreurMessage = error.response?.data?.message || error.message;
      handleError(erreurMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        sx: {
          borderRadius: "20px",
          width: "90%",
          maxWidth: "600px",
          height: "auto",
          maxHeight: "90vh",
          backgroundColor: "#f9f9f9",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: { lg: "20px", md: "16px", sm: "14px", xs: "12px" },
            color: "black",
            fontFamily: "Space Grotesk",
            fontWeight: "700",
            letterSpacing: "-1px",
          }}
        >
          {t("deletePageDialog.confirmDeletion")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("deletePageDialog.confirmDeletionMessage", {
            pageName: page?.nom,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", width: "50%" }}>
        <Button
          onClick={onClose}
          sx={{
            color: "white",
            fontSize: {
              xl: "20px",
              lg: "20px",
              md: "18px",
              sm: "16px",
              xs: "14px",
            },
            px: "6%",
            mt: "5%",
            ml: "2%",
            fontFamily: "Space Grotesk",
            fontWeight: "700",
            background: "#878ca7",
            letterSpacing: "-1px",
            textTransform: "none",
            boxShadow: "none",
            borderRadius: "10px",
            "&:hover": {
              background: "#5e6172",
              boxShadow: "none",
              color: "white",
            },
          }}
        >
          {t("deletePageDialog.cancel")}
        </Button>
        <LoadingButton
          onClick={handleDelete}
          loading={loading}
          variant="contained"
          color="primary"
          sx={{
            mt: "5%",
            ml: "2%",
            px: "6%",
            fontFamily: "Space Grotesk",
            fontWeight: "700",
            background: "#ff0042",
            color: "white",
            fontSize: {
              xl: "20px",
              lg: "20px",
              md: "18px",
              sm: "16px",
              xs: "14px",
            },
            textTransform: "none",
            letterSpacing: "-1px",
            boxShadow: "none",
            borderRadius: "10px",
            "&:hover": {
              background: "#c10033",
              boxShadow: "none",
              color: "white",
            },
          }}
        >
          {t("deletePageDialog.delete")}
        </LoadingButton>
      </DialogActions>
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </Dialog>
  );
};

export default DeletePageDialog;
