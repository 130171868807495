import React from "react";
import { Box, Typography, Grid, Paper, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Fonctionnement() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleClick = () => {
    navigate("/informations");
  };

  return (
    <Box
      id="fonctionnement"
      sx={{
        backgroundColor: "#02011b",
        color: "#fff",
        padding: "40px 20px",
        textAlign: "center",
        minHeight: "1024px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontFamily: "Inter Variable",
          fontWeight: "400",
          marginBottom: "10px",
          letterSpacing: "-0.5px",
          textTransform: "uppercase",
          fontSize: {
            xl: "18px",
            lg: "16px",
            md: "14px",
            sm: "14px",
            xs: "14px",
          },
          color: "#0042ff",
        }}
      >
        {t("fonctionnement.commentCaMarche")}
      </Typography>
      <Typography
        variant="h3"
        sx={{
          fontSize: {
            xl: "50px",
            lg: "40px",
            md: "40px",
            sm: "40px",
            xs: "30px",
          },
          fontFamily: "Space Grotesk, sans-serif",
          fontWeight: "600",
          letterSpacing: {
            xl: "-3px",
            lg: "-2.5px",
            md: "-2.5px",
            sm: "-2px",
            xs: "-1.75px",
          },
          marginBottom: "40px",
        }}
      >
        {t("fonctionnement.futur")}
      </Typography>
      <Grid container spacing={4} direction="column" alignItems="center">
        <Grid item xs={12}>
          <Paper
            sx={{
              width: { xs: "100%", md: "1000px" },
              height: { xs: "100%", md: "200px" },
              padding: "20px",
              background: "linear-gradient(to right, #0042ff, #75b3ff)",
              borderRadius: "10px",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="h5"
                sx={{
                  marginBottom: "10px",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "600",
                  letterSpacing: "-2px",
                  marginTop: "0%",
                  color: "white",
                  textAlign: "left",
                }}
              >
                {t("fonctionnement.step1")}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: "20px",
                  marginTop: "-2%",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "600",
                  letterSpacing: "-3px",
                  color: "white",
                  textAlign: "left",
                }}
              >
                {t("fonctionnement.importYourProduct")}
              </Typography>
              <Typography
                sx={{
                  width: { md: "60%", sm: "100%" },
                  marginBottom: "20px",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "500",
                  letterSpacing: "-1px",
                  color: "white",
                  textAlign: "left",
                }}
              >
                {t("fonctionnement.importDescription")}
              </Typography>
            </Box>
            <Box
              component="img"
              src="/images/step1.png"
              alt="Importez votre produit"
              sx={{
                width: "100%",
                maxWidth: "300px",
                height: "auto",
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              width: { xs: "100%", md: "1000px" },
              height: { xs: "100%", md: "200px" },
              background: "linear-gradient(to left, #0042ff, #75b3ff)",
              padding: "20px",
              backgroundColor: "#1565c0",
              borderRadius: "10px",
              display: "flex",
              flexDirection: { xs: "column", md: "row-reverse" },
              alignItems: "center",
              justifyContent: "space-between",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="h5"
                sx={{
                  marginBottom: "10px",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "600",
                  letterSpacing: "-2px",
                  marginTop: "0%",
                  color: "white",
                  textAlign: "right",
                }}
              >
                {t("fonctionnement.step2")}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: "20px",
                  marginTop: "-2%",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "600",
                  letterSpacing: "-3px",
                  color: "white",
                  textAlign: "right",
                }}
              >
                {t("fonctionnement.chooseBranding")}
              </Typography>
              <Typography
                sx={{
                  marginBottom: "20px",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "500",
                  letterSpacing: "-1px",
                  color: "white",
                  textAlign: "right",
                }}
              >
                {t("fonctionnement.brandingDescriptionUn")}
              </Typography>
              <Typography
                sx={{
                  mt: "-3%",
                  marginBottom: "20px",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "500",
                  letterSpacing: "-1px",
                  color: "white",
                  textAlign: "right",
                }}
              >
                {t("fonctionnement.brandingDescriptionDeux")}
              </Typography>
            </Box>
            <Box
              component="img"
              src="images/step2.png"
              alt="Choisissez votre branding"
              sx={{
                width: "100%",
                maxWidth: "300px",
                height: "auto",
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              width: { xs: "100%", md: "1000px" },
              height: { xs: "100%", md: "200px" },
              padding: "20px",
              background: "linear-gradient(to right, #0042ff, #75b3ff)",
              borderRadius: "10px",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="h5"
                sx={{
                  marginBottom: "10px",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "600",
                  letterSpacing: "-2px",
                  marginTop: "0%",
                  color: "white",
                  textAlign: "left",
                }}
              >
                {t("fonctionnement.step3")}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: "10px",
                  marginTop: "-2%",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "600",
                  letterSpacing: "-3px",
                  color: "white",
                  textAlign: "left",
                }}
              >
                {t("fonctionnement.generateWebsite")}
              </Typography>
              <Typography
                sx={{
                  width: { md: "50%", sm: "100%" },
                  marginBottom: "10px",
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: "500",
                  letterSpacing: "-1px",
                  color: "white",
                  textAlign: "left",
                }}
              >
                {t("fonctionnement.generateWebsiteDesc")}
              </Typography>
            </Box>
            <Box
              component="img"
              src="/images/step3.png"
              alt="Générez votre site"
              sx={{
                width: "100%",
                maxWidth: "300px",
                height: "auto",
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      <Button
        onClick={handleClick}
        variant="contained"
        sx={{
          backgroundColor: "#124ae5",
          color: "white",
          fontSize: {
            xs: "16px",
            sm: "18px",
            md: "18px",
            lg: "20px",
            xl: "20px",
          },
          fontFamily: "Inter Variable",
          letterSpacing: "-0.5px",
          mt: { xs: "10%", md: "7%", lg: "5%", xl: "5%" },
          fontWeight: "700",
          textTransform: "none",
          boxShadow: "none",
          borderRadius: "10px",
          padding: "10px 20px",
          "&:hover": {
            backgroundColor: "#03247f",
            boxShadow: "none",
          },
          marginBottom: "1%",
        }}
      >
        {t("fonctionnement.startFreeTrial")}
      </Button>
    </Box>
  );
}

export default Fonctionnement;
