import React from "react";
import { Typography, Box, Grid, Card } from "@mui/material";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const LiveView = ({ realTimeData, dateRange }) => {
  const {
    cart_view = 0,
    checkout_view = 0,
    thank_you_visit = 0,
  } = realTimeData || {};

  const maxEvents = 20;

  const generateChartData = (value) => {
    const points = 9;
    const curveData = Array.from({ length: points }, (_, i) => ({
      time: i,
      value: Math.sin((Math.PI * i) / (points - 1)) * value,
    }));
    return curveData;
  };

  const renderAreaChart = (data, color) => (
    <Box sx={{ height: 200, display: "flex", justifyContent: "center" }}>
      <ResponsiveContainer width="100%" height="100%" aspect={1.5}>
        <AreaChart data={generateChartData(data)}>
          <XAxis dataKey="time" hide />
          <YAxis hide domain={[0, maxEvents]} />
          <Tooltip
            contentStyle={{
              display: "none",
            }}
            cursor={false}
          />
          <Area
            type="monotone"
            dataKey="value"
            stroke={color}
            fillOpacity={0.8}
            fill={color}
            activeDot={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );

  return (
    <Card
      raised
      sx={{
        m: 2,
        p: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        boxShadow: "none",
        border: "2px solid #c6c7ce",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          mb: 2,
          fontFamily: "Space Grotesk, sans serif",
          fontWeight: "1000",
          letterSpacing: { xs: "-1px", sm: "-1px", md: "-2px", lg: "-3px" },
          fontSize: { xs: "25px", sm: "25px", md: "30px", lg: "40px" },
        }}
      >
        Comportement client
      </Typography>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={4}>
          {renderAreaChart(cart_view, "#8884d8")}
          <Typography
            variant="h6"
            align="center"
            sx={{
              color: "black",
              fontSize: { xl: "18px", lg: "18px", md: "18px" },
              fontFamily: "Inter Variable",
              letterSpacing: "-0.5px",
              fontWeight: "600",
              textAlign: "center",
              mt: "2%",
            }}
          >
            {cart_view}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            sx={{
              color: "black",
              fontSize: { xl: "14px", lg: "14px", md: "14px" },
              fontFamily: "Inter Variable",
              letterSpacing: "-0.25px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Paniers actifs
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {renderAreaChart(checkout_view, "#82ca9d")}
          <Typography
            variant="h6"
            align="center"
            sx={{
              color: "black",
              fontSize: { xl: "18px", lg: "18px", md: "18px" },
              fontFamily: "Inter Variable",
              letterSpacing: "-0.5px",
              fontWeight: "600",
              textAlign: "center",
              mt: "2%",
            }}
          >
            {checkout_view}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            sx={{
              color: "black",
              fontSize: { xl: "14px", lg: "14px", md: "14px" },
              fontFamily: "Inter Variable",
              letterSpacing: "-0.25px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Passage en caisse
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {renderAreaChart(thank_you_visit, "#ffc658")}
          <Typography
            variant="h6"
            align="center"
            sx={{
              color: "black",
              fontSize: { xl: "18px", lg: "18px", md: "18px" },
              fontFamily: "Inter Variable",
              letterSpacing: "-0.5px",
              fontWeight: "600",
              textAlign: "center",
              mt: "2%",
            }}
          >
            {thank_you_visit}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            sx={{
              color: "black",
              fontSize: { xl: "14px", lg: "14px", md: "14px" },
              fontFamily: "Inter Variable",
              letterSpacing: "-0.25px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Ventes
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: { xs: "2rem", md: "2rem" } }}>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          sx={{ mb: 1, fontFamily: "Inter Variable", textAlign: "center" }}
        >
          {dateRange}
        </Typography>
      </Box>
    </Card>
  );
};

export default LiveView;
