import React, { useState } from "react";
import {
  Paper,
  Typography,
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Grid,
  Dialog,
  IconButton,
  DialogContent,
} from "@mui/material";
import WooCommerceStats from "./WooCommerceStats";
import AnalyticsStats from "./AnalyticsStats";
import { startOfDay, subDays, subMonths } from "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fr } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import PixelForm from "./PixelForm";
import CustomDateRangePicker from "../../../../shared/date/DatePicker";

const GestionStats = ({ siteConsulte }) => {
  const { t } = useTranslation();
  const [selectedPeriod, setSelectedPeriod] = useState("week");
  const [startDate, setStartDate] = useState(
    startOfDay(subDays(new Date(), 6))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [granularity, setGranularity] = useState("semaine");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [openPixelForm, setOpenPixelForm] = useState(false);

  const setWeek = () => {
    const now = new Date();
    const start = subDays(now, 6);
    setStartDate(startOfDay(start));
    setEndDate(now);
    setSelectedPeriod("week");
    setGranularity("semaine");
  };

  const setMonth = () => {
    const now = new Date();
    const start = subDays(now, 29);
    setStartDate(startOfDay(start));
    setEndDate(now);
    setSelectedPeriod("month");
    setGranularity("mois");
  };

  const setYear = () => {
    const now = new Date();
    const start = subMonths(now, 11);
    setStartDate(startOfDay(start));
    setEndDate(now);
    setSelectedPeriod("year");
    setGranularity("annee");
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setSelectedPeriod("custom");
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setSelectedPeriod("custom");
  };

  const handleOpenPixelForm = () => {
    setOpenPixelForm(true);
  };

  const handleClosePixelForm = () => {
    setOpenPixelForm(false);
  };

  return (
    <div style={{ flexGrow: 1, padding: "2%", backgroundColor: "#f5f8fa" }}>
      <Paper
        sx={{
          margin: "0px",
          overflowX: "auto",
          boxShadow: "none",
          backgroundColor: "#f5f8fa",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontSize: { xs: "26px", sm: "60px" },
              fontFamily: "Space Grotesk, sans serif",
              fontWeight: "1000",
              letterSpacing: { xs: "-2px", sm: "-5px", md: "-5px", lg: "-5px" },
              ml: "2%",
              mt: { xs: "5%", sm: 0 },
            }}
          >
            {t("statistique.statistics")}
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenPixelForm}
              sx={{
                background: "#0042ff",
                color: "white",
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.25px",
                textTransform: "none",
                mr: 2,
                borderRadius: "10px",
                border: "1px solid #276CFA",
                outline: "1px solid #0646C6",
                "&:hover": {
                  backgroundColor: "#1459e2",
                  boxShadow: "none",
                },
              }}
            >
              {t("statistique.add_pixel")}
              <AddIcon sx={{ fontSize: "20px", ml: "3px" }} />
            </Button>
          </Box>
        </div>

        <Typography
          sx={{
            color: "black",
            fontSize: "16px",
            fontFamily: "Inter Variable, sans-serif",
            fontWeight: "500",
            letterSpacing: "-0.75px",
            mb: "3%",
            mt: "0%",
            ml: "2%",
          }}
        >
          {t("statistique.analyze_stats")}
        </Typography>

        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
          <Box sx={{ my: { xs: "2rem", sm: "1rem" }, mx: "3rem" }}>
            <Grid
              container
              spacing={3}
              direction={isMobile ? "column" : "row"}
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <Box
                  display="flex"
                  gap={2}
                  ml="-2%"
                  justifyContent={isMobile ? "center" : "left"}
                  alignItems="center"
                >
                  <Button
                    variant={
                      selectedPeriod === "week" ? "contained" : "outlined"
                    }
                    onClick={setWeek}
                    sx={{
                      borderRadius: "8px",
                      fontFamily: "Space Grotesk, sans-serif",
                      textAlign: "center",
                      letterSpacing: "-1px",
                      fontWeight: selectedPeriod === "week" ? "600" : "400",
                      fontSize: "16px",
                      textTransform: "none",
                      backgroundColor:
                        selectedPeriod === "week" ? "#0042FF" : "#ededf5",
                      color: selectedPeriod === "week" ? "white" : "#000000",
                      border: "none",
                      boxShadow: "none",
                      "&:hover": {
                        backgroundColor:
                          selectedPeriod === "week" ? "#0032CC" : "#b7b7be",
                        boxShadow: "none",
                        border: "none",
                      },
                    }}
                  >
                    {t("statistique.week")}
                  </Button>
                  <Button
                    variant={
                      selectedPeriod === "month" ? "contained" : "outlined"
                    }
                    onClick={setMonth}
                    sx={{
                      borderRadius: "8px",
                      fontFamily: "Space Grotesk, sans-serif",
                      textAlign: "center",
                      letterSpacing: "-1px",
                      fontWeight: selectedPeriod === "month" ? "600" : "400",
                      fontSize: "16px",
                      textTransform: "none",
                      backgroundColor:
                        selectedPeriod === "month" ? "#0042FF" : "#ededf5",
                      color: selectedPeriod === "month" ? "white" : "#000000",
                      border: "none",
                      boxShadow: "none",
                      "&:hover": {
                        backgroundColor:
                          selectedPeriod === "month" ? "#0032CC" : "#b7b7be",
                        boxShadow: "none",
                        border: "none",
                      },
                    }}
                  >
                    {t("statistique.month")}
                  </Button>
                  <Button
                    variant={
                      selectedPeriod === "year" ? "contained" : "outlined"
                    }
                    onClick={setYear}
                    sx={{
                      borderRadius: "8px",
                      fontFamily: "Space Grotesk, sans-serif",
                      textAlign: "center",
                      letterSpacing: "-1px",
                      fontWeight: selectedPeriod === "year" ? "600" : "400",
                      fontSize: "16px",
                      textTransform: "none",
                      backgroundColor:
                        selectedPeriod === "year" ? "#0042FF" : "#ededf5",
                      color: selectedPeriod === "year" ? "white" : "#000000",
                      border: "none",
                      boxShadow: "none",
                      "&:hover": {
                        backgroundColor:
                          selectedPeriod === "year" ? "#0032CC" : "#b7b7be",
                        boxShadow: "none",
                        border: "none",
                      },
                    }}
                  >
                    {t("statistique.year")}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  display="flex"
                  gap={2}
                  justifyContent={isMobile ? "center" : "right"}
                  alignItems="center"
                >
                  <CustomDateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onStartDateChange={handleStartDateChange}
                    onEndDateChange={handleEndDateChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </LocalizationProvider>

        <WooCommerceStats
          siteConsulte={siteConsulte}
          startDate={startDate}
          endDate={endDate}
          granularity={granularity}
        />

        <AnalyticsStats
          siteConsulte={siteConsulte}
          startDate={startDate}
          endDate={endDate}
          granularity={granularity}
        />
      </Paper>
      <Dialog
        open={openPixelForm}
        onClose={handleClosePixelForm}
        aria-labelledby="form-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClosePixelForm}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <PixelForm
            siteConsulte={siteConsulte}
            onClose={handleClosePixelForm}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default GestionStats;
