import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import "@fontsource-variable/inter";
import HeaderVisualBuilder from "./HeaderVisualBuilder";
import Sidebar from "./SideBar";
import PreviewSite from "./PreviewSite";
import AttributeEditor from "./AttributeEditor";
import { addHttpsPrefix, convertHtmlToText } from "../../shared/html/HtmlUtils";
import Drawer from "@mui/material/Drawer";
import { v4 as uuidv4 } from "uuid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import AddPageDialog from "./AddPageDialog";
import DeletePageDialog from "./DeletePageDialog";
import LogoForm from "./LogoForm";
import FaviconForm from "./FaviconForm";
import ToastError from "../../shared/toast/ToastError";

const VisualBuilder = ({ siteConsulte }) => {
  const [modulesBdd, setModulesBdd] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedInstanceId, setSelectedInstanceId] = useState(null);
  const [instancesData, setInstancesData] = useState([]);
  const [pages, setPages] = useState([]);
  const [pageSelectionnee, setPageSelectionnee] = useState(null);
  const [attributeModifications, setAttributeModifications] = useState({});
  const [products, setProducts] = useState([]);
  const [isLogoFormOpen, setIsLogoFormOpen] = useState(false);
  const [isFaviconFormOpen, setIsFaviconFormOpen] = useState(false);

  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const [customerId] = useState(profil?.customerId);

  const [openAddPageDialog, setOpenAddPageDialog] = useState(false);
  const [openDeletePageDialog, setOpenDeletePageDialog] = useState(false);

  const [refreshKey, setRefreshKey] = useState(0);
  const [loading, setLoading] = useState(false);

  const [expandedAccordions, setExpandedAccordions] = useState([]);
  const [mobileEditMode, setMobileEditMode] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xl"));
  const [error, setError] = useState(null);

  const handleError = useCallback((message) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 3000);
  }, []);

  const fetchPages = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_SERVER_ENDPOINT}/pages/site/${siteConsulte.nom}`
      );
      const pagesData = response.data.pages;
      setPages(pagesData);
      if (!pageSelectionnee && pagesData.length > 0) {
        const accueilPage = pagesData.find(
          (page) => page.nom.toLowerCase() === "accueil"
        );
        setPageSelectionnee(accueilPage || pagesData[0]);
      }
    } catch (error) {
      handleError(error.message);
    }
  }, [siteConsulte?.nom, pageSelectionnee, handleError]);

  const fetchModules = useCallback(async () => {
    try {
      const response = await axios.get(`${API_SERVER_ENDPOINT}/modules`);
      setModulesBdd(response.data);
    } catch (error) {
      handleError(error.message);
    }
  }, [handleError]);

  const fetchPageSelectionneData = useCallback(async () => {
    if (!pageSelectionnee) return;
    try {
      const response = await axios.get(
        `${API_SERVER_ENDPOINT}/page/${customerId}/site/${siteConsulte.nom}`,
        { params: { pageName: pageSelectionnee.nom } }
      );

      setInstancesData(response.data.instances);
    } catch (error) {
      handleError(error.message);
    }
  }, [customerId, pageSelectionnee, siteConsulte, handleError]);

  const loadProducts = useCallback(() => {
    if (siteConsulte?.nom && siteConsulte?.woocommerceActive) {
      const url = `${API_SERVER_ENDPOINT}/products?siteNom=${encodeURIComponent(
        siteConsulte.nom
      )}`;

      axios
        .get(url)
        .then((response) => {
          const processedProducts = response.data.map((product) => ({
            ...product,
            description: convertHtmlToText(product.description),
          }));

          setProducts(processedProducts);
        })
        .catch((error) => {
          handleError(error.message);
        });
    }
  }, [siteConsulte, handleError]);

  useEffect(() => {
    fetchPages();
    fetchModules();
    loadProducts();
  }, [fetchPages, fetchModules, loadProducts]);

  useEffect(() => {
    fetchPageSelectionneData();
  }, [pageSelectionnee, fetchPageSelectionneData]);

  const updatePage = async (payload) => {
    try {
      await axios.post(`${API_SERVER_ENDPOINT}/page/update`, payload);
      return true;
    } catch (error) {
      handleError(error.message);
      return false;
    }
  };

  const getInstancesWithModifiedValues = (
    instancesData,
    attributeModifications
  ) => {
    return instancesData.map((instance) => ({
      moduleId: instance.moduleId,
      section: instance.section,
      elements: instance.elements.map((element) => ({
        nom: element.nom,
        attributes: element.attributes.map((attr) => {
          const attrKey = `${instance.moduleId}_${instance._id}_${element.nom}_${attr.nom}`;
          return {
            nom: attr.nom,
            valeurDefaut: attributeModifications[attrKey] ?? attr.valeurDefaut,
            _id: attr._id,
          };
        }),
        _id: element._id,
      })),
      _id: instance._id,
    }));
  };

  const updatePageSelectionne = async () => {
    setLoading(true);

    const instancesWithModifiedValues = getInstancesWithModifiedValues(
      instancesData,
      attributeModifications
    );

    const payload = {
      titrePage: pageSelectionnee?.nom,
      post_id: pageSelectionnee?.post_id,
      instances: instancesWithModifiedValues,
      siteNom: siteConsulte.nom,
      customerId: customerId,
      pageId: pageSelectionnee._id,
    };

    const updateSuccessful = await updatePage(payload);

    if (updateSuccessful) {
      refreshIframe();
    }
  };

  const onModuleAdd = (moduleId) => {
    const moduleToAdd = modulesBdd.find((m) => m.moduleId === moduleId);
    if (moduleToAdd) {
      const sectionMapping = {
        header: "header",
        enTete: "page",
        produits: "page",
        blocTexte: "page",
        reassurances: "page",
        autres: "page",
        footer: "footer",
      };

      const newInstance = {
        _id: uuidv4(),
        moduleId: moduleId,
        section: sectionMapping[moduleToAdd.type] || "page",
        elements: moduleToAdd.elements.map((element) => ({
          nom: element.nom,
          attributes: element.attributes.map((attr) => ({
            nom: attr.nom,
            valeurDefaut: attr.valeurBase,
          })),
        })),
      };

      setInstancesData((prev) => [...prev, newInstance]);
    }
  };

  const onModuleRemove = async (instanceId) => {
    const updatedInstances = instancesData.filter(
      (instance) => instance._id !== instanceId
    );

    setInstancesData(updatedInstances);
    resetModuleSelection();
  };

  const resetModuleSelection = () => {
    setSelectedModule(null);
  };

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    // Ne rien faire si l'élément est déposé en dehors d'une zone valide
    if (!destination) {
      return;
    }

    // Ne rien faire si l'élément est déposé à la même position
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    ) {
      return;
    }

    // Gérer le déplacement uniquement pour la section "page"
    if (
      source.droppableId === "pageList" &&
      destination.droppableId === "pageList"
    ) {
      const pageInstances = instancesData.filter(
        (instance) => instance.section === "page"
      );
      const otherInstances = instancesData.filter(
        (instance) => instance.section !== "page"
      );

      const [movedItem] = pageInstances.splice(source.index, 1);
      pageInstances.splice(destination.index, 0, movedItem);

      const newInstances = [...otherInstances, ...pageInstances];

      setInstancesData(newInstances);
    }
  };

  const handleElementClick = (moduleId, instanceId, elementNom) => {
    const moduleInstance = modulesBdd.find((m) => m.moduleId === moduleId);
    if (moduleInstance) {
      const element = moduleInstance.elements.find(
        (el) => el.nom === elementNom
      );
      if (element) {
        const moduleInstance = instancesData.find(
          (instance) =>
            instance.moduleId === moduleId && instance._id === instanceId
        );

        const templateElement = moduleInstance?.elements.find(
          (el) => el.nom === elementNom
        );

        const elementAttributes = element.attributes.map((attr) => {
          const templateAttribute = templateElement?.attributes.find(
            (a) => a.nom === attr.nom
          );

          return {
            ...attr,
            valeur:
              attributeModifications[
                `${moduleId}_${instanceId}_${elementNom}_${attr.nom}`
              ] ??
              templateAttribute?.valeurDefaut ??
              attr.valeur,
          };
        });

        setSelectedInstanceId(instanceId);
        setSelectedModule({
          ...moduleInstance,
          selectedElement: { ...element, attributes: elementAttributes },
        });
      }
    }
  };

  const updateAttributeModifications = useCallback(
    (moduleId, instanceId, elementNom, attributeName, newValue) => {
      const key = `${moduleId}_${instanceId}_${elementNom}_${attributeName}`;
      setAttributeModifications((prev) => ({
        ...prev,
        [key]: newValue,
      }));
    },
    []
  );

  const refreshIframe = () => {
    setRefreshKey((prev) => prev + 1);
  };

  const handleDrawerToggle = () => {
    setMobileEditMode(!mobileEditMode);
  };

  const handleAccordionChange = (instanceId) => (event, isExpanded) => {
    setExpandedAccordions((prevExpandedAccordions) => {
      if (isExpanded) {
        return [...prevExpandedAccordions, instanceId];
      } else {
        return prevExpandedAccordions.filter((id) => id !== instanceId);
      }
    });
  };

  const handleNewPage = () => {
    setOpenAddPageDialog(true);
  };

  const handleCloseAddPageDialog = () => {
    setOpenAddPageDialog(false);
  };

  const handleAddPageSuccess = (newPage) => {
    setPageSelectionnee(newPage);
  };

  const handleDeletePage = () => {
    setOpenDeletePageDialog(true);
  };

  const handleCloseDeletePageDialog = () => {
    setOpenDeletePageDialog(false);
  };

  const handleDeleteSuccess = () => {
    const accueilPage = pages.find(
      (page) => page.nom.toLowerCase() === "accueil"
    );
    if (accueilPage) {
      setPageSelectionnee(accueilPage);
    }
  };

  const handleModifyLogo = () => {
    setIsLogoFormOpen(true);
  };

  const handleLogoUpdated = () => {
    setIsLogoFormOpen(false);
    setLoading(true);
    refreshIframe();
  };

  const handleModifyFavicon = () => {
    setIsFaviconFormOpen(true);
  };

  const handleFaviconUpdated = () => {
    setIsFaviconFormOpen(false);
    setLoading(true);
    refreshIframe();
  };

  const [menus, setMenus] = useState([]);
  const [loadingPopover, setLoadingPopover] = useState(false);

  const loadMenus = useCallback(async () => {
    if (siteConsulte && siteConsulte.nom) {
      const url = `${API_SERVER_ENDPOINT}/menus?siteNom=${encodeURIComponent(
        siteConsulte.nom
      )}`;

      try {
        const response = await axios.get(url);
        const sortedMenus = response.data.sort((a, b) => {
          if (a.name.toLowerCase() === "principal") return -1;
          if (b.name.toLowerCase() === "principal") return 1;
          return 0;
        });
        setMenus(sortedMenus);
      } catch (error) {
        handleError(error.message);
      } finally {
      }
    }
  }, [siteConsulte, handleError]);

  useEffect(() => {
    loadMenus();
  }, [siteConsulte, loadMenus]);

  const handleAddMenuItem = useCallback(
    async (title, urlMenu) => {
      setLoadingPopover(true);

      const principalMenu = menus.find(
        (menu) => menu.name.toLowerCase() === "principal"
      );

      if (!principalMenu) {
        handleError("Le menu principal n'a pas été trouvé.");
        return;
      }

      const menuId = principalMenu.ID;

      try {
        const url = `${API_SERVER_ENDPOINT}/menu-item`;
        const payload = {
          siteNom: siteConsulte.nom,
          menuId: menuId,
          title: title,
          url: urlMenu,
        };

        await axios.post(url, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        loadMenus();
      } catch (error) {
        handleError(error.message);
      } finally {
        refreshIframe();
        setLoadingPopover(false);
      }
    },
    [menus, siteConsulte.nom, handleError, loadMenus]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: {
          xs: "calc(100vh - 56px)",
          md: `calc(100vh - 64px)`,
          lg: "100vh",
        },
      }}
    >
      <HeaderVisualBuilder
        selectedPage={pageSelectionnee}
        onPageChange={(selectedPage) => {
          setPageSelectionnee(selectedPage);
        }}
        onUpdate={() => {
          updatePageSelectionne();
        }}
        onNewPage={handleNewPage}
        onDeletePage={handleDeletePage}
        onModifyLogo={handleModifyLogo}
        onModifyFavicon={handleModifyFavicon}
        onAddMenuItem={handleAddMenuItem}
        loadingPopover={loadingPopover}
        pages={pages}
        siteConsulte={siteConsulte}
        handleDrawerToggle={handleDrawerToggle}
        isMobile={isMobile}
        sx={{ height: `64px` }}
      />

      <AddPageDialog
        open={openAddPageDialog}
        onClose={handleCloseAddPageDialog}
        siteNom={siteConsulte.nom}
        fetchPages={fetchPages}
        onAddPageSuccess={handleAddPageSuccess}
      />

      <DeletePageDialog
        open={openDeletePageDialog}
        onClose={handleCloseDeletePageDialog}
        page={pageSelectionnee}
        siteNom={siteConsulte.nom}
        fetchPages={fetchPages}
        onDeleteSuccess={handleDeleteSuccess}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: {
            xs: "calc(100vh - 56px)",
            md: `calc(100vh - 64px)`,
          },
          overflow: "hidden",
        }}
      >
        {!isMobile && (
          <>
            {!selectedModule ? (
              <Sidebar
                instancesData={instancesData}
                modulesBdd={modulesBdd}
                onElementClick={handleElementClick}
                onModuleAdd={onModuleAdd}
                onModuleRemove={onModuleRemove}
                onDragEnd={handleDragEnd}
                expandedAccordions={expandedAccordions}
                onAccordionChange={handleAccordionChange}
                sx={{
                  width: { xs: "100%", xl: "20%" },
                  overflowY: "auto",
                  height: "100%",
                }}
              />
            ) : (
              <AttributeEditor
                selectedModule={selectedModule}
                instancesData={instancesData}
                instanceId={selectedInstanceId}
                attributeModifications={attributeModifications}
                onBack={resetModuleSelection}
                onUpdateAttributes={updateAttributeModifications}
                products={products}
                templateId={pageSelectionnee?.templateId}
                sx={{
                  width: { xs: "95%", xl: "20%" },
                  overflowY: "auto",
                  height: "100%",
                }}
              />
            )}
          </>
        )}

        {isMobile && (
          <Drawer
            variant="temporary"
            open={mobileEditMode}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{
              "& .MuiDrawer-paper": { width: { xs: "80%", sm: "50%" } },
            }}
          >
            {!selectedModule ? (
              <Sidebar
                instancesData={instancesData}
                modulesBdd={modulesBdd}
                onElementClick={handleElementClick}
                onModuleAdd={onModuleAdd}
                onModuleRemove={onModuleRemove}
                onDragEnd={handleDragEnd}
                expandedAccordions={expandedAccordions}
                onAccordionChange={handleAccordionChange}
                sx={{
                  width: "100%",
                  overflowY: "auto",
                }}
              />
            ) : (
              <AttributeEditor
                selectedModule={selectedModule}
                instancesData={instancesData}
                instanceId={selectedInstanceId}
                attributeModifications={attributeModifications}
                onBack={resetModuleSelection}
                onUpdateAttributes={updateAttributeModifications}
                products={products}
                sx={{
                  width: "100%",
                  overflowY: "auto",
                }}
              />
            )}
          </Drawer>
        )}

        {pageSelectionnee?.url ? (
          <PreviewSite
            iframeURL={addHttpsPrefix(pageSelectionnee.url)}
            refresh={refreshKey}
            loading={loading}
            setLoading={setLoading}
            sx={{
              flex: 1,
              overflowY: "auto",
              width: isMobile ? "0%" : "auto",
            }}
          />
        ) : null}
      </Box>

      <LogoForm
        open={isLogoFormOpen}
        onClose={() => setIsLogoFormOpen(false)}
        siteConsulte={siteConsulte}
        onLogoUpdated={handleLogoUpdated}
      />

      <FaviconForm
        open={isFaviconFormOpen}
        onClose={() => setIsFaviconFormOpen(false)}
        siteConsulte={siteConsulte}
        onFaviconUpdated={handleFaviconUpdated}
      />
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </Box>
  );
};

export default VisualBuilder;
