import React, { useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { API_SERVER_ENDPOINT } from "../api/ConstantesEndpoints";
import ToastError from "../toast/ToastError";

function PasswordPopup({
  customerId,
  handleClosePasswordPopup,
  handleSuccess,
}) {
  const { t } = useTranslation();

  const [passwordFields, setPasswordFields] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (prop) => (event) => {
    setPasswordFields({ ...passwordFields, [prop]: event.target.value });
  };

  const handleClickShowPassword = (prop) => () => {
    setShowPassword({ ...showPassword, [prop]: !showPassword[prop] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormSubmit = async () => {
    setError(null);

    if (passwordFields.newPassword !== passwordFields.confirmPassword) {
      setError(t("passwordPopup.errorPasswordMismatch"));
      return;
    }

    handleSavePassword();
  };

  const handleSavePassword = async () => {
    setIsLoading(true);

    try {
      await axios.put(
        `${API_SERVER_ENDPOINT}/utilisateur/${customerId}/motdepasse`,
        {
          oldPassword: passwordFields.oldPassword,
          newPassword: passwordFields.newPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      handleSuccess();
      handleClosePasswordPopup();
      setPasswordFields({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      const message = error.response?.data?.error || error.message;
      setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        "& .MuiTextField-root": { mb: 2 },
        padding: "16px",
        borderRadius: "20px",
        maxWidth: "400px",
        margin: "auto",
      }}
    >
      <Typography
        sx={{
          fontSize: { lg: "24px", md: "18px", sm: "14px", xs: "12px" },
          color: "black",
          fontFamily: "Inter Variable, sans-serif",
          fontWeight: "600",
          letterSpacing: "-1px",
          textAlign: "center",
          mt: "1%",
          mb: "2%",
        }}
      >
        Changer de mot de passe
      </Typography>
      <TextField
        placeholder={t("passwordPopup.oldPassword")}
        type={showPassword.oldPassword ? "text" : "password"}
        value={passwordFields.oldPassword}
        onChange={handleChange("oldPassword")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword("oldPassword")}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword.oldPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            mt: "5%",
            width: "100%",
            height: "50px",
            borderRadius: "12px",
            backgroundColor: "white",
            border: "1px solid #E3E3E3",
            fontWeight: "500",
            fontFamily: "Inter Variable",
            fontSize: "14px",
            padding: "0%",
            mb: "2%",
            letterSpacing: "-0.25px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            input: {
              color: "text.primary",
            },
            "&::placeholder": {
              color: "#000000",
              opacity: 0.8,
            },
          },
        }}
      />
      <TextField
        placeholder={t("passwordPopup.newPassword")}
        type={showPassword.newPassword ? "text" : "password"}
        value={passwordFields.newPassword}
        onChange={handleChange("newPassword")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword("newPassword")}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword.newPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            width: "100%",
            height: "50px",
            borderRadius: "12px",
            backgroundColor: "white",
            border: "1px solid #E3E3E3",
            fontWeight: "500",
            fontFamily: "Inter Variable",
            fontSize: "14px",
            padding: "0%",
            mb: "2%",
            letterSpacing: "-0.25px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            input: {
              color: "text.primary",
            },
            "&::placeholder": {
              color: "#000000",
              opacity: 0.8,
            },
          },
        }}
      />
      <TextField
        placeholder={t("passwordPopup.confirmPassword")}
        type={showPassword.confirmPassword ? "text" : "password"}
        value={passwordFields.confirmPassword}
        onChange={handleChange("confirmPassword")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword("confirmPassword")}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword.confirmPassword ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            width: "100%",
            height: "50px",
            borderRadius: "12px",
            backgroundColor: "white",
            border: "1px solid #E3E3E3",
            fontWeight: "500",
            fontFamily: "Inter Variable",
            fontSize: "14px",
            padding: "0%",
            mb: "2%",
            letterSpacing: "-0.25px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            input: {
              color: "text.primary",
            },
            "&::placeholder": {
              color: "#000000",
              opacity: 0.8,
            },
          },
        }}
      />
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={handleFormSubmit}
        loading={isLoading}
        sx={{
          background: "#0042ff",
          color: "white",
          fontSize: {
            lg: "16px",
            md: "16px",
            sm: "12px",
            xs: "12px",
          },
          width: "100%",
          fontFamily: "Inter Variable",
          fontWeight: "500",
          letterSpacing: "-0.25px",
          textTransform: "none",
          mr: 2,
          borderRadius: "10px",
          border: "1px solid #276CFA",
          outline: "1px solid #0646C6",
          "&:hover": {
            backgroundColor: "#1459e2",
            boxShadow: "none",
          },
        }}
      >
        {t("passwordPopup.confirm")}
      </LoadingButton>
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </Box>
  );
}

export default PasswordPopup;
