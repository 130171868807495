import {
  Typography,
  Box,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../../../shared/api/ConstantesEndpoints";
import ToastError from "../../../../shared/toast/ToastError";

const AddZoneLivraisonDialog = ({ siteConsulte, open, handleClose }) => {
  const { t } = useTranslation();

  const [zoneName, setZoneName] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [deliveryType, setDeliveryType] = useState("");
  const [deliveryPrice, setDeliveryPrice] = useState("");
  const [countryNames, setCountryNames] = useState({});
  const [deliveryMinOrderAmount, setDeliveryMinOrderAmount] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchInitialCountries = async () => {
      try {
        const response = await axios.get(`${API_SERVER_ENDPOINT}/countries`);
        const namesMap = response.data.reduce((acc, country) => {
          acc[country.code] = country.name;
          return acc;
        }, {});
        setCountryNames(namesMap);
      } catch (error) {
        handleError(error.message);
      }
    };

    if (open) {
      fetchInitialCountries();
    }
  }, [open]);

  const handleCountryChange = (event) => {
    setSelectedCountries(event.target.value);
  };

  const handleNewZoneSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.post(`${API_SERVER_ENDPOINT}/livraison/zones`, {
        siteNom: siteConsulte.nom,
        name: zoneName,
        countries: selectedCountries,
        deliveryType,
        price: deliveryType === "payante" ? deliveryPrice : "0",
        minOrderAmount: deliveryMinOrderAmount,
      });
      handleClose();
    } catch (error) {
      handleError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "20px",
          width: "90%",
          maxWidth: "600px",
          height: "auto",
          maxHeight: "90vh",
          backgroundColor: "#f9f9f9",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle
        sx={{
          textAlign: "center",
          width: "70%",
          margin: "0 auto 16px auto",
        }}
      >
        <Typography
          sx={{
            fontSize: { lg: "24px", md: "18px", sm: "14px", xs: "12px" },
            color: "black",
            fontFamily: "Inter Variable, sans-serif",
            fontWeight: "600",
            letterSpacing: "-1px",
            textAlign: "center",
            mt: "1%",
          }}
        >
          {t("livraison.ajouterUneNouvelleZoneDeLivraison")}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ width: "70%", margin: "0 auto" }}>
        <form onSubmit={handleNewZoneSubmit}>
          <TextField
            placeholder={t("livraison.nomDeLaZone")}
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            value={zoneName}
            onChange={(e) => setZoneName(e.target.value)}
            variant="outlined"
            InputProps={{
              sx: {
                width: "100%",
                height: "50px",
                borderRadius: "12px",
                backgroundColor: "white",
                border: "1px solid #E3E3E3",
                fontWeight: "500",
                fontFamily: "Inter Variable",
                fontSize: "14px",
                padding: "0%",
                letterSpacing: "-0.25px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                input: {
                  color: "text.primary",
                },
                "&::placeholder": {
                  color: "#000000",
                  opacity: 0.8,
                },
              },
            }}
          />
          <FormControl fullWidth margin="dense">
            <Typography
              sx={{
                color: "text.primary",
                fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "12px" },
                fontFamily: "Inter Variable",
                fontWeight: "700",
                letterSpacing: "-1px",
                marginLeft: "0%",
                marginBottom: "1%",
                marginTop: "3%",
              }}
            >
              {t("livraison.paysConcerne")}
            </Typography>
            <Select
              placeholder=""
              multiple
              value={selectedCountries}
              onChange={handleCountryChange}
              renderValue={(selected) =>
                selected.map((code) => countryNames[code] || code).join(", ")
              }
              sx={{
                display: "flex",
                mb: "3%",
                justifyContent: "center",
                width: "100%",
                bgcolor: "#ECEDF5",
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.55px",
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                color: "black",
                borderRadius: "10px",
                "& .MuiSelect-select": {
                  borderRadius: "20px",
                  textAlign: "center",
                  padding: "10px 0",
                  border: "none",
                },
                "&:before, &:after": {
                  border: "none",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiPaper-root": {
                  bgcolor: "#ECEDF5",
                  borderRadius: "10px",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    mt: "-0.5%",
                    bgcolor: "#ECEDF5",
                    boxShadow: "none",
                    borderBottomRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    "& .MuiMenuItem-root": {
                      fontFamily: "Inter Variable",
                      fontWeight: "500",
                      letterSpacing: "-0.5px",
                      fontSize: {
                        lg: "14px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                      "&:hover": {
                        background: "#0033c1",
                        fontWeight: "500",
                        color: "white",
                      },
                      "&.Mui-selected": {
                        bgcolor: "#ECEDF5",
                        "&:hover": {
                          bgcolor: "#D0D0D0",
                          color: "black",
                        },
                      },
                    },
                  },
                },
              }}
            >
              {Object.entries(countryNames).map(([code, name]) => (
                <MenuItem key={code} value={code}>
                  <Checkbox checked={selectedCountries.includes(code)} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography
              sx={{
                color: "text.primary",
                fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "12px" },
                fontFamily: "Inter Variable",
                fontWeight: "700",
                letterSpacing: "-0.5px",
                marginLeft: "0%",
                marginBottom: "1%",
                marginTop: "3%",
              }}
            >
              {t("livraison.typeDeLivraison")}
            </Typography>
            <Select
              value={deliveryType}
              onChange={(e) => setDeliveryType(e.target.value)}
              sx={{
                display: "flex",
                mb: "3%",
                justifyContent: "center",
                width: "100%",
                bgcolor: "#ECEDF5",
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.55px",
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                color: "black",
                borderRadius: "10px",
                "& .MuiSelect-select": {
                  borderRadius: "20px",
                  textAlign: "center",
                  padding: "10px 0",
                  border: "none",
                },
                "&:before, &:after": {
                  border: "none",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiPaper-root": {
                  bgcolor: "#ECEDF5",
                  borderRadius: "10px",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    mt: "-0.5%",
                    bgcolor: "#ECEDF5",
                    boxShadow: "none",
                    borderBottomRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    "& .MuiMenuItem-root": {
                      fontFamily: "Inter Variable",
                      fontWeight: "500",
                      letterSpacing: "-0.5px",
                      fontSize: {
                        lg: "14px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                      "&:hover": {
                        background: "#0033c1",
                        fontWeight: "500",
                        color: "white",
                      },
                      "&.Mui-selected": {
                        bgcolor: "#ECEDF5",
                        "&:hover": {
                          bgcolor: "#D0D0D0",
                          color: "black",
                        },
                      },
                    },
                  },
                },
              }}
            >
              <MenuItem value="gratuite">{t("livraison.gratuite")}</MenuItem>
              <MenuItem value="payante">{t("livraison.payante")}</MenuItem>
            </Select>
          </FormControl>
          {deliveryType === "payante" && (
            <Box>
              <Typography
                sx={{
                  color: "text.primary",
                  fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "12px" },
                  fontFamily: "Inter Variable",
                  fontWeight: "700",
                  letterSpacing: "-0.5px",
                  marginLeft: "0%",
                  marginBottom: "1%",
                  marginTop: "3%",
                }}
              >
                {t("livraison.prixDeLaLivraison")}
              </Typography>
              <TextField
                margin="dense"
                id="delivery-price"
                type="number"
                fullWidth
                value={deliveryPrice}
                onChange={(e) => setDeliveryPrice(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment sx={{ ml: 1 }} position="start">
                      €
                    </InputAdornment>
                  ),
                  sx: {
                    width: "100%",
                    height: "50px",
                    borderRadius: "12px",
                    backgroundColor: "white",
                    border: "1px solid #E3E3E3",
                    fontWeight: "500",
                    fontFamily: "Inter Variable",
                    fontSize: "14px",
                    padding: "0%",
                    letterSpacing: "-0.25px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    input: {
                      color: "text.primary",
                    },
                    "&::placeholder": {
                      color: "#000000",
                      opacity: 0.8,
                    },
                  },
                }}
                variant="outlined"
              />
            </Box>
          )}
          {deliveryType === "gratuite" && (
            <FormControl fullWidth margin="dense">
              <Typography
                sx={{
                  color: "text.primary",
                  fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "12px" },
                  fontFamily: "Inter Variable",
                  fontWeight: "700",
                  letterSpacing: "-0.5px",
                  marginLeft: "0%",
                  marginBottom: "1%",
                  marginTop: "3%",
                }}
              >
                {t("livraison.montantMinimumDeCommande")}
                <Tooltip
                  title={t("livraison.tooltipMontantMinimumDeCommande")}
                  arrow
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        fontSize: "16px",
                        fontFamily: "Inter Variable",
                      },
                    },
                  }}
                >
                  <InfoIcon sx={{ marginLeft: "5px", fontSize: "1rem" }} />
                </Tooltip>
              </Typography>
              <TextField
                margin="dense"
                id="min-order-amount"
                type="number"
                fullWidth
                value={deliveryMinOrderAmount}
                onChange={(e) => setDeliveryMinOrderAmount(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment sx={{ ml: 1 }} position="start">
                      €
                    </InputAdornment>
                  ),
                  sx: {
                    width: "100%",
                    height: "50px",
                    borderRadius: "12px",
                    backgroundColor: "white",
                    border: "1px solid #E3E3E3",
                    fontWeight: "500",
                    fontFamily: "Inter Variable",
                    fontSize: "14px",
                    padding: "0%",
                    letterSpacing: "-0.25px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    input: {
                      color: "text.primary",
                    },
                    "&::placeholder": {
                      color: "#000000",
                      opacity: 0.8,
                    },
                  },
                }}
                variant="outlined"
              />
            </FormControl>
          )}
          <DialogActions sx={{ justifyContent: "center", mt: "5%" }}>
            <Button
              onClick={handleClose}
              sx={{
                bgcolor: "#F3F4F6",
                color: "#303030",
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.25px",
                textTransform: "none",
                mr: 1,
                pl: "3%",
                pr: "3%",
                boxShadow: "none",
                borderRadius: "10px",
                border: "1px solid #ECEFF2",
                outline: "1px solid #303030",
                "&:hover": {
                  bgcolor: "#e2e2e2",
                  color: "#303030",
                  boxShadow: "none",
                },
              }}
            >
              {t("livraison.annuler")}
            </Button>
            <LoadingButton
              type="submit"
              endIcon={<ArrowForwardIosIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
              color="primary"
              sx={{
                background: "#0042ff",
                color: "white",
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.25px",
                textTransform: "none",
                mr: 2,
                borderRadius: "10px",
                border: "1px solid #276CFA",
                outline: "1px solid #0646C6",
                "&:hover": {
                  backgroundColor: "#1459e2",
                  boxShadow: "none",
                },
              }}
            >
              {t("livraison.creer")}
            </LoadingButton>
          </DialogActions>
          <ToastError
            open={error !== null}
            onClose={() => setError(null)}
            message={error}
          />
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddZoneLivraisonDialog;
