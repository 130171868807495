export const LITE_PLAN = {
  prix: 1990,
  label: "LITE",
  priceId: "price_1PoQCIP3nsiReptRhr7mQchY",
  maxSites: 1,
};
export const BASIC_PLAN = {
  prix: 3990,
  label: "BASIC",
  priceId: "price_1PoQCcP3nsiReptRTYDRWoeh",
  maxSites: 1,
};
export const PREMIUM_PLAN = {
  prix: 9990,
  label: "PREMIUM",
  priceId: "price_1PoQCrP3nsiReptRpurrcDUR",
  maxSites: 5,
};

export const PLANS = {
  [LITE_PLAN.priceId]: LITE_PLAN,
  [BASIC_PLAN.priceId]: BASIC_PLAN,
  [PREMIUM_PLAN.priceId]: PREMIUM_PLAN,
};
