import {
  Autocomplete,
  TextField,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import { useTranslation } from "react-i18next";

const SecteurSelector = ({ value, onChange, onValidation }) => {
  const [inputSecteur, setInputSecteur] = useState(value);
  const [newMetier, setNewMetier] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showNewMetierField, setShowNewMetierField] = useState(false);
  const { t } = useTranslation();

  const validateInput = useCallback(
    (fetchedOptions, currentInput) => {
      const isValid = fetchedOptions.some(
        (option) => option.label.toLowerCase() === currentInput.toLowerCase()
      );
      onValidation(isValid);
    },
    [onValidation]
  );

  const fetchMetiersWithDefaultLetter = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_SERVER_ENDPOINT}/metiers?q=a`);
      const fetchedOptions = response.data;
      setOptions(fetchedOptions);
      validateInput(fetchedOptions, inputSecteur);
    } catch (error) {
      onValidation(false);
      setOptions([]);
    } finally {
      setIsLoading(false);
    }
  }, [validateInput, onValidation, inputSecteur]);

  const fetchMetiers = useCallback(async () => {
    setIsLoading(true);

    if (inputSecteur) {
      try {
        const response = await axios.get(
          `${API_SERVER_ENDPOINT}/metiers?q=${inputSecteur}`
        );
        const fetchedOptions = response.data;
        setOptions(fetchedOptions);
        validateInput(fetchedOptions, inputSecteur);
      } catch (error) {
        onValidation(false);
        setOptions([]);
      } finally {
        setIsLoading(false);
      }
    } else {
      setOptions([]);
      onValidation(false);
      setIsLoading(false);
    }
  }, [inputSecteur, validateInput, onValidation]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (inputSecteur) {
        fetchMetiers();
      } else {
        fetchMetiersWithDefaultLetter();
      }
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [inputSecteur, fetchMetiers, fetchMetiersWithDefaultLetter]);

  const handleInputChange = (event, newInputValue) => {
    setInputSecteur(newInputValue);
    onChange(newInputValue, "selector");
  };

  const handleNewMetierChange = (event) => {
    const newValue = event.target.value;
    setNewMetier(newValue);
    onChange(newValue, "textfield");
    onValidation(true);
  };

  const renderOption = (props, option) => {
    const lowerInput = inputSecteur.toLowerCase();
    const lowerLabel = option.label.toLowerCase();

    const matches = lowerLabel.startsWith(lowerInput);
    const matchIndex = lowerLabel.indexOf(lowerInput);

    const parts =
      matches && matchIndex === 0
        ? [
            option.label.substr(0, matchIndex),
            option.label.substr(matchIndex, inputSecteur.length),
            option.label.substr(matchIndex + inputSecteur.length),
          ]
        : [option.label];

    return (
      <li {...props}>
        {parts[0]}
        {matches ? <b>{parts[1]}</b> : parts[1]}
        {parts[2]}
      </li>
    );
  };

  return (
    <Box>
      {/* Autocomplete for selecting an existing sector */}
      <Autocomplete
        freeSolo
        inputValue={inputSecteur}
        onInputChange={handleInputChange}
        options={options}
        loading={isLoading}
        getOptionLabel={(option) => option.label}
        renderOption={renderOption}
        componentsProps={{
          popper: {
            sx: {
              "& .MuiAutocomplete-paper": {
                backgroundColor: "#eef6ff",
                borderRadius: "20px",
                borderColor: "rgba(0, 0, 0, 0)",
                boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1)",
              },
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            autoComplete="off"
            variant="outlined"
            placeholder={t("secteurSelector.nicheField")}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={inputStyle}
          />
        )}
      />

      {/* Text for adding a new sector if not listed */}
      <Box
        display="flex"
        justifyContent="center"
        textAlign="center"
        sx={{ mt: "7%", px: "3rem" }}
      >
        <Typography
          sx={{
            fontFamily: "Inter Variable",
            fontSize: "14px",
            fontWeight: "400",
            color: "black",
            mr: 1,
          }}
          variant="body1"
          component="span"
        >
          {t("secteurSelector.notInListText")}{" "}
        </Typography>
        <Typography
          onClick={() => setShowNewMetierField(true)}
          sx={{
            fontFamily: "Inter Variable",
            fontSize: "14px",
            fontWeight: "400",
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          variant="body1"
          component="span"
        >
          {t("secteurSelector.addYourOwn")}
        </Typography>
      </Box>

      {/* TextField for adding a new sector */}
      {showNewMetierField && (
        <Box sx={{ marginTop: 3 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder={t("secteurSelector.enterNewMetier")}
            value={newMetier}
            onChange={handleNewMetierChange}
            sx={inputStyle}
          />
        </Box>
      )}
    </Box>
  );
};

const inputStyle = {
  fontFamily: "Inter Variable",
  borderRadius: "20px",
  backgroundColor: "white",
  mt: "3%",
  display: "flex",
  alignItems: "center",
  width: "100%",

  ".MuiInputBase-input": {
    fontSize: { xs: "14px", sm: "16px" },
    fontFamily: "Inter Variable",
    fontWeight: "800",
    letterSpacing: "-0.5px",
  },
  ".MuiInputBase-input::placeholder": {
    color: "rgba(0, 0, 0, 0.7)",
    fontSize: { xs: "14px", sm: "16px" },
    fontWeight: "500",
    fontFamily: "Inter Variable",
    opacity: 1,
    letterSpacing: "-0.5px",
  },
  ".MuiOutlinedInput-root": {
    backgroundColor: "#eef6ff",
    borderRadius: "20px",
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0)",
      boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "20px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0)",
    },
  },
  "@media (max-width: 768px)": {
    ".MuiInputBase-input": {
      fontSize: "16px",
    },
  },
};

export default SecteurSelector;
