import React from "react";
import { CircularProgress, Box, Typography } from "@mui/material";

const CircularProgressWithLabel = ({ value }) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Cercle de fond gris */}
      <CircularProgress
        variant="determinate"
        value={100}
        size={120}
        thickness={5}
        sx={{
          color: "#E0E0E0",
          position: "absolute",
        }}
      />

      <CircularProgress
        variant="determinate"
        value={value}
        size={120}
        thickness={5}
        sx={{
          color: "#76c7c0",
        }}
      />

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{ fontSize: "24px", fontWeight: "bold" }}
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
