import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import axios from "axios";
import MenuConnexion from "../../shared/menuConnexion/MenuConnexion";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import { useTranslation } from "react-i18next";
import ToastError from "../../shared/toast/ToastError";
import LoadingScreen from "../../shared/loader/LoadingScreen";

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          style: {
            fontFamily: "Inter Variable",
            fontWeight: "400",
            fontSize: "15px",
          },
        },
      },
    },
  },
});

const DemandePasswordReset = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [loadingBackground, setLoadingBackground] = useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/demande-reset-password`,
        { email }
      );

      setMessage(response.data.message);
      setTimeout(() => {
        setMessage(null);
      }, 5000);
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const handleBackgroundLoad = () => {
    setLoadingBackground(false);
  };

  return (
    <ThemeProvider theme={theme}>
      {loadingBackground && <LoadingScreen />}
      <div style={{ display: loadingBackground ? "none" : "block" }}>
        <img
          src={process.env.PUBLIC_URL + "/background/one-site-bg.png"}
          alt="Background"
          onLoad={handleBackgroundLoad}
          style={{ display: "none" }}
        />
        <Paper
          sx={{
            width: "100%",
            height: "100vh",
            overflowX: "auto",
            boxShadow: "none",
            backgroundImage: loadingBackground
              ? "none"
              : `url(${
                  process.env.PUBLIC_URL + "/background/one-site-bg.png"
                })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MenuConnexion />
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "10%",
            }}
          >
            <Box
              sx={{
                width: { xs: "90%", sm: "500px", xl: "600px" },
                minHeight: "400px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "20px",
                bgcolor: "white",
                boxShadow: "0 0 100px 0 rgba(0, 150, 255, 0.2)",
                padding: "20px",
                "@media (max-width:768px)": {
                  width: "80%",
                },
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: { xs: "24px", xl: "30px" },
                  fontFamily: "Space Grotesk, sans-serif",
                  letterSpacing: "-1px",
                  fontWeight: "800",
                  textAlign: "center",
                  mb: 3,
                }}
              >
                {t("passwordReset.title")}
              </Typography>

              <Typography
                sx={{
                  width: "70%",
                  color: "black",
                  fontSize: "16px",
                  fontFamily: "Inter Variable",
                  textAlign: "center",
                  fontWeight: "500",
                  letterSpacing: "-0.5px",
                  mb: 4,
                }}
              >
                {t("passwordReset.description")}
              </Typography>

              <form
                onSubmit={handleSubmit}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TextField
                  fullWidth
                  label={t("passwordReset.emailLabel")}
                  variant="outlined"
                  size="large"
                  sx={{
                    mb: 3,
                    width: "80%",
                    ".MuiOutlinedInput-root": {
                      backgroundColor: "#eef6ff",
                      borderRadius: "20px",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 0)",
                        boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1)",
                      },
                    },
                    ".MuiInputBase-input": {
                      fontSize: { xs: "14px", sm: "16px" },
                      fontFamily: "Space Grotesk, sans-serif",
                      fontWeight: "800",
                      letterSpacing: "-0.5px",
                    },
                  }}
                  autoComplete="off"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 2,
                    bgcolor: "#2a1881",
                    color: "white",
                    fontSize: "18px",
                    fontFamily: "Inter Variable",
                    fontWeight: "700",
                    textTransform: "none",
                    boxShadow: "none",
                    borderRadius: "10px",
                    padding: "10px 20px",
                    "&:hover": {
                      bgcolor: "#462655",
                    },
                  }}
                >
                  {t("passwordReset.sendButton")}
                </Button>
              </form>

              <Box
                sx={{
                  mt: 4,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {message && <Alert severity="success">{message}</Alert>}
                <ToastError
                  open={error !== null}
                  onClose={() => setError(null)}
                  message={error}
                />
              </Box>
            </Box>
          </Box>
        </Paper>
      </div>
    </ThemeProvider>
  );
};

export default DemandePasswordReset;
