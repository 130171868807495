import React from "react";
import { Box, Typography, Link as MuiLink, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const scrollToReference = (encre) => {
    const element = document.getElementById(encre);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClick = (event, to) => {
    event.preventDefault();
    if (to.startsWith("#")) {
      scrollToReference(to.substring(1));
    } else if (to.startsWith("/#")) {
      const [hash] = to.split("#");
      setTimeout(() => {
        scrollToReference(hash);
      }, 100);
    } else {
      navigate(to);
    }
  };

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#02010e",
        color: "#fff",
        textAlign: "center",
        padding: "40px 20px",
        fontFamily: "Space Grotesk, sans-serif",
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <img
            src={process.env.PUBLIC_URL + "/logo/onesite.png"}
            alt="Logo"
            style={{ height: "80px", marginBottom: "15px" }}
          />
        </Grid>
        <Grid item sx={{ ml: 4 }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Space Grotesk, sans-serif",
              fontWeight: "600",
              fontSize: "20px",
              marginBottom: "10px",
              textAlign: "center",
              letterSpacing: "-1px",
            }}
          >
            {t("footer.menu")}
          </Typography>
          <Box component="nav">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              component="ul"
              sx={{ listStyle: "none", padding: 0, margin: 0 }}
            >
              {[
                { text: t("footer.home"), to: "/" },
                { text: t("footer.howItWorks"), to: "#fonctionnement" },
                { text: t("footer.visualEditor"), to: "#visualbuilder" },
                { text: t("footer.pricing"), to: "#tarifs" },
                { text: t("footer.login"), to: "/connexion-compte" },
                {
                  text: t("footer.condition_utilisation"),
                  to: "/conditions-utilisation",
                },
              ].map((item, index) => (
                <Box component="li" key={index} sx={{ marginBottom: "10px" }}>
                  <MuiLink
                    component={Link}
                    to={item.to}
                    onClick={(e) => handleClick(e, item.to)}
                    sx={{
                      color: "#fff",
                      textDecoration: "none",
                      fontSize: "16px",
                      fontFamily: "Space Grotesk, sans-serif",
                      fontWeight: "350",
                      textAlign: "left",
                      letterSpacing: "-1px",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {item.text}
                  </MuiLink>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          textAlign: "center",
          fontSize: "14px",
          marginTop: "20px",
          fontFamily: "Space Grotesk, sans-serif",
        }}
      >
        <p>&copy; 2024 oneSite. {t("footer.rightsReserved")}</p>
      </Box>
    </Box>
  );
};

export default Footer;
