import React, { useState } from "react";
import { Button, TextField, Typography, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import ToastError from "../../../../shared/toast/ToastError";

const VirementConfiguration = ({
  paymentMethod,
  onClose,
  onSubmitSettings,
}) => {
  const { t } = useTranslation();

  const [bankName, setBankName] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [iban, setIban] = useState("");
  const [bic, setBic] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    const instructions = `${t("paiement.bankTransferInstructions", {
      bankName,
      accountHolder,
      iban,
      bic,
    })}`;

    const settings = {
      instructions: instructions,
    };

    try {
      await onSubmitSettings(paymentMethod, true, settings);
      onClose();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
        {t("paiement.activateBankTransfer")}
      </Typography>
      <TextField
        label={t("paiement.bankName")}
        variant="outlined"
        value={bankName}
        onChange={(e) => setBankName(e.target.value)}
        required
        fullWidth
        margin="normal"
      />
      <TextField
        label={t("paiement.accountHolder")}
        variant="outlined"
        value={accountHolder}
        onChange={(e) => setAccountHolder(e.target.value)}
        required
        fullWidth
        margin="normal"
      />
      <TextField
        label="IBAN"
        variant="outlined"
        value={iban}
        onChange={(e) => setIban(e.target.value)}
        required
        fullWidth
        margin="normal"
      />
      <TextField
        label="BIC"
        variant="outlined"
        value={bic}
        onChange={(e) => setBic(e.target.value)}
        required
        fullWidth
        margin="normal"
      />

      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <Button
          type="submit"
          variant={loading ? "outlined" : "contained"}
          color={loading ? "primary" : "success"}
          startIcon={loading ? null : <CheckIcon />}
          disabled={loading}
          sx={{
            transition: "transform 0.3s ease",
            background: loading
              ? "none"
              : "linear-gradient(to bottom, #3674f9, #3544d0)",
            color: "white",
            fontSize: { lg: "18px", md: "16px", sm: "16px", xs: "12px" },
            fontFamily: "Inter Variable",
            fontWeight: "700",
            textTransform: "none",
            mr: { lg: "6px", md: "0", sm: "0", xs: "0" },
            boxShadow: loading
              ? "none"
              : "0px 0px 15px 2px rgba(105, 57, 153, .10)",
            borderRadius: "10px",
            "&:hover": {
              background: loading
                ? "none"
                : "linear-gradient(to bottom, #3264F3, #3321BE)",
              boxShadow: loading
                ? "none"
                : "0px 0px 15px 7px rgba(50, 100, 243, .20)",
              color: "white",
            },
          }}
        >
          {loading ? t("paiement.updating") : t("paiement.validate")}
        </Button>
      </Box>
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </form>
  );
};

export default VirementConfiguration;
