import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "@fontsource-variable/inter";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ImageIcon from "@mui/icons-material/Image";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import ModuleSelector from "./ModuleSelector";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Sidebar = ({
  instancesData,
  modulesBdd,
  onElementClick,
  onModuleAdd,
  onModuleRemove,
  onDragEnd,
  expandedAccordions,
  onAccordionChange,
}) => {
  const { t } = useTranslation();

  const groupModulesByType = (modules) => {
    const types = [
      "header",
      "enTete",
      "produits",
      "blocTexte",
      "reassurances",
      "autres",
      "footer",
    ];
    return modules.reduce((acc, module) => {
      const type = types.includes(module.type) ? module.type : "autres";
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(module);
      return acc;
    }, {});
  };

  const modulesGroupedByType = groupModulesByType(modulesBdd);

  const instancesBySection = instancesData.reduce(
    (acc, instance) => {
      const section = instance.section || "page";
      if (!acc[section]) {
        acc[section] = [];
      }
      acc[section].push(instance);
      return acc;
    },
    { header: [], page: [], footer: [] }
  );

  const renderInstances = (instances, isRemovable = true) =>
    instances.map((instance, index) => (
      <Draggable key={instance._id} draggableId={instance._id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              marginBottom: "8px",
              userSelect: "none",
              backgroundColor: "#fff",
              borderRadius: "10px",
              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
              ...provided.draggableProps.style,
            }}
          >
            {renderInstance(instance, isRemovable)}
          </div>
        )}
      </Draggable>
    ));

  const renderInstance = (instance, isRemovable) => (
    <Accordion
      key={instance._id}
      expanded={expandedAccordions.includes(instance._id)}
      onChange={onAccordionChange(instance._id)}
      sx={{
        bgcolor: "#ecedf5",
        borderRadius: "15px",
        mb: 2,
        boxShadow: "none",
        border: "none",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          bgcolor: "#ecedf5",
          borderRadius: "10px",
          boxShadow: "none",
          border: "none",
        }}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: {
              lg: "14px",
              md: "14px",
              sm: "12px",
              xs: "12px",
            },
            fontFamily: "Inter Variable",
            fontWeight: "600",
            letterSpacing: "-0.25px",
          }}
        >
          {modulesBdd.find((m) => m.moduleId === instance.moduleId)?.name ||
            "Module"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          bgcolor: "white",
          boxShadow: "none",
          border: "none",
        }}
      >
        {modulesBdd
          .find((m) => m.moduleId === instance.moduleId)
          ?.elements.map((element) => (
            <Box
              key={element.nom}
              sx={{
                display: "flex",
                alignItems: "center",
                mt: "3%",
                mb: "3%",
                p: { xs: "2%", sm: "1%", md: "1%", lg: "2%" },
                borderRadius: "10px",
                cursor: "pointer",
                backgroundColor: "white",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
              onClick={() =>
                onElementClick(instance.moduleId, instance._id, element.nom)
              }
            >
              {element.type === "text" && (
                <TextFieldsIcon
                  sx={{
                    mr: 2,
                    fontSize: {
                      lg: "16px",
                      md: "16px",
                      sm: "14px",
                      xs: "12px",
                    },
                  }}
                />
              )}
              {element.type === "button" && (
                <RadioButtonUncheckedIcon
                  sx={{
                    mr: 2,
                    fontSize: {
                      lg: "16px",
                      md: "16px",
                      sm: "14px",
                      xs: "12px",
                    },
                  }}
                />
              )}
              {element.type === "image" && (
                <ImageIcon
                  sx={{
                    mr: 2,
                    fontSize: {
                      lg: "16px",
                      md: "16px",
                      sm: "14px",
                      xs: "12px",
                    },
                  }}
                />
              )}

              <Typography
                sx={{
                  color: "black",
                  fontSize: {
                    lg: "14px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  fontFamily: "Inter Variable",
                  letterSpacing: "-0.25px",
                  fontWeight: "500",
                }}
              >
                {element.displayName}
              </Typography>
            </Box>
          ))}
        {isRemovable && (
          <Button
            onClick={() => onModuleRemove(instance._id)}
            variant="contained"
            sx={{
              mt: "3%",
              mb: "3%",
              background: "#e6332d",
              color: "white",
              fontSize: {
                lg: "14px",
                md: "14px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              letterSpacing: "-0.25px",
              fontWeight: "600",
              textTransform: "none",
              boxShadow: "none",
              mr: 1,
              outline: "1px solid #c12724",
              border: "1px solid #f74a47",
              borderRadius: "10px",
              "&:hover": {
                background: "#c12724",
                boxShadow: "none",
                color: "white",
              },
            }}
          >
            {t("sidebar.deleteModule")}
          </Button>
        )}
      </AccordionDetails>
    </Accordion>
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "98%", xl: "20%" },
          overflowY: "auto",
          bgcolor: "background.paper",
          p: 2,
          borderRadius: "10px",
          boxShadow: "none",
          position: "relative",
        }}
      >
        {instancesBySection.header.length > 0 && (
          <>
            <Typography
              variant="h6"
              sx={{
                mb: 1,
                fontSize: "12px",
                fontFamily: "Inter Variable",
                fontWeight: "600",
                textAlign: "left",
                letterSpacing: "0px",
                color: "black",
              }}
            >
              {t("sidebar.sectionHeader")}
            </Typography>
            <Droppable droppableId="headerList">
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{ paddingBottom: "16px" }}
                >
                  {renderInstances(instancesBySection.header, false)}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
            <hr
              style={{
                width: "100%",
                border: "1px solid #d3d3d3",
                margin: "20px 0",
              }}
            />
          </>
        )}

        <Typography
          variant="h6"
          sx={{
            mb: 1,
            fontSize: "12px",
            fontFamily: "Inter Variable",
            fontWeight: "600",
            textAlign: "left",
            letterSpacing: "0px",
            color: "black",
          }}
        >
          {t("sidebar.sectionPage")}
        </Typography>
        <ModuleSelector
          type="page"
          modules={[
            ...(modulesGroupedByType.enTete ?? []),
            ...(modulesGroupedByType.produits ?? []),
            ...(modulesGroupedByType.blocTexte ?? []),
            ...(modulesGroupedByType.reassurances ?? []),
            ...(modulesGroupedByType.autres ?? []),
          ]}
          onModuleAdd={onModuleAdd}
          buttonLabel="Ajouter un module de page +"
        />
        <Droppable droppableId="pageList">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{ flexGrow: 1, paddingBottom: "16px" }}
            >
              {renderInstances(instancesBySection.page, true)}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>

        <hr
          style={{
            width: "100%",
            border: "1px solid #d3d3d3",
            margin: "20px 0",
          }}
        />

        <Typography
          variant="h6"
          sx={{
            mb: 1,
            fontSize: "12px",
            fontFamily: "Inter Variable",
            fontWeight: "600",
            textAlign: "left",
            letterSpacing: "0px",
            color: "black",
          }}
        >
          {t("sidebar.sectionFooter")}
        </Typography>
        <ModuleSelector
          type="footer"
          modules={modulesGroupedByType.footer ?? []}
          onModuleAdd={onModuleAdd}
          buttonLabel="Ajouter un module footer +"
        />
        <Droppable droppableId="footerList">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{ paddingBottom: "16px" }}
            >
              {renderInstances(instancesBySection.footer, false)}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Box>
    </DragDropContext>
  );
};

export default Sidebar;
