import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Typography, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { API_SERVER_ENDPOINT } from "../../../shared/api/ConstantesEndpoints";
import Loader from "../../../shared/loader/Loader";
import MenuItem from "./MenuItem";
import ToastError from "../../../shared/toast/ToastError";

const GestionMenu = ({ siteConsulte }) => {
  const { t } = useTranslation();

  const [menus, setMenus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [editData, setEditData] = useState({
    title: "",
    path: "",
    hostname: "",
  });
  const [isUrlValid, setIsUrlValid] = useState(true);

  const handleError = useCallback((message) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 3000);
  }, []);

  const loadMenus = useCallback(async () => {
    if (siteConsulte && siteConsulte.nom) {
      setIsLoading(true);

      const url = `${API_SERVER_ENDPOINT}/menus?siteNom=${encodeURIComponent(
        siteConsulte.nom
      )}`;

      try {
        const response = await axios.get(url);
        const sortedMenus = response.data.sort((a, b) => {
          if (a.name.toLowerCase() === "principal") return -1;
          if (b.name.toLowerCase() === "principal") return 1;
          return 0;
        });

        setMenus(sortedMenus);
      } catch (error) {
        handleError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  }, [siteConsulte, handleError]);

  useEffect(() => {
    loadMenus();
  }, [loadMenus]);

  const handleDeleteMenuItem = async (itemId) => {
    setIsLoading(true);

    try {
      const url = `${API_SERVER_ENDPOINT}/menu-item/${encodeURIComponent(
        siteConsulte.nom
      )}/${itemId}`;

      await axios.delete(url);
      loadMenus();
    } catch (error) {
      handleError(error.message);
      setIsLoading(false);
    }
  };

  const handleEditClick = (item) => {
    const url = new URL(item.url);
    const hostname = url.origin;
    let path = url.pathname.substring(1);
    if (path.endsWith("/")) {
      path = path.slice(0, -1);
    }

    setEditingItem(item.ID);
    setEditData({
      title: item.title,
      path: path,
      hostname: hostname,
    });
  };

  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setEditData((prev) => ({ ...prev, [name]: value }));

    if (name === "path") {
      setIsUrlValid(isValidPath(value));
    }
  };

  const handleSaveEdit = async (menuId, itemId) => {
    setIsLoading(true);

    try {
      const url = `${API_SERVER_ENDPOINT}/menu-item/${encodeURIComponent(
        siteConsulte.nom
      )}/${itemId}`;

      const payload = {
        menuId,
        title: editData.title,
        url: `${editData.hostname}/${editData.path}/`,
      };

      await axios.put(url, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      loadMenus();
      setEditingItem(null);
    } catch (error) {
      handleError(error.message);
      setIsLoading(false);
    }
  };

  const setMenuItems = async (updatedItems, menuName) => {
    setMenus((prevMenus) =>
      prevMenus.map((menu) =>
        menu.name === menuName ? { ...menu, items: updatedItems } : menu
      )
    );

    const menu = menus.find((m) => m.name === menuName);
    if (menu) {
      const payload = {
        siteNom: siteConsulte.nom,
        items: updatedItems.map((item, index) => ({
          ID: item.ID,
          menu_order: index + 1,
        })),
      };

      try {
        const url = `${API_SERVER_ENDPOINT}/menu-items/order`;

        await axios.put(url, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } catch (error) {
        handleError(error.message);
      }
    }
  };

  const totalMenuItems = menus.reduce(
    (acc, menu) => acc + menu.items.length,
    0
  );

  const isValidPath = (path) => {
    if (path === null) {
      return true;
    }
    const regex = /^[a-zA-Z0-9-_]+$/;
    return regex.test(path);
  };

  return (
    <div
      style={{
        flexGrow: 1,
        padding: "2%",
        backgroundColor: "#f5f8fa",
        minHeight: "100vh",
      }}
    >
      <Paper
        sx={{
          margin: "0px",
          overflowX: "auto",
          boxShadow: "none",
          backgroundColor: "#f5f8fa",
        }}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: { xs: "26px", sm: "60px" },
            fontFamily: "Space Grotesk, sans serif",
            fontWeight: "1000",
            letterSpacing: { xs: "-2px", sm: "-5px", md: "-5px", lg: "-5px" },
            ml: "2%",
            mt: { xs: "5%", sm: 0 },
          }}
        >
          {t("menu.title")}
        </Typography>

        <Typography
          sx={{
            color: "black",
            fontSize: "16px",
            fontFamily: "Inter Variable, sans-serif",
            fontWeight: "500",
            letterSpacing: "-0.75px",
            mb: "2%",
            ml: "2%",
          }}
        >
          {t("menu.manageSiteMenu")} <br />
          {t("menu.modifyTabs")} <br />
          {t("menu.visualEditor")}
        </Typography>
      </Paper>

      {menus.map((menu) => (
        <MenuItem
          key={menu.name}
          menu={menu}
          editingItem={editingItem}
          setEditingItem={setEditingItem}
          editData={editData}
          handleChangeEdit={handleChangeEdit}
          handleSaveEdit={handleSaveEdit}
          handleEditClick={handleEditClick}
          handleDeleteMenuItem={(itemId) => handleDeleteMenuItem(itemId)}
          isUrlValid={isUrlValid}
          setMenuItems={(updatedItems) => setMenuItems(updatedItems, menu.name)}
          canDelete={totalMenuItems > 1}
          siteConsulteUrl={siteConsulte.url}
        />
      ))}

      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
      <Loader loading={isLoading}></Loader>
    </div>
  );
};

export default GestionMenu;
