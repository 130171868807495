import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../shared/loader/Loader";
import MenuConnexion from "../../shared/menuConnexion/MenuConnexion";
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  LinearProgress,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AuthContext } from "../../components/authentification/Authentification";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import { GoogleLogin } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import ToastError from "../../shared/toast/ToastError";
import LoadingScreen from "../../shared/loader/LoadingScreen";

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          style: {
            fontFamily: "Inter Variable",
            fontWeight: "400",
            fontSize: "15px",
          },
        },
      },
    },
  },
});

function CreationCompte() {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingBackground, setLoadingBackground] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const pageAppelante = location.state?.pageAppelante;
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const { setUserAuthenticated } = useContext(AuthContext);

  const handleNomClientChange = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordStrength(checkPasswordStrength(newPassword));
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleError = (error) => {
    setLoading(false);
    setError(error);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Vérifier que les mots de passe sont les mêmes
    if (password !== confirmPassword) {
      handleError(t("createAccount.passwordsDoNotMatch"));
      return;
    }

    // Vérifier la robustesse du mot de passe
    if (!isPasswordValid(password)) {
      handleError(t("createAccount.invalidPassword"));
      return;
    }

    setLoading(true);

    await createCompte();

    setLoading(false);
  };

  const checkPasswordStrength = (password) => {
    let score = 0;

    if (password.length >= 5) score += 1;
    if (/\d/.test(password)) score += 1;
    if (/[a-z]/.test(password) && /[A-Z]/.test(password)) score += 1;
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) score += 1;

    return score;
  };

  const isPasswordValid = (password) => {
    return password.length >= 5;
  };

  async function createCompte() {
    try {
      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/register`,
        {
          username,
          email,
          password,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = response.data;
      sessionStorage.setItem("profil", JSON.stringify(data));
      setUserAuthenticated(true);

      redirection();
    } catch (error) {
      handleError(
        error.response?.data?.error || t("createAccount.accountCreationFailed")
      );
    }
  }

  const handleGoogleLoginSuccess = async (response) => {
    try {
      const res = await axios.post(
        `${API_SERVER_ENDPOINT}/connexion/google`,
        {
          token: response.credential,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = res.data;
      sessionStorage.setItem("profil", JSON.stringify(data));
      setUserAuthenticated(true);

      redirection();
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleGoogleLoginFailure = (error) => {
    handleError(t("createAccount.googleLoginError", { error: error.message }));
  };

  const redirection = () => {
    if (pageAppelante === "/preview") {
      sessionStorage.setItem("authorizedForPayment", "true");

      navigate("/paiement");
    } else {
      navigate("/gestion-compte");
    }
  };

  const redirectionCompte = () => {
    navigate("/connexion-compte", { state: { pageAppelante } });
  };

  const redirectionPasswordReset = () => {
    navigate("/password-reset");
  };

  const handleBackgroundLoad = () => {
    setLoadingBackground(false);
  };

  return (
    <ThemeProvider theme={theme}>
      {loadingBackground && <LoadingScreen />}
      <div style={{ display: loadingBackground ? "none" : "block" }}>
        <img
          src={process.env.PUBLIC_URL + "/background/one-site-bg.png"}
          alt="Background"
          onLoad={handleBackgroundLoad}
          style={{ display: "none" }}
        />
        <Paper
          sx={{
            width: "100%",
            height: "100vh",
            overflowX: "auto",
            boxShadow: "none",
            backgroundImage: loadingBackground
              ? "none"
              : `url(${
                  process.env.PUBLIC_URL + "/background/one-site-bg.png"
                })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MenuConnexion />
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "5%",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "500px", xl: "600px" },
                maxHeight: "90vh",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: "20px",
                bgcolor: "white",
                boxShadow: "0 0 100px 0 rgba(0, 150, 255, 0.2)",
                padding: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: { xs: "24px", xl: "30px" },
                  fontFamily: "Space Grotesk, sans-serif",
                  letterSpacing: "-1px",
                  fontWeight: "800",
                  textAlign: "center",
                  mb: { xs: 3, xl: 5 },
                }}
              >
                {t("createAccount.title")}
              </Typography>
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                {[
                  {
                    label: "createAccount.username",
                    value: username,
                    onChange: handleNomClientChange,
                    type: "text",
                  },
                  {
                    label: "createAccount.email",
                    value: email,
                    onChange: handleEmailChange,
                    type: "email",
                  },
                  {
                    label: "createAccount.password",
                    value: password,
                    onChange: handlePasswordChange,
                    type: "password",
                  },
                  {
                    label: "createAccount.confirmPassword",
                    value: confirmPassword,
                    onChange: handleConfirmPasswordChange,
                    type: "password",
                  },
                ].map((field, index) => (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mb: "3%" }}
                  >
                    <TextField
                      fullWidth
                      label={t(field.label)}
                      variant="outlined"
                      size="large"
                      sx={{
                        width: "80%",
                        ".MuiOutlinedInput-root": {
                          backgroundColor: "#eef6ff",
                          borderRadius: "20px",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 0)",
                            boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1)",
                          },
                        },
                        ".MuiInputBase-input": {
                          fontSize: { xs: "14px", sm: "16px" },
                          fontFamily: "Space Grotesk, sans-serif",
                          fontWeight: "800",
                          letterSpacing: "-0.5px",
                        },
                      }}
                      autoComplete="off"
                      type={field.type}
                      value={field.value}
                      onChange={field.onChange}
                      required
                    />
                  </Box>
                ))}

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "100%", mb: 3, mt: 3 }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={(passwordStrength / 4) * 100}
                    sx={{
                      width: "70%",
                      height: "10px",
                      borderRadius: "5px",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor:
                          passwordStrength < 2
                            ? "red"
                            : passwordStrength < 3
                            ? "orange"
                            : "green",
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      color:
                        passwordStrength < 2
                          ? "red"
                          : passwordStrength < 3
                          ? "orange"
                          : "green",
                      fontFamily: "Space Grotesk, sans-serif",
                      fontWeight: "600",
                    }}
                  >
                    {passwordStrength < 2
                      ? "Faible"
                      : passwordStrength < 3
                      ? "Moyen"
                      : "Fort"}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ textAlign: "center", mt: 2 }}
                >
                  <GoogleLogin
                    onSuccess={handleGoogleLoginSuccess}
                    onError={handleGoogleLoginFailure}
                  />
                </Box>

                <Box sx={{ textAlign: "center", width: "100%", mt: "10%" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      bgcolor: "#2a1881",
                      color: "white",
                      fontSize: "18px",
                      fontFamily: "Inter Variable",
                      fontWeight: "700",
                      textTransform: "none",
                      boxShadow: "none",
                      borderRadius: "10px",
                      "&:hover": {
                        bgcolor: "#462655",
                      },
                    }}
                  >
                    {t("createAccount.createAccount")}
                  </Button>
                </Box>
              </form>
              <Box
                alignItems="center"
                textAlign="center"
                sx={{ mt: { xs: "4%", xl: "7%" } }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter Variable",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  {t("createAccount.alreadyHaveAccount")}{" "}
                  <span
                    onClick={redirectionCompte}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {t("createAccount.loginHere")}
                  </span>
                </Typography>
              </Box>
              <Box textAlign="center" sx={{ mt: 1 }}>
                <Typography
                  sx={{
                    fontFamily: "Inter Variable",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  {t("createAccount.forgotPassword")}{" "}
                  <span
                    onClick={redirectionPasswordReset}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {t("createAccount.resetHere")}
                  </span>
                </Typography>
              </Box>
              {loading && <Loader loading={loading} />}
              <ToastError
                open={error !== null}
                onClose={() => setError(null)}
                message={error}
              />
            </Box>
          </Box>
        </Paper>
      </div>
    </ThemeProvider>
  );
}

export default CreationCompte;
