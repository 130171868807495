import React, { useState } from "react";
import {
  Card,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const PageViewsTable = ({ data, dateRange }) => {
  const { t } = useTranslation();
  const [visibleRows, setVisibleRows] = useState(5);

  const handleShowMore = () => {
    setVisibleRows((prevVisibleRows) => prevVisibleRows + 5);
  };

  const handleShowLess = () => {
    setVisibleRows(5);
  };

  return (
    <Card
      raised
      sx={{
        m: 2,
        p: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        boxShadow: "none",
        border: "2px solid #c6c7ce",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 5,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            mb: 2,
            fontFamily: "Space Grotesk, sans serif",
            fontWeight: "1000",
            letterSpacing: { xs: "-1px", sm: "-1px", md: "-2px", lg: "-3px" },
            fontSize: { xs: "25px", sm: "25px", md: "30px", lg: "40px" },
          }}
        >
          {t("pageViewsTable.title")}
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Inter Variable",
                    fontWeight: "600",
                    letterSpacing: "-0.5px",
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "18px",
                      lg: "20px",
                    },
                  }}
                >
                  {t("pageViewsTable.page")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Inter Variable",
                    fontWeight: "600",
                    letterSpacing: "-0.5px",
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "18px",
                      lg: "20px",
                    },
                  }}
                >
                  {t("pageViewsTable.views")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(0, visibleRows).map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Inter Variable",
                      letterSpacing: "-0.5px",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "16px",
                        lg: "18px",
                      },
                    }}
                  >
                    {row.pagePath}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Inter Variable",
                      letterSpacing: "-0.5px",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "16px",
                        lg: "18px",
                      },
                    }}
                  >
                    {row.screenPageViews}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          marginTop: { xs: "1rem", md: "1rem" },
          display: "flex",
          justifyContent: "center",
        }}
      >
        {visibleRows < data.length && (
          <Button
            variant="contained"
            onClick={handleShowMore}
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: {
                lg: "16px",
                md: "16px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Space Grotesk, sans-serif",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
              mx: 1,
            }}
          >
            {t("pageViewsTable.showMore")}
          </Button>
        )}
        {visibleRows > 5 && (
          <Button
            variant="contained"
            onClick={handleShowLess}
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: {
                lg: "16px",
                md: "16px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Space Grotesk, sans-serif",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
              mx: 1,
            }}
          >
            {t("pageViewsTable.showLess")}
          </Button>
        )}
      </Box>
      <Box sx={{ marginTop: { xs: "1rem", md: "1rem" } }}>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          sx={{ mb: 1, fontFamily: "Inter Variable", textAlign: "center" }}
        >
          {dateRange}
        </Typography>
      </Box>
    </Card>
  );
};

export default PageViewsTable;
