import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../shared/loader/Loader";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import ToastError from "../../shared/toast/ToastError";

const ConfirmationReouverture = () => {
  const profil = JSON.parse(sessionStorage.getItem("profil"));

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const abonnementIdBdd = searchParams.get("abonnementIdBdd");
  const setupIntentId = searchParams.get("setup_intent");
  const priceId = searchParams.get("priceId");
  const promoCode = searchParams.get("promoCode");

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleError = (error) => {
    setLoading(false);
    setError(error);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const redirectionGestionCompte = useCallback(() => {
    navigate("/gestion-compte");
  }, [navigate]);

  const finaliserReactivationAbonnement = useCallback(async () => {
    try {
      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/abonnements/reactiver`,
        {
          priceId,
          customerId: profil.customerId,
          promoCode,
          setupIntentId,
          abonnementIdBdd,
        }
      );

      if (response.status === 201) {
        const { operationId } = response.data;
        sessionStorage.setItem("operationIdReouverture", operationId);

        redirectionGestionCompte();
      } else {
        throw new Error("Erreur lors de la reouverture de l'abonnement.");
      }
    } catch (error) {
      handleError(error.message);
    } finally {
      sessionStorage.removeItem("authorizedForPayment");
    }
  }, [
    abonnementIdBdd,
    profil.customerId,
    priceId,
    promoCode,
    setupIntentId,
    redirectionGestionCompte,
  ]);

  useEffect(() => {
    finaliserReactivationAbonnement();
  }, [finaliserReactivationAbonnement]);

  return (
    <div>
      {loading && <Loader loading={loading} />}
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </div>
  );
};

export default ConfirmationReouverture;
