import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../shared/loader/Loader";
import MenuConnexion from "../../shared/menuConnexion/MenuConnexion";
import { Box, Typography, Paper, Button, TextField } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AuthContext } from "../../components/authentification/Authentification";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import { GoogleLogin } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import ToastError from "../../shared/toast/ToastError";
import LoadingScreen from "../../shared/loader/LoadingScreen";

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          style: {
            fontFamily: "Inter Variable",
            fontWeight: "400",
            fontSize: "15px",
          },
        },
      },
    },
  },
});

function ConnexionCompte() {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingBackground, setLoadingBackground] = useState(true);
  const [usernameMail, setUsernameMail] = useState("");
  const [password, setPassword] = useState("");
  const { setUserAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const pageAppelante = location.state?.pageAppelante;

  const handleUsernameEmailChange = (e) => {
    setUsernameMail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleError = (error) => {
    setLoading(false);
    setError(error);
    setTimeout(() => {
      setError(null);
    }, 5000);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    await connexionCompte();

    setLoading(false);
  };

  async function connexionCompte() {
    setLoading(true);

    try {
      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/login`,
        {
          identifier: usernameMail,
          password,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = response.data;

      sessionStorage.setItem("profil", JSON.stringify(data));
      setUserAuthenticated(true);

      setLoading(false);

      redirection();
    } catch (error) {
      setLoading(false);

      if (error.response) {
        if (error.response.status === 401) {
          handleError(t("login.incorrectEmailOrPassword"));
        } else if (error.response.status === 403) {
          handleError(t("login.accountBlocked"));
        } else {
          handleError(error.response.data?.error || t("login.loginFailed"));
        }
      } else {
        handleError(t("login.unexpectedError"));
      }
    }
  }

  const handleGoogleLoginSuccess = async (response) => {
    try {
      const res = await axios.post(
        `${API_SERVER_ENDPOINT}/connexion/google`,
        {
          token: response.credential,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = res.data;
      sessionStorage.setItem("profil", JSON.stringify(data));
      setUserAuthenticated(true);

      redirection();
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleGoogleLoginFailure = (error) => {
    handleError(t("login.googleLoginError", { error: error.message }));
  };

  const redirection = () => {
    if (pageAppelante === "/preview") {
      sessionStorage.setItem("authorizedForPayment", "true");

      navigate("/paiement");
    } else {
      navigate("/gestion-compte");
    }
  };

  const redirectionCompte = () => {
    navigate("/creation-compte", { state: { pageAppelante } });
  };

  const redirectionPasswordReset = () => {
    navigate("/password-reset");
  };

  const handleBackgroundLoad = () => {
    setLoadingBackground(false);
  };

  return (
    <ThemeProvider theme={theme}>
      {loadingBackground && <LoadingScreen loadingBackground={true} />}
      <div style={{ display: loadingBackground ? "none" : "block" }}>
        <img
          src={process.env.PUBLIC_URL + "/background/one-site-bg.png"}
          alt="Background"
          onLoad={handleBackgroundLoad}
          style={{ display: "none" }}
        />
        <Paper
          sx={{
            width: "100%",
            height: "100vh",
            overflowX: "auto",
            boxShadow: "none",
            backgroundImage: "url(/background/one-site-bg.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MenuConnexion />
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "10%",
            }}
          >
            <Box
              sx={{
                width: { xs: "90%", sm: "400px", xl: "500px" },
                maxHeight: "90vh",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "20px",
                bgcolor: "white",
                boxShadow: "0 0 100px 0 rgba(0, 150, 255, 0.2)",
                padding: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: { xs: "24px", xl: "30px" },
                  fontFamily: "Space Grotesk, sans-serif",
                  letterSpacing: "-1px",
                  fontWeight: "800",
                  textAlign: "center",
                  mb: { xs: 3, xl: 5 },
                }}
              >
                {t("login.title")}
              </Typography>

              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <TextField
                  fullWidth
                  label={t("login.usernameEmail")}
                  variant="outlined"
                  size="large"
                  sx={{
                    mb: "3%",
                    ".MuiOutlinedInput-root": {
                      backgroundColor: "#eef6ff",
                      borderRadius: "20px",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 0)",
                        boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1)",
                      },
                    },
                  }}
                  autoComplete="off"
                  type="text"
                  value={usernameMail}
                  onChange={handleUsernameEmailChange}
                  required
                />

                <TextField
                  fullWidth
                  label={t("login.password")}
                  variant="outlined"
                  size="large"
                  sx={{
                    mb: "3%",
                    ".MuiOutlinedInput-root": {
                      backgroundColor: "#eef6ff",
                      borderRadius: "20px",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 0)",
                        boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1)",
                      },
                    },
                  }}
                  autoComplete="off"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <GoogleLogin
                    onSuccess={handleGoogleLoginSuccess}
                    onError={handleGoogleLoginFailure}
                  />
                </Box>

                <Box sx={{ textAlign: "center", width: "100%" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      mt: { xs: "3%", xl: "5%" },
                      bgcolor: "#2a1881",
                      color: "white",
                      fontSize: "18px",
                      fontFamily: "Inter Variable",
                      fontWeight: "700",
                      textTransform: "none",
                      boxShadow: "none",
                      borderRadius: "10px",
                      "&:hover": {
                        bgcolor: "#462655",
                      },
                    }}
                  >
                    {t("login.title")}
                  </Button>
                </Box>
              </form>

              <Box sx={{ mt: { xs: "4%", xl: "7%" }, textAlign: "center" }}>
                <Typography
                  sx={{
                    fontFamily: "Inter Variable",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  {t("login.noAccount")}{" "}
                  <span
                    onClick={redirectionCompte}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {t("login.createOneHere")}
                  </span>
                </Typography>
              </Box>
              <Box sx={{ mt: 1, textAlign: "center" }}>
                <Typography
                  sx={{
                    fontFamily: "Inter Variable",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  {t("login.forgotPassword")}{" "}
                  <span
                    onClick={redirectionPasswordReset}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {t("login.resetHere")}
                  </span>
                </Typography>
              </Box>
              {loading && <Loader loading={loading} />}
              <ToastError
                open={error !== null}
                onClose={() => setError(null)}
                message={error}
              />
            </Box>
          </Box>
        </Paper>
      </div>
    </ThemeProvider>
  );
}

export default ConnexionCompte;
