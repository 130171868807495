import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import EditNote from "@mui/icons-material/EditNote";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { addHttpsPrefix } from "../../shared/html/HtmlUtils";
import PageSelector from "./PageSelector";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const HeaderVisualBuilder = ({
  selectedPage,
  onPageChange,
  onUpdate,
  pages,
  siteConsulte,
  handleDrawerToggle,
  isMobile,
  onNewPage,
  onDeletePage,
  onModifyLogo,
  onModifyFavicon,
  onAddMenuItem,
  loadingPopover,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 2,
        bgcolor: "white",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        mt: { sm: "1%", lg: "0%" },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          size="large"
          sx={{ mr: 2, ml: 1, display: { sm: "1", xl: "none" } }}
        >
          <EditNote />
        </IconButton>
        {!isMobileDevice && (
          <>
            <Button
              onClick={onModifyLogo}
              variant="contained"
              sx={{
                background: "#0042ff",
                color: "white",
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.25px",
                textTransform: "none",
                mr: 2,
                borderRadius: "10px",
                border: "1px solid #276CFA",
                outline: "1px solid #0646C6",
                "&:hover": {
                  backgroundColor: "#1459e2",
                  boxShadow: "none",
                },
              }}
            >
              {t("headerVisualBuilder.modifyLogo")}
            </Button>
            <Button
              onClick={onModifyFavicon}
              variant="contained"
              sx={{
                background: "#0042ff",
                color: "white",
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.25px",
                textTransform: "none",
                mr: 2,
                borderRadius: "10px",
                border: "1px solid #276CFA",
                outline: "1px solid #0646C6",
                "&:hover": {
                  backgroundColor: "#1459e2",
                  boxShadow: "none",
                },
              }}
            >
              {t("headerVisualBuilder.modifyFavicon")}
            </Button>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: { xs: "80%", sm: "30%" },
        }}
      >
        <PageSelector
          pages={pages}
          selectedPage={selectedPage}
          onPageChange={onPageChange}
          onNewPage={onNewPage}
          onDeletePage={onDeletePage}
          siteConsulte={siteConsulte}
          onAddMenuItem={onAddMenuItem}
          loadingPopover={loadingPopover}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {!isMobile && (
          <Button
            variant="contained"
            sx={{
              bgcolor: "#F3F4F6",
              color: "#303030",
              fontSize: {
                lg: "14px",
                md: "14px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              mr: 1,
              boxShadow: "none",
              borderRadius: "10px",
              border: "1px solid #ECEFF2",
              outline: "1px solid #303030",
              "&:hover": {
                bgcolor: "#e2e2e2",
                color: "#303030",
                boxShadow: "none",
              },
            }}
            onClick={() => {
              const urlToOpen = addHttpsPrefix(selectedPage?.url);
              window.open(urlToOpen, "_blank");
            }}
          >
            {t("headerVisualBuilder.viewSite")}
          </Button>
        )}

        <Button
          onClick={onUpdate}
          variant="contained"
          sx={{
            background: "#1460F9",
            color: "white",
            fontSize: {
              lg: "14px",
              md: "14px",
              sm: "12px",
              xs: "12px",
            },
            fontFamily: "Inter Variable",
            fontWeight: "500",
            letterSpacing: "-0.25px",
            textTransform: "none",
            mr: isMobileDevice ? 0 : "6px",
            borderRadius: "10px",
            border: "1px solid #276CFA",
            outline: "1px solid #0646C6",
            "&:hover": {
              backgroundColor: "#1459e2",
              boxShadow: "none",
            },
          }}
        >
          {t("headerVisualBuilder.update")}
        </Button>
        {!isMobileDevice && (
          <Tooltip
            title={t("headerVisualBuilder.updateInfo")}
            arrow
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  fontSize: "1rem",
                  fontFamily: "Inter Variable",
                },
              },
            }}
          >
            <IconButton sx={{ marginRight: "6px" }}>
              <InfoIcon sx={{ color: "#3674f9", boxShadow: "none" }} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default HeaderVisualBuilder;
