import React, { useState } from "react";
import { Box, Button, Popover } from "@mui/material";

import { format } from "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { fr } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const CustomDateRangePicker = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
      <Button
        onClick={handleClick}
        variant="outlined"
        startIcon={<CalendarTodayIcon sx={{ color: "white" }} />}
        sx={{
          borderColor: "#c4cdd5",
          bgcolor: "#0042ff",
          color: "white",
          p: 1,
          borderRadius: "10px",
          textTransform: "none",
          fontFamily: "Inter Variable, sans-serif",
          fontWeight: "500",
          border: "1px solid #276CFA",
          outline: "1px solid #0646C6",
          "&:hover": {
            backgroundColor: "#1459e2",
            boxShadow: "none",
          },
        }}
      >
        {startDate && endDate
          ? `${format(startDate, "dd MMM yyyy")} - ${format(
              endDate,
              "dd MMM yyyy"
            )}`
          : t("statistique.selectDateRange")}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            mt: "0.25%",
            borderRadius: "15px",
            backgroundColor: "white",
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
            fontFamily: "Inter Variable, sans-serif",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            p: 2,
            "& .MuiTypography-root": {
              fontFamily: "Inter Variable, sans-serif",
            },
            "& .MuiPickersDay-root": {
              fontFamily: "Inter Variable, sans-serif",
            },
          }}
        >
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={startDate}
            onChange={(newDate) => {
              onStartDateChange(newDate);
              handleClose();
            }}
            renderInput={() => null}
            showToolbar={false}
            sx={{
              ".MuiPickersCalendarHeader-label": {
                fontFamily: "Inter Variable",
                textTransform: "uppercase",
              },
              ".MuiMonthCalendar-root": {
                fontFamily: "Inter Variable",
                textTransform: "uppercase",
              },
            }}
          />
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={endDate}
            onChange={(newDate) => {
              onEndDateChange(newDate);
              handleClose();
            }}
            renderInput={() => null}
            showToolbar={false}
            sx={{
              ".MuiPickersCalendarHeader-label": {
                fontFamily: "Inter Variable",
                textTransform: "uppercase",
              },
              ".MuiMonthCalendar-root": {
                fontFamily: "Inter Variable",
                textTransform: "uppercase",
              },
            }}
          />
        </Box>
      </Popover>
    </LocalizationProvider>
  );
};
export default CustomDateRangePicker;
