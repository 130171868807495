import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";

const ModuleSelector = ({
  type = "",
  modules = [],
  onModuleAdd,
  buttonLabel,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredModule, setHoveredModule] = useState(null);
  const buttonRef = useRef(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleModuleSelect = (moduleId) => {
    onModuleAdd(moduleId);
    handlePopoverClose();
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ mb: 2 }}>
      <Button
        ref={buttonRef}
        onClick={handlePopoverOpen}
        variant="outlined"
        sx={{
          background: "#1460F9",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          fontSize: {
            lg: "14px",
            md: "14px",
            sm: "12px",
            xs: "12px",
          },
          fontFamily: "Inter Variable",
          fontWeight: "500",
          letterSpacing: "-0.25px",
          textTransform: "none",
          mr: { lg: "6px", md: "0", sm: "0", xs: "0" },
          borderRadius: "10px",
          border: "1px solid #276CFA",
          outline: "1px solid #0646C6",
          "&:hover": {
            backgroundColor: "#1459e2",
            boxShadow: "none",
          },
        }}
      >
        {buttonLabel || t("sidebar.addModule", "Ajouter un module") + " +"}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            ml: "0.5%",
            width: "400px",
            display: "flex",
            flexDirection: "row",
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
            borderRadius: "15px",
            overflow: "hidden",
          },
        }}
      >
        <List
          sx={{
            width: "50%",
            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
            maxHeight: "300px",
            overflow: "auto",
          }}
        >
          {modules.map((module, index) => (
            <ListItem
              key={module.moduleId}
              button
              onClick={() => handleModuleSelect(module.moduleId)}
              onMouseEnter={() => setHoveredModule(module)}
              onMouseLeave={() => setHoveredModule(null)}
              sx={{
                borderBottom:
                  index < modules.length - 1 ? "1px solid #d8d8d8" : "none",
                "&:last-child": {
                  borderBottom: "none",
                },
              }}
            >
              <ListItemText
                primary={module.name}
                primaryTypographyProps={{
                  sx: {
                    fontFamily: "Inter Variable",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
        <Box
          sx={{
            width: "50%",
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F5F8FA",
          }}
        >
          {hoveredModule && hoveredModule.urlImage && (
            <img
              src={hoveredModule.urlImage}
              alt={hoveredModule.name}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default ModuleSelector;
