import { styled, Switch } from "@mui/material";

const CustomSwitch = styled(Switch)(() => ({
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      color: "#3576FF",
      "& + .MuiSwitch-track": {
        backgroundColor: "#3576FF",
      },
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#fff",
    borderRadius: 20 / 2,
  },
}));

export default CustomSwitch;
