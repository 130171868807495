import React, { useState } from "react";
import Footer from "../../components/footer/Footer";
import MenuAccueil from "../../components/accueil/menu/MenuAccueil";
import Banner from "../../components/accueil/Banner";
import Fonctionnement from "../../components/accueil/Fonctionnement";
import EditeurVisuel from "../../components/accueil/EditeurVisuel";
import Pricing from "../../components/accueil/Pricing";
import { Box } from "@mui/material";
import LoadingScreen from "../../shared/loader/LoadingScreen";

function Accueil() {
  const [loading, setLoading] = useState(true);

  const handleBackgroundLoad = () => {
    setLoading(false);
  };

  return (
    <>
      {loading && <LoadingScreen />}
      <div style={{ display: loading ? "none" : "block" }}>
        <img
          src={process.env.PUBLIC_URL + "/background/one-site-bg.png"}
          alt="Background"
          onLoad={handleBackgroundLoad}
          style={{ display: "none" }}
        />
        <Box
          sx={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/background/one-site-bg.png"
            })`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <MenuAccueil />
          <Banner />
        </Box>
        <Fonctionnement />
        <EditeurVisuel />
        <Pricing />
        <Footer />
      </div>
    </>
  );
}

export default Accueil;
