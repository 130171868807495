import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  DialogTitle,
  Button,
  InputAdornment,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../../../shared/api/ConstantesEndpoints";
import { useTranslation } from "react-i18next";
import ToastError from "../../../../shared/toast/ToastError";

const DiscountDialog = ({
  open,
  onClose,
  siteNom,
  selectedProduct,
  currentVariation,
  loadProducts,
}) => {
  const { t } = useTranslation();
  const [discountType, setDiscountType] = useState("percentage");
  const [discount, setDiscount] = useState(0);
  const [discountPrice, setDiscountPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (open) {
      setDiscount(0);
      setDiscountPrice("");
    }
  }, [open]);

  const handleApplyDiscount = async () => {
    try {
      setLoading(true);
      const url = currentVariation?.id
        ? `${API_SERVER_ENDPOINT}/products/${selectedProduct.id}/variations/${currentVariation.id}/discount`
        : `${API_SERVER_ENDPOINT}/products/${selectedProduct.id}/discount`;

      const payload = {
        siteNom,
        discountType,
      };

      if (discountType === "percentage") {
        payload.discountPercentage = discount;
      } else if (discountType === "fixed") {
        payload.discountPrice = discountPrice;
      }

      await axios.put(url, payload);
      loadProducts();
      onClose();
    } catch (error) {
      const erreurMessage =
        error.response?.data?.message || t("produits.apply_discount_error");
      handleError(erreurMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDiscount = async () => {
    try {
      setLoading(true);
      const url = currentVariation?.id
        ? `${API_SERVER_ENDPOINT}/products/${selectedProduct.id}/variations/${currentVariation.id}/discount`
        : `${API_SERVER_ENDPOINT}/products/${selectedProduct.id}/discount`;

      const payload = {
        siteNom,
      };

      await axios.delete(url, { data: payload });
      loadProducts();
      onClose();
    } catch (error) {
      const erreurMessage = error.response?.data?.message || error.message;
      handleError(erreurMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        sx: {
          borderRadius: "20px",
          width: { sm: "600px" },
          m: 0,
          p: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <Box sx={{ padding: "5%", width: "100%" }}>
        <DialogTitle
          sx={{
            textAlign: "center",
            mb: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: { lg: "24px", md: "18px", sm: "14px", xs: "12px" },
              color: "black",
              fontFamily: "Inter Variable, sans-serif",
              fontWeight: "500",
              letterSpacing: "-1px",
              textAlign: "center",
              mt: "1%",
              mb: "3%",
            }}
          >
            {t("produits.add_discount")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              color: "text.primary",
              fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "12px" },
              fontFamily: "Inter Variable",
              fontWeight: "700",
              letterSpacing: "-1px",
              marginLeft: "1%",
            }}
          >
            {t("produits.discount_type")}
          </Typography>
          <RadioGroup
            row
            value={discountType}
            onChange={(e) => setDiscountType(e.target.value)}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <FormControlLabel
              value="percentage"
              control={<Radio />}
              label={t("produits.discount_percentage")}
            />
            <FormControlLabel
              value="fixed"
              control={<Radio />}
              label={t("produits.discount_fixed")}
            />
          </RadioGroup>

          {discountType === "percentage" && (
            <>
              <Typography
                sx={{
                  color: "text.primary",
                  fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "12px" },
                  fontFamily: "Inter Variable",
                  fontWeight: "700",
                  letterSpacing: "-1px",
                  marginLeft: "1%",
                  mt: 2,
                }}
              >
                {t("produits.discount_percentage")}
              </Typography>
              <TextField
                margin="dense"
                type="number"
                fullWidth
                variant="outlined"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment sx={{ ml: 1 }} position="start">
                      %
                    </InputAdornment>
                  ),
                  sx: {
                    width: "100%",
                    height: "50px",
                    borderRadius: "12px",
                    backgroundColor: "white",
                    border: "1px solid #E3E3E3",
                    fontWeight: "500",
                    fontFamily: "Inter Variable",
                    fontSize: "14px",
                    mb: "3%",
                    padding: "0%",
                    letterSpacing: "-0.25px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    input: {
                      color: "text.primary",
                    },
                    "&::placeholder": {
                      color: "#000000",
                      opacity: 0.8,
                    },
                  },
                }}
              />
            </>
          )}

          {discountType === "fixed" && (
            <>
              <Typography
                sx={{
                  color: "text.primary",
                  fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "12px" },
                  fontFamily: "Inter Variable",
                  fontWeight: "700",
                  letterSpacing: "-1px",
                  marginLeft: "1%",
                  mt: 2,
                }}
              >
                {t("produits.discount_price")}
              </Typography>
              <TextField
                margin="dense"
                type="number"
                fullWidth
                variant="outlined"
                value={discountPrice}
                onChange={(e) => setDiscountPrice(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment sx={{ ml: 1 }} position="start">
                      €
                    </InputAdornment>
                  ),
                  sx: {
                    width: "100%",
                    height: "50px",
                    borderRadius: "12px",
                    backgroundColor: "white",
                    border: "1px solid #E3E3E3",
                    fontWeight: "500",
                    fontFamily: "Inter Variable",
                    fontSize: "14px",
                    mb: "3%",
                    padding: "0%",
                    letterSpacing: "-0.25px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    input: {
                      color: "text.primary",
                    },
                    "&::placeholder": {
                      color: "#000000",
                      opacity: 0.8,
                    },
                  },
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={onClose}
            sx={{
              bgcolor: "#F3F4F6",
              color: "#303030",
              fontSize: {
                lg: "16px",
                md: "16px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              mr: 1,
              pl: "3%",
              pr: "3%",
              boxShadow: "none",
              borderRadius: "10px",
              border: "1px solid #ECEFF2",
              outline: "1px solid #303030",
              "&:hover": {
                bgcolor: "#e2e2e2",
                color: "#303030",
                boxShadow: "none",
              },
            }}
          >
            {t("produits.cancel")}
          </Button>
          <LoadingButton
            onClick={handleApplyDiscount}
            loading={loading}
            variant="contained"
            color="primary"
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: {
                lg: "16px",
                md: "16px",
                sm: "12px",
                xs: "12px",
              },
              width: "100%",
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              mr: 2,
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
            }}
          >
            {t("produits.apply")}
          </LoadingButton>
          {(selectedProduct?.sale_price || currentVariation?.sale_price) && (
            <LoadingButton
              onClick={handleDeleteDiscount}
              loading={loading}
              variant="contained"
              color="error"
              sx={{
                mt: "5%",
                ml: "2%",
                px: "6%",
                fontFamily: "Space Grotesk",
                fontWeight: "700",
                background: "#ff0042",
                color: "white",
                fontSize: {
                  xl: "20px",
                  lg: "20px",
                  md: "18px",
                  sm: "16px",
                  xs: "14px",
                },
                textTransform: "none",
                letterSpacing: "-1px",
                boxShadow: "none",
                borderRadius: "10px",
                "&:hover": {
                  background: "#c10033",
                  boxShadow: "none",
                  color: "white",
                },
              }}
            >
              {t("produits.delete")}
            </LoadingButton>
          )}
        </DialogActions>
        <ToastError
          open={error !== null}
          onClose={() => setError(null)}
          message={error}
        />
      </Box>
    </Dialog>
  );
};

export default DiscountDialog;
