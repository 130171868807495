import React from "react";
import { Box, Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";

function BoutonRetour({ handleBackClick, sx }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        ...sx,
      }}
    >
      <Button
        sx={{
          fontFamily: "Inter Variable",
          borderRadius: "10px",
          background: "white",
          border: "none",
          color: "#1d35e5",
          fontWeight: 650,
          fontSize: "1rem",
          textTransform: "none",
          "&:hover": {
            background: "white",
          },
          "@media (max-width: 600px)": {
            fontSize: "0.8rem",
            fontWeight: 600,
            px: "1rem",
            py: "0.5rem",
          },
        }}
        startIcon={<ArrowBackIosNewIcon />}
        onClick={handleBackClick}
      >
        {t("button.back")}
      </Button>
    </Box>
  );
}

export default BoutonRetour;
