import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const UsePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const pagePath = location.pathname;

    window.gtag("event", "page_view", {
      page_path: pagePath,
    });

    switch (pagePath) {
      case "/connexion-compte":
        window.gtag("event", "login_page_view", {
          event_category: "user_engagement",
          event_label: "connexion_compte",
        });
        break;

      case "/paiement":
        window.gtag("event", "payment_page_view", {
          event_category: "ecommerce",
          event_label: "paiement_abonnement",
        });
        break;

      case "/creation-compte":
        window.gtag("event", "signup_page_view", {
          event_category: "user_engagement",
          event_label: "creation_compte",
        });
        break;

      case "/informations":
        window.gtag("event", "info_page_view", {
          event_category: "content",
          event_label: "informations",
        });
        break;

      case "/gestion-compte":
        window.gtag("event", "account_management_page_view", {
          event_category: "user_engagement",
          event_label: "gestion_compte",
        });
        break;

      default:
        break;
    }
  }, [location]);
};

export default UsePageTracking;
