import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Box,
  InputAdornment,
  Tooltip,
  Button,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../../../shared/api/ConstantesEndpoints";
import ToastError from "../../../../shared/toast/ToastError";

function AddShippingMethodDialog({ open, onClose, zoneId, siteConsulte }) {
  const { t } = useTranslation();

  const [settings, setSettings] = useState({
    title: "",
    cost: "",
    minAmount: "",
    method_id: "free_shipping",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `${API_SERVER_ENDPOINT}/livraison/zones/${zoneId}/methodes`,
        {
          siteNom: siteConsulte.nom,
          settings,
        }
      );
      onClose();
    } catch (error) {
      handleError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeType = (type) => {
    setSettings((prev) => ({
      ...prev,
      method_id: type === "payante" ? "flat_rate" : "free_shipping",
      cost: type === "payante" ? prev.cost : "0",
    }));
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "20px",
          width: "90%",
          maxWidth: "600px",
          height: "auto",
          maxHeight: "90vh",
          backgroundColor: "#f9f9f9",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle
        sx={{
          textAlign: "center",
          width: "70%",
          margin: "0 auto 16px auto",
        }}
      >
        <Typography
          sx={{
            fontSize: { lg: "24px", md: "18px", sm: "14px", xs: "12px" },
            color: "black",
            fontFamily: "Inter Variable, sans-serif",
            fontWeight: "600",
            letterSpacing: "-1px",
            textAlign: "center",
            mt: "1%",
          }}
        >
          {t("livraison.ajouterUneMethodeDeLivraison")}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ width: "70%", margin: "0 auto" }}>
        <TextField
          placeholder={t("livraison.nomDeLaMethodeDeLivraison")}
          autoFocus
          margin="dense"
          type="text"
          fullWidth
          variant="outlined"
          value={settings.title}
          onChange={(e) =>
            setSettings((prev) => ({ ...prev, title: e.target.value }))
          }
          InputProps={{
            sx: {
              width: "100%",
              height: "50px",
              borderRadius: "12px",
              backgroundColor: "white",
              border: "1px solid #E3E3E3",
              fontWeight: "500",
              fontFamily: "Inter Variable",
              fontSize: "14px",
              padding: "0%",
              letterSpacing: "-0.25px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              input: {
                color: "text.primary",
              },
              "&::placeholder": {
                color: "#000000",
                opacity: 0.8,
              },
            },
          }}
        />
        <FormControl fullWidth margin="dense">
          <Typography
            sx={{
              color: "text.primary",
              fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "12px" },
              fontFamily: "Space Grotesk",
              fontWeight: "700",
              letterSpacing: "-1px",
              marginLeft: "0%",
              marginBottom: "1%",
              marginTop: "3%",
            }}
          >
            {t("livraison.typeDeLivraison")}
          </Typography>
          <Select
            value={settings.method_id === "flat_rate" ? "payante" : "gratuite"}
            onChange={(e) => handleChangeType(e.target.value)}
            sx={{
              display: "flex",
              mb: "3%",
              justifyContent: "center",
              width: "100%",
              bgcolor: "#ECEDF5",
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.55px",
              fontSize: {
                lg: "14px",
                md: "14px",
                sm: "12px",
                xs: "12px",
              },
              color: "black",
              borderRadius: "10px",
              "& .MuiSelect-select": {
                borderRadius: "20px",
                textAlign: "center",
                padding: "10px 0",
                border: "none",
              },
              "&:before, &:after": {
                border: "none",
              },
              "& .MuiSvgIcon-root": {
                color: "black",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiPaper-root": {
                bgcolor: "#ECEDF5",
                borderRadius: "10px",
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  mt: "-0.5%",
                  bgcolor: "#ECEDF5",
                  boxShadow: "none",
                  borderBottomRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  "& .MuiMenuItem-root": {
                    fontFamily: "Inter Variable",
                    fontWeight: "500",
                    letterSpacing: "-0.5px",
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                    justifyContent: "center",
                    width: "100%",
                    textAlign: "center",
                    "&:hover": {
                      background: "#0033c1",
                      fontWeight: "500",
                      color: "white",
                    },
                    "&.Mui-selected": {
                      bgcolor: "#ECEDF5",
                      "&:hover": {
                        bgcolor: "#D0D0D0",
                        color: "black",
                      },
                    },
                  },
                },
              },
            }}
          >
            <MenuItem value="gratuite">{t("livraison.gratuite")}</MenuItem>
            <MenuItem value="payante">{t("livraison.payante")}</MenuItem>
          </Select>
        </FormControl>
        {settings.method_id === "flat_rate" && (
          <Box>
            <Typography
              sx={{
                color: "text.primary",
                fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "12px" },
                fontFamily: "Space Grotesk",
                fontWeight: "700",
                letterSpacing: "-1px",
                marginLeft: "0%",
                marginBottom: "1%",
                marginTop: "3%",
              }}
            >
              {t("livraison.prixDeLaLivraison")}
            </Typography>
            <TextField
              margin="dense"
              type="number"
              fullWidth
              variant="outlined"
              value={settings.cost}
              onChange={(e) =>
                setSettings((prev) => ({ ...prev, cost: e.target.value }))
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment sx={{ ml: 1 }} position="start">
                    €
                  </InputAdornment>
                ),
                sx: {
                  width: "100%",
                  height: "50px",
                  borderRadius: "12px",
                  backgroundColor: "white",
                  border: "1px solid #E3E3E3",
                  fontWeight: "500",
                  fontFamily: "Inter Variable",
                  fontSize: "14px",
                  padding: "0%",
                  letterSpacing: "-0.25px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  input: {
                    color: "text.primary",
                  },
                  "&::placeholder": {
                    color: "#000000",
                    opacity: 0.8,
                  },
                },
              }}
            />
          </Box>
        )}
        {settings.method_id === "free_shipping" && (
          <Box>
            <Typography
              sx={{
                color: "text.primary",
                fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "12px" },
                fontWeight: "700",
                fontFamily: "Inter Variable",
                marginLeft: "0%",
                letterSpacing: "-0.5px",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              {t("livraison.montantMinimumDeCommande")}
              <Tooltip
                title={t("livraison.tooltipMontantMinimumDeCommande")}
                arrow
                PopperProps={{
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      fontSize: "16px",
                      fontFamily: "Inter Variable",
                    },
                  },
                }}
              >
                <InfoIcon sx={{ marginLeft: "5px", fontSize: "1rem" }} />
              </Tooltip>
            </Typography>
            <TextField
              margin="dense"
              type="number"
              fullWidth
              variant="outlined"
              value={settings.minAmount}
              onChange={(e) =>
                setSettings((prev) => ({ ...prev, minAmount: e.target.value }))
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment sx={{ ml: 1 }} position="start">
                    €
                  </InputAdornment>
                ),
                sx: {
                  width: "100%",
                  height: "50px",
                  borderRadius: "12px",
                  backgroundColor: "white",
                  border: "1px solid #E3E3E3",
                  fontWeight: "500",
                  fontFamily: "Inter Variable",
                  fontSize: "14px",
                  padding: "0%",
                  letterSpacing: "-0.25px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  input: {
                    color: "text.primary",
                  },
                  "&::placeholder": {
                    color: "#000000",
                    opacity: 0.8,
                  },
                },
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", mt: "5%" }}>
        <Button
          onClick={onClose}
          color="primary"
          disabled={isLoading}
          sx={{
            bgcolor: "#F3F4F6",
            color: "#303030",
            fontSize: {
              lg: "16px",
              md: "16px",
              sm: "12px",
              xs: "12px",
            },
            fontFamily: "Inter Variable",
            fontWeight: "500",
            letterSpacing: "-0.25px",
            textTransform: "none",
            mr: 1,
            pl: "3%",
            pr: "3%",
            boxShadow: "none",
            borderRadius: "10px",
            border: "1px solid #ECEFF2",
            outline: "1px solid #303030",
            "&:hover": {
              bgcolor: "#e2e2e2",
              color: "#303030",
              boxShadow: "none",
            },
          }}
        >
          {t("livraison.annuler")}
        </Button>
        <LoadingButton
          onClick={handleSave}
          endIcon={<ArrowForwardIosIcon />}
          loading={isLoading}
          loadingPosition="end"
          variant="contained"
          color="primary"
          sx={{
            background: "#0042ff",
            color: "white",
            fontSize: {
              lg: "16px",
              md: "16px",
              sm: "12px",
              xs: "12px",
            },
            fontFamily: "Inter Variable",
            fontWeight: "500",
            letterSpacing: "-0.25px",
            textTransform: "none",
            mr: 2,
            borderRadius: "10px",
            border: "1px solid #276CFA",
            outline: "1px solid #0646C6",
            "&:hover": {
              backgroundColor: "#1459e2",
              boxShadow: "none",
            },
          }}
        >
          {t("livraison.enregistrer")}
        </LoadingButton>
      </DialogActions>
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </Dialog>
  );
}

export default AddShippingMethodDialog;
