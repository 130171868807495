import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import ToastError from "../../shared/toast/ToastError";
import useMediaQuery from "@mui/material/useMediaQuery";

const PreviewSite = ({ iframeURL, refresh, loading, setLoading }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);

  const isMobile = useMediaQuery("(max-width:600px)");

  const handleToggleView = () => {
    setIsMobileView((prev) => !prev);
  };

  const iframeStyles = {
    overflowY: "scroll",
    width: isMobileView ? "375px" : "100%",
    height: isMobileView ? "667px" : "100%",
    borderRadius: "10px",
    border: "none",
    transition: "width 0.3s ease, height 0.3s ease",
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  useEffect(() => {
    setLoading(true);
  }, [iframeURL, setLoading]);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <Box
      sx={{
        position: "relative",
        flex: 1,
        bgcolor: "background.paper",
        p: 2,
        borderRadius: "10px",
        mt: "10px",
        ml: "5px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      {!isMobile && (
        <Button
          variant="contained"
          onClick={handleToggleView}
          sx={{
            width: "150px",
            position: "absolute",
            top: "90%",
            right: "75px",
            zIndex: 1000,
            transition: "opacity 0.3s ease",
            "&:hover": {
              opacity: 1,
            },
            background: "#1460F9",
            color: "white",
            fontSize: {
              lg: "14px",
              md: "14px",
              sm: "12px",
              xs: "12px",
            },
            fontFamily: "Inter Variable",
            fontWeight: "500",
            letterSpacing: "-0.25px",
            textTransform: "none",
            borderRadius: "10px",
            border: "1px solid #276CFA",
            outline: "1px solid #0646C6",
          }}
        >
          {isMobileView
            ? t("previewSite.viewDesktop")
            : t("previewSite.viewMobile")}
        </Button>
      )}

      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            bgcolor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <iframe
          key={refresh}
          style={iframeStyles}
          src={iframeURL}
          title={t("previewSite.title")}
          onLoad={handleIframeLoad}
          onError={() => handleError(t("previewSite.error"))}
        />
      </Box>
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </Box>
  );
};

export default PreviewSite;
