import React from "react";
import { Card, Typography, Box } from "@mui/material";
import CircularProgressWithLabel from "./CircularProgressWithLabel";

const ConversionRateStatCard = ({ title, value, dateRange }) => {
  const displayValue = value != null ? value : 0;

  return (
    <Card
      raised
      sx={{
        m: 2,
        p: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        boxShadow: "none",
        border: "2px solid #c6c7ce",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          mb: 2,
          fontFamily: "Space Grotesk, sans serif",
          fontWeight: "1000",
          letterSpacing: { xs: "-1px", sm: "-1px", md: "-2px", lg: "-3px" },
          fontSize: { xs: "25px", sm: "25px", md: "30px", lg: "40px" },
        }}
      >
        {title}
      </Typography>
      <CircularProgressWithLabel value={displayValue} label={`${title} Rate`} />
      <Box sx={{ marginTop: { xs: "1rem", md: "1rem" } }}>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          sx={{ mb: 1, fontFamily: "Inter Variable", textAlign: "center" }}
        >
          {dateRange}
        </Typography>
      </Box>
    </Card>
  );
};

export default ConversionRateStatCard;
