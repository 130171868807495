import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../../../shared/api/ConstantesEndpoints";

const PixelForm = ({ siteConsulte, onClose }) => {
  const { t } = useTranslation();
  const [pixels, setPixels] = useState({
    tiktok: "",
    pinterest: "",
    facebook: "",
  });
  const [loading, setLoading] = useState({
    tiktok: false,
    pinterest: false,
    facebook: false,
  });
  const [success, setSuccess] = useState({
    tiktok: false,
    pinterest: false,
    facebook: false,
  });

  const loadPixels = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_SERVER_ENDPOINT}/site/${siteConsulte.nom}/pixels`
      );

      const defaultPixels = {
        tiktok: "",
        pinterest: "",
        facebook: "",
      };

      const pixelData = response.data.pixels.reduce((acc, pixel) => {
        acc[pixel.type.toLowerCase()] = pixel.code;
        return acc;
      }, defaultPixels);

      setPixels(pixelData);
    } catch (error) {
      console.error(error.message);
    }
  }, [siteConsulte]);

  useEffect(() => {
    if (siteConsulte && siteConsulte.nom) {
      loadPixels();
    }
  }, [siteConsulte, loadPixels]);

  const handlePixelChange = (e) => {
    const { name, value } = e.target;
    setPixels((prevPixels) => ({ ...prevPixels, [name]: value }));
  };

  const handlePixelSubmit = async (type) => {
    setLoading((prev) => ({ ...prev, [type]: true }));
    setSuccess((prev) => ({ ...prev, [type]: false }));

    const endpoints = {
      tiktok: `${API_SERVER_ENDPOINT}/site/${siteConsulte.nom}/set-pixel-tiktok`,
      pinterest: `${API_SERVER_ENDPOINT}/site/${siteConsulte.nom}/set-pixel-pinterest`,
      facebook: `${API_SERVER_ENDPOINT}/site/${siteConsulte.nom}/set-pixel-facebook`,
    };

    try {
      await axios.post(endpoints[type], { pixel_code: pixels[type] });
      setSuccess((prev) => ({ ...prev, [type]: true }));
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading((prev) => ({ ...prev, [type]: false }));
    }
  };

  const toUpperCase = (text) => text.toUpperCase();

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        sx: {
          borderRadius: "20px",
          width: { sm: "600px" },
          m: 0,
          p: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              variant="h6"
              sx={{
                fontSize: { lg: "24px", md: "18px", sm: "14px", xs: "12px" },
                color: "black",
                fontFamily: "Inter Variable, sans-serif",
                fontWeight: "500",
                letterSpacing: "-1px",
                textAlign: "center",
                mt: "1%",
                mb: "3%",
              }}
            >
              {t("pixel.add_pixel")}
            </Typography>
          </Box>

          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "20px",
              boxShadow: "none",
              border: "none",
              width: { xl: "95%", lg: "95%", md: "95%", sm: "95%", xs: "95%" },
              mb: "1%",
              ml: "3%",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: { xl: "14px", lg: "14px", md: "14px" },
                      fontFamily: "Inter Variable",
                      letterSpacing: "-0.5px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    {t("pixel.pixel_type")}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xl: "14px", lg: "14px", md: "14px" },
                      fontFamily: "Inter Variable",
                      letterSpacing: "-0.5px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    {t("pixel.pixel_code")}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xl: "14px", lg: "14px", md: "14px" },
                      fontFamily: "Inter Variable",
                      letterSpacing: "-0.5px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    {t("pixel.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(pixels).map((key) => (
                  <TableRow key={key}>
                    <TableCell
                      sx={{
                        fontSize: { xl: "14px", lg: "14px", md: "14px" },
                        fontFamily: "Inter Variable",
                        letterSpacing: "-0.5px",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {toUpperCase(t(key))}
                    </TableCell>
                    <TableCell>
                      <TextField
                        placeholder="ID du pixel"
                        fullWidth
                        name={key}
                        value={pixels[key]}
                        onChange={handlePixelChange}
                        InputProps={{
                          sx: {
                            width: "100%",
                            height: "50px",
                            borderRadius: "12px",
                            backgroundColor: "white",
                            border: "1px solid #E3E3E3",
                            fontWeight: "500",
                            fontFamily: "Inter Variable",
                            fontSize: "14px",
                            padding: "0%",
                            letterSpacing: "-0.25px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "transparent",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "transparent",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "transparent",
                            },
                            input: {
                              color: "text.primary",
                            },
                            "&::placeholder": {
                              color: "#000000",
                              opacity: 0.8,
                            },
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <LoadingButton
                        variant="contained"
                        color={success[key] ? "success" : "primary"}
                        onClick={() => handlePixelSubmit(key)}
                        loading={loading[key]}
                        loadingPosition="end"
                        endIcon={success[key] ? <CheckIcon /> : null}
                        sx={{
                          background: success[key] ? "#28a745" : "#0042ff",
                          color: "white",
                          fontSize: {
                            lg: "14px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                          fontFamily: "Inter Variable",
                          fontWeight: "500",
                          letterSpacing: "-0.25px",
                          textTransform: "none",
                          borderRadius: "10px",
                          border: success[key]
                            ? "1px solid #28a745"
                            : "1px solid #276CFA",
                          outline: success[key]
                            ? "1px solid #28a745"
                            : "1px solid #0646C6",
                          "&:hover": {
                            backgroundColor: success[key]
                              ? "#218838"
                              : "#1459e2",
                            boxShadow: "none",
                          },
                        }}
                      >
                        {t("pixel.save")}
                      </LoadingButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={onClose}
              sx={{
                bgcolor: "#F3F4F6",
                color: "#303030",
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.25px",
                textTransform: "none",
                mr: 1,
                pl: "3%",
                pr: "3%",
                boxShadow: "none",
                borderRadius: "10px",
                border: "1px solid #ECEFF2",
                outline: "1px solid #303030",
                "&:hover": {
                  bgcolor: "#e2e2e2",
                  color: "#303030",
                  boxShadow: "none",
                },
              }}
            >
              {t("pixel.cancel")}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PixelForm;
