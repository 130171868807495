import React, { useState, useCallback } from "react";
import axios from "axios";
import {
  Typography,
  Paper,
  Select,
  MenuItem,
  Slider,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { API_SERVER_ENDPOINT } from "../../../../../shared/api/ConstantesEndpoints";
import ToastError from "../../../../../shared/toast/ToastError";
import Loader from "../../../../../shared/loader/Loader";
import SuccessToast from "../../../../../shared/toast/ToastSuccess";

const ReviewGenerator = ({ siteConsulte, products }) => {
  const { t } = useTranslation();

  const [selectedProduct, setSelectedProduct] = useState("");
  const [numReviews, setNumReviews] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleError = useCallback((message) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 3000);
  }, []);

  const handleSuccess = useCallback(() => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  }, []);

  const handleGenerateAndImport = async () => {
    if (!selectedProduct) {
      handleError(t("reviewGenerator.selectProductError"));
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/generate_reviews`,
        {
          product_id: selectedProduct,
          num_reviews: numReviews,
          nomSite: siteConsulte.nom,
        }
      );

      if (response.status === 200) {
        handleSuccess();
      }
    } catch (error) {
      handleError(t("reviewGenerator.errorGeneratingReviews"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Paper
        sx={{
          margin: "0px",
          overflowX: "auto",
          boxShadow: "none",
          backgroundColor: "#f5f8fa",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            mt: 4,
          }}
        >
          <Box
            sx={{
              mt: "5%",
              width: "50%",
              maxWidth: "70%",
              borderRadius: "20px",
              padding: "20px",
              backgroundColor: "white",
              boxShadow: "none",
              display: "flex",
              border: "2px solid #c6c7ce",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "18px",
                  fontFamily: "Inter Variable, sans-serif",
                  fontWeight: "500",
                  mb: 3,
                }}
              >
                {t("reviewGenerator.description")}
              </Typography>
            </Box>
            <Select
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
              displayEmpty
              fullWidth
              sx={{
                display: "flex",
                mb: "3%",
                justifyContent: "center",
                width: "100%",
                bgcolor: "#ECEDF5",
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.55px",
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                color: "black",
                borderRadius: "10px",
                "& .MuiSelect-select": {
                  borderRadius: "20px",
                  textAlign: "center",
                  padding: "10px 0",
                  fontStyle: "normal !important",
                  border: "none",
                },
                "&:before, &:after": {
                  border: "none",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiPaper-root": {
                  bgcolor: "#ECEDF5",
                  borderRadius: "10px",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    mt: "-0.5%",
                    bgcolor: "#ECEDF5",
                    boxShadow: "none",
                    borderBottomRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    "& .MuiMenuItem-root": {
                      fontFamily: "Inter Variable",
                      fontWeight: "500",
                      letterSpacing: "-0.5px",
                      fontSize: {
                        lg: "14px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                      fontStyle: "normal !important",
                      "&:hover": {
                        background: "#0033c1",
                        fontWeight: "500",
                        color: "white",
                      },
                      "&.Mui-selected": {
                        bgcolor: "#ECEDF5",
                        "&:hover": {
                          bgcolor: "#D0D0D0",
                          color: "black",
                        },
                      },
                    },
                  },
                },
              }}
            >
              <MenuItem value="" sx={{ fontStyle: "normal !important" }}>
                {t("reviewGenerator.selectProduct")}
              </MenuItem>
              {products.map((product) => (
                <MenuItem key={product.id} value={product.id}>
                  {product.name}
                </MenuItem>
              ))}
            </Select>

            <Typography
              gutterBottom
              sx={{
                color: "text.primary",
                fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "12px" },
                fontFamily: "Inter Variable",
                fontWeight: "700",
                letterSpacing: "-0.5px",
                marginLeft: "0%",
                marginBottom: "1%",
              }}
            >
              {t("reviewGenerator.numberOfReviews")}: {numReviews}
            </Typography>
            <Slider
              value={numReviews}
              onChange={(e, newValue) => setNumReviews(newValue)}
              min={1}
              max={30}
              valueLabelDisplay="auto"
              sx={{ mb: 4 }}
            />

            <LoadingButton
              type="button"
              loading={isLoading}
              variant="contained"
              color="primary"
              onClick={handleGenerateAndImport}
              disabled={isLoading || !selectedProduct}
              fullWidth
              sx={{
                background: "#0042ff",
                color: "white",
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Space Grotesk, sans-serif",
                fontWeight: "500",
                letterSpacing: "-0.25px",
                textTransform: "none",
                borderRadius: "10px",
                border: "1px solid #276CFA",
                outline: "1px solid #0646C6",
                "&:hover": {
                  backgroundColor: "#1459e2",
                  boxShadow: "none",
                },
              }}
            >
              {isLoading
                ? t("reviewGenerator.generating")
                : t("reviewGenerator.generateButton")}
            </LoadingButton>
          </Box>
        </Box>
      </Paper>
      <Loader loading={isLoading} />
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
      <SuccessToast
        open={success}
        onClose={() => setSuccess(false)}
        message={t("reviewGenerator.successMessage", { count: numReviews })}
      />
    </div>
  );
};

export default ReviewGenerator;
