import React, { useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import formatAddress from "../../../../shared/adresse/AdresseUtils";
import { useTranslation } from "react-i18next";
import GetStatusChip from "./StatutChip";

const CommandeCard = ({ order, handleStatusChange }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (status) => {
    handleStatusChange(order.id, status);
    handleClose();
  };

  return (
    <Card
      sx={{
        marginBottom: 2,
        boxShadow: 3,
        borderColor: "grey.300",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "16px",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontSize: "20px",
              fontFamily: "Inter Variable",
              fontWeight: "600",
              letterSpacing: "-0.5px",
            }}
          >
            {order.id}
          </Typography>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleAction("cancelled")}>
              {t("commande.annuler")}
            </MenuItem>
            <MenuItem onClick={() => handleAction("completed")}>
              {t("commande.completer")}
            </MenuItem>
            <MenuItem onClick={() => handleAction("on-hold")}>
              {t("commande.mettreEnAttente")}
            </MenuItem>
          </Menu>
        </Box>

        <Box sx={{ marginBottom: 1 }}>
          <Typography
            variant="body2"
            sx={{
              fontSize: "20px",
              fontFamily: "Inter Variable",
              fontWeight: "600",
              letterSpacing: "-0.5px",
            }}
          >
            {t("commande.date")}
          </Typography>
          <Typography
            sx={{
              marginBottom: 1,
              fontSize: "20px",
              fontFamily: "Inter Variable",
              fontWeight: "600",
              letterSpacing: "-0.5px",
            }}
          >
            {new Date(order.date_created).toLocaleDateString("fr-FR")}
          </Typography>
        </Box>

        <Box sx={{ marginBottom: 1 }}>
          <Typography
            variant="body2"
            sx={{
              fontSize: "20px",
              fontFamily: "Inter Variable",
              fontWeight: "600",
              letterSpacing: "-0.5px",
            }}
          >
            {t("commande.client")}
          </Typography>
          <Typography
            sx={{
              marginBottom: 1,
              fontSize: "20px",
              fontFamily: "Inter Variable",
              fontWeight: "600",
              letterSpacing: "-0.5px",
            }}
          >
            {order.customer
              ? `${order.shipping.first_name} ${order.shipping.last_name}`
              : t("commande.invite")}
          </Typography>
        </Box>

        <Box sx={{ marginBottom: 1 }}>
          <Typography
            variant="body2"
            sx={{
              fontSize: "20px",
              fontFamily: "Inter Variable",
              fontWeight: "600",
              letterSpacing: "-0.5px",
            }}
          >
            {t("commande.montant")}
          </Typography>
          <Typography
            sx={{
              marginBottom: 1,
              fontSize: "20px",
              fontFamily: "Inter Variable",
              fontWeight: "600",
              letterSpacing: "-0.5px",
            }}
          >
            {order.total} {order.currency}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {GetStatusChip(order.status)}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Typography variant="h6" gutterBottom component="div">
              {t("commande.produitsAchetes")}
            </Typography>
            <List>
              {order.line_items.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={item.name}
                    secondary={`${t("commande.quantite")}: ${
                      item.quantity
                    }, ${t("commande.prix")}: ${item.price}`}
                  />
                </ListItem>
              ))}
            </List>
            <Typography variant="h6">
              {t("commande.adresseDeLivraison")}:
            </Typography>
            <Typography>{formatAddress(order.shipping, t)}</Typography>
            <Typography variant="h6" sx={{ marginTop: "16px" }}>
              {t("commande.adresseDeFacturation")}:
            </Typography>
            <Typography>{formatAddress(order.billing, t)}</Typography>
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default CommandeCard;
