import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  Typography,
  Button,
  DialogTitle,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../../../shared/api/ConstantesEndpoints";
import { useTranslation } from "react-i18next";
import ToastError from "../../../../shared/toast/ToastError";

const DeleteDialog = ({
  open,
  onClose,
  itemType,
  siteNom,
  selectedProduct,
  selectedItem,
  loadProducts,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleDelete = async () => {
    try {
      setLoading(true);
      let url = "";

      if (itemType === "variation") {
        url = `${API_SERVER_ENDPOINT}/products/${selectedProduct.id}/variations/${selectedItem.id}`;
      } else if (itemType === "variation-discount") {
        url = `${API_SERVER_ENDPOINT}/products/${selectedProduct.id}/variations/${selectedItem.id}/discount`;
      } else if (itemType === "product-discount") {
        url = `${API_SERVER_ENDPOINT}/products/${selectedProduct.id}/discount`;
      } else if (itemType === "product") {
        url = `${API_SERVER_ENDPOINT}/products/${selectedProduct.id}`;
      }

      const payload = {
        siteNom,
      };

      await axios.delete(url, { data: payload });
      loadProducts();
      onClose();
    } catch (error) {
      const erreurMessage = error.response?.data?.message || error.message;
      handleError(erreurMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const getDeleteConfirmationText = () => {
    switch (itemType) {
      case "variation":
        return t("produits.delete_variation_confirmation");
      case "variation-discount":
        return t("produits.delete_variation_discount_confirmation");
      case "product-discount":
        return t("produits.delete_product_discount_confirmation");
      case "product":
        return t("produits.delete_product_confirmation");
      default:
        return t("produits.delete_confirmation_generic");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        sx: {
          borderRadius: "20px",
          width: "90%",
          maxWidth: "600px",
          height: "auto",
          maxHeight: "90vh",
          backgroundColor: "#f9f9f9",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: { lg: "20px", md: "16px", sm: "14px", xs: "12px" },
            color: "black",
            fontFamily: "Inter Variable",
            fontWeight: "600",
            letterSpacing: "-1px",
          }}
        >
          {getDeleteConfirmationText()}
        </Typography>
      </DialogTitle>
      <DialogActions sx={{ justifyContent: "center", width: "50%" }}>
        <Button
          onClick={onClose}
          sx={{
            bgcolor: "#F3F4F6",
            color: "#303030",
            width: "40%",
            fontSize: {
              lg: "14px",
              md: "14px",
              sm: "12px",
              xs: "12px",
            },
            fontFamily: "Inter Variable",
            fontWeight: "500",
            letterSpacing: "-0.25px",
            textTransform: "none",
            mr: 1,
            boxShadow: "none",
            borderRadius: "10px",
            border: "1px solid #ECEFF2",
            outline: "1px solid #303030",
            "&:hover": {
              bgcolor: "#e2e2e2",
              color: "#303030",
              boxShadow: "none",
            },
          }}
        >
          {t("produits.cancel")}
        </Button>
        <LoadingButton
          onClick={handleDelete}
          endIcon={<DeleteIcon />}
          loading={loading}
          loadingPosition="end"
          variant="contained"
          color="primary"
          sx={{
            mt: "3%",
            mb: "3%",
            background: "#e6332d",
            color: "white",
            fontSize: {
              lg: "14px",
              md: "14px",
              sm: "12px",
              xs: "12px",
            },
            fontFamily: "Inter Variable",
            letterSpacing: "-0.25px",
            fontWeight: "600",
            textTransform: "none",
            boxShadow: "none",
            mr: 1,
            outline: "1px solid #c12724",
            border: "1px solid #f74a47",
            borderRadius: "10px",
            "&:hover": {
              background: "#c12724",
              boxShadow: "none",
              color: "white",
            },
          }}
        >
          {t("produits.delete")}
        </LoadingButton>
      </DialogActions>
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </Dialog>
  );
};

export default DeleteDialog;
