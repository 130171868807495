import { Alert, Snackbar } from "@mui/material";

const SuccessToast = ({ open, onClose, message }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={onClose}
        severity="success"
        variant="filled"
        sx={{
          bgcolor: "#4caf50",
          color: "white",
          fontSize: "16px",
          fontWeight: "600",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          "& .MuiAlert-icon": {
            color: "white",
          },
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SuccessToast;
