import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Paper,
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../../../../shared/loader/Loader";
import { API_SERVER_ENDPOINT } from "../../../../shared/api/ConstantesEndpoints";
import ToastError from "../../../../shared/toast/ToastError";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PaymentMethodCard from "./PaymentMethodCard";
import CheckIcon from "@mui/icons-material/Check";

const GestionPaiement = ({ siteConsulte, getUtilisateurSite }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const [customerId] = useState(profil?.customerId);

  const [currency, setCurrency] = useState("EUR");
  const [currencyPosition, setCurrencyPosition] = useState("right");
  const [thousandSeparator, setThousandSeparator] = useState(",");
  const [siteLangue, setSiteLangue] = useState("fr");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showSuccessLangue, setShowSuccessLangue] = useState(false);
  const [showSuccessCurrency, setShowSuccessCurrency] = useState(false);

  const [loadingCurrency, setLoadingCurrency] = useState(false);
  const [loadingLangue, setLoadingLangue] = useState(false);

  const getUtilisateurInformations = useCallback(async () => {
    try {
      const response = await axios.get(`${API_SERVER_ENDPOINT}/utilisateur`, {
        params: {
          customerId: customerId,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const utilisateur = response.data;

      const abonnement = utilisateur.abonnements.find((abonnement) =>
        abonnement.sites.some((site) => site.nom === siteConsulte.nom)
      );

      if (abonnement) {
        const site = abonnement.sites.find(
          (site) => site.nom === siteConsulte.nom
        );

        if (site && site.parametres) {
          setCurrency(site.parametres.monnaie || "EUR");
          setCurrencyPosition(site.parametres.positionMonnaie || "right");
          setThousandSeparator(site.parametres.separateurMilliers || ",");
          setSiteLangue(site.parametres.langue || "fr_FR");
        }
      }
    } catch (error) {
      handleError(error.message);
    }
  }, [siteConsulte, customerId]);

  const loadOptionsPaiement = useCallback(() => {
    setIsLoading(true);

    if (siteConsulte && siteConsulte.nom) {
      const url = `${API_SERVER_ENDPOINT}/moyens-de-paiement?siteNom=${encodeURIComponent(
        siteConsulte.nom
      )}`;

      axios
        .get(url)
        .then((response) => {
          setPaymentOptions(response.data);
        })
        .catch((error) => {
          handleError(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [siteConsulte]);

  const onModifierMoyenPaiement = async (gatewayId, enableState, settings) => {
    setIsLoading(true);

    try {
      const response = await axios.put(
        `${API_SERVER_ENDPOINT}/moyens-de-paiement/${gatewayId}`,
        {
          enableState,
          settings,
        },
        {
          params: { siteNom: siteConsulte.nom },
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        loadOptionsPaiement();
      }
    } catch (error) {
      handleError(error.message);
      setIsLoading(false);
    }
  };

  const handleSaveCurrencySettings = async () => {
    setLoadingCurrency(true);
    try {
      await axios.put(
        `${API_SERVER_ENDPOINT}/settings/currency`,
        { currency },
        {
          params: { siteNom: siteConsulte.nom },
          headers: { "Content-Type": "application/json" },
        }
      );

      await axios.put(
        `${API_SERVER_ENDPOINT}/settings/currency-position`,
        { currencyPosition },
        {
          params: { siteNom: siteConsulte.nom },
          headers: { "Content-Type": "application/json" },
        }
      );

      await axios.put(
        `${API_SERVER_ENDPOINT}/settings/thousand-separator`,
        { thousandSeparator },
        {
          params: { siteNom: siteConsulte.nom },
          headers: { "Content-Type": "application/json" },
        }
      );

      setShowSuccessCurrency(true);
      setTimeout(() => setShowSuccessCurrency(false), 3000);
    } catch (error) {
      const message = error.response?.data?.error || error.message;
      handleError(message);
    } finally {
      setLoadingCurrency(false);
    }
  };

  const handleSaveLangue = async () => {
    setLoadingLangue(true);
    try {
      await axios.post(
        `${API_SERVER_ENDPOINT}/site/change-langue`,
        { language: siteLangue },
        {
          params: { siteNom: siteConsulte.nom },
          headers: { "Content-Type": "application/json" },
        }
      );

      setShowSuccessLangue(true);
      setTimeout(() => setShowSuccessLangue(false), 3000);
    } catch (error) {
      const message = error.response?.data?.error || error.message;
      handleError(message);
    } finally {
      setLoadingLangue(false);
    }
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  useEffect(() => {
    if (siteConsulte && siteConsulte.nom) {
      getUtilisateurInformations();
      loadOptionsPaiement();
    }
  }, [siteConsulte, getUtilisateurInformations, loadOptionsPaiement]);

  return (
    <div
      style={{
        flexGrow: 1,
        padding: "2%",
        backgroundColor: "#f5f8fa",
        minHeight: "100vh",
      }}
    >
      <Paper
        sx={{
          margin: "0px",
          overflowX: "auto",
          boxShadow: "none",
          backgroundColor: "#f5f8fa",
        }}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: { xs: "26px", sm: "60px" },
            fontFamily: "Space Grotesk, sans serif",
            fontWeight: "1000",
            letterSpacing: { xs: "-2px", sm: "-5px", md: "-5px", lg: "-5px" },
            ml: "2%",
            mt: { xs: "5%", sm: 0 },
          }}
        >
          {t("paiement.paymentOptions")}
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: "16px",
            fontFamily: "Inter Variable, sans-serif",
            fontWeight: "500",
            letterSpacing: "-0.75px",
            mb: "3%",
            mt: "0%",
            ml: "2%",
          }}
        >
          {t("paiement.managePaymentProcessors")}
        </Typography>

        <Grid container spacing={3} sx={{ mt: 2 }}>
          {paymentOptions.map((paymentMethod, index) => (
            <Grid item xs={12} sm={6} md={3} key={paymentMethod.id || index}>
              <PaymentMethodCard
                paymentMethod={paymentMethod}
                onToggleActive={onModifierMoyenPaiement}
                onSubmitSettings={onModifierMoyenPaiement}
                onDialogClose={loadOptionsPaiement}
                siteConsulte={siteConsulte}
                getUtilisateurSite={getUtilisateurSite}
              />
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 5 }}>
          {/* Bloc des paramètres de devise */}
          <Typography
            sx={{
              color: "black",
              fontSize: { xs: "26px", sm: "40px" },
              fontFamily: "Space Grotesk, sans serif",
              fontWeight: "1000",
              letterSpacing: { xs: "-2px", sm: "-5px", md: "-3px", lg: "-3px" },
              ml: "2%",
              mt: { xs: "5%", sm: 0 },
              mb: 3,
            }}
          >
            {t("parametre.currency_settings")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              gap: 2,
              width: isMobile ? "90%" : "20%",
              ml: "2%",
            }}
          >
            <FormControl fullWidth>
              <InputLabel>{t("parametre.select_currency")}</InputLabel>
              <Select
                value={currency}
                onChange={(event) => setCurrency(event.target.value)}
                sx={{
                  display: "flex",
                  mb: "3%",
                  justifyContent: "center",
                  width: "100%",
                  bgcolor: "#ECEDF5",
                  fontFamily: "Inter Variable",
                  fontWeight: "500",
                  letterSpacing: "-0.55px",
                  fontSize: {
                    lg: "14px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  color: "black",
                  borderRadius: "10px",
                  "& .MuiSelect-select": {
                    borderRadius: "20px",
                    textAlign: "center",
                    padding: "10px 0",
                    border: "none",
                  },
                  "&:before, &:after": {
                    border: "none",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "black",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiPaper-root": {
                    bgcolor: "#ECEDF5",
                    borderRadius: "10px",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      mt: "-0.5%",
                      bgcolor: "#ECEDF5",
                      boxShadow: "none",
                      borderBottomRightRadius: "10px",
                      borderBottomLeftRadius: "10px",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      "& .MuiMenuItem-root": {
                        fontFamily: "Inter Variable",
                        fontWeight: "500",
                        letterSpacing: "-0.5px",
                        fontSize: {
                          lg: "14px",
                          md: "14px",
                          sm: "12px",
                          xs: "12px",
                        },
                        justifyContent: "center",
                        width: "100%",
                        textAlign: "center",
                        "&:hover": {
                          background: "#0033c1",
                          fontWeight: "500",
                          color: "white",
                        },
                        "&.Mui-selected": {
                          bgcolor: "#ECEDF5",
                          "&:hover": {
                            bgcolor: "#D0D0D0",
                            color: "black",
                          },
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="EUR">EUR</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="GBP">GBP</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>{t("parametre.select_currency_position")}</InputLabel>
              <Select
                value={currencyPosition}
                onChange={(event) => setCurrencyPosition(event.target.value)}
                sx={{
                  display: "flex",
                  mb: "3%",
                  justifyContent: "center",
                  width: "100%",
                  bgcolor: "#ECEDF5",
                  fontFamily: "Inter Variable",
                  fontWeight: "500",
                  letterSpacing: "-0.55px",
                  fontSize: {
                    lg: "14px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  color: "black",
                  borderRadius: "10px",
                  "& .MuiSelect-select": {
                    borderRadius: "20px",
                    textAlign: "center",
                    padding: "10px 0",
                    border: "none",
                  },
                  "&:before, &:after": {
                    border: "none",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "black",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiPaper-root": {
                    bgcolor: "#ECEDF5",
                    borderRadius: "10px",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      mt: "-0.5%",
                      bgcolor: "#ECEDF5",
                      boxShadow: "none",
                      borderBottomRightRadius: "10px",
                      borderBottomLeftRadius: "10px",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      "& .MuiMenuItem-root": {
                        fontFamily: "Inter Variable",
                        fontWeight: "500",
                        letterSpacing: "-0.5px",
                        fontSize: {
                          lg: "14px",
                          md: "14px",
                          sm: "12px",
                          xs: "12px",
                        },
                        justifyContent: "center",
                        width: "100%",
                        textAlign: "center",
                        "&:hover": {
                          background: "#0033c1",
                          fontWeight: "500",
                          color: "white",
                        },
                        "&.Mui-selected": {
                          bgcolor: "#ECEDF5",
                          "&:hover": {
                            bgcolor: "#D0D0D0",
                            color: "black",
                          },
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="left">{t("parametre.position_left")}</MenuItem>
                <MenuItem value="right">
                  {t("parametre.position_right")}
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>
                {t("parametre.select_thousand_separator")}
              </InputLabel>
              <Select
                value={thousandSeparator}
                onChange={(event) => setThousandSeparator(event.target.value)}
                sx={{
                  display: "flex",
                  mb: "3%",
                  justifyContent: "center",
                  width: "100%",
                  bgcolor: "#ECEDF5",
                  fontFamily: "Inter Variable",
                  fontWeight: "500",
                  letterSpacing: "-0.55px",
                  fontSize: {
                    lg: "14px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  color: "black",
                  borderRadius: "10px",
                  "& .MuiSelect-select": {
                    borderRadius: "20px",
                    textAlign: "center",
                    padding: "10px 0",
                    border: "none",
                  },
                  "&:before, &:after": {
                    border: "none",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "black",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiPaper-root": {
                    bgcolor: "#ECEDF5",
                    borderRadius: "10px",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      mt: "-0.5%",
                      bgcolor: "#ECEDF5",
                      boxShadow: "none",
                      borderBottomRightRadius: "10px",
                      borderBottomLeftRadius: "10px",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      "& .MuiMenuItem-root": {
                        fontFamily: "Inter Variable",
                        fontWeight: "500",
                        letterSpacing: "-0.5px",
                        fontSize: {
                          lg: "14px",
                          md: "14px",
                          sm: "12px",
                          xs: "12px",
                        },
                        justifyContent: "center",
                        width: "100%",
                        textAlign: "center",
                        "&:hover": {
                          background: "#0033c1",
                          fontWeight: "500",
                          color: "white",
                        },
                        "&.Mui-selected": {
                          bgcolor: "#ECEDF5",
                          "&:hover": {
                            bgcolor: "#D0D0D0",
                            color: "black",
                          },
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value=",">,</MenuItem>
                <MenuItem value=".">.</MenuItem>
                <MenuItem value=" ">{t("parametre.space")}</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              mt: { xs: 0, sm: 5 },
            }}
          >
            <LoadingButton
              onClick={handleSaveCurrencySettings}
              endIcon={
                showSuccessCurrency ? <CheckIcon /> : <ArrowForwardIosIcon />
              }
              loading={loadingCurrency}
              loadingPosition="end"
              variant="contained"
              color={showSuccessCurrency ? "success" : "primary"}
              sx={{
                background: "#0042ff",
                color: "white",
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.25px",
                textTransform: "none",
                ml: "2%",
                mb: "2%",
                borderRadius: "10px",
                border: "1px solid #276CFA",
                outline: "1px solid #0646C6",
                "&:hover": {
                  backgroundColor: "#1459e2",
                  boxShadow: "none",
                },
              }}
            >
              <span>{t("parametre.save")}</span>
            </LoadingButton>
          </Box>

          {/* Bloc des paramètres de langue */}
          <Typography
            sx={{
              color: "black",
              fontSize: { xs: "26px", sm: "40px" },
              fontFamily: "Space Grotesk, sans serif",
              fontWeight: "1000",
              letterSpacing: { xs: "-2px", sm: "-5px", md: "-3px", lg: "-3px" },
              ml: "2%",
              mt: { xs: "5%", sm: 0 },
              mb: 3,
            }}
          >
            {t("parametre.language_settings")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              gap: 2,
              width: isMobile ? "90%" : "20%",
              ml: "2%",
            }}
          >
            <FormControl fullWidth>
              <InputLabel>{t("parametre.select_language")}</InputLabel>
              <Select
                value={siteLangue}
                onChange={(event) => setSiteLangue(event.target.value)}
                sx={{
                  display: "flex",
                  mb: "3%",
                  justifyContent: "center",
                  width: "100%",
                  bgcolor: "#ECEDF5",
                  fontFamily: "Inter Variable",
                  fontWeight: "500",
                  letterSpacing: "-0.55px",
                  fontSize: {
                    lg: "14px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  color: "black",
                  borderRadius: "10px",
                  "& .MuiSelect-select": {
                    borderRadius: "20px",
                    textAlign: "center",
                    padding: "10px 0",
                    border: "none",
                  },
                  "&:before, &:after": {
                    border: "none",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "black",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiPaper-root": {
                    bgcolor: "#ECEDF5",
                    borderRadius: "10px",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      mt: "-0.5%",
                      bgcolor: "#ECEDF5",
                      boxShadow: "none",
                      borderBottomRightRadius: "10px",
                      borderBottomLeftRadius: "10px",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      "& .MuiMenuItem-root": {
                        fontFamily: "Inter Variable",
                        fontWeight: "500",
                        letterSpacing: "-0.5px",
                        fontSize: {
                          lg: "14px",
                          md: "14px",
                          sm: "12px",
                          xs: "12px",
                        },
                        justifyContent: "center",
                        width: "100%",
                        textAlign: "center",
                        "&:hover": {
                          background: "#0033c1",
                          fontWeight: "500",
                          color: "white",
                        },
                        "&.Mui-selected": {
                          bgcolor: "#ECEDF5",
                          "&:hover": {
                            bgcolor: "#D0D0D0",
                            color: "black",
                          },
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="de_DE">Allemand</MenuItem>
                <MenuItem value="ar">Arabe</MenuItem>
                <MenuItem value="en_AU">Anglais (Australie)</MenuItem>
                <MenuItem value="en_GB">Anglais (Royaume-Uni)</MenuItem>
                <MenuItem value="en_US">Anglais (États-Unis)</MenuItem>
                <MenuItem value="en_CA">Anglais (Canada)</MenuItem>
                <MenuItem value="bg_BG">Bulgare</MenuItem>
                <MenuItem value="bn_BD">Bengali</MenuItem>
                <MenuItem value="ca">Catalan</MenuItem>
                <MenuItem value="zh_CN">Chinois simplifié</MenuItem>
                <MenuItem value="zh_TW">Chinois traditionnel</MenuItem>
                <MenuItem value="ko_KR">Coréen</MenuItem>
                <MenuItem value="da_DK">Danois</MenuItem>
                <MenuItem value="es_ES">Espagnol</MenuItem>
                <MenuItem value="et">Estonien</MenuItem>
                <MenuItem value="fi">Finnois</MenuItem>
                <MenuItem value="fr_FR">Français</MenuItem>
                <MenuItem value="el">Grec</MenuItem>
                <MenuItem value="he_IL">Hébreu</MenuItem>
                <MenuItem value="hi_IN">Hindi</MenuItem>
                <MenuItem value="hu_HU">Hongrois</MenuItem>
                <MenuItem value="id_ID">Indonésien</MenuItem>
                <MenuItem value="it_IT">Italien</MenuItem>
                <MenuItem value="ja">Japonais</MenuItem>
                <MenuItem value="lv">Letton</MenuItem>
                <MenuItem value="lt_LT">Lituanien</MenuItem>
                <MenuItem value="mk_MK">Macédonien</MenuItem>
                <MenuItem value="ms_MY">Malais</MenuItem>
                <MenuItem value="nb_NO">Norvégien Bokmål</MenuItem>
                <MenuItem value="nn_NO">Norvégien Nynorsk</MenuItem>
                <MenuItem value="fa_IR">Persan</MenuItem>
                <MenuItem value="pl_PL">Polonais</MenuItem>
                <MenuItem value="pt_BR">Portugais (Brésil)</MenuItem>
                <MenuItem value="pt_PT">Portugais (Portugal)</MenuItem>
                <MenuItem value="ro_RO">Roumain</MenuItem>
                <MenuItem value="ru_RU">Russe</MenuItem>
                <MenuItem value="sr_RS">Serbe</MenuItem>
                <MenuItem value="sk_SK">Slovaque</MenuItem>
                <MenuItem value="sl_SI">Slovène</MenuItem>
                <MenuItem value="sv_SE">Suédois</MenuItem>
                <MenuItem value="cs_CZ">Tchèque</MenuItem>
                <MenuItem value="th">Thaï</MenuItem>
                <MenuItem value="tr_TR">Turc</MenuItem>
                <MenuItem value="uk">Ukrainien</MenuItem>
                <MenuItem value="vi">Vietnamien</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              mt: { xs: 0, sm: 5 },
            }}
          >
            <LoadingButton
              onClick={handleSaveLangue}
              endIcon={
                showSuccessLangue ? <CheckIcon /> : <ArrowForwardIosIcon />
              }
              loading={loadingLangue}
              loadingPosition="end"
              variant="contained"
              color={showSuccessLangue ? "success" : "primary"}
              sx={{
                background: "#0042ff",
                color: "white",
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.25px",
                textTransform: "none",
                ml: "2%",
                mb: "2%",
                borderRadius: "10px",
                border: "1px solid #276CFA",
                outline: "1px solid #0646C6",
                "&:hover": {
                  backgroundColor: "#1459e2",
                  boxShadow: "none",
                },
              }}
            >
              <span>{t("parametre.save")}</span>
            </LoadingButton>
          </Box>
        </Box>
      </Paper>
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
      <Loader loading={isLoading} />
    </div>
  );
};

export default GestionPaiement;
