import React, { useMemo } from "react";
import {
  Box,
  Card,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import WarningIcon from "@mui/icons-material/Warning";
import HelpIconButton from "../../../shared/tooltip/HelpIconButton";
import { isValidUrlDomaine } from "../../../shared/html/HtmlUtils";
import { useTranslation } from "react-i18next";

const SiteDetails = ({
  selectedSite,
  newNomDomaine,
  handleUrlChange,
  handleSubmit,
  handleValidateUrl,
  loadingAjouterUrl,
  loadingChangementUrl,
  showSuccessAjouterNomDomaine,
  showSuccessValiderNomDomaine,
}) => {
  const { t } = useTranslation();

  const containsInvalidDomain =
    newNomDomaine.includes("one-site.fr") ||
    newNomDomaine.includes("onesite.fr");

  const isDisabled = useMemo(() => {
    if (containsInvalidDomain || !isValidUrlDomaine(newNomDomaine)) {
      return true;
    }

    if (selectedSite.urlPropose) {
      return newNomDomaine === selectedSite.urlPropose;
    }

    return newNomDomaine === selectedSite.url;
  }, [
    newNomDomaine,
    selectedSite.url,
    selectedSite.urlPropose,
    containsInvalidDomain,
  ]);

  const isConfirmationDisabled = useMemo(() => {
    return newNomDomaine !== selectedSite.urlPropose;
  }, [newNomDomaine, selectedSite.urlPropose]);

  const buttonLabel = selectedSite.urlPropose
    ? t("domaine.modifierNomDomaine")
    : t("domaine.ajouterNomDomaine");

  const showValidateButton =
    selectedSite.urlPropose && selectedSite.url !== selectedSite.urlPropose;

  return (
    <Box
      sx={{ mt: 3, display: "flex", justifyContent: "center" }}
      id="detailsCard"
    >
      <Card
        sx={{
          m: 2,
          p: 3,
          width: "60%",
          height: "auto",
          borderRadius: "20px",
          border: "1px solid #c6c7ce",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ px: "20px" }}>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Space Grotesk, sans serif",
                  letterSpacing: "-1px",
                  fontWeight: "800",
                  mb: 3,
                  textAlign: "center",
                }}
              >
                {selectedSite.nom}
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Space Grotesk, sans serif",
                  letterSpacing: "-1px",
                  fontWeight: "800",
                  mt: 3,
                  mb: 1,
                }}
              >
                {t("domaine.enregistrementsDNS")}:
              </Typography>
              {selectedSite.dnsRecords && selectedSite.dnsRecords.length > 0 ? (
                selectedSite.dnsRecords.map((record, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Typography sx={{ fontFamily: "Inter Variable", mb: 1 }}>
                      <strong>{t("domaine.valeur")}:</strong> {record.domain}
                    </Typography>
                    <Typography sx={{ fontFamily: "Inter Variable", mb: 1 }}>
                      <strong>{t("domaine.type")}:</strong> {record.recordType}
                    </Typography>
                    <Typography sx={{ fontFamily: "Inter Variable", mb: 1 }}>
                      <strong>{t("domaine.ttl")}:</strong> {record.ttl}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography sx={{ fontFamily: "Inter Variable", mb: 1 }}>
                  {t("domaine.aucunEnregistrementDNS")}
                </Typography>
              )}

              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Space Grotesk, sans serif",
                  letterSpacing: "-1px",
                  fontWeight: "800",
                  mt: 3,
                  mb: 1,
                }}
              >
                {t("domaine.serveursDeNoms")}:
              </Typography>
              {selectedSite.nameServersDns &&
              selectedSite.nameServersDns.length > 0 ? (
                selectedSite.nameServersDns.map((ns, index) => (
                  <Typography
                    key={index}
                    sx={{ fontFamily: "Inter Variable", mb: 1 }}
                  >
                    {ns}
                  </Typography>
                ))
              ) : (
                <Typography sx={{ fontFamily: "Inter Variable", mb: 1 }}>
                  {t("domaine.aucunServeurDeNoms")}{" "}
                  {t("domaine.serveursDisponiblesApresAjout")}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                px: "20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter Variable",
                  textAlign: "center",
                  mb: 3,
                }}
              >
                {t("domaine.changerNomDomaineDescription")}
              </Typography>
              <Box sx={{ fontFamily: "Inter Variable", textAlign: "left" }}>
                <ol>
                  <li>{t("domaine.trouverSectionGestionDNS")}</li>
                  <li>{t("domaine.ajouterModifierEnregistrementsDNS")}</li>
                </ol>
              </Box>
              <TextField
                placeholder={t("domaine.nomDeDomaine")}
                variant="outlined"
                fullWidth
                name="url"
                value={newNomDomaine}
                onChange={handleUrlChange}
                InputProps={{
                  endAdornment: (
                    <>
                      {selectedSite.urlPropose &&
                        selectedSite.urlPropose !== selectedSite.url && (
                          <Tooltip
                            title={t("domaine.warningUrlPropose")}
                            PopperProps={{
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  fontSize: "14px",
                                  fontFamily: "Inter Variable",
                                },
                              },
                            }}
                          >
                            <InputAdornment position="end" sx={{ pr: 2 }}>
                              <WarningIcon color="warning" />
                            </InputAdornment>
                          </Tooltip>
                        )}
                    </>
                  ),
                  sx: {
                    mb: "5%",
                    width: "100%",
                    height: "50px",
                    borderRadius: "12px",
                    backgroundColor: "white",
                    border: "1px solid #E3E3E3",
                    fontWeight: "500",
                    fontFamily: "Inter Variable",
                    fontSize: "14px",
                    padding: "0%",
                    letterSpacing: "-0.25px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    input: {
                      color: "text.primary",
                    },
                    "&::placeholder": {
                      color: "#000000",
                      opacity: 0.8,
                    },
                  },
                }}
              />
              <LoadingButton
                onClick={handleSubmit}
                endIcon={
                  showSuccessAjouterNomDomaine ? (
                    <CheckIcon />
                  ) : (
                    <ArrowForwardIosIcon />
                  )
                }
                loading={loadingAjouterUrl}
                loadingPosition="end"
                variant="contained"
                color={showSuccessAjouterNomDomaine ? "success" : "primary"}
                disabled={isDisabled}
                sx={{
                  background: isDisabled ? "#d3d3d3" : "#0042ff",
                  color: "white",
                  fontSize: {
                    lg: "14px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  width: "100%",
                  maxWidth: "300px",
                  fontFamily: "Inter Variable",
                  fontWeight: "500",
                  letterSpacing: "-0.25px",
                  textTransform: "none",
                  borderRadius: "10px",
                  border: "1px solid #276CFA",
                  outline: "1px solid #0646C6",
                  "&:hover": {
                    backgroundColor: isDisabled ? "#d3d3d3" : "#1459e2",
                    boxShadow: "none",
                  },
                }}
              >
                <span>{buttonLabel}</span>
              </LoadingButton>

              {showValidateButton && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 2,
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      maxWidth: "300px",
                    }}
                  >
                    <LoadingButton
                      onClick={handleValidateUrl}
                      endIcon={
                        showSuccessValiderNomDomaine ? (
                          <CheckIcon />
                        ) : (
                          <ArrowForwardIosIcon />
                        )
                      }
                      loading={loadingChangementUrl}
                      loadingPosition="end"
                      variant="contained"
                      color={
                        showSuccessValiderNomDomaine ? "success" : "primary"
                      }
                      disabled={isConfirmationDisabled}
                      sx={{
                        background: isConfirmationDisabled
                          ? "#d3d3d3"
                          : "#28a745",
                        color: "white",
                        fontSize: {
                          lg: "14px",
                          md: "14px",
                          sm: "12px",
                          xs: "12px",
                        },
                        width: "100%",
                        fontFamily: "Inter Variable",
                        fontWeight: "500",
                        letterSpacing: "-0.25px",
                        textTransform: "none",
                        borderRadius: "10px",
                        border: "1px solid #28a745",
                        outline: "1px solid #28a745",
                        "&:hover": {
                          backgroundColor: isConfirmationDisabled
                            ? "#d3d3d3"
                            : "#218838",
                          boxShadow: "none",
                        },
                      }}
                    >
                      <span>{t("domaine.validerNomDomaine")}</span>
                    </LoadingButton>

                    <Box
                      sx={{
                        position: "absolute",
                        right: "-40px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <HelpIconButton
                        helpText={t("domaine.help_valider_url")}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default SiteDetails;
