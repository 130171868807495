import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../shared/loader/Loader";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import ToastError from "../../shared/toast/ToastError";

const ConfirmationInstallation = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const setupIntentId = searchParams.get("setup_intent");
  const priceId = searchParams.get("priceId");
  const promoCode = searchParams.get("promoCode");

  const isBoutique = searchParams.get("isBoutique") === "true";
  const templateId = searchParams.get("templateId");

  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const customerId = profil?.customerId;
  const nomSite = sessionStorage.getItem("nomSite");

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);

  const navigate = useNavigate();

  const handleError = (error) => {
    setLoading(false);
    setError(error);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const redirectionFelicitationInstallation = useCallback(() => {
    sessionStorage.setItem("fromFelicitationInstallation", "true");
    navigate("/bienvenue");
  }, [navigate]);

  const ajouterAbonnementUtilisateur = useCallback(async () => {
    try {
      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/utilisateur/abonnements`,
        {
          customerId,
          priceId,
          setupIntentId,
          promoCode,
        }
      );

      if (response.status === 201) {
        const { subscriptionId } = response.data;
        setSubscriptionId(subscriptionId);
      } else {
        throw new Error("Erreur lors de l'ajout de l'abonnement.");
      }
    } catch (error) {
      handleError("Erreur lors de l'ajout de l'abonnement.");
      throw error;
    }
  }, [customerId, priceId, setupIntentId, promoCode]);

  const installationSiteClient = useCallback(async () => {
    setLoading(true);

    try {
      const response = await axios.post(`${API_SERVER_ENDPOINT}/create-site`, {
        siteName: nomSite,
        idTemplate: templateId,
        customerId,
        subscriptionId,
        isBoutique,
      });

      if (response.status === 201) {
        const { operationId } = response.data;
        sessionStorage.setItem("operationIdInstallation", operationId);

        redirectionFelicitationInstallation();
      } else {
        throw new Error("Erreur lors de l'installation du site.");
      }
    } catch (error) {
      handleError(error.message);
    } finally {
      setLoading(false);
      sessionStorage.removeItem("authorizedForPayment");
      sessionStorage.removeItem("nomSite");
      sessionStorage.removeItem("boutique");
      sessionStorage.removeItem("urlPreview");
      sessionStorage.removeItem("postId");
      sessionStorage.removeItem("templateId");
      sessionStorage.removeItem("accessToPreview");
    }
  }, [
    nomSite,
    templateId,
    customerId,
    subscriptionId,
    isBoutique,
    redirectionFelicitationInstallation,
  ]);

  useEffect(() => {
    const createAbonnement = async () => {
      if (customerId && setupIntentId && priceId) {
        try {
          await ajouterAbonnementUtilisateur();
        } catch (error) {
          handleError(error.message);
        }
      }
    };

    createAbonnement();
  }, [ajouterAbonnementUtilisateur, customerId, setupIntentId, priceId]);

  useEffect(() => {
    if (subscriptionId) {
      installationSiteClient();
    }
  }, [subscriptionId, installationSiteClient]);

  return (
    <div>
      {loading && <Loader loading={loading} />}
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </div>
  );
};

export default ConfirmationInstallation;
