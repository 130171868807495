import React from "react";
import { Box, Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";

function BoutonSuivant({ isNextButtonDisabled, handleNextClick, label, sx }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        ...sx,
      }}
    >
      <Button
        variant="contained"
        disabled={isNextButtonDisabled()}
        onClick={handleNextClick}
        sx={{
          fontFamily: "Inter Variable",
          borderRadius: "10px",
          background: "#1d35e5",
          color: "#ffffff",
          fontWeight: 650,
          fontSize: "1rem",
          textTransform: "none",
          border: "none",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            bgcolor: "#00258c",
          },
          "&.Mui-disabled": {
            opacity: 0.5,
            cursor: "not-allowed",
          },
          "@media (max-width: 600px)": {
            fontSize: "0.8rem",
            fontWeight: 600,
            px: "1rem",
            py: "0.5rem",
            border: "none",
            boxShadow: "none",
          },
        }}
        endIcon={<ArrowForwardIosIcon />}
      >
        {t(label)}
      </Button>
    </Box>
  );
}

export default BoutonSuivant;
