import React from "react";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function EditeurVisuel() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate("/informations");
  };

  return (
    <Box
      id="visualbuilder"
      sx={{
        backgroundColor: "#0f011e",
        color: "#fff",
        padding: "40px 20px",
        textAlign: "center",
        minHeight: "800px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontFamily: "Inter Variable",
          fontWeight: "400",
          marginBottom: "10px",
          letterSpacing: "-0.5px",
          textTransform: "uppercase",
          fontSize: {
            xl: "18px",
            lg: "16px",
            md: "14px",
            sm: "14px",
            xs: "14px",
          },
          color: "#6000ce",
        }}
      >
        {t("editeurVisuel.editeurVisuelComplet")}
      </Typography>
      <Typography
        variant="h3"
        sx={{
          width: { xs: "100%", sm: "85%", md: "70%", lg: "50%", xl: "50%" },
          fontSize: {
            xl: "50px",
            lg: "40px",
            md: "40px",
            sm: "40px",
            xs: "30px",
          },
          fontFamily: "Space Grotesk, sans-serif",
          fontWeight: "600",
          letterSpacing: {
            xl: "-3px",
            lg: "-2.5px",
            md: "-2.5px",
            sm: "-2px",
            xs: "-1.75px",
          },
          marginBottom: "20px",
        }}
      >
        {t("editeurVisuel.editeurVisuelTitle")}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontFamily: "Inter Variable",
          fontWeight: "400",
          marginBottom: "30px",
          maxWidth: "600px",
        }}
      >
        {t("editeurVisuel.editeurVisuelDesc")}
      </Typography>
      <Button
        onClick={handleClick}
        variant="contained"
        sx={{
          backgroundColor: "#124ae5",
          color: "white",
          fontSize: {
            xs: "16px",
            sm: "18px",
            md: "18px",
            lg: "20px",
            xl: "20px",
          },
          fontFamily: "Inter Variable",
          letterSpacing: "-0.5px",
          fontWeight: "700",
          textTransform: "none",
          boxShadow: "none",
          borderRadius: "10px",
          padding: "10px 20px",
          "&:hover": {
            backgroundColor: "#03247f",
            boxShadow: "none",
          },
          marginBottom: "3%",
        }}
      >
        {t("editeurVisuel.startFreeTrial")}
      </Button>
      <Box
        component="img"
        src={isMobile ? "/images/vb_mobile.png" : "/images/vb_desktop.png"}
        alt="Éditeur visuel"
        sx={{
          width: "100%",
          maxWidth: { xs: "100%", md: "100%" },
          marginTop: { md: "-30%", xs: "-80%" },
          display: "block",
          verticalAlign: "bottom",
          height: "auto",
          marginBottom: {
            xs: "-15%",
            sm: "-5%",
            md: "-50%",
            lg: "-3%",
            xl: "-0%",
          },
        }}
      />
    </Box>
  );
}

export default EditeurVisuel;
