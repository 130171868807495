function convertHtmlToText(htmlString) {
  return htmlString.replace(/<[^>]+>/g, "");
}

function addHttpsPrefix(urlString) {
  if (!/^https?:\/\//i.test(urlString)) {
    return "https://" + urlString;
  }
  return urlString;
}

const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

const isValidUrlDomaine = (url) => {
  const regex = /^[a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z]{2,}$/;
  return regex.test(url);
};

export { addHttpsPrefix, convertHtmlToText, isValidUrl, isValidUrlDomaine };
