import { Typography } from "@mui/material";
import "./RadioGroup.css";

const RadioGroup = ({
  titre,
  description,
  prix,
  priceId,
  selectedPriceId,
  onRadioChange,
}) => {
  const prixEnEuros = (prix / 100).toFixed(2);

  const handleChange = () => {
    onRadioChange(priceId, prix);
  };

  return (
    <div className="radio-bouton" onClick={handleChange}>
      <input
        className="input-radio"
        type="radio"
        checked={selectedPriceId === priceId}
        onChange={handleChange}
      />
      <div className="titre-radio">
        {titre}
        <Typography
          sx={{
            color: "gray",
            fontSize: "12px",
            fontFamily: "Inter Variable",
            fontWeight: "400",
            marginLeft: "10px",
          }}
        >
          {description}
        </Typography>
      </div>
      <div className="prix-radio">{prixEnEuros}€</div>
    </div>
  );
};

export default RadioGroup;
