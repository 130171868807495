import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import App from "./App";
import Preview from "./views/preview/Preview";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PaiementAbonnement from "./views/paiement/PaiementAbonnement";
import CreationCompte from "./views/compte/CreationCompte";
import Informations from "./views/informations/Informations";
import ConnexionCompte from "./views/compte/ConnexionCompte";
import GestionCompte from "./views/compte/GestionCompte";
import AuthProvider from "./components/authentification/Authentification";
import FelicitationInstallation from "./views/paiement/FelicitationInstallation";
import {
  ProtectedPaymentSuccessRoute,
  ProtectedPaymentRoute,
  ProtectedPreviewRoute,
  ProtectedFelicitationInstallationRoute,
} from "./routes/RouteTunnelVente";
import {
  GuestOnlyRoute,
  RouteRequireAuth,
} from "./routes/RouteAuthentification";
import DemandePasswordReset from "./views/compte/DemandePasswordReset";
import ConfirmationPasswordReset from "./views/compte/ConfirmationPasswordReset";
import ConfirmationMail from "./views/compte/ConfirmationMail";

import "@fontsource-variable/lexend";
import "@fontsource-variable/inter";
import ReouvertureAbonnement from "./views/paiement/ReouvertureAbonnement";
import ConfirmationReouverture from "./views/paiement/ConfirmationReouverture";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./i18n";
import ConfirmationInstallation from "./views/paiement/ConfirmationInstallation";
import ConditionUtilisation from "./views/condition/ConditionUtilisation";
import UsePageTracking from "./hooks/usePageTracking";
import sendToAnalytics from "./analytics/analytics";
import ScrollToTop from "./shared/scroll/ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));

const isMaintenance = process.env.REACT_APP_MAINTENANCE === "true";

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE}>
    <AuthProvider>
      <BrowserRouter>
        <UsePageTracking />
        <ScrollToTop />
        <Routes>
          {isMaintenance ? (
            <>
              <Route path="/" element={<App />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route path="/" element={<App />} />
              <Route path="/informations" element={<Informations />} />
              <Route
                path="/informations/:abonnementId"
                element={<Informations />}
              />
              <Route
                path="/informations/:abonnementId/:siteNom"
                element={<Informations />}
              />
              <Route
                path="/preview"
                element={
                  <ProtectedPreviewRoute>
                    <Preview />
                  </ProtectedPreviewRoute>
                }
              />
              <Route
                path="/preview/:abonnementId"
                element={
                  <ProtectedPreviewRoute>
                    <Preview />
                  </ProtectedPreviewRoute>
                }
              />
              <Route
                path="/preview/:abonnementId/:siteNom"
                element={
                  <ProtectedPreviewRoute>
                    <Preview />
                  </ProtectedPreviewRoute>
                }
              />
              <Route
                path="/paiement"
                element={
                  <ProtectedPaymentRoute>
                    <PaiementAbonnement />
                  </ProtectedPaymentRoute>
                }
              />
              <Route
                path="/reouverture-abonnement"
                element={
                  <ProtectedPaymentRoute>
                    <ReouvertureAbonnement />
                  </ProtectedPaymentRoute>
                }
              />
              <Route
                path="/confirmation-installation"
                element={
                  <ProtectedPaymentSuccessRoute>
                    <ConfirmationInstallation />
                  </ProtectedPaymentSuccessRoute>
                }
              />
              <Route
                path="/confirmation-reouverture"
                element={
                  <ProtectedPaymentSuccessRoute>
                    <ConfirmationReouverture />
                  </ProtectedPaymentSuccessRoute>
                }
              />
              <Route
                path="/bienvenue"
                element={
                  <ProtectedFelicitationInstallationRoute>
                    <FelicitationInstallation />
                  </ProtectedFelicitationInstallationRoute>
                }
              />
              <Route
                path="/creation-compte"
                element={
                  <GuestOnlyRoute>
                    <CreationCompte />
                  </GuestOnlyRoute>
                }
              />
              <Route
                path="/connexion-compte"
                element={
                  <GuestOnlyRoute>
                    <ConnexionCompte />
                  </GuestOnlyRoute>
                }
              />
              <Route
                path="/password-reset"
                element={<DemandePasswordReset />}
              />
              <Route
                path="/reinitialiser-mot-de-passe/:token"
                element={<ConfirmationPasswordReset />}
              />
              <Route
                path="/confirmer-email/:token"
                element={<ConfirmationMail />}
              />
              <Route
                path="/gestion-compte"
                element={
                  <RouteRequireAuth>
                    <GestionCompte />
                  </RouteRequireAuth>
                }
              />
              <Route
                path="/conditions-utilisation"
                element={<ConditionUtilisation />}
              />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  </GoogleOAuthProvider>
);

reportWebVitals(sendToAnalytics);
