import React from "react";
import { Card, Box, Typography, useTheme } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";

const ActiveUsersChart = ({ data, dateRange }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Card
      raised
      sx={{
        m: 2,
        p: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mb: "10%",
        borderRadius: "10px",
        boxShadow: "none",
        border: "2px solid #c6c7ce",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
            ml: 1,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Space Grotesk, sans serif",
              fontWeight: "1000",
              letterSpacing: { xs: "-1px", sm: "-1px", md: "-2px", lg: "-3px" },
              fontSize: { xs: "25px", sm: "25px", md: "30px", lg: "40px" },
            }}
          >
            {t("activeUsersChart.title")}
          </Typography>
        </Box>
      </Box>
      <ResponsiveContainer height={250} width="100%">
        <BarChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid vertical={false} stroke={theme.palette.grey[300]} />
          <XAxis
            dataKey="time"
            tick={{
              fontSize: 14,
              fontFamily: "Space Grotesk, sans-serif",
              fill: theme.palette.text.primary,
            }}
            axisLine={{ stroke: "#0042FF" }}
            tickLine={false}
            interval="preserveEnd"
            padding={{ right: 20 }}
          />
          <YAxis
            tickFormatter={(tick) =>
              tick.toLocaleString("fr-FR", { maximumFractionDigits: 0 })
            }
            tick={{
              fontSize: 14,
              fontFamily: "Space Grotesk, sans-serif",
              fill: theme.palette.text.primary,
            }}
            axisLine={{ stroke: "transparent" }}
            tickLine={false}
            domain={[0, "dataMax + 10"]}
          />
          <Tooltip
            labelFormatter={(label) => label}
            contentStyle={{
              backgroundColor: theme.palette.background.default,
              borderRadius: "8px",
              fontFamily: "Space Grotesk, sans-serif",
            }}
          />
          <Bar dataKey="activeUsers" fill="#0042FF" barSize={30} />
        </BarChart>
      </ResponsiveContainer>
      <Box sx={{ marginTop: { xs: "2rem", md: "2rem" } }}>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          sx={{ mb: 1, fontFamily: "Inter Variable", textAlign: "center" }}
        >
          {dateRange}
        </Typography>
      </Box>
    </Card>
  );
};

export default ActiveUsersChart;
