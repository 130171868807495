import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Box, Paper, Typography, Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MenuConnexion from "../../shared/menuConnexion/MenuConnexion";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../../shared/loader/LoadingScreen";

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          style: {
            fontFamily: "Inter Variable",
            fontWeight: "400",
            fontSize: "15px",
          },
        },
      },
    },
  },
});

function ConfirmationMail() {
  const { t } = useTranslation();
  const { token } = useParams();
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [loadingBackground, setLoadingBackground] = useState(true);
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/");
  };

  const navigateToAccountManagement = () => {
    navigate("/gestion-compte");
  };

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await axios.get(
          `${API_SERVER_ENDPOINT}/confirmer-email/${token}`
        );
        setMessage(response.data.message);
      } catch (error) {
        setError(true);
        setMessage(error.response?.data?.message || error.message);
      }
    };

    if (token) {
      confirmEmail();
    }
  }, [token, t]);

  const handleBackgroundLoad = () => {
    setLoadingBackground(false);
  };

  return (
    <ThemeProvider theme={theme}>
      {loadingBackground && <LoadingScreen />}
      <div style={{ display: loadingBackground ? "none" : "block" }}>
        <img
          src={process.env.PUBLIC_URL + "/background/one-site-bg.png"}
          alt="Background"
          onLoad={handleBackgroundLoad}
          style={{ display: "none" }}
        />
        <Paper
          sx={{
            width: "100%",
            height: "100vh",
            overflowX: "auto",
            boxShadow: "none",
            backgroundImage: loadingBackground
              ? "none"
              : `url(${
                  process.env.PUBLIC_URL + "/background/one-site-bg.png"
                })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MenuConnexion />
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "10%",
            }}
          >
            <Paper
              sx={{
                width: { xs: "90%", sm: "500px", md: "600px" },
                height: { xs: "auto", sm: "400px", md: "450px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "2rem",
                borderRadius: "20px",
                bgcolor: "white",
                boxShadow: "0 0 100px 0 rgba(0, 150, 255, 0.2)",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "1.5rem", sm: "2rem" },
                  mb: { xs: "1rem", sm: "1.5rem" },
                  fontFamily: "Space Grotesk, sans-serif",
                  letterSpacing: "-1px",
                }}
              >
                {message}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem" },
                  mb: "1.5rem",
                  fontFamily: "Inter Variable",
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  letterSpacing: "-0.5px",
                }}
              >
                {!error
                  ? t("confirmationMail.successMessage")
                  : t("confirmationMail.errorMessage")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <Button
                  sx={{
                    width: { xs: "100%", sm: "45%" },
                    bgcolor: "#F3F4F6",
                    color: "#303030",
                    fontSize: "14px",
                    fontFamily: "Inter Variable",
                    fontWeight: "500",
                    letterSpacing: "-0.25px",
                    textTransform: "none",
                    boxShadow: "none",
                    borderRadius: "10px",
                    border: "1px solid #ECEFF2",
                    outline: "1px solid #303030",
                    "&:hover": {
                      bgcolor: "#e2e2e2",
                      color: "#303030",
                      boxShadow: "none",
                    },
                  }}
                  onClick={navigateToHome}
                >
                  {t("confirmationMail.backToHome")}
                </Button>
                <Button
                  sx={{
                    width: { xs: "100%", sm: "45%" },
                    background: "#0042ff",
                    color: "white",
                    fontSize: "14px",
                    fontFamily: "Inter Variable",
                    fontWeight: "500",
                    letterSpacing: "-0.25px",
                    textTransform: "none",
                    borderRadius: "10px",
                    border: "1px solid #276CFA",
                    outline: "1px solid #0646C6",
                    "&:hover": {
                      backgroundColor: "#1459e2",
                      boxShadow: "none",
                    },
                  }}
                  onClick={navigateToAccountManagement}
                >
                  {t("confirmationMail.accountManagement")}
                </Button>
              </Box>
            </Paper>
          </Box>
        </Paper>
      </div>
    </ThemeProvider>
  );
}

export default ConfirmationMail;
