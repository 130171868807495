import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import Loader from "../../../shared/loader/Loader";
import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  API_SERVER_ENDPOINT,
  BASE_URL_ONESITE,
} from "../../../shared/api/ConstantesEndpoints";
import { useTranslation } from "react-i18next";
import ToastError from "../../../shared/toast/ToastError";

const PaiementStripeFormulaire = ({ selectedPriceId, promoCode }) => {
  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const customerId = profil?.customerId;
  const { t } = useTranslation();

  if (!stripe || !elements) {
    return "";
  }

  const handleError = (error) => {
    setLoading(false);
    const errorMessage = error.message ? error.message : error;
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedPriceId) {
      handleError(t("paiementStripeFormulaire.selectSubscription"));
      return;
    }

    const templateId = sessionStorage.getItem("templateId");
    if (!templateId) {
      handleError(t("paiementStripeFormulaire.templateIdMissing"));
      return;
    }

    const nomSite = sessionStorage.getItem("nomSite");
    if (!nomSite) {
      handleError(t("paiementStripeFormulaire.templateIdMissing"));
      return;
    }

    const isBoutique = sessionStorage.getItem("boutique");
    if (isBoutique === null) {
      handleError(t("paiementStripeFormulaire.templateIdMissing"));
      return;
    }

    try {
      setLoading(true);

      if (!stripe) {
        setLoading(false);
        return;
      }

      const submitResult = await elements.submit();

      if (submitResult.error) {
        handleError(submitResult.error.message);
        setLoading(false);
        return;
      }

      const clientSecret = await createSetupIntent(customerId);

      sessionStorage.setItem("fromDemandeInstallation", "true");

      const { error } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${BASE_URL_ONESITE}/confirmation-installation?templateId=${encodeURIComponent(
            templateId
          )}&isBoutique=${encodeURIComponent(
            isBoutique
          )}&priceId=${encodeURIComponent(
            selectedPriceId
          )}&promoCode=${encodeURIComponent(promoCode || "")}`,
        },
      });

      if (error) {
        handleError(error.message);
        setLoading(false);
        return;
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  async function createSetupIntent(customerId) {
    try {
      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/utilisateur/setup-intent`,
        {
          customerId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { clientSecret } = response.data;

      return clientSecret;
    } catch (error) {
      setLoading(false);
      handleError(t("paiementStripeFormulaire.setupError"));
      throw error;
    }
  }

  const paymentElementOptions = {
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <PaymentElement className="mb-3" options={paymentElementOptions} />
        <AddressElement
          options={{ mode: "billing", autocomplete: { mode: "disabled" } }}
        />
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 4 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={loading}
            sx={{
              width: "300px",
              height: "60px",
              bgcolor: "#2a1881",
              color: "white",
              fontSize: "18px",
              fontFamily: "Inter Variable",
              fontWeight: "500",
              textTransform: "none",
              boxShadow: "none",
              borderRadius: "10px",
              "&:hover": {
                bgcolor: "#462655",
                color: "white",
                boxShadow: "none",
              },
            }}
            disabled={!stripe || !elements}
          >
            {t("paiementStripeFormulaire.confirmOrder")}
          </LoadingButton>
        </Box>
      </form>
      {loading && <Loader loading={loading} />}
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </div>
  );
};

export default PaiementStripeFormulaire;
