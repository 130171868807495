import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { API_SERVER_ENDPOINT } from "../../../../shared/api/ConstantesEndpoints";
import { convertHtmlToText } from "../../../../shared/html/HtmlUtils";
import RowProduit from "./RowProduit";
import ProduitCard from "./ProduitCard";
import { useTranslation } from "react-i18next";
import Loader from "../../../../shared/loader/Loader";
import SuccessToast from "../../../../shared/toast/ToastSuccess";
import ProductForm from "./ProductForm";
import ToastError from "../../../../shared/toast/ToastError";
import ReviewGenerator from "./avis/ReviewGenerator";

const GestionProduits = ({ siteConsulte }) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editingProduct, setEditingProduct] = useState(null);
  const [showProductForm, setShowProductForm] = useState(false);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [openToastSuccess, setOpenToastSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [error, setError] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleShowProductForm = (product = null) => {
    setEditingProduct(product);
    setShowProductForm(true);
  };

  const handleCloseProductForm = () => {
    setShowProductForm(false);
    setEditingProduct(null);
    loadProducts();
  };

  const handleShowReviewForm = () => {
    setShowReviewForm(true);
  };

  const handleCloseReviewForm = () => {
    setShowReviewForm(false);
  };

  const loadProducts = useCallback(() => {
    setIsLoading(true);

    if (siteConsulte && siteConsulte.nom) {
      const url = `${API_SERVER_ENDPOINT}/products?siteNom=${encodeURIComponent(
        siteConsulte.nom
      )}`;

      axios
        .get(url)
        .then((response) => {
          const processedProducts = response.data.map((product) => ({
            ...product,
            description: convertHtmlToText(product.description),
          }));

          setProducts(processedProducts);
        })
        .catch((error) => {
          handleError(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [siteConsulte]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const handleDelete = async (product) => {
    const deleteEndpoint = `${API_SERVER_ENDPOINT}/products/${product.id}`;

    try {
      setIsLoading(true);

      await axios.delete(deleteEndpoint, {
        data: { siteNom: siteConsulte.nom },
      });
      loadProducts();
    } catch (error) {
      handleError(error.message);
      setIsLoading(false);
    }
  };

  const handleProductSubmitted = (actionType) => {
    handleOpenToastSuccess(actionType);
    loadProducts();
  };

  const handleOpenToastSuccess = (actionType) => {
    switch (actionType) {
      case "add":
        setToastMessage(t("produits.toastAdd"));
        setShowProductForm(false);
        break;
      case "edit":
        setToastMessage(t("produits.toastEdit"));
        setShowProductForm(false);
        break;
      default:
        setToastMessage(t("produits.toastdefault"));
    }
    setOpenToastSuccess(true);
  };

  const handleCloseToastSuccess = (_) => {
    setOpenToastSuccess(false);
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  if (showProductForm) {
    return (
      <div
        style={{
          flexGrow: 1,
          padding: "2%",
          backgroundColor: "#f5f8fa",
          minHeight: "100vh",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <IconButton onClick={handleCloseProductForm}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            sx={{
              color: "black",
              fontSize: { xs: "26px", sm: "40px" },
              fontFamily: "Space Grotesk, sans serif",
              fontWeight: "1000",
              letterSpacing: "-3px",
              mt: { xs: "5%", sm: 0 },
            }}
          >
            {editingProduct
              ? t("produits.edit_product")
              : t("produits.add_product")}
          </Typography>
        </Box>
        <ProductForm
          siteConsulte={siteConsulte}
          product={editingProduct}
          onClose={handleCloseProductForm}
          onSubmitted={handleProductSubmitted}
        />
      </div>
    );
  }

  if (showReviewForm) {
    return (
      <div
        style={{
          flexGrow: 1,
          padding: "2%",
          backgroundColor: "#f5f8fa",
          minHeight: "100vh",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <IconButton onClick={handleCloseReviewForm}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            sx={{
              color: "black",
              fontSize: { xs: "26px", sm: "40px" },
              fontFamily: "Space Grotesk, sans serif",
              fontWeight: "1000",
              letterSpacing: "-3px",
              mt: { xs: "5%", sm: 0 },
            }}
          >
            {t("reviewGenerator.title")}
          </Typography>
        </Box>
        <ReviewGenerator
          siteConsulte={siteConsulte}
          products={products}
          onClose={handleCloseReviewForm}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        flexGrow: 1,
        padding: "2%",
        backgroundColor: "#f5f8fa",
        minHeight: "100vh",
      }}
    >
      <Paper
        sx={{
          margin: "0px",
          overflowX: "auto",
          overflowY: "auto",
          boxShadow: "none",
          backgroundColor: "#f5f8fa",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontSize: { xs: "26px", sm: "60px" },
              fontFamily: "Space Grotesk, sans serif",
              fontWeight: "1000",
              letterSpacing: { xs: "-2px", sm: "-5px", md: "-5px", lg: "-5px" },
              ml: "2%",
              mt: { xs: "5%", sm: 0 },
            }}
          >
            {t("produits.products")}
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleShowProductForm()}
              sx={{
                background: "#0042ff",
                color: "white",
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.25px",
                textTransform: "none",
                mr: 2,
                borderRadius: "10px",
                border: "1px solid #276CFA",
                outline: "1px solid #0646C6",
                "&:hover": {
                  backgroundColor: "#1459e2",
                  boxShadow: "none",
                },
              }}
            >
              {t("produits.add_product")}
              <AddIcon sx={{ fontSize: "20px", ml: "3px" }} />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleShowReviewForm}
              sx={{
                background: "#0042ff",
                color: "white",
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.25px",
                textTransform: "none",
                mr: 2,
                borderRadius: "10px",
                border: "1px solid #276CFA",
                outline: "1px solid #0646C6",
                "&:hover": {
                  backgroundColor: "#1459e2",
                  boxShadow: "none",
                },
              }}
            >
              {t("reviewGenerator.add_review")}
              <AddIcon sx={{ fontSize: "20px", ml: "3px" }} />
            </Button>
          </Box>
        </div>
        <Typography
          sx={{
            color: "black",
            fontSize: "16px",
            fontFamily: "Inter Variable, sans-serif",
            fontWeight: "500",
            letterSpacing: "-0.75px",
            mb: "5%",
            mt: "0%",
            ml: "2%",
          }}
        >
          {t("produits.add_edit_delete_products")}
        </Typography>

        <Box
          marginTop="2rem"
          sx={{
            borderRadius: "20px",
            boxShadow: "none",
            width: { xl: "90%", lg: "90%", md: "95%", sm: "95%", xs: "95%" },
            mb: "1%",
            ml: "3%",
          }}
        >
          {products.length > 0 ? (
            isMobile ? (
              products.map((product) => (
                <ProduitCard
                  key={product.id}
                  product={product}
                  handleEdit={() => handleShowProductForm(product)}
                  handleDelete={() => handleDelete(product)}
                />
              ))
            ) : (
              <TableContainer
                component={Paper}
                style={{ borderRadius: "20px", border: "1px solid #c6c7ce" }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "600",
                        }}
                      >
                        {t("produits.product_photo")}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                        align="center"
                      >
                        {t("produits.name")}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                        align="center"
                      >
                        {t("produits.price")}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                        align="center"
                      >
                        {t("produits.actions")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((product) => (
                      <RowProduit
                        key={product.id}
                        product={product}
                        handleEdit={() => handleShowProductForm(product)}
                        handleDelete={() => handleDelete(product)}
                        siteNom={siteConsulte.nom}
                        loadProducts={loadProducts}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          ) : (
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                color: "grey",
                fontSize: "20px",
                fontFamily: "Inter Variable, sans serif",
                letterSpacing: "-1px",
                fontWeight: "500",
                mt: "10%",
                textAlign: "center",
              }}
            >
              {t("produits.no_products_display")}
            </Typography>
          )}
        </Box>
      </Paper>
      <Loader loading={isLoading} />
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
      <SuccessToast
        open={openToastSuccess}
        onClose={handleCloseToastSuccess}
        message={toastMessage}
      />
    </div>
  );
};

export default GestionProduits;
