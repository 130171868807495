import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    verifyUserAuthentication();
  }, []);

  const verifyUserAuthentication = async () => {
    try {
      const response = await axios.get(`${API_SERVER_ENDPOINT}/profile`, {
        withCredentials: true,
      });

      const data = response.data;

      if (data.isAuthenticated) {
        sessionStorage.setItem("profil", JSON.stringify(data));
        setUserAuthenticated(true);
      } else {
        setUserAuthenticated(false);
      }
    } catch (error) {
      setUserAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        userAuthenticated,
        setUserAuthenticated,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
