import { useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../api/ConstantesEndpoints";

const OperationStatusChecker = ({ operationId, type, onComplete, onError }) => {
  const pollingIntervalRef = useRef(null);

  const checkOperationStatus = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_SERVER_ENDPOINT}/operation/${operationId}`
      );
      if (response.data.status === "completed") {
        clearInterval(pollingIntervalRef.current);
        onComplete(type);
      } else if (response.data.status === "failed") {
        clearInterval(pollingIntervalRef.current);
        onError(type);
      }
    } catch (error) {
      onError(type);
    }
  }, [operationId, onComplete, onError, type]);

  useEffect(() => {
    if (operationId) {
      pollingIntervalRef.current = setInterval(() => {
        checkOperationStatus();
      }, 10000);
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [operationId, checkOperationStatus]);

  return null;
};

export default OperationStatusChecker;
