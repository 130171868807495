import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Table,
  TableHead,
  TableBody,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import formatAddress from "../../../../shared/adresse/AdresseUtils";
import { useTranslation } from "react-i18next";
import GetStatusChip from "./StatutChip";

const RowCommande = ({ order, handleStatusChange }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (status) => {
    handleStatusChange(order.id, status);
    handleClose();
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography
            sx={{
              color: "black",
              fontSize: { xl: "14px", lg: "14px", md: "14px" },
              fontFamily: "Inter Variable",
              letterSpacing: "-0.5px",
              fontWeight: "500",
            }}
          >
            {order.id}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            sx={{
              color: "black",
              fontSize: { xl: "14px", lg: "14px", md: "14px" },
              fontFamily: "Inter Variable",
              letterSpacing: "-0.5px",
              fontWeight: "500",
            }}
          >
            {new Date(order.date_created).toLocaleDateString()}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            sx={{
              color: "black",
              fontSize: { xl: "14px", lg: "14px", md: "14px" },
              fontFamily: "Inter Variable",
              letterSpacing: "-0.5px",
              fontWeight: "500",
            }}
          >
            {order.customer
              ? `${order.shipping.first_name} ${order.shipping.last_name}`
              : t("commande.invite")}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            sx={{
              color: "black",
              fontSize: { xl: "14px", lg: "14px", md: "14px" },
              fontFamily: "Inter Variable",
              letterSpacing: "-0.5px",
              fontWeight: "500",
            }}
          >
            {order.total} {order.currency}
          </Typography>
        </TableCell>
        <TableCell align="center">{GetStatusChip(order.status)}</TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "10px",
                textAlign: "center",
              },
              "& .MuiMenuItem-root": {
                fontFamily: "Inter Variable, sans-serif",
              },
            }}
          >
            <MenuItem onClick={() => handleAction("cancelled")}>
              {t("commande.annuler")}
            </MenuItem>
            <MenuItem onClick={() => handleAction("completed")}>
              {t("commande.completer")}
            </MenuItem>
            <MenuItem onClick={() => handleAction("on-hold")}>
              {t("commande.mettreEnAttente")}
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="medium" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: { xl: "14px", lg: "14px", md: "14px" },
                        fontFamily: "Inter Variable",
                        letterSpacing: "-0.5px",
                        fontWeight: "500",
                      }}
                    >
                      {t("commande.produit")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: { xl: "14px", lg: "14px", md: "14px" },
                        fontFamily: "Inter Variable",
                        letterSpacing: "-0.5px",
                        fontWeight: "500",
                      }}
                    >
                      {t("commande.quantite")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: { xl: "14px", lg: "14px", md: "14px" },
                        fontFamily: "Inter Variable",
                        letterSpacing: "-0.5px",
                        fontWeight: "500",
                      }}
                    >
                      {t("commande.prixUnite")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.line_items.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "500",
                        }}
                      >
                        {item.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "500",
                        }}
                      >
                        {item.quantity}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: { xl: "14px", lg: "14px", md: "14px" },
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          fontWeight: "500",
                        }}
                      >{`${item.price} ${order.currency}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    fontSize: { xl: "14px", lg: "14px", md: "14px" },
                    fontFamily: "Inter Variable",
                    letterSpacing: "-0.5px",
                    fontWeight: "500",
                  }}
                >
                  {t("commande.methodeDePaiement")}:{" "}
                  {order.payment_method_title || t("commande.nonSpecifiee")}
                </Typography>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    fontSize: { xl: "14px", lg: "14px", md: "14px" },
                    fontFamily: "Inter Variable",
                    letterSpacing: "-0.5px",
                    fontWeight: "500",
                  }}
                >
                  {t("commande.adresseDeFacturation")}:{" "}
                  {formatAddress(order.billing, t)}
                </Typography>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    fontSize: { xl: "14px", lg: "14px", md: "14px" },
                    fontFamily: "Inter Variable",
                    letterSpacing: "-0.5px",
                    fontWeight: "500",
                  }}
                >
                  {t("commande.adresseDeDestination")}:{" "}
                  {formatAddress(order.shipping, t)}
                </Typography>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RowCommande;
