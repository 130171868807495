import { Navigate } from "react-router-dom";

const ProtectedPreviewRoute = ({ children }) => {
  const accessToPreview = sessionStorage.getItem("accessToPreview");

  if (accessToPreview !== "true") {
    return <Navigate to="/" replace />;
  }

  return children;
};

const ProtectedPaymentRoute = ({ children }) => {
  const isAuthorizedForPayment = sessionStorage.getItem("authorizedForPayment");

  if (isAuthorizedForPayment !== "true") {
    return <Navigate to="/" replace />;
  }

  return children;
};

const ProtectedPaymentSuccessRoute = ({ children }) => {
  const isFromSuccessPayment = sessionStorage.getItem(
    "fromDemandeInstallation"
  );

  if (!isFromSuccessPayment) {
    return <Navigate to="/" replace />;
  }

  sessionStorage.removeItem("fromDemandeInstallation");

  return children;
};

const ProtectedFelicitationInstallationRoute = ({ children }) => {
  const isFromSuccessInstallation = sessionStorage.getItem(
    "fromFelicitationInstallation"
  );

  if (!isFromSuccessInstallation) {
    return <Navigate to="/" replace />;
  }

  sessionStorage.removeItem("fromFelicitationInstallation");

  return children;
};

export {
  ProtectedPreviewRoute,
  ProtectedPaymentRoute,
  ProtectedPaymentSuccessRoute,
  ProtectedFelicitationInstallationRoute,
};
