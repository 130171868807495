import React, { useCallback, useEffect, useRef, useState } from "react";
import VisualBuilder from "../../components/visualBuilder/VisualBuilder";
import SidebarGestionCompte from "../../components/compte/SidebarGestionCompte";
import GestionProduits from "../../components/compte/eCommerce/produit/GestionProduits";
import GestionLivraison from "../../components/compte/eCommerce/livraison/GestionLivraison";
import GestionPaiement from "../../components/compte/eCommerce/paiement/GestionPaiement";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Accueil from "../../components/compte/Accueil";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import GestionStats from "../../components/compte/eCommerce/stats/GestionStats";
import GestionCommandes from "../../components/compte/eCommerce/commande/GestionCommandes";
import GestionMenu from "../../components/compte/menu/GestionMenu";
import DetailsInformations from "../../components/compte/information/DetailsInformations";
import GestionDomaine from "../../components/compte/domaine/GestionDomaine";
import DetailsAbonnement from "../../components/compte/abonnement/DetailsAbonnement";
import { useLocation, useNavigate } from "react-router-dom";
import ToastError from "../../shared/toast/ToastError";

const GestionCompte = () => {
  const [activeComponent, setActiveComponent] = useState("accueil");
  const [siteConsulte, setSiteConsulte] = useState(
    JSON.parse(sessionStorage.getItem("selectedSite")) || null
  );
  const [sitesUtilisateur, setSitesUtilisateur] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const customerId = profil?.customerId;

  const siteConsulteRef = useRef(siteConsulte);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (
      queryParams.get("stripe") === "success" ||
      queryParams.get("paypal") === "success"
    ) {
      setActiveComponent("gestionPaiement");
      queryParams.delete("stripe");
      queryParams.delete("paypal");
      navigate(
        {
          pathname: location.pathname,
          search: queryParams.toString(),
        },
        { replace: true }
      );
    }
  }, [location.search, navigate, location.pathname]);

  const getUtilisateurSite = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_SERVER_ENDPOINT}/utilisateur`, {
        params: { customerId },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const utilisateurData = response.data;
      const sites = utilisateurData.abonnements.flatMap(
        (abonnement) => abonnement.sites
      );

      setSitesUtilisateur(sites);

      if (sites.length > 0) {
        let updatedSite = sites[0];

        if (siteConsulteRef.current) {
          const matchedSite = sites.find(
            (site) => site.nom === siteConsulteRef.current.nom
          );
          if (matchedSite) {
            updatedSite = matchedSite;
          }
        }

        setSiteConsulte(updatedSite);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [customerId]);

  useEffect(() => {
    if (customerId) {
      getUtilisateurSite();
    }
  }, [customerId, getUtilisateurSite]);

  useEffect(() => {
    if (siteConsulte) {
      sessionStorage.setItem("selectedSite", JSON.stringify(siteConsulte));
      siteConsulteRef.current = siteConsulte;
    }
  }, [siteConsulte]);

  const changeActiveComponentAndCloseDrawer = (componentName) => {
    setActiveComponent(componentName);
    if (isMobile) setDrawerOpen(false);
  };

  const changeSiteComponent = useCallback((site) => {
    setSiteConsulte(site);
  }, []);

  const renderSidebarContent = () => (
    <SidebarGestionCompte
      changeActiveComponent={changeActiveComponentAndCloseDrawer}
      changeSite={changeSiteComponent}
      sitesUtilisateur={sitesUtilisateur}
      getUtilisateurSite={getUtilisateurSite}
      siteConsulte={siteConsulte}
      loading={loading}
      error={setError}
    />
  );

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "accueil":
        return <Accueil sitesUtilisateur={sitesUtilisateur} />;
      case "gestionStats":
        return <GestionStats siteConsulte={siteConsulte} />;
      case "gestionCommandes":
        return <GestionCommandes siteConsulte={siteConsulte} />;
      case "gestionMenu":
        return <GestionMenu siteConsulte={siteConsulte} />;
      case "productManager":
        return <GestionProduits siteConsulte={siteConsulte} />;
      case "gestionLivraison":
        return <GestionLivraison siteConsulte={siteConsulte} />;
      case "gestionPaiement":
        return (
          <GestionPaiement
            siteConsulte={siteConsulte}
            getUtilisateurSite={getUtilisateurSite}
          />
        );
      case "gestionDomaine":
        return <GestionDomaine getUtilisateurSite={getUtilisateurSite} />;
      case "visualBuilder":
        return <VisualBuilder siteConsulte={siteConsulte} />;
      case "mesInformations":
        return <DetailsInformations />;
      case "mesAbonnements":
        return <DetailsAbonnement />;
      default:
        return <div></div>;
    }
  };

  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      {isMobile ? (
        <>
          <AppBar position="fixed" sx={{ background: "#02010e" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>
              <Box
                component="img"
                src={process.env.PUBLIC_URL + "/logo/onesite.png"}
                alt="Logo"
                sx={{
                  height: "3rem",
                  marginLeft: 0,
                }}
              />
            </Toolbar>
          </AppBar>
          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={() => setDrawerOpen(false)}
          >
            {renderSidebarContent()}
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, mt: 7 }}>
            {renderActiveComponent()}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              position: "sticky",
              top: 0,
              flexShrink: 0,
              height: "100vh",
              overflowY: "auto",
            }}
            role="presentation"
          >
            {renderSidebarContent()}
          </Box>
          <Box component="main" sx={{ flexGrow: 1 }}>
            {renderActiveComponent()}
          </Box>
        </>
      )}
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </div>
  );
};

export default GestionCompte;
