import React, { useState, useEffect } from "react";
import { CircularProgress, Box, Typography, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const StatutSite = ({ site }) => {
  const { t } = useTranslation();

  const [progress, setProgress] = useState(() => {
    return Number(sessionStorage.getItem("installationProgress")) || 0;
  });

  useEffect(() => {
    if (
      (site?.etat?.toLowerCase() === "installation" ||
        site?.etat?.toLowerCase() === "actif" ||
        site?.etat?.toLowerCase() === "erreur") &&
      progress < 100
    ) {
      const intervalDuration = 7000;
      const steps = 90;
      const increment = 90 / steps;

      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          let nextProgress = prevProgress;

          if (
            site?.etat?.toLowerCase() === "actif" ||
            site?.etat?.toLowerCase() === "erreur"
          ) {
            clearInterval(interval);
            sessionStorage.removeItem("installationProgress");
            return 100;
          }

          if (prevProgress < 90) {
            nextProgress = Math.min(prevProgress + increment, 90);
          }

          sessionStorage.setItem("installationProgress", nextProgress);
          return nextProgress;
        });
      }, intervalDuration);

      return () => clearInterval(interval);
    }
  }, [site, progress]);

  const statusLowerCase = site?.etat?.toLowerCase();
  let color;

  switch (statusLowerCase) {
    case "actif":
      color = "success";
      break;
    case "inactif":
    case "maintenance":
    case "erreur":
      color = "error";
      break;
    case "installation":
      color = "warning";
      break;
    default:
      color = "default";
  }

  if (statusLowerCase === "installation" && progress < 100) {
    return (
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          p: 1,
        }}
      >
        <CircularProgress
          variant="determinate"
          value={progress}
          sx={{ color: "blue" }}
          size={50}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
            sx={{
              fontSize: "12px",
              fontWeight: 600,
              color: "blue",
            }}
          >{`${progress}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const label = t(`statutSite.${statusLowerCase}`, {
    defaultValue: t("statutSite.unknown"),
  });

  return (
    <Chip
      label={label}
      color={color}
      sx={{
        fontSize: { xl: "14px", lg: "14px", md: "14px" },
        fontFamily: "Inter Variable",
        letterSpacing: "-0.5px",
        fontWeight: "500",
        borderRadius: "8px",
        boxShadow: "none",
      }}
    />
  );
};

export default StatutSite;
