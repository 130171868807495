import React from "react";
import {
  Box,
  Link,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ConditionUtilisation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const { t } = useTranslation();

  const title = t("terms_of_use.title");
  const sections = t("terms_of_use.sections", { returnObjects: true });

  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      {isMobile ? (
        <RenderCondition title={title} sections={sections} />
      ) : (
        <>
          <Sidebar />
          <RenderCondition title={title} sections={sections} />
        </>
      )}
    </div>
  );
};

const RenderCondition = ({ title, sections }) => (
  <Box
    component="main"
    sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
  >
    <div style={{ flexGrow: 1, padding: "2%", backgroundColor: "#f5f8fa" }}>
      <Typography
        sx={{
          color: "black",
          fontSize: { xs: "26px", sm: "60px" },
          fontFamily: "Space Grotesk, sans serif",
          fontWeight: "1000",
          letterSpacing: {
            xs: "-2px",
            sm: "-5px",
            md: "-5px",
            lg: "-5px",
          },
          textAlign: "center",
          mt: { xs: "5%", sm: 0 },
        }}
      >
        {title}
      </Typography>
      <Paper
        sx={{
          margin: "0 auto",
          maxWidth: "1000px",
          overflowX: "auto",
          boxShadow: "none",
          backgroundColor: "#f5f8fa",
          minHeight: "100vh",
          padding: "2%",
        }}
      >
        {sections.map((section, index) => (
          <div key={index}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                color: "black",
                fontSize: { xs: "20px", sm: "24px" },
                fontFamily: "Inter Variable, sans serif",
                letterSpacing: "-1px",
                fontWeight: "600",
                mb: "24px",
                textAlign: "center",
              }}
            >
              {section.title}
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{
                color: "black",
                fontSize: { xs: "16px", sm: "18px" },
                fontFamily: "Inter Variable, sans serif",
                letterSpacing: "-0.5px",
                fontWeight: "400",
                mb: "16px",
                textAlign: "center",
              }}
            >
              {section.content}
            </Typography>
          </div>
        ))}
      </Paper>
    </div>
  </Box>
);

const Sidebar = () => {
  const navigate = useNavigate();

  const redirectionAccueil = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        flexShrink: 0,
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Box
          sx={{
            width: "300px",
            backgroundColor: "#02010e",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "100vh",
            overflowY: "auto",
          }}
        >
          <Box>
            <Box
              sx={{
                padding: "5%",
                justifyContent: "left",
                display: "flex",
                alignItems: "left",
              }}
            >
              <Link
                component="button"
                onClick={redirectionAccueil}
                sx={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/logo/onesite.png"}
                  alt="Presentation"
                  style={{ maxWidth: "7rem", margin: "0 auto" }}
                />
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConditionUtilisation;
