import React, { useState } from "react";
import { Box, Paper, Typography, TextField } from "@mui/material";
import axios from "axios";
import PaymentSubscribeAbonnement from "./stripe/PaymentSubscribeAbonnement";
import { useTranslation } from "react-i18next";
import RadioGroup from "../../shared/radioGroup/RadioGroup";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const FormulairePaiement = () => {
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  const [prix, setSelectedPrix] = useState(null);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [promoCode, setPromoCode] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSuccessPromo, setShowSuccessPromo] = useState(false);
  const { t } = useTranslation();

  const handleRadioChange = (priceId, prix) => {
    setSelectedPriceId(priceId);
    setSelectedPrix(prix);
  };

  const handleApplyPromoCode = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/utilisateur/validate-promo`,
        { promoCode }
      );

      const { valid, discount } = response.data;

      if (valid) {
        setShowSuccessPromo(true);

        setDiscountPercentage(discount);
        setError(null);

        setTimeout(() => {
          setShowSuccessPromo(false);
        }, 3000);
      } else {
        handleError(t("formulairePaiement.invalidPromoCode"));
      }
    } catch (error) {
      handleError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const calculateDiscountedPrice = (price) => {
    return price * (1 - discountPercentage / 100);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "0px",
        flexDirection: "column",
        paddingTop: "3rem",
      }}
    >
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: {
            xs: "20px",
            sm: "24px",
            lg: "26px",
            xl: "30px",
          },
          textAlign: "center",
          fontFamily: "Inter Variable",
          letterSpacing: "-1px",
          mb: 3,
        }}
      >
        {t("formulairePaiement.chooseSubscription")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "center",
          alignItems: "center",
          width: "100%",
          mb: 3,
        }}
      >
        <Box sx={{ width: { xs: "80%", md: "90%" } }}>
          <RadioGroup
            titre={
              <Typography
                sx={{
                  color: "black",
                  fontSize: "20px",
                  fontFamily: "Inter Variable",
                  fontWeight: "500",
                  letterSpacing: "-0.5px",
                }}
              >
                {t("formulairePaiement.liteSubscription")}
              </Typography>
            }
            description="3 jours d'essai gratuit"
            prix={calculateDiscountedPrice(1990)}
            priceId={"price_1PoQCIP3nsiReptRhr7mQchY"}
            selectedPriceId={selectedPriceId}
            onRadioChange={handleRadioChange}
            sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "center",
          alignItems: "center",
          width: "100%",
          mb: 3,
        }}
      >
        <Box sx={{ width: { xs: "80%", md: "90%" } }}>
          <RadioGroup
            titre={
              <Typography
                sx={{
                  color: "black",
                  fontSize: "20px",
                  fontFamily: "Inter Variable",
                  fontWeight: "500",
                  letterSpacing: "-0.5px",
                }}
              >
                {t("formulairePaiement.basicSubscription")}
              </Typography>
            }
            description="3 jours d'essai gratuit"
            prix={calculateDiscountedPrice(3990)}
            priceId={"price_1PoQCcP3nsiReptRTYDRWoeh"}
            selectedPriceId={selectedPriceId}
            onRadioChange={handleRadioChange}
            sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "center",
          alignItems: "center",
          width: "100%",
          mb: 3,
        }}
      >
        <Box sx={{ width: { xs: "80%", md: "90%" } }}>
          <RadioGroup
            titre={
              <Typography
                sx={{
                  color: "black",
                  fontSize: "20px",
                  fontFamily: "Inter Variable",
                  fontWeight: "500",
                  letterSpacing: "-0.5px",
                }}
              >
                {t("formulairePaiement.premiumSubscription")}
              </Typography>
            }
            description="3 jours d'essai gratuit"
            prix={calculateDiscountedPrice(9990)}
            priceId={"price_1PoQCrP3nsiReptRpurrcDUR"}
            selectedPriceId={selectedPriceId}
            onRadioChange={handleRadioChange}
            sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: { xs: "80%", md: "90%" },
          mb: 4,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter Variable",
            fontSize: "16px",
            fontWeight: "500",
            letterSpacing: "-0.5px",
            mb: 1,
          }}
        >
          {t("formulairePaiement.promoCodeLabel")}
        </Typography>
        <TextField
          label={t("formulairePaiement.promoCode")}
          variant="outlined"
          fullWidth
          margin="normal"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
        />
        <LoadingButton
          variant="contained"
          onClick={handleApplyPromoCode}
          loading={loading}
          endIcon={showSuccessPromo ? <CheckIcon /> : <ArrowForwardIosIcon />}
          sx={{
            width: "250px",
            height: "40px",
            bgcolor: "#2a1881",
            color: "white",
            fontSize: "14px",
            fontFamily: "Inter Variable",
            fontWeight: "500",
            textTransform: "none",
            boxShadow: "none",
            borderRadius: "10px",
            "&:hover": {
              bgcolor: "#462655",
              color: "white",
              boxShadow: "none",
            },
            mt: 2,
          }}
          disabled={!promoCode}
        >
          {t("formulairePaiement.applyPromoCode")}
        </LoadingButton>
        {error && (
          <Typography
            color="error"
            sx={{ mt: 2, fontFamily: "Inter Variable", fontSize: "14px" }}
          >
            {error}
          </Typography>
        )}
      </Box>

      <Typography
        sx={{
          fontWeight: "600",
          fontSize: {
            xs: "20px",
            sm: "24px",
            lg: "26px",
            xl: "30px",
          },
          textAlign: "center",
          fontFamily: "Inter Variable",
          letterSpacing: "-1px",
          mb: 3,
        }}
      >
        {t("formulairePaiement.paymentDetails")}
      </Typography>
      <Box sx={{ width: { xs: "80%", md: "90%" } }}>
        <PaymentSubscribeAbonnement
          selectedPriceId={selectedPriceId}
          montant={calculateDiscountedPrice(prix)}
          promoCode={promoCode}
        />
      </Box>
    </Paper>
  );
};

export default FormulairePaiement;
