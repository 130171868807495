import React, { useCallback, useEffect, useRef, useState } from "react";
import { Paper, Typography, Grid, Box, Button } from "@mui/material";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import axios from "axios";
import StatCard from "../../shared/statistique/StatCard";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachMonthOfInterval,
  eachDayOfInterval,
} from "date-fns";
import SiteStatsCard from "../../shared/statistique/SiteStatsCard";
import { useTranslation } from "react-i18next";
import ComingSoonCard from "../../shared/actualite/ComingSoonCard";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import SocialMediaCard from "../../shared/reseaux/SocialMediaCard";
import ToastError from "../../shared/toast/ToastError";

const Accueil = ({ sitesUtilisateur }) => {
  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const customerId = profil?.customerId;
  const { t } = useTranslation();

  const [error, setError] = useState(null);
  const [totalSales, setTotalSales] = useState(0.0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [chartDataSales, setChartDataSales] = useState([]);
  const [chartDataOrders, setChartDataOrders] = useState([]);
  const [chartDataCustomers, setChartDataCustomers] = useState([]);
  const [salesPercentageChange, setSalesPercentageChange] = useState(0);
  const [ordersPercentageChange, setOrdersPercentageChange] = useState(0);
  const [customersPercentageChange, setCustomersPercentageChange] = useState(0);

  const startDate = startOfMonth(new Date());
  const endDate = endOfMonth(new Date());
  const granularity = "mois";

  const navigate = useNavigate();

  const handleError = useCallback((message) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 3000);
  }, []);

  const updatePercentageChanges = useCallback(
    (
      chartDataSales,
      chartDataOrders,
      chartDataCustomers,
      setSalesPercentageChange,
      setOrdersPercentageChange,
      setCustomersPercentageChange
    ) => {
      const previousTotalSales =
        chartDataSales[chartDataSales.length - 2]?.value || 0;
      const latestTotalSales =
        chartDataSales[chartDataSales.length - 1]?.value || 0;
      setSalesPercentageChange(
        calculatePercentageChange(latestTotalSales, previousTotalSales)
      );

      const previousTotalOrders =
        chartDataOrders[chartDataOrders.length - 2]?.value || 0;
      const latestTotalOrders =
        chartDataOrders[chartDataOrders.length - 1]?.value || 0;
      setOrdersPercentageChange(
        calculatePercentageChange(latestTotalOrders, previousTotalOrders)
      );

      const previousTotalCustomers =
        chartDataCustomers[chartDataCustomers.length - 2]?.value || 0;
      const latestTotalCustomers =
        chartDataCustomers[chartDataCustomers.length - 1]?.value || 0;
      setCustomersPercentageChange(
        calculatePercentageChange(latestTotalCustomers, previousTotalCustomers)
      );
    },
    []
  );

  const prepareChartData = useCallback(
    async (startDate, endDate, granularity) => {
      if (startDate > endDate) {
        throw new Error(t("error.invalidDateRange"));
      }

      let dateRange;

      if (granularity === "annee") {
        dateRange = eachMonthOfInterval({ start: startDate, end: endDate });
      } else if (granularity === "mois") {
        dateRange = eachDayOfInterval({ start: startDate, end: endDate });
      } else {
        dateRange = eachDayOfInterval({ start: startDate, end: endDate });
      }

      return dateRange.map((date) => ({
        date: format(date, granularity === "annee" ? "yyyy-MM" : "yyyy-MM-dd"),
        value: 0,
      }));
    },
    [t]
  );

  const mergeChartData = async (initialDataPromise, actualDataPromise) => {
    try {
      const initialData = await initialDataPromise;
      const actualData = await actualDataPromise;

      const dataMap = new Map(
        actualData.map((item) => [item.date, item.value])
      );

      return initialData.map((item) => ({
        date: item.date,
        value: dataMap.get(item.date) || item.value,
      }));
    } catch (error) {
      return [];
    }
  };

  const calculatePercentageChange = (current, previous) => {
    if (!previous || previous === "0.00") return 0;
    return (((current - previous) / previous) * 100).toFixed(2);
  };

  const loadStatistics = useCallback(async () => {
    try {
      const url = `${API_SERVER_ENDPOINT}/statistiques/site/all?clientId=${encodeURIComponent(
        customerId
      )}&start=${format(startDate, "yyyy-MM-dd")}&end=${format(
        endDate,
        "yyyy-MM-dd"
      )}&granularity=${granularity}`;

      const response = await axios.get(url);
      if (response.status === 200) {
        const results = response.data;

        const initialChartDataSales = prepareChartData(
          startDate,
          endDate,
          granularity
        );
        const initialChartDataOrders = prepareChartData(
          startDate,
          endDate,
          granularity
        );
        const initialChartDataCustomers = prepareChartData(
          startDate,
          endDate,
          granularity
        );

        let aggregatedSales = 0;
        let aggregatedOrders = 0;
        let aggregatedCustomers = 0;
        let aggregatedChartDataSales = [];
        let aggregatedChartDataOrders = [];
        let aggregatedChartDataCustomers = [];

        results.forEach((data) => {
          aggregatedSales += Number(data.salesData.totalSales);
          aggregatedOrders += Number(data.salesData.totalOrders);
          aggregatedCustomers += Number(data.salesData.totalCustomers);

          aggregatedChartDataSales = aggregatedChartDataSales.concat(
            data.salesData.chartDataSales
          );
          aggregatedChartDataOrders = aggregatedChartDataOrders.concat(
            data.salesData.chartDataOrders
          );
          aggregatedChartDataCustomers = aggregatedChartDataCustomers.concat(
            data.salesData.chartDataCustomers
          );
        });

        const finalChartDataSales = await mergeChartData(
          Promise.resolve(initialChartDataSales),
          Promise.resolve(aggregatedChartDataSales)
        );
        const finalChartDataOrders = await mergeChartData(
          Promise.resolve(initialChartDataOrders),
          Promise.resolve(aggregatedChartDataOrders)
        );
        const finalChartDataCustomers = await mergeChartData(
          Promise.resolve(initialChartDataCustomers),
          Promise.resolve(aggregatedChartDataCustomers)
        );

        setTotalSales(aggregatedSales);
        setTotalOrders(aggregatedOrders);
        setTotalCustomers(aggregatedCustomers);
        setChartDataSales(finalChartDataSales);
        setChartDataOrders(finalChartDataOrders);
        setChartDataCustomers(finalChartDataCustomers);

        updatePercentageChanges(
          finalChartDataSales,
          finalChartDataOrders,
          finalChartDataCustomers,
          setSalesPercentageChange,
          setOrdersPercentageChange,
          setCustomersPercentageChange
        );
      }
    } catch (error) {
      handleError(error.message);
    }
  }, [
    customerId,
    startDate,
    endDate,
    granularity,
    updatePercentageChanges,
    prepareChartData,
    handleError,
  ]);

  const loadStatisticsRef = useRef(loadStatistics);

  useEffect(() => {
    loadStatisticsRef.current = loadStatistics;
  }, [loadStatistics]);

  useEffect(() => {
    if (sitesUtilisateur.length > 0) {
      loadStatisticsRef.current();
    }
  }, [sitesUtilisateur]);

  const redirectionCreerSite = () => navigate("/informations");

  return (
    <div
      style={{
        flexGrow: 1,
        padding: "2%",
        backgroundColor: "#f5f8fa",
        minHeight: "100vh",
      }}
    >
      <Paper
        sx={{
          margin: "0px",
          overflowX: "auto",
          boxShadow: "none",
          backgroundColor: "#f5f8fa",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontSize: { xs: "26px", sm: "60px" },
              fontFamily: "Space Grotesk, sans serif",
              fontWeight: "1000",
              letterSpacing: { xs: "-2px", sm: "-5px", md: "-5px", lg: "-5px" },
              ml: "2%",
              mt: { xs: "5%", sm: 0 },
            }}
          >
            {t("home.title")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={redirectionCreerSite}
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "10px" },
              fontFamily: "Space Grotesk, sans serif",
              fontWeight: "600",
              textTransform: "none",
              mr: { lg: "0px", md: "10%", sm: "0", xs: "0" },
              mt: { lg: "0px", md: "1%", sm: "5%", xs: "10%" },
              mb: "1rem",
              boxShadow: "none",
              borderRadius: "10px",
              "&:hover": {
                background: "#0033c1",
                color: "white",
                boxShadow: "none",
              },
            }}
          >
            {t("home.creerUnSite")}
            <AddIcon sx={{ fontSize: "20px", ml: "3px" }} />
          </Button>
        </div>

        <Typography
          sx={{
            color: "black",
            fontSize: "20px",
            fontFamily: "Space Grotesk, sans serif",
            fontWeight: "500",
            letterSpacing: "-1.5px",
            mb: "3%",
            mt: "0%",
            ml: "2%",
          }}
        >
          {t("home.subtitle")}
        </Typography>

        <Paper
          sx={{
            flex: 1,
            overflowX: "auto",
            boxShadow: "none",
            padding: "20px",
            backgroundColor: "#f5f8fa",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <StatCard
                  title={t("home.totalSales")}
                  value={`${totalSales} $`}
                  percentageChange={salesPercentageChange}
                  data={chartDataSales}
                  dateRange={`${t("home.dateRange")} ${format(
                    startDate,
                    "MMM dd"
                  )} ${t("home.to")} ${format(endDate, "MMM dd, yyyy")}`}
                  comparisonInfo={t("home.salesComparison")}
                  tooltipContentLine="value"
                  granularity={granularity}
                />
                <StatCard
                  title={t("home.customers")}
                  value={`${totalCustomers}`}
                  percentageChange={customersPercentageChange}
                  data={chartDataCustomers}
                  dateRange={`${t("home.dateRange")} ${format(
                    startDate,
                    "MMM dd"
                  )} ${t("home.to")} ${format(endDate, "MMM dd, yyyy")}`}
                  comparisonInfo={t("home.customersComparison")}
                  tooltipContentLine="value"
                  granularity={granularity}
                />
                <ComingSoonCard />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <StatCard
                  title={t("home.orders")}
                  value={`${totalOrders}`}
                  percentageChange={ordersPercentageChange}
                  data={chartDataOrders}
                  dateRange={`${t("home.dateRange")} ${format(
                    startDate,
                    "MMM dd"
                  )} ${t("home.to")} ${format(endDate, "MMM dd, yyyy")}`}
                  comparisonInfo={t("home.ordersComparison")}
                  tooltipContentLine="value"
                  granularity={granularity}
                />
                <SiteStatsCard sites={sitesUtilisateur} />
                <SocialMediaCard />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Paper>
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </div>
  );
};

export default Accueil;
