import React from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
  Tooltip,
} from "@mui/material";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ListIcon from "@mui/icons-material/List";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalShipping from "@mui/icons-material/LocalShipping";
import PaymentIcon from "@mui/icons-material/Payment";
import DrawIcon from "@mui/icons-material/Draw";
import BookIcon from "@mui/icons-material/Book";
import StarIcon from "@mui/icons-material/Star";
import { useTranslation } from "react-i18next";

const SidebarECommerce = ({ selectedIndex, onListItemClick, site }) => {
  const { t } = useTranslation();
  const isSiteActive = site.etat === "actif";
  const isWooCommerceActive = site.woocommerceActive;

  const selectedStyle = {
    backgroundColor: "#0042ff !important",
    borderRadius: "8px",
    marginBottom: "2px",
    width: "90%",
    "& .MuiTypography-root": {
      color: "white",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  };

  const getTooltipTitle = (requiresWooCommerce) => {
    if (!isSiteActive) {
      return t("sidebarGestionCompte.site_not_active");
    } else if (!isWooCommerceActive && requiresWooCommerce) {
      return t("sidebarGestionCompte.woocommerce_not_ready");
    } else {
      return "";
    }
  };

  const renderListItem = (index, icon, text, requiresWooCommerce) => (
    <Tooltip
      title={getTooltipTitle(requiresWooCommerce)}
      placement="right"
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "16px",
            fontFamily: "Inter Variable",
          },
        },
      }}
    >
      <span>
        <ListItemButton
          selected={selectedIndex === index}
          onClick={() => onListItemClick(index)}
          disabled={
            !isSiteActive || (requiresWooCommerce && !isWooCommerceActive)
          }
          sx={selectedIndex === index ? selectedStyle : { marginBottom: "2px" }}
        >
          <ListItemIcon>
            {icon}
            <Typography
              sx={{
                color: "white",
                fontSize: {
                  xs: "14px",
                  md: "14px",
                  lg: "14px",
                  xl: "14px",
                },
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.5px",
                ml: "10px",
              }}
            >
              {text}
            </Typography>
          </ListItemIcon>
        </ListItemButton>
      </span>
    </Tooltip>
  );

  return (
    <List component="nav">
      {renderListItem(
        "gestionStats",
        <EqualizerIcon
          sx={{
            color: "white",
            fontSize: {
              xs: "18px",
              md: "20px",
              lg: "20px",
              xl: "20px",
            },
          }}
        />,
        t("sidebarGestionCompte.statistics"),
        true
      )}
      {renderListItem(
        "gestionCommandes",
        <ShoppingCartIcon
          sx={{
            color: "white",
            fontSize: {
              xs: "18px",
              md: "20px",
              lg: "20px",
              xl: "20px",
            },
          }}
        />,
        t("sidebarGestionCompte.orders"),
        true
      )}
      {renderListItem(
        "productManager",
        <InventoryIcon
          sx={{
            color: "white",
            fontSize: {
              xs: "18px",
              md: "20px",
              lg: "20px",
              xl: "20px",
            },
          }}
        />,
        t("sidebarGestionCompte.products"),
        true
      )}
      {renderListItem(
        "gestionLivraison",
        <LocalShipping
          sx={{
            color: "white",
            fontSize: {
              xs: "18px",
              md: "20px",
              lg: "20px",
              xl: "20px",
            },
          }}
        />,
        t("sidebarGestionCompte.delivery"),
        true
      )}
      {renderListItem(
        "gestionPaiement",
        <PaymentIcon
          sx={{
            color: "white",
            fontSize: {
              xs: "18px",
              md: "20px",
              lg: "20px",
              xl: "20px",
            },
          }}
        />,
        t("sidebarGestionCompte.payment"),
        true
      )}
      {renderListItem(
        "gestionMenu",
        <ListIcon
          sx={{
            color: "white",
            fontSize: {
              xs: "18px",
              md: "20px",
              lg: "20px",
              xl: "20px",
            },
          }}
        />,
        t("sidebarGestionCompte.menu"),
        false
      )}
      {renderListItem(
        "visualBuilder",
        <DrawIcon
          sx={{
            color: "white",
            fontSize: {
              xs: "18px",
              md: "20px",
              lg: "20px",
              xl: "20px",
            },
          }}
        />,
        t("sidebarGestionCompte.visualBuilder"),
        false
      )}
      <Tooltip
        title={t("sidebarGestionCompte.coming_soon")}
        PopperProps={{
          sx: {
            "& .MuiTooltip-tooltip": {
              fontSize: "16px",
              fontFamily: "Inter Variable",
            },
          },
        }}
      >
        <span>
          <ListItemButton disabled sx={{ marginBottom: "2px", width: "90%" }}>
            <ListItemIcon>
              <BookIcon
                sx={{
                  color: "white",
                  fontSize: {
                    xs: "18px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                }}
              />
              <Typography
                sx={{
                  color: "white",
                  fontSize: {
                    xs: "14px",
                    md: "14px",
                    lg: "14px",
                    xl: "14px",
                  },
                  fontFamily: "Inter Variable",
                  fontWeight: "500",
                  letterSpacing: "-0.5px",
                  ml: "10px",
                }}
              >
                {t("sidebarGestionCompte.academy")}
              </Typography>
            </ListItemIcon>
          </ListItemButton>
        </span>
      </Tooltip>
    </List>
  );
};

export default SidebarECommerce;
