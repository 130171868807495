import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Paper,
  Button,
  Tooltip,
  Collapse,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";
import ReceiptIcon from "@mui/icons-material/Receipt";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { addHttpsPrefix } from "../../../shared/html/HtmlUtils";
import StatutSite from "../../../shared/statut/StatutSite";
import { useTranslation } from "react-i18next";
import {
  LITE_PLAN,
  PLANS,
  PREMIUM_PLAN,
} from "../../../shared/enumeration/PriceEnum";
import { getStatutAbonnement } from "../../../shared/statut/StatutAbonnement";

const AbonnementTable = ({
  abonnements,
  requestAnnulation,
  handleReouvrirAbonnement,
  generateInvoice,
  rerollSite,
  ajouterSite,
  supprimerSite,
  customerId,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState({});

  const handleToggle = (abonnementId) => {
    setOpen((prev) => ({
      ...prev,
      [abonnementId]: !prev[abonnementId],
    }));
  };

  const getAbonnementLabel = (priceId) => {
    return PLANS[priceId]?.label || "Abonnement";
  };

  const canAddSite = (abonnement) => {
    const plan = PLANS[abonnement.priceId];
    return plan ? abonnement.sites.length < plan.maxSites : false;
  };

  const isPremiumPlan = (priceId) => {
    return priceId === PREMIUM_PLAN.priceId;
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        borderRadius: "20px",
        border: "1px solid #c6c7ce",
        boxShadow: "none",
      }}
    >
      <Table sx={{ minWidth: 400 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">
              <Typography
                sx={{
                  fontSize: { xl: "14px", lg: "14px", md: "14px" },
                  fontFamily: "Inter Variable",
                  letterSpacing: "-0.5px",
                  fontWeight: "600",
                }}
              >
                {t("abonnement.type")}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                sx={{
                  fontSize: { xl: "14px", lg: "14px", md: "14px" },
                  fontFamily: "Inter Variable",
                  letterSpacing: "-0.5px",
                  fontWeight: "600",
                }}
              >
                {t("abonnement.prochaineEcheance")}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                sx={{
                  fontSize: { xl: "14px", lg: "14px", md: "14px" },
                  fontFamily: "Inter Variable",
                  letterSpacing: "-0.5px",
                  fontWeight: "600",
                }}
              >
                {t("abonnement.montant")}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                sx={{
                  fontSize: { xl: "14px", lg: "14px", md: "14px" },
                  fontFamily: "Inter Variable",
                  letterSpacing: "-0.5px",
                  fontWeight: "600",
                }}
              >
                {t("abonnement.statutabonnement")}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                sx={{
                  fontSize: { xl: "14px", lg: "14px", md: "14px" },
                  fontFamily: "Inter Variable",
                  letterSpacing: "-0.5px",
                  fontWeight: "600",
                }}
              >
                {t("abonnement.actions")}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {abonnements.map((abonnement) => (
            <React.Fragment key={abonnement.abonnementId}>
              <TableRow>
                <TableCell>
                  {abonnement.sites.length > 0 && (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleToggle(abonnement.abonnementId)}
                    >
                      {open[abonnement.abonnementId] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  )}
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: { xl: "16px", lg: "16px", md: "14px" },
                      fontFamily: "Inter Variable",
                      letterSpacing: "-0.5px",
                      fontWeight: "600",
                    }}
                  >
                    {getAbonnementLabel(abonnement.priceId)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: { xl: "14px", lg: "14px", md: "14px" },
                      fontFamily: "Inter Variable",
                      letterSpacing: "-0.5px",
                      fontWeight: "500",
                    }}
                  >
                    {new Date(abonnement.periodeFin).toLocaleDateString(
                      "fr-FR",
                      {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      }
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: { xl: "14px", lg: "14px", md: "14px" },
                      fontFamily: "Inter Variable",
                      letterSpacing: "-0.5px",
                      fontWeight: "500",
                    }}
                  >
                    {abonnement.prix / 100} {abonnement.monnaie}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {getStatutAbonnement(abonnement.status, t)}
                </TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip
                      title={t("abonnement.genererFacture")}
                      enterDelay={500}
                      PopperProps={{
                        sx: {
                          "& .MuiTooltip-tooltip": {
                            fontSize: "1rem",
                            fontFamily: "Inter Variable",
                          },
                        },
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => generateInvoice(abonnement.abonnementId)}
                        sx={{
                          background: "#0042ff",
                          color: "white",
                          fontSize: {
                            lg: "14px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                          width: "60%",
                          fontFamily: "Inter Variable",
                          fontWeight: "500",
                          letterSpacing: "-0.25px",
                          textTransform: "none",
                          mr: 2,
                          borderRadius: "10px",
                          border: "1px solid #276CFA",
                          outline: "1px solid #0646C6",
                          "&:hover": {
                            backgroundColor: "#1459e2",
                            boxShadow: "none",
                          },
                        }}
                      >
                        {t("abonnement.facture")}
                        <ReceiptIcon sx={{ fontSize: "20px", ml: "3px" }} />
                      </Button>
                    </Tooltip>
                    {abonnement.status === "active" ||
                    abonnement.status === "trialing" ? (
                      <Tooltip
                        title={t("abonnement.annulerAbonnement")}
                        enterDelay={500}
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              fontSize: "1rem",
                              fontFamily: "Inter Variable",
                            },
                          },
                        }}
                      >
                        <Button
                          onClick={() =>
                            requestAnnulation(abonnement.abonnementId)
                          }
                          color="secondary"
                          sx={{
                            bgcolor: "#F3F4F6",
                            color: "#303030",
                            fontSize: {
                              lg: "14px",
                              md: "14px",
                              sm: "12px",
                              xs: "12px",
                            },
                            width: "60%",
                            mt: "3%",
                            fontFamily: "Inter Variable",
                            fontWeight: "500",
                            letterSpacing: "-0.25px",
                            textTransform: "none",
                            mr: 2,
                            boxShadow: "none",
                            borderRadius: "10px",
                            border: "1px solid #ECEFF2",
                            outline: "1px solid #303030",
                            "&:hover": {
                              bgcolor: "#e2e2e2",
                              color: "#303030",
                              boxShadow: "none",
                            },
                          }}
                        >
                          {t("abonnement.supprimer")}
                          <DeleteIcon sx={{ fontSize: "20px", ml: "3px" }} />
                        </Button>
                      </Tooltip>
                    ) : abonnement.status === "canceled" ? (
                      <Tooltip
                        title={t("abonnement.reouvrirAbonnement")}
                        enterDelay={500}
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              fontSize: "1rem",
                              fontFamily: "Inter Variable",
                            },
                          },
                        }}
                      >
                        <Button
                          onClick={() =>
                            handleReouvrirAbonnement(
                              abonnement.abonnementId,
                              customerId
                            )
                          }
                          color="primary"
                          sx={{
                            bgcolor: "#F3F4F6",
                            color: "#303030",
                            fontSize: {
                              lg: "14px",
                              md: "14px",
                              sm: "12px",
                              xs: "12px",
                            },
                            width: "60%",
                            mt: "3%",
                            fontFamily: "Inter Variable",
                            fontWeight: "500",
                            letterSpacing: "-0.25px",
                            textTransform: "none",
                            mr: 2,
                            boxShadow: "none",
                            borderRadius: "10px",
                            border: "1px solid #ECEFF2",
                            outline: "1px solid #303030",
                            "&:hover": {
                              bgcolor: "#e2e2e2",
                              color: "#303030",
                              boxShadow: "none",
                            },
                          }}
                        >
                          {t("abonnement.reouvrir")}
                          <ReplayIcon sx={{ fontSize: "20px", ml: "3px" }} />
                        </Button>
                      </Tooltip>
                    ) : null}
                    {(abonnement.status === "active" ||
                      abonnement.status === "trialing") &&
                      canAddSite(abonnement) && (
                        <Tooltip
                          title={t("abonnement.ajouterSite")}
                          enterDelay={500}
                          PopperProps={{
                            sx: {
                              "& .MuiTooltip-tooltip": {
                                fontSize: "1rem",
                                fontFamily: "Inter Variable",
                              },
                            },
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => ajouterSite(abonnement.abonnementId)}
                            sx={{
                              background: "#0042ff",
                              color: "white",
                              fontSize: {
                                lg: "14px",
                                md: "14px",
                                sm: "12px",
                                xs: "12px",
                              },
                              width: "60%",
                              fontFamily: "Inter Variable",
                              fontWeight: "500",
                              letterSpacing: "-0.25px",
                              textTransform: "none",
                              mr: 2,
                              mt: 2,
                              borderRadius: "10px",
                              border: "1px solid #276CFA",
                              outline: "1px solid #0646C6",
                              "&:hover": {
                                backgroundColor: "#1459e2",
                                boxShadow: "none",
                              },
                            }}
                          >
                            {t("abonnement.ajouterSite")}
                          </Button>
                        </Tooltip>
                      )}
                  </Box>
                </TableCell>
              </TableRow>
              {abonnement.sites.length > 0 && (
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse
                      in={open[abonnement.abonnementId]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box margin={3}>
                        <Table size="small" aria-label="sites">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="left"
                                sx={{
                                  fontSize: {
                                    xl: "14px",
                                    lg: "14px",
                                    md: "14px",
                                  },
                                  fontFamily: "Inter Variable",
                                  letterSpacing: "-0.5px",
                                  fontWeight: "600",
                                }}
                              >
                                {t("abonnement.siteUrl")}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontSize: {
                                    xl: "14px",
                                    lg: "14px",
                                    md: "14px",
                                  },
                                  fontFamily: "Inter Variable",
                                  letterSpacing: "-0.5px",
                                  fontWeight: "600",
                                }}
                              >
                                {t("abonnement.siteName")}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontSize: {
                                    xl: "14px",
                                    lg: "14px",
                                    md: "14px",
                                  },
                                  fontFamily: "Inter Variable",
                                  letterSpacing: "-0.5px",
                                  fontWeight: "600",
                                }}
                              >
                                {t("abonnement.statutduSite")}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontSize: {
                                    xl: "14px",
                                    lg: "14px",
                                    md: "14px",
                                  },
                                  fontFamily: "Inter Variable",
                                  letterSpacing: "-0.5px",
                                  fontWeight: "600",
                                }}
                              >
                                {t("abonnement.actions")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {abonnement.sites.map((site) => (
                              <TableRow key={site._id}>
                                <TableCell>
                                  <Box sx={{ display: "flex" }}>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: "black",
                                        marginRight: "8px",
                                        fontSize: {
                                          xl: "14px",
                                          lg: "14px",
                                          md: "14px",
                                        },
                                        fontFamily: "Inter Variable",
                                        letterSpacing: "-0.5px",
                                        fontWeight: "500",
                                        textDecoration: "none",
                                      }}
                                    >
                                      {site?.url}
                                    </Typography>
                                    <IconButton
                                      component="a"
                                      href={addHttpsPrefix(site?.url)}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      sx={{ padding: "0" }}
                                    >
                                      <OpenInNewIcon />
                                    </IconButton>
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: "black",
                                      fontSize: {
                                        xl: "14px",
                                        lg: "14px",
                                        md: "14px",
                                      },
                                      fontFamily: "Inter Variable",
                                      letterSpacing: "-0.5px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {site.nom}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <StatutSite site={site} />
                                </TableCell>
                                <TableCell align="center">
                                  {(abonnement.status === "active" ||
                                    abonnement.status === "trialing") &&
                                    site.etat === "actif" && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Tooltip
                                          title={
                                            abonnement.priceId ===
                                              LITE_PLAN.priceId &&
                                            site.nombreReroll >= 2
                                              ? t(
                                                  "abonnement.rerollDisabledTooltip"
                                                )
                                              : t("abonnement.rerollTooltip")
                                          }
                                          enterDelay={500}
                                          PopperProps={{
                                            sx: {
                                              "& .MuiTooltip-tooltip": {
                                                fontSize: "1rem",
                                                fontFamily: "Inter Variable",
                                              },
                                            },
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: "50%",
                                            }}
                                          >
                                            <Button
                                              variant="contained"
                                              onClick={() =>
                                                rerollSite(
                                                  abonnement.abonnementId,
                                                  site.nom
                                                )
                                              }
                                              disabled={
                                                abonnement.priceId ===
                                                  LITE_PLAN.priceId &&
                                                site.nombreReroll >= 2
                                              }
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "100%",
                                                background: "#0042ff",
                                                color: "white",
                                                fontSize: {
                                                  lg: "14px",
                                                  md: "14px",
                                                  sm: "12px",
                                                  xs: "12px",
                                                },
                                                mt: 2,
                                                fontFamily: "Inter Variable",
                                                fontWeight: "500",
                                                letterSpacing: "-0.25px",
                                                textTransform: "none",
                                                borderRadius: "10px",
                                                border: "1px solid #276CFA",
                                                outline: "1px solid #0646C6",
                                                "&:hover": {
                                                  backgroundColor: "#1459e2",
                                                  boxShadow: "none",
                                                },
                                              }}
                                            >
                                              {t("abonnement.reroll")}
                                              <ReplayIcon
                                                sx={{
                                                  fontSize: "20px",
                                                  ml: "3px",
                                                }}
                                              />
                                            </Button>
                                          </span>
                                        </Tooltip>
                                        {isPremiumPlan(abonnement.priceId) && (
                                          <Tooltip
                                            title={t(
                                              "abonnement.supprimerSite"
                                            )}
                                            enterDelay={500}
                                            PopperProps={{
                                              sx: {
                                                "& .MuiTooltip-tooltip": {
                                                  fontSize: "1rem",
                                                  fontFamily: "Inter Variable",
                                                },
                                              },
                                            }}
                                          >
                                            <Button
                                              variant="contained"
                                              onClick={() =>
                                                supprimerSite(site.nom)
                                              }
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "50%",
                                                background: "#878CA7",
                                                color: "white",
                                                mt: 2,
                                                mb: 2,
                                                fontSize: {
                                                  lg: "14px",
                                                  md: "14px",
                                                  sm: "12px",
                                                  xs: "12px",
                                                },
                                                fontFamily: "Inter Variable",
                                                fontWeight: "500",
                                                letterSpacing: "-0.25px",
                                                textTransform: "none",
                                                borderRadius: "10px",
                                                border: "1px solid #878CA7",
                                                outline: "1px solid #878CA7",
                                                "&:hover": {
                                                  backgroundColor: "#6c6e80",
                                                  boxShadow: "none",
                                                },
                                              }}
                                            >
                                              {t("abonnement.supprimerSite")}
                                              <DeleteIcon
                                                sx={{
                                                  fontSize: "20px",
                                                  ml: "3px",
                                                }}
                                              />
                                            </Button>
                                          </Tooltip>
                                        )}
                                      </Box>
                                    )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AbonnementTable;
