import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { EditNote } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNew from "@mui/icons-material/ArrowBack";
import "@fontsource-variable/inter";
import { useTranslation } from "react-i18next";

const HeaderVisualBuilder = ({
  onUpdate,
  handleDrawerToggle,
  disableSubmitButton,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/informations");
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 2,
        bgcolor: "white",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        mt: { sm: "1%", lg: "0%" },
      }}
    >
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        size="large"
        sx={{ display: { xl: "none" } }}
      >
        <EditNote />
      </IconButton>
      <IconButton
        onClick={handleBack}
        color="inherit"
        aria-label="back to information"
        edge="start"
        sx={{ ml: { xs: 0, md: 1 } }}
      >
        <ArrowBackIosNew />
      </IconButton>

      <Box sx={{ display: "flex", marginLeft: "auto", alignItems: "center" }}>
        <Tooltip
          title={t("tooltip.updatePreview")}
          arrow
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                fontSize: "1rem",
                fontFamily: "Inter Variable",
              },
            },
          }}
        >
          <IconButton sx={{ marginRight: "6px" }}>
            <InfoIcon sx={{ color: "#3674f9", boxShadow: "none" }} />
          </IconButton>
        </Tooltip>
        <Button
          onClick={() => {
            if (onUpdate) {
              onUpdate();
            }
          }}
          variant="contained"
          sx={{
            background: "#1460F9",
            color: "white",
            fontSize: {
              lg: "14px",
              md: "14px",
              sm: "12px",
              xs: "12px",
            },
            fontFamily: "Inter Variable",
            fontWeight: "500",
            letterSpacing: "-0.25px",
            textTransform: "none",
            mr: { lg: "6px", md: "0", sm: "0", xs: "0" },
            borderRadius: "10px",
            border: "1px solid #276CFA",
            outline: "1px solid #0646C6",
            "&:hover": {
              backgroundColor: "#1459e2",
              boxShadow: "none",
            },
          }}
        >
          {t("button.update")}
        </Button>
        {disableSubmitButton ? (
          <Tooltip
            title={t("tooltip.submitDisabled")}
            arrow
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  fontSize: "1rem",
                  fontFamily: "Inter Variable",
                },
              },
            }}
          >
            <span>
              <Button
                onClick={handleSubmit}
                variant="contained"
                disabled={disableSubmitButton}
                sx={{
                  background: "#1460F9",
                  color: "white",
                  fontSize: {
                    lg: "14px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  fontFamily: "Inter Variable",
                  fontWeight: "500",
                  letterSpacing: "-0.25px",
                  textTransform: "none",
                  mr: { lg: "6px", md: "0", sm: "0", xs: "0" },
                  borderRadius: "10px",
                  border: "1px solid #276CFA",
                  outline: "1px solid #0646C6",
                  "&:hover": {
                    backgroundColor: "#1459e2",
                    boxShadow: "none",
                  },
                }}
              >
                {t("button.submit")}
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              background: "#1460F9",
              color: "white",
              fontSize: {
                lg: "14px",
                md: "14px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              mr: { lg: "6px", md: "0", sm: "0", xs: "0" },
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
            }}
          >
            {t("button.submit")}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default HeaderVisualBuilder;
