import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

const HelpIconButton = ({ helpText }) => {
  return (
    <Tooltip
      title={helpText}
      arrow
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "1rem",
            fontFamily: "Inter Variable",
            boxShadow: "none",
            borderRadius: "10px",
            letterSpacing: "-0.25px",
            textAlign: "center",
            padding: "3%",
          },
        },
      }}
    >
      <IconButton>
        <InfoIcon sx={{ color: "#3674f9", height: "20px" }} />
      </IconButton>
    </Tooltip>
  );
};

export default HelpIconButton;
