import React, { useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";

const ProduitCard = ({ product, handleEdit, handleDelete }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [variationAnchorEl, setVariationAnchorEl] = useState({});

  const originalPrice = parseFloat(product.price);
  const salePrice = parseFloat(product.sale_price);
  const hasDiscount =
    !isNaN(originalPrice) && !isNaN(salePrice) && salePrice < originalPrice;

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuAction = (action, item = null) => {
    if (action === "edit") handleEdit(item || product);
    if (action === "delete") handleDelete(item || product);
    handleMenuClose();
  };

  const handleVariationMenuClick = (event, variationId) => {
    setVariationAnchorEl((prev) => ({
      ...prev,
      [variationId]: event.currentTarget,
    }));
  };

  const handleVariationMenuClose = (variationId) => {
    setVariationAnchorEl((prev) => ({ ...prev, [variationId]: null }));
  };

  return (
    <Card
      sx={{
        marginBottom: 2,
        boxShadow: 3,
        borderColor: "grey.300",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "16px",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontSize: "20px",
              fontFamily: "Inter Variable",
              fontWeight: "600",
              letterSpacing: "-0.5px",
            }}
          >
            {product.name}
          </Typography>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleMenuAction("edit")}>
              {t("produits.edit")}
            </MenuItem>
            <MenuItem onClick={() => handleMenuAction("delete")}>
              {t("produits.delete")}
            </MenuItem>
            {!hasDiscount && (
              <MenuItem onClick={() => handleMenuAction("add-discount")}>
                {t("produits.add_discount")}
              </MenuItem>
            )}
            {hasDiscount && (
              <MenuItem onClick={() => handleMenuAction("remove-discount")}>
                {t("produits.remove_discount")}
              </MenuItem>
            )}
          </Menu>
        </Box>
        <Box
          component="img"
          sx={{
            width: "100px",
            height: "100px",
            objectFit: "cover",
            mb: "1rem",
          }}
          src={product.images[0]?.src}
          alt={product.name}
        />
        <Box sx={{ marginBottom: 1 }}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "Inter Variable",
              fontWeight: "600",
              letterSpacing: "-0.5px",
              fontSize: "16px",
            }}
          >
            {t("produits.price")}
          </Typography>
          <Typography
            sx={{
              marginBottom: 1,
              fontWeight: "500",
              fontFamily: "Inter Variable",
              letterSpacing: "-0.5px",
              fontSize: "16px",
            }}
          >
            {hasDiscount ? (
              <>
                <span
                  style={{ textDecoration: "line-through", marginRight: "8px" }}
                >
                  {originalPrice.toFixed(2)}€
                </span>
                <span style={{ color: "red" }}>{salePrice.toFixed(2)}€</span>
              </>
            ) : (
              `${originalPrice.toFixed(2)}€`
            )}
          </Typography>
        </Box>
        <Box sx={{ marginBottom: 1 }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "600",
              fontFamily: "Inter Variable",
              letterSpacing: "-0.5px",
              fontSize: "16px",
            }}
          >
            {t("produits.description")}
          </Typography>
          <div style={{ maxWidth: "300px", wordWrap: "break-word" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Inter Variable",
                letterSpacing: "-0.5px",
                fontWeight: "400",
              }}
            >
              {product.description || "N/A"}
            </Typography>
          </div>
        </Box>
        {product.variations.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        )}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Typography variant="h6" gutterBottom component="div">
              {t("produits.variations")}
            </Typography>
            <Table size="small" aria-label="variations">
              <TableHead>
                <TableRow>
                  <TableCell>{t("produits.attribute")}</TableCell>
                  <TableCell>{t("produits.option")}</TableCell>
                  <TableCell align="right">{t("produits.price")}</TableCell>
                  <TableCell align="center">{t("produits.actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {product.variations.map((variation) => {
                  const variationOriginalPrice = parseFloat(
                    variation.regular_price
                  );
                  const variationSalePrice = parseFloat(variation.sale_price);
                  const variationHasDiscount =
                    !isNaN(variationOriginalPrice) &&
                    !isNaN(variationSalePrice) &&
                    variationSalePrice < variationOriginalPrice;
                  return (
                    <TableRow key={variation.id}>
                      <TableCell>
                        {variation.attributes.map((attr) => (
                          <div key={attr.id}>{attr.name}</div>
                        ))}
                      </TableCell>
                      <TableCell>
                        {variation.attributes.map((attr) => (
                          <div key={attr.id}>{attr.option}</div>
                        ))}
                      </TableCell>
                      <TableCell align="right">
                        {variationHasDiscount ? (
                          <>
                            <span
                              style={{
                                textDecoration: "line-through",
                                marginRight: "8px",
                              }}
                            >
                              {variationOriginalPrice.toFixed(2)}€
                            </span>
                            <span style={{ color: "red" }}>
                              {variationSalePrice.toFixed(2)}€
                            </span>
                          </>
                        ) : (
                          `${variationOriginalPrice.toFixed(2)}€`
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="more"
                          aria-controls={`variation-menu-${variation.id}`}
                          aria-haspopup="true"
                          onClick={(event) =>
                            handleVariationMenuClick(event, variation.id)
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id={`variation-menu-${variation.id}`}
                          anchorEl={variationAnchorEl[variation.id] || null}
                          keepMounted
                          open={Boolean(variationAnchorEl[variation.id])}
                          onClose={() => handleVariationMenuClose(variation.id)}
                        >
                          <MenuItem
                            onClick={() => handleMenuAction("edit", variation)}
                          >
                            {t("produits.modify")}
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleMenuAction("delete", variation)
                            }
                          >
                            {t("produits.delete")}
                          </MenuItem>
                          {!variationHasDiscount && (
                            <MenuItem
                              onClick={() =>
                                handleMenuAction("add-discount", variation)
                              }
                            >
                              {t("produits.add_discount")}
                            </MenuItem>
                          )}
                          {variationHasDiscount && (
                            <MenuItem
                              onClick={() =>
                                handleMenuAction("remove-discount", variation)
                              }
                            >
                              {t("produits.remove_discount")}
                            </MenuItem>
                          )}
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default ProduitCard;
