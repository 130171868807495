function formatAddress(shipping, translate) {
  const parts = [
    shipping.first_name,
    shipping.last_name,
    shipping.address_1,
    shipping.address_2,
    shipping.city,
    shipping.state,
    shipping.postcode,
    shipping.country,
  ]
    .filter((part) => part)
    .join(", ");

  return parts || translate("address.notSpecified");
}

export default formatAddress;
