import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import ToastError from "../../shared/toast/ToastError";

const FaviconForm = ({ open, onClose, siteConsulte, onFaviconUpdated }) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleImageUpload = (acceptedFiles) => {
    const validMimeTypes = [
      "image/x-icon",
      "image/png",
      "image/gif",
      "image/jpeg",
    ];
    const file = acceptedFiles[0];

    if (file && validMimeTypes.includes(file.type)) {
      setSelectedFile(file);
    } else {
      handleError(t("favicon.upload_error"));
    }
  };

  const handleRemoveImage = (event) => {
    event.stopPropagation();
    setSelectedFile(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleImageUpload,
    accept: {
      "image/x-icon": [],
      "image/png": [],
      "image/gif": [],
      "image/jpeg": [],
      "image/heif": [],
      "image/heic": [],
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      handleError(t("favicon.no_file_selected"));
      return;
    }

    const formData = new FormData();
    formData.append("siteNom", siteConsulte.nom);
    formData.append("imageFavicon", selectedFile);

    try {
      setLoading(true);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/site/update-favicon`,
        formData,
        config
      );

      if (onFaviconUpdated) {
        onFaviconUpdated(response.data);
      }
      onClose();
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      handleError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          position: "fixed",
          top: "30%",
          left: "50%",
          transform: "translate(-50%, -20%)",
          borderRadius: "20px",
          padding: "1%",
        },
      }}
    >
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              sx={{
                fontSize: { lg: "24px", md: "18px", sm: "14px", xs: "12px" },
                color: "black",
                fontFamily: "Inter Variable, sans-serif",
                fontWeight: "500",
                letterSpacing: "-0.75px",
                textAlign: "center",
                mt: "1%",
              }}
            >
              {t("favicon.modifyFavicon")}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            {...getRootProps()}
            sx={{
              border: "2px dashed #9a9b9f",
              borderRadius: "16px",
              padding: "20px",
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              height: "250px",
              mt: 2,
              mb: 2,
              width: "100%",
              backgroundColor: "#fcfcfc",
            }}
          >
            <input {...getInputProps()} />
            {selectedFile ? (
              <Box sx={{ position: "relative", display: "inline-block" }}>
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt="Preview"
                  style={{
                    width: "120px",
                    height: "120px",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
                <IconButton
                  onClick={handleRemoveImage}
                  sx={{
                    position: "absolute",
                    top: -10,
                    right: -10,
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  <DeleteIcon sx={{ color: "red" }} />
                </IconButton>
              </Box>
            ) : (
              <Typography
                sx={{
                  fontFamily: "Inter Variable",
                  fontWeight: 500,
                  letterSpacing: "-0.25px",
                  fontSize: { xs: "16px", md: "20px" },
                  textAlign: "center",
                  color: "#43444c",
                }}
              >
                {t("favicon.drop_or_click_upload")}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "right" }}>
          <Button
            onClick={onClose}
            sx={{
              bgcolor: "#F3F4F6",
              color: "#303030",
              fontSize: {
                lg: "14px",
                md: "14px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              mr: 1,
              pl: "3%",
              pr: "3%",
              boxShadow: "none",
              borderRadius: "10px",
              border: "1px solid #ECEFF2",
              outline: "1px solid #303030",
              "&:hover": {
                bgcolor: "#e2e2e2",
                color: "#303030",
                boxShadow: "none",
              },
            }}
          >
            {t("favicon.cancel")}
          </Button>
          <LoadingButton
            type="submit"
            loading={loading}
            variant="contained"
            color="primary"
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: {
                lg: "14px",
                md: "14px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              mr: 2,
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
            }}
          >
            {t("button.update")}
          </LoadingButton>
        </DialogActions>
      </Box>
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </Dialog>
  );
};

export default FaviconForm;
