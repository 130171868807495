import React, { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import "@fontsource-variable/inter";
import HeaderVisualBuilder from "./HeaderVisualBuilder";
import Sidebar from "../SideBar";
import AttributeEditor from "../AttributeEditor";
import PreviewSite from "../PreviewSite";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { v4 as uuidv4 } from "uuid";
import { API_SERVER_ENDPOINT } from "../../../shared/api/ConstantesEndpoints";
import { AuthContext } from "../../authentification/Authentification";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SuccessToast from "../../../shared/toast/ToastSuccess";
import Loader from "../../../shared/loader/Loader";
import ToastError from "../../../shared/toast/ToastError";

const VisualBuilder = ({
  urlPreview,
  postId,
  templateId,
  abonnementId,
  siteNom,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [modulesBdd, setModulesBdd] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedInstanceId, setSelectedInstanceId] = useState(null);
  const [instancesData, setInstancesData] = useState([]);
  const [attributeModifications, setAttributeModifications] = useState({});

  const [refreshKey, setRefreshKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingGlobal, setLoadingGlobal] = useState(false);

  const [expandedAccordions, setExpandedAccordions] = useState([]);
  const [mobileEditMode, setMobileEditMode] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xl"));

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [instanceDataAvantModif, setInstanceDataAvantModif] = useState(null);
  const [error, setError] = useState(null);

  const { userAuthenticated } = useContext(AuthContext);
  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const [customerId] = useState(profil?.customerId);

  const [openToastSuccess, setOpenToastSuccess] = useState(false);

  const handleError = useCallback((message) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 3000);
  }, []);

  const fetchModules = useCallback(async () => {
    try {
      const response = await axios.get(`${API_SERVER_ENDPOINT}/modules`);
      setModulesBdd(response.data);
    } catch (error) {
      handleError(error.message);
    }
  }, [handleError]);

  const fetchTemplateClientById = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_SERVER_ENDPOINT}/template-client/${templateId}`
      );
      const template = response.data;

      const accueilPage = template.pages.find(
        (page) => page.post_id === postId
      );

      if (accueilPage) {
        setInstancesData(accueilPage.instances);
        setInstanceDataAvantModif(accueilPage.instances);
      } else {
        handleError("La page avec le post_id fourni n'a pas été trouvée.");
      }
    } catch (error) {
      handleError(error.message);
    }
  }, [handleError, templateId, postId]);

  useEffect(() => {
    fetchModules();
    fetchTemplateClientById();
  }, [urlPreview, postId, templateId, fetchModules, fetchTemplateClientById]);

  const updatePage = async (payload) => {
    try {
      await axios.post(
        `${API_SERVER_ENDPOINT}/template-client/update`,
        payload
      );
      return true;
    } catch (error) {
      handleError(error.message);
      return false;
    }
  };

  const getInstancesWithModifiedValues = (
    instancesData,
    attributeModifications
  ) => {
    return instancesData.map((instance) => ({
      moduleId: instance.moduleId,
      section: instance.section,
      elements: instance.elements.map((element) => ({
        nom: element.nom,
        attributes: element.attributes.map((attr) => {
          const attrKey = `${instance.moduleId}_${instance._id}_${element.nom}_${attr.nom}`;
          return {
            nom: attr.nom,
            valeurDefaut: attributeModifications[attrKey] ?? attr.valeurDefaut,
            _id: attr._id,
          };
        }),
        _id: element._id,
      })),
      _id: instance._id,
    }));
  };

  const updatePageSelectionne = async () => {
    setLoading(true);

    const instancesWithModifiedValues = getInstancesWithModifiedValues(
      instancesData,
      attributeModifications
    );

    const payload = {
      templateId: templateId,
      post_id: postId,
      instances: instancesWithModifiedValues,
    };

    const updateSuccess = await updatePage(payload);

    if (updateSuccess) {
      setDisableSubmitButton(false);
      refreshIframe();
    }
  };

  const onModuleAdd = (moduleId) => {
    const moduleToAdd = modulesBdd.find((m) => m.moduleId === moduleId);
    if (moduleToAdd) {
      const sectionMapping = {
        header: "header",
        enTete: "page",
        produits: "page",
        blocTexte: "page",
        reassurances: "page",
        autres: "page",
        footer: "footer",
      };

      const newInstance = {
        _id: uuidv4(),
        moduleId: moduleId,
        section: sectionMapping[moduleToAdd.type] || "page",
        elements: moduleToAdd.elements.map((element) => ({
          nom: element.nom,
          attributes: element.attributes.map((attr) => ({
            nom: attr.nom,
            valeurDefaut: attr.valeurBase,
          })),
        })),
      };

      setInstancesData((prev) => [...prev, newInstance]);
    }
  };

  const onModuleRemove = async (instanceId) => {
    const updatedInstances = instancesData.filter(
      (instance) => instance._id !== instanceId
    );

    setInstancesData(updatedInstances);
    resetModuleSelection();
  };

  const resetModuleSelection = () => {
    setSelectedModule(null);
  };

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    // Ne rien faire si l'élément est déposé en dehors d'une zone valide
    if (!destination) {
      return;
    }

    // Ne rien faire si l'élément est déposé à la même position
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    ) {
      return;
    }

    // Gérer le déplacement uniquement pour la section "page"
    if (
      source.droppableId === "pageList" &&
      destination.droppableId === "pageList"
    ) {
      const pageInstances = instancesData.filter(
        (instance) => instance.section === "page"
      );
      const otherInstances = instancesData.filter(
        (instance) => instance.section !== "page"
      );

      const [movedItem] = pageInstances.splice(source.index, 1);
      pageInstances.splice(destination.index, 0, movedItem);

      const newInstances = [...otherInstances, ...pageInstances];

      setInstancesData(newInstances);
    }
  };

  const handleElementClick = (moduleId, instanceId, elementNom) => {
    const moduleInstance = modulesBdd.find((m) => m.moduleId === moduleId);
    if (moduleInstance) {
      const element = moduleInstance.elements.find(
        (el) => el.nom === elementNom
      );
      if (element) {
        const moduleInstance = instancesData.find(
          (instance) =>
            instance.moduleId === moduleId && instance._id === instanceId
        );

        const templateElement = moduleInstance?.elements.find(
          (el) => el.nom === elementNom
        );

        const elementAttributes = element.attributes.map((attr) => {
          const templateAttribute = templateElement?.attributes.find(
            (a) => a.nom === attr.nom
          );

          return {
            ...attr,
            valeur:
              attributeModifications[
                `${moduleId}_${instanceId}_${elementNom}_${attr.nom}`
              ] ??
              templateAttribute?.valeurDefaut ??
              attr.valeur,
          };
        });

        setSelectedInstanceId(instanceId);
        setSelectedModule({
          ...moduleInstance,
          selectedElement: { ...element, attributes: elementAttributes },
        });
      }
    }
  };

  const updateAttributeModifications = useCallback(
    (moduleId, instanceId, elementNom, attributeName, newValue) => {
      const key = `${moduleId}_${instanceId}_${elementNom}_${attributeName}`;
      setAttributeModifications((prev) => ({
        ...prev,
        [key]: newValue,
      }));
    },
    []
  );

  const refreshIframe = () => {
    setRefreshKey((prev) => prev + 1);
  };

  const handleAccordionChange = (instanceId) => (event, isExpanded) => {
    setExpandedAccordions((prevExpandedAccordions) => {
      if (isExpanded) {
        return [...prevExpandedAccordions, instanceId];
      } else {
        return prevExpandedAccordions.filter((id) => id !== instanceId);
      }
    });
  };

  const handleDrawerToggle = () => {
    setMobileEditMode(!mobileEditMode);
  };

  const handleSubmit = async (_) => {
    if (userAuthenticated) {
      if (siteNom) {
        rerollSite();
      } else if (abonnementId) {
        await verifyRightsAndRedirect();
      } else {
        sessionStorage.setItem("authorizedForPayment", "true");
        navigate("/paiement");
      }
    } else {
      navigate("/connexion-compte", { state: { pageAppelante: "/preview" } });
    }
  };

  const verifyRightsAndRedirect = async () => {
    const authorizedForAjouterSite = sessionStorage.getItem(
      "authorizedForAjouterSite"
    );

    if (authorizedForAjouterSite) {
      try {
        const response = await axios.get(
          `${API_SERVER_ENDPOINT}/utilisateur/abonnement/verify-rights`,
          {
            params: { abonnementId },
          }
        );

        const { canAddMoreSites } = response.data;

        if (canAddMoreSites) {
          const templateId = sessionStorage.getItem("templateId");
          if (!templateId) {
            handleError(t("visualBuilder.ajoutError"));
            return;
          }

          const nomSite = sessionStorage.getItem("nomSite");
          if (!nomSite) {
            handleError(t("visualBuilder.ajoutError"));
            return;
          }

          const isBoutique = sessionStorage.getItem("boutique");
          if (isBoutique === null) {
            handleError(t("visualBuilder.ajoutError"));
            return;
          }

          const encodedSubscriptionId = encodeURIComponent(abonnementId);
          const encodedTemplateId = encodeURIComponent(templateId);
          const encodedIsBoutique = encodeURIComponent(isBoutique);

          sessionStorage.setItem("fromDemandeInstallation", "true");
          sessionStorage.removeItem("authorizedForAjouterSite");

          navigate(
            `/confirmation-installation?subscriptionId=${encodedSubscriptionId}&templateId=${encodedTemplateId}&isBoutique=${encodedIsBoutique}`
          );
        } else {
          handleError(t("abonnement.noMoreSitesAllowed"));
        }
      } catch (error) {
        handleError(t("abonnement.noMoreSitesAllowed"));
      }
    } else {
      handleError(t("visualBuilder.ajoutError"));
    }
  };

  const rerollSite = async () => {
    setLoadingGlobal(true);

    const authorizedForReroll = sessionStorage.getItem("authorizedForReroll");

    if (authorizedForReroll) {
      try {
        await axios.post(`${API_SERVER_ENDPOINT}/reroll-site`, {
          templateId,
          siteNom,
          customerId,
          abonnementId,
        });

        sessionStorage.removeItem("authorizedForReroll");
        sessionStorage.removeItem("accessToPreview");
        sessionStorage.removeItem("urlPreview");
        sessionStorage.removeItem("postId");
        sessionStorage.removeItem("templateId");

        handleOpenToastSuccess();

        setTimeout(() => {
          navigate("/gestion-compte");
        }, 3000);
      } catch (error) {
        handleError(error.message);
        return false;
      } finally {
        setLoadingGlobal(false);
      }
    } else {
      handleError(t("visualBuilder.reinitError"));
    }
  };

  useEffect(() => {
    const hasAttributeModifications =
      Object.keys(attributeModifications).length > 0;

    const hasInstanceDataChanges =
      JSON.stringify(instancesData) !== JSON.stringify(instanceDataAvantModif);

    setDisableSubmitButton(hasAttributeModifications || hasInstanceDataChanges);
  }, [attributeModifications, instancesData, instanceDataAvantModif]);

  const handleOpenToastSuccess = () => {
    setOpenToastSuccess(true);
  };

  const handleCloseToastSuccess = () => {
    setOpenToastSuccess(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: {
          xs: "calc(100vh - 56px)",
          md: `calc(100vh - 64px)`,
          lg: "100vh",
        },
      }}
    >
      <HeaderVisualBuilder
        onUpdate={() => {
          updatePageSelectionne();
          refreshIframe();
        }}
        handleDrawerToggle={handleDrawerToggle}
        disableSubmitButton={disableSubmitButton}
        handleSubmit={handleSubmit}
        sx={{ height: `64px` }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: {
            xs: "calc(100vh - 56px)",
            md: `calc(100vh - 64px)`,
          },
          overflow: "hidden",
        }}
      >
        {!isMobile && (
          <>
            {!selectedModule ? (
              <Sidebar
                instancesData={instancesData}
                modulesBdd={modulesBdd}
                onElementClick={handleElementClick}
                onModuleAdd={onModuleAdd}
                onModuleRemove={onModuleRemove}
                onDragEnd={handleDragEnd}
                expandedAccordions={expandedAccordions}
                onAccordionChange={handleAccordionChange}
                sx={{
                  width: { xs: "100%", xl: "20%" },
                  overflowY: "auto",
                  height: "100%",
                }}
              />
            ) : (
              <AttributeEditor
                selectedModule={selectedModule}
                instancesData={instancesData}
                instanceId={selectedInstanceId}
                attributeModifications={attributeModifications}
                onBack={resetModuleSelection}
                onUpdateAttributes={updateAttributeModifications}
                sx={{
                  width: { xs: "95%", xl: "20%" },
                  overflowY: "auto",
                  height: "100%",
                }}
              />
            )}
          </>
        )}

        {isMobile && (
          <Drawer
            variant="temporary"
            open={mobileEditMode}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{
              "& .MuiDrawer-paper": { width: { xs: "80%", sm: "50%" } },
            }}
          >
            {!selectedModule ? (
              <Sidebar
                instancesData={instancesData}
                modulesBdd={modulesBdd}
                onElementClick={handleElementClick}
                onModuleAdd={onModuleAdd}
                onModuleRemove={onModuleRemove}
                onDragEnd={handleDragEnd}
                expandedAccordions={expandedAccordions}
                onAccordionChange={handleAccordionChange}
                sx={{
                  width: "100%",
                  overflowY: "auto",
                }}
              />
            ) : (
              <AttributeEditor
                selectedModule={selectedModule}
                instancesData={instancesData}
                instanceId={selectedInstanceId}
                attributeModifications={attributeModifications}
                onBack={resetModuleSelection}
                onUpdateAttributes={updateAttributeModifications}
                templateId={templateId}
                sx={{
                  width: "100%",
                  overflowY: "auto",
                }}
              />
            )}
          </Drawer>
        )}

        <PreviewSite
          iframeURL={urlPreview}
          refresh={refreshKey}
          loading={loading}
          setLoading={setLoading}
          sx={{
            flex: 1,
            overflowY: "auto",
            width: isMobile ? "0%" : "auto",
          }}
        />
      </Box>
      <SuccessToast
        open={openToastSuccess}
        onClose={handleCloseToastSuccess}
        message="Reinitialisation du site effectuée avec succès. Redirection en cours..."
      />
      <Loader loading={loadingGlobal}></Loader>
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </Box>
  );
};

export default VisualBuilder;
