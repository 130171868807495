import React, { useState, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import { useTranslation } from "react-i18next";
import ToastError from "../../shared/toast/ToastError";

const AddPageDialog = ({
  open,
  onClose,
  fetchPages,
  siteNom,
  onAddPageSuccess,
}) => {
  const { t } = useTranslation();
  const [pageTitle, setPageTitle] = useState("");
  const [instances, setInstances] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleError = useCallback((message) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 3000);
  }, []);

  const handleAdd = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_SERVER_ENDPOINT}/page`, {
        siteNom,
        pageTitle,
        instances,
      });
      fetchPages();
      onAddPageSuccess(response.data.page);
      onClose();
    } catch (error) {
      handleError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        sx: {
          borderRadius: "20px",
          width: "90%",
          maxWidth: "600px",
          height: "auto",
          maxHeight: "90vh",
          backgroundColor: "#f9f9f9",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: { lg: "20px", md: "16px", sm: "14px", xs: "12px" },
            color: "black",
            fontFamily: "Space Grotesk",
            fontWeight: "700",
            letterSpacing: "-1px",
          }}
        >
          {t("addPageDialog.title")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="pageTitle"
          label={t("addPageDialog.pageTitleLabel")}
          type="text"
          fullWidth
          variant="outlined"
          value={pageTitle}
          onChange={(e) => setPageTitle(e.target.value)}
          InputProps={{
            sx: {
              mb: "3%",
              borderRadius: "16px",
              backgroundColor: "#ECEDF5",
              fontFamily: "Inter Variable",
              fontWeight: "700",
              letterSpacing: "-1px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              input: {
                color: "text.primary",
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", width: "50%" }}>
        <Button
          onClick={onClose}
          sx={{
            color: "white",
            fontSize: {
              xl: "20px",
              lg: "20px",
              md: "18px",
              sm: "16px",
              xs: "14px",
            },
            px: "6%",
            mt: "5%",
            ml: "2%",
            fontFamily: "Space Grotesk",
            fontWeight: "700",
            background: "#878ca7",
            letterSpacing: "-1px",
            textTransform: "none",
            boxShadow: "none",
            borderRadius: "10px",
            "&:hover": {
              background: "#5e6172",
              boxShadow: "none",
              color: "white",
            },
          }}
        >
          {t("addPageDialog.cancelButton")}
        </Button>
        <LoadingButton
          onClick={handleAdd}
          loading={loading}
          variant="contained"
          color="primary"
          sx={{
            mt: "5%",
            ml: "2%",
            px: "6%",
            fontFamily: "Space Grotesk",
            fontWeight: "700",
            background: "#0042ff",
            color: "white",
            fontSize: {
              xl: "20px",
              lg: "20px",
              md: "18px",
              sm: "16px",
              xs: "14px",
            },
            textTransform: "none",
            letterSpacing: "-1px",
            boxShadow: "none",
            borderRadius: "10px",
            "&:hover": {
              background: "#0033c1",
              boxShadow: "none",
              color: "white",
            },
          }}
        >
          {t("addPageDialog.addButton")}
        </LoadingButton>
      </DialogActions>
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </Dialog>
  );
};

export default AddPageDialog;
