import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Collapse,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FormulairePaiement from "../../components/paiement/FormulairePaiement";
import { useTranslation } from "react-i18next";
import sendToAnalytics from "../../analytics/analytics";
import LoadingScreen from "../../shared/loader/LoadingScreen";

const PaiementAbonnement = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loadingBackground, setLoadingBackground] = useState(true);

  const secteurActivite = JSON.parse(sessionStorage.getItem("secteurActivite"));
  const boutique = sessionStorage.getItem("boutique");
  const nomSite = sessionStorage.getItem("nomSite");
  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const theme = useTheme();
  const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    sendToAnalytics({
      id: "paiement-abonnement-page",
      name: "paiement_abonnement_page_visit",
      value: 1,
    });
  }, []);

  const handleBackgroundLoad = () => {
    setLoadingBackground(false);
  };

  return (
    <Box sx={{ height: "100vh", overflowX: "hidden" }}>
      {loadingBackground && <LoadingScreen />}
      <div style={{ display: loadingBackground ? "none" : "block" }}>
        <img
          src={process.env.PUBLIC_URL + "/background/one-site-bg.png"}
          alt="Background"
          onLoad={handleBackgroundLoad}
          style={{ display: "none" }}
        />
        <div
          className="row"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/background/one-site-bg.png"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="col-sm-6">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "sticky",
                top: 0,
                height: { xs: open ? "90vh" : "15vh", sm: "100vh" },
                transition: "height 0.3s ease",
                mb: { xs: open ? "5%" : "5%", sm: 0 },
              }}
            >
              <Box
                component="img"
                sx={{
                  mt: { xs: "1rem", sm: "-5rem" },
                  height: { xs: "3rem", sm: "7rem" },
                }}
                src={process.env.PUBLIC_URL + "/logo/onesite.png"}
                alt="Logo"
              />
              {isPhoneScreen ? (
                <>
                  <Button
                    variant="contained"
                    onClick={() => setOpen(!open)}
                    sx={{
                      mt: 2,
                      mb: 1,
                      backgroundColor: "#0033c1",
                      color: "white",
                      fontFamily: "Space Grotesk, sans-serif",
                      fontWeight: "bold",
                      letterSpacing: "-0.5px",
                      borderRadius: "10px",
                      border: "none",
                    }}
                  >
                    {open
                      ? t("paiementAbonnement.hideSummary")
                      : t("paiementAbonnement.showSummary")}
                  </Button>
                  <Collapse in={open}>
                    <Card
                      sx={{
                        marginTop: "1rem",
                        boxShadow: 3,
                        paddingBottom: 2,
                        paddingTop: 2,
                        borderRadius: "16px",
                        width: "80%",
                        marginBottom: "10%",
                        mx: "auto",
                        backgroundColor: "rgba(0, 66, 255, 0.2)",
                        border: "1px solid rgba(0, 66, 255, 0.5)",
                      }}
                    >
                      <CardContent>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: {
                              xs: "1.3rem",
                              sm: "1.5rem",
                            },
                            mb: "5%",
                            color: "white",
                            textAlign: "center",
                            fontFamily: "Space Grotesk, sans serif",
                            letterSpacing: "-1px",
                            mx: "2rem",
                          }}
                        >
                          {t("paiementAbonnement.subscriptionSummary")}
                        </Typography>

                        <Box>
                          {/* Détails de la commande */}
                          <Grid
                            container
                            spacing={1}
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Grid item xs={12} md={5}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontWeight: "bold",
                                  fontFamily: "Inter Variable",
                                  letterSpacing: "-1px",
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  color: "white",
                                }}
                              >
                                {t("paiementAbonnement.clientName")} :{" "}
                                {profil.customerName}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} md={5}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontWeight: "bold",
                                  fontFamily: "Inter Variable",
                                  letterSpacing: "-1px",
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  color: "white",
                                }}
                              >
                                {t("paiementAbonnement.sector")} :{" "}
                                {secteurActivite.label}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} md={5}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontWeight: "bold",
                                  fontFamily: "Inter Variable",
                                  letterSpacing: "-1px",
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  color: "white",
                                }}
                              >
                                {t("paiementAbonnement.siteName")} : {nomSite}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} md={5}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontWeight: "bold",
                                  fontFamily: "Inter Variable",
                                  letterSpacing: "-1px",
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  color: "white",
                                }}
                              >
                                {t("paiementAbonnement.shop")} :{" "}
                                {boutique ? "Oui" : "Non"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          sx={{
                            textAlign: "center",
                            mt: "5%",
                            mx: { xs: "1rem", md: "3rem" },
                            pb: "1rem",
                          }}
                        >
                          <Typography
                            paragraph
                            sx={{
                              fontSize: "1rem",
                              fontFamily: "Inter Variable",
                              letterSpacing: "-0.5px",
                              textAlign: "center",
                              fontWeight: "6px",
                              color: "white",
                            }}
                          >
                            {t("paiementAbonnement.noCommitment")}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Collapse>
                </>
              ) : (
                <Card
                  sx={{
                    marginTop: "1rem",
                    boxShadow: 3,
                    paddingBottom: 2,
                    paddingTop: 2,
                    borderRadius: "16px",
                    width: "80%",
                    backgroundColor: "rgba(0, 66, 255, 0.2)",
                    border: "1px solid rgba(0, 66, 255, 0.5)",
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: {
                          xs: "1.3rem",
                          sm: "1.5rem",
                        },
                        mb: "3%",
                        textAlign: "center",
                        fontFamily: "Space Grotesk, sans serif",
                        letterSpacing: "-0.5px",
                        mx: "2rem",
                        color: "white",
                      }}
                    >
                      {t("paiementAbonnement.subscriptionSummary")}
                    </Typography>

                    <Box>
                      {/* Détails de la commande */}
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Grid item xs={12} md={5}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "bold",
                              fontFamily: "Inter Variable",
                              letterSpacing: "-0.5px",
                              textAlign: "center",
                              fontSize: "1rem",
                              color: "white",
                            }}
                          >
                            {t("paiementAbonnement.clientName")} :{" "}
                            {profil.customerName}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={5}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "bold",
                              fontFamily: "Inter Variable",
                              letterSpacing: "-0.5px",
                              textAlign: "center",
                              fontSize: "1rem",
                              color: "white",
                            }}
                          >
                            {t("paiementAbonnement.sector")} :{" "}
                            {secteurActivite?.label}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={5}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "bold",
                              fontFamily: "Inter Variable",
                              letterSpacing: "-0.5px",
                              textAlign: "center",
                              fontSize: "1rem",
                              color: "white",
                            }}
                          >
                            {t("paiementAbonnement.siteName")} : {nomSite}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={5}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "bold",
                              fontFamily: "Inter Variable",
                              letterSpacing: "-1px",
                              textAlign: "center",
                              fontSize: "1rem",
                              color: "white",
                            }}
                          >
                            {t("paiementAbonnement.shop")} :{" "}
                            {boutique ? "Oui" : "Non"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box
                      sx={{
                        textAlign: "center",
                        mt: "5%",
                        mx: { xs: "1rem", md: "3rem" },
                        pb: "1rem",
                      }}
                    >
                      <Typography
                        paragraph
                        sx={{
                          fontSize: "1rem",
                          fontFamily: "Inter Variable",
                          letterSpacing: "-0.5px",
                          textAlign: "center",
                          fontWeight: "6px",
                          color: "white",
                        }}
                      >
                        {t("paiementAbonnement.noCommitment")}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Box>
          </div>
          <div className="col-sm-6" style={{ paddingLeft: "0px" }}>
            <FormulairePaiement />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default PaiementAbonnement;
