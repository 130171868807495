import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Box,
  Button,
  IconButton,
  Grow,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import { useTranslation } from "react-i18next";
import ToastError from "../toast/ToastError";
import ScrapeResult from "./ScrapeResult";

const ScrapePopup = ({
  open,
  onClose,
  onConfirm,
  importOnConfirm,
  siteConsulte,
}) => {
  const { t } = useTranslation();
  const [scrapeUrl, setScrapeUrl] = useState("");
  const [scrapedData, setScrapedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [scrapeSource, setScrapeSource] = useState("aliexpress");
  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const customerId = profil?.customerId;

  const handleScrapeUrlChange = (e) => {
    setScrapeUrl(e.target.value);
  };

  const handleScrapeSourceChange = (source) => {
    setScrapeSource(source);
    setScrapeUrl("");
  };

  const handleScrape = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/scrape-${scrapeSource}`,
        { url: scrapeUrl }
      );
      setScrapedData(response.data.productData);
    } catch (error) {
      setError(
        t(
          `error.invalid${
            scrapeSource.charAt(0).toUpperCase() + scrapeSource.slice(1)
          }Url`
        )
      );
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    if (scrapedData) {
      if (importOnConfirm) {
        await handleImport(scrapedData);
      } else {
        onConfirm(scrapedData);
      }
    }
    onClose();
  };

  const handleImport = async (productData) => {
    const importData = {
      siteNom: siteConsulte.nom,
      name: productData.title,
      price: productData.price,
      description: productData.description,
      imageUrls: [productData.image],
      customerId: customerId,
    };

    try {
      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/products`,
        importData
      );
      onConfirm(response.data);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleBack = () => {
    setScrapedData(null);
    setScrapeUrl("");
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={scrapedData ? "lg" : "md"}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "20px",
          width: "100%",
          maxWidth: scrapedData ? "900px" : "600px",
          transition: "max-width 0.3s ease-in-out",
          height: "auto",
          maxHeight: "90vh",
          backgroundColor: "#f9f9f9",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          width: "70%",
          margin: "0 auto 16px auto",
          position: "relative",
        }}
      >
        {scrapedData && (
          <IconButton
            onClick={handleBack}
            sx={{
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              color: "#0042ff",
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography
          sx={{
            color: "text.primary",
            fontSize: { lg: "20px", md: "16px", sm: "14px", xs: "12px" },
            fontWeight: "700",
            fontFamily: "Space Grotesk",
            letterSpacing: "-1px",
            mt: "3%",
          }}
        >
          {t("scrapePopup.title")}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ width: "90%", margin: "0 auto" }}>
        {!scrapedData ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                width: "100%",
              }}
            >
              <Button
                onClick={() => handleScrapeSourceChange("aliexpress")}
                sx={{
                  flex: 1,
                  width: "50px",
                  height: "50px",
                  background:
                    scrapeSource === "aliexpress" ? "#0042ff" : "#878ca7",
                  color: "white",
                  fontWeight: "700",
                  fontFamily: "Inter Variable",
                  borderRadius: "10px",
                  textTransform: "none",
                  marginRight: 1,
                  "&:hover": {
                    background:
                      scrapeSource === "aliexpress" ? "#0033c1" : "#5e6172",
                  },
                }}
              >
                <img
                  src="images/aliexpress.png"
                  alt="AliExpress"
                  style={{ width: 30, height: 30 }}
                />
              </Button>
              <Button
                onClick={() => handleScrapeSourceChange("amazon")}
                sx={{
                  flex: 1,
                  background: scrapeSource === "amazon" ? "#0042ff" : "#878ca7",
                  color: "white",
                  fontWeight: "700",
                  fontFamily: "Space Grotesk",
                  borderRadius: "10px",
                  marginLeft: 1,
                  "&:hover": {
                    background:
                      scrapeSource === "amazon" ? "#0033c1" : "#5e6172",
                  },
                }}
              >
                <img
                  src="images/amazon.png"
                  alt="Amazon"
                  style={{ width: 30, height: 30 }}
                />
              </Button>
            </Box>
            <TextField
              fullWidth
              placeholder={
                scrapeSource === "aliexpress"
                  ? t("scrapePopup.urlLabelAliExpress")
                  : t("scrapePopup.urlLabelAmazon")
              }
              value={scrapeUrl}
              onChange={handleScrapeUrlChange}
              variant="outlined"
              margin="normal"
              disabled={loading}
              InputProps={{
                sx: {
                  mb: "3%",
                  width: "100%",
                  height: "50px",
                  borderRadius: "12px",
                  backgroundColor: "white",
                  border: "1px solid #E3E3E3",
                  fontWeight: "500",
                  fontFamily: "Inter Variable",
                  fontSize: "14px",
                  padding: "0%",
                  letterSpacing: "-0.25px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  input: {
                    color: "text.primary",
                  },
                  "&::placeholder": {
                    color: "#000000",
                    opacity: 0.8,
                  },
                },
              }}
            />
            <LoadingButton
              onClick={handleScrape}
              color="primary"
              variant="contained"
              loading={loading}
              disabled={!scrapeUrl}
              sx={{
                mt: "5%",
                background: "#0042ff",
                width: "100%",
                color: "white",
                fontSize: {
                  lg: "16px",
                  md: "16px",
                  sm: "12px",
                  xs: "12px",
                },
                fontFamily: "Inter Variable",
                fontWeight: "500",
                letterSpacing: "-0.25px",
                textTransform: "none",
                mr: 2,
                borderRadius: "10px",
                border: "1px solid #276CFA",
                outline: "1px solid #0646C6",
                "&:hover": {
                  backgroundColor: "#1459e2",
                  boxShadow: "none",
                },
              }}
            >
              {t("scrapePopup.scrapeButton")}
            </LoadingButton>
          </>
        ) : (
          <Grow in={!!scrapedData}>
            <Box>
              <ScrapeResult
                scrapedData={scrapedData}
                onBack={handleBack}
                onConfirm={handleConfirm}
                t={t}
              />
            </Box>
          </Grow>
        )}
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "center", width: "50%", mb: 2, mt: "5%" }}
      >
        <Button
          onClick={onClose}
          color="secondary"
          disabled={loading}
          sx={{
            bgcolor: "#F3F4F6",
            color: "#303030",
            fontSize: {
              lg: "16px",
              md: "16px",
              sm: "12px",
              xs: "12px",
            },
            fontFamily: "Inter Variable",
            fontWeight: "500",
            letterSpacing: "-0.25px",
            textTransform: "none",
            mr: 1,
            pl: "3%",
            pr: "3%",
            boxShadow: "none",
            borderRadius: "10px",
            border: "1px solid #ECEFF2",
            outline: "1px solid #303030",
            "&:hover": {
              bgcolor: "#e2e2e2",
              color: "#303030",
              boxShadow: "none",
            },
          }}
        >
          {t("scrapePopup.cancelButton")}
        </Button>
        {scrapedData && (
          <Button
            onClick={handleConfirm}
            color="primary"
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: {
                lg: "16px",
                md: "16px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              mr: 2,
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
            }}
          >
            {t("scrapePopup.confirmButton")}
          </Button>
        )}
      </DialogActions>
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </Dialog>
  );
};

export default ScrapePopup;
