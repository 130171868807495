import React from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const FullScreenLoader = ({ loading }) => {
  const { t } = useTranslation();

  if (!loading) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1300,
      }}
    >
      <Card
        sx={{
          minWidth: 300,
          padding: 3,
          borderRadius: "10px",
          textAlign: "center",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          backdropFilter: "blur(10px)",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/logo/onesite.png"}
              alt={t("menuAccueil.logoAlt")}
              style={{ marginBottom: "16px", maxWidth: "150px" }}
            />
            <Typography variant="h6" sx={{ mb: 2, color: "#ffffff" }}>
              Génération de votre site en cours
            </Typography>
            <CircularProgress sx={{ color: "#ffffff" }} />{" "}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default FullScreenLoader;
