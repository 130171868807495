import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaymentsIcon from "@mui/icons-material/Payments";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ChequeConfiguration from "./ChequeConfiguration";
import VirementConfiguration from "./VirementConfiguration";
import LivraisonConfiguration from "./LivraisonConfiguration";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { API_SERVER_ENDPOINT } from "../../../../shared/api/ConstantesEndpoints";
import Loader from "../../../../shared/loader/Loader";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ToastError from "../../../../shared/toast/ToastError";

const PaymentMethodCard = ({
  paymentMethod,
  onToggleActive,
  onSubmitSettings,
  onDialogClose,
  siteConsulte,
  getUtilisateurSite,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleActive = (event) => {
    const checked = event.target.checked;
    onToggleActive(paymentMethod.id, checked, null);
  };

  const handleClose = () => {
    setIsOpen(false);
    onDialogClose();
  };

  const handleConfigurationOpen = () => {
    setIsOpen(true);
  };

  const handleReinitialize = async (endpoint) => {
    setIsLoading(true);

    try {
      await axios.get(endpoint, {
        params: {
          siteName: siteConsulte.nom,
          paymentMethodId: paymentMethod.id,
        },
      });
      getUtilisateurSite();
    } catch (error) {
      handleError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const handleConfiguration = async () => {
    setIsLoading(true);

    try {
      let endpoint;
      const params = {
        siteUrl: siteConsulte.url,
        siteName: siteConsulte.nom,
        clientId:
          siteConsulte.stripePublishableKey || siteConsulte.paypalUserId,
        clientSecret: siteConsulte.stripeSecretKey || siteConsulte.paypalSecret,
      };

      if (paymentMethod.id === "stripe") {
        endpoint = `${API_SERVER_ENDPOINT}/connect/stripe`;
        const response = await axios.get(endpoint, { params });
        if (response.data.redirectUrl) {
          window.location.href = response.data.redirectUrl;
        }
      } else if (paymentMethod.id === "ppcp-gateway") {
        endpoint = `${API_SERVER_ENDPOINT}/connect/paypal`;
        const response = await axios.get(endpoint, { params });
        if (response.data.redirectUrl) {
          window.location.href = response.data.redirectUrl;
        }
      }
    } catch (error) {
      handleError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getIcon = () => {
    switch (paymentMethod.id) {
      case "stripe":
        return (
          <img
            src="images/Stripe.webp"
            alt="Stripe"
            style={{ width: 50, height: 50 }}
          />
        );
      case "bacs":
        return (
          <AccountBalanceIcon style={{ fontSize: 50, color: "#0042ff" }} />
        );
      case "cheque":
        return <PaymentsIcon style={{ fontSize: 50, color: "#0042ff" }} />;
      case "cod":
        return <LocalShippingIcon style={{ fontSize: 50, color: "#0042ff" }} />;
      default:
        return <CreditCardIcon style={{ fontSize: 50, color: "#0042ff" }} />;
    }
  };

  const renderActionButton = () => {
    const isStripe = paymentMethod.id === "stripe";
    const isPayPal = paymentMethod.id === "ppcp-gateway";
    const hasInstructions =
      paymentMethod.id !== "stripe" &&
      paymentMethod.id !== "ppcp-gateway" &&
      paymentMethod.description !== "";

    if (
      (isStripe && siteConsulte.stripePublishableKey) ||
      (isPayPal && siteConsulte.paypalUserId) ||
      hasInstructions
    ) {
      return (
        <>
          <Box>
            <Switch
              checked={paymentMethod.enabled}
              onChange={handleToggleActive}
              sx={{ mt: 1, mr: 1 }}
            />

            <Tooltip title={t("paiement.reinitialize")}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  background: "#0042ff",
                  color: "white",
                  fontSize: {
                    lg: "14px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  mb: "3%",
                  mt: "5%",
                  fontFamily: "Inter Variable",
                  fontWeight: "500",
                  letterSpacing: "-0.25px",
                  textTransform: "none",
                  mr: 2,
                  borderRadius: "10px",
                  border: "1px solid #276CFA",
                  outline: "1px solid #0646C6",
                  "&:hover": {
                    backgroundColor: "#1459e2",
                    boxShadow: "none",
                  },
                }}
                onClick={() =>
                  handleReinitialize(
                    isStripe
                      ? `${API_SERVER_ENDPOINT}/reinitialize-stripe`
                      : isPayPal
                      ? `${API_SERVER_ENDPOINT}/reinitialize-paypal`
                      : `${API_SERVER_ENDPOINT}/reinitialize`
                  )
                }
              >
                {t("paiement.reinitialize")}
              </Button>
            </Tooltip>
          </Box>
        </>
      );
    }

    return (
      <Button
        variant="contained"
        color="primary"
        sx={{
          background: "#0042ff",
          color: "white",
          fontSize: {
            lg: "14px",
            md: "14px",
            sm: "12px",
            xs: "12px",
          },
          fontFamily: "Inter Variable",
          fontWeight: "500",
          letterSpacing: "-0.25px",
          textTransform: "none",
          mr: 2,
          mb: "3%",
          borderRadius: "10px",
          border: "1px solid #276CFA",
          outline: "1px solid #0646C6",
          "&:hover": {
            backgroundColor: "#1459e2",
            boxShadow: "none",
          },
        }}
        onClick={
          isStripe || isPayPal ? handleConfiguration : handleConfigurationOpen
        }
      >
        {t("paiement.configure")}
      </Button>
    );
  };

  return (
    <Card
      sx={{
        height: 350,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 2,
        boxSizing: "border-box",
        borderRadius: "10px",
        boxShadow: "none",
        border: "2px solid #c6c7ce",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 1,
          mt: "5%",
        }}
      >
        {getIcon()}
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            marginTop: 2,
            marginBottom: 1,
            fontFamily: "Inter Variable, sans-serif",
            textAlign: "center",
          }}
        >
          {paymentMethod.title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Inter Variable, sans-serif",
            textAlign: "center",
            flexGrow: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
          }}
        >
          {paymentMethod.description}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        {renderActionButton()}
      </Box>

      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle
          sx={{
            textAlign: "center",
            fontSize: { lg: "24px", md: "18px", sm: "14px", xs: "12px" },
            color: "black",
            fontFamily: "Inter Variable, sans-serif",
            fontWeight: "600",
            letterSpacing: "-1px",
            mt: "1%",
          }}
        >
          {t("paiement.configure")} {paymentMethod.title}
        </DialogTitle>
        <DialogContent>
          {paymentMethod.id === "cheque" && (
            <ChequeConfiguration
              paymentMethod={paymentMethod.id}
              onClose={handleClose}
              onSubmitSettings={onSubmitSettings}
            />
          )}
          {paymentMethod.id === "bacs" && (
            <VirementConfiguration
              paymentMethod={paymentMethod.id}
              onClose={handleClose}
              onSubmitSettings={onSubmitSettings}
            />
          )}
          {paymentMethod.id === "cod" && (
            <LivraisonConfiguration
              paymentMethod={paymentMethod.id}
              onClose={handleClose}
              onSubmitSettings={onSubmitSettings}
            />
          )}
        </DialogContent>
      </Dialog>
      <Loader loading={isLoading} />
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </Card>
  );
};

export default PaymentMethodCard;
