import React from "react";
import {
  Typography,
  Button,
  Box,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const MenuItem = ({
  menu,
  setMenuItems,
  editingItem,
  setEditingItem,
  editData,
  handleChangeEdit,
  handleSaveEdit,
  handleEditClick,
  handleDeleteMenuItem,
  isUrlValid,
  canDelete,
  siteConsulteUrl,
}) => {
  const { t } = useTranslation();

  const baseUrls = [
    "/shop/",
    "/checkout/",
    "/checkout-view/",
    "/cart/",
    "/my-account/",
    "/produit-",
  ];

  const isBaseUrl = (itemUrl) => {
    const itemUrlPath = new URL(itemUrl).pathname;
    return baseUrls.some((baseUrl) => itemUrlPath.includes(baseUrl));
  };

  const isAccueil = (itemUrl) => {
    return (
      itemUrl.endsWith(siteConsulteUrl) ||
      itemUrl.endsWith(siteConsulteUrl + "/")
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      menu.items,
      result.source.index,
      result.destination.index
    );

    setMenuItems(reorderedItems);
  };

  return (
    <Box
      sx={{
        px: { xs: "50px", md: "150px" },
        bgcolor: "background.paper",
        width: "100%",
        position: "relative",
        backgroundColor: "#f5f8fa",
        mt: "3%",
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-menu-items">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{ p: 3 }}
            >
              {menu.items?.map((item, index) => (
                <Draggable
                  key={item.ID}
                  draggableId={item.ID.toString()}
                  index={index}
                >
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        width: "100%",
                        p: 2,
                        mb: 2,
                        backgroundColor: "white",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                    >
                      {editingItem === item.ID ? (
                        <Box sx={{ width: "100%" }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              mb: 2,
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: "Inter Variable",
                                fontWeight: "500",
                                mr: 1,
                              }}
                            >
                              {t("menu.tabTitle")}
                            </Typography>
                            <TextField
                              fullWidth
                              name="title"
                              placeholder={t("menu.tabTitle")}
                              value={editData.title}
                              onChange={handleChangeEdit}
                              variant="outlined"
                              margin="normal"
                              InputProps={{
                                sx: {
                                  width: "100%",
                                  height: "50px",
                                  borderRadius: "12px",
                                  backgroundColor: "white",
                                  border: "1px solid #E3E3E3",
                                  fontWeight: "500",
                                  fontFamily: "Inter Variable",
                                  fontSize: "14px",
                                  padding: "0%",
                                  letterSpacing: "-0.25px",
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "transparent",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "transparent",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "transparent",
                                    },
                                  input: {
                                    color: "text.primary",
                                  },
                                  "&::placeholder": {
                                    color: "#000000",
                                    opacity: 0.8,
                                  },
                                },
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                mt: 3,
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  fontFamily: "Inter Variable",
                                  fontWeight: "500",
                                  mr: 1,
                                }}
                              >
                                {editData.hostname}/
                              </Typography>
                              <TextField
                                fullWidth
                                placeholder={t("menu.tabURL")}
                                name="path"
                                value={editData.path}
                                onChange={handleChangeEdit}
                                variant="outlined"
                                margin="normal"
                                disabled={
                                  isBaseUrl(item.url) || isAccueil(item.url)
                                }
                                InputProps={{
                                  sx: {
                                    width: "100%",
                                    height: "50px",
                                    borderRadius: "12px",
                                    backgroundColor: "white",
                                    border: "1px solid #E3E3E3",
                                    fontWeight: "500",
                                    fontFamily: "Inter Variable",
                                    fontSize: "14px",
                                    padding: "0%",
                                    letterSpacing: "-0.25px",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "transparent",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "transparent",
                                      },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "transparent",
                                      },
                                    input: {
                                      color: "text.primary",
                                    },
                                    "&::placeholder": {
                                      color: "#000000",
                                      opacity: 0.8,
                                    },
                                  },
                                }}
                              />
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              mt: 2,
                              gap: 1,
                            }}
                          >
                            <Button
                              onClick={() => setEditingItem(null)}
                              sx={{
                                bgcolor: "#F3F4F6",
                                color: "#303030",
                                fontSize: {
                                  lg: "16px",
                                  md: "16px",
                                  sm: "12px",
                                  xs: "12px",
                                },
                                fontFamily: "Inter Variable",
                                fontWeight: "500",
                                letterSpacing: "-0.25px",
                                textTransform: "none",
                                pl: "3%",
                                pr: "3%",
                                boxShadow: "none",
                                borderRadius: "10px",
                                border: "1px solid #ECEFF2",
                                outline: "1px solid #303030",
                                "&:hover": {
                                  bgcolor: "#e2e2e2",
                                  color: "#303030",
                                  boxShadow: "none",
                                },
                              }}
                            >
                              {t("menu.cancel")}
                            </Button>
                            <Tooltip
                              title={isUrlValid ? "" : t("error.invalidPath")}
                            >
                              <span>
                                <Button
                                  onClick={() =>
                                    handleSaveEdit(menu.ID, item.ID)
                                  }
                                  sx={{
                                    background: isUrlValid
                                      ? "#0042ff"
                                      : "#d3d3d3",
                                    color: isUrlValid ? "white" : "#a9a9a9",
                                    fontSize: {
                                      lg: "16px",
                                      md: "16px",
                                      sm: "12px",
                                      xs: "12px",
                                    },
                                    fontFamily: "Inter Variable",
                                    fontWeight: "500",
                                    letterSpacing: "-0.25px",
                                    textTransform: "none",
                                    borderRadius: "10px",
                                    border: "1px solid #276CFA",
                                    outline: "1px solid #0646C6",
                                    "&:hover": {
                                      backgroundColor: isUrlValid
                                        ? "#1459e2"
                                        : "#d3d3d3",
                                      boxShadow: "none",
                                    },
                                  }}
                                  disabled={!isUrlValid}
                                >
                                  {t("menu.saveTab")}
                                </Button>
                              </span>
                            </Tooltip>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              sx={{
                                fontFamily: "Inter Variable",
                                letterSpacing: "-0.75px",
                                fontWeight: "600",
                              }}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontFamily: "Inter Variable",
                                color: "text.secondary",
                              }}
                            >
                              {item.url}
                            </Typography>
                          </Box>
                          <IconButton onClick={() => handleEditClick(item)}>
                            <EditIcon />
                          </IconButton>
                          <Tooltip
                            title={
                              canDelete
                                ? ""
                                : "Vous devez avoir au moins un élément sur le menu"
                            }
                          >
                            <span>
                              <IconButton
                                onClick={() => handleDeleteMenuItem(item.ID)}
                                disabled={!canDelete}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default MenuItem;
