import React from "react";
import "./LoadingScreen.css";

function LoadingScreen() {
  return (
    <div className="loading-screen">
      <img
        src={process.env.PUBLIC_URL + "/logo/onesitelogoblack.png"}
        alt="oneSite Logo"
        className="loading-logo"
      />
    </div>
  );
}

export default LoadingScreen;
