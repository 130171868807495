import React from "react";
import { Typography, Card, CardContent, Box } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import ShopIcon from "@mui/icons-material/Shop";
import { useTranslation } from "react-i18next";

const ComingSoonCard = () => {
  const { t } = useTranslation();

  return (
    <Card
      raised
      sx={{
        m: 2,
        p: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        boxShadow: "none",
        border: "2px solid #c6c7ce",
      }}
    >
      <CardContent sx={{ width: "100%", textAlign: "center" }}>
        <Typography
          variant="h5"
          sx={{
            mb: 2,
            fontFamily: "Space Grotesk, sans-serif",
            fontWeight: "1000",
            letterSpacing: {
              xs: "-2px",
              sm: "-2px",
              md: "-3px",
              lg: "-5px",
            },
            fontSize: {
              xs: "25px",
              sm: "30px",
              md: "40px",
              lg: "60px",
            },
          }}
        >
          {t("comingSoon.title")}
        </Typography>
        <Typography color="text.secondary">
          {t("comingSoon.description")}
        </Typography>
      </CardContent>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 5,
        }}
      >
        <AppleIcon sx={{ fontSize: 50, marginRight: 5 }} />
        <ShopIcon sx={{ fontSize: 50 }} />
      </Box>
    </Card>
  );
};

export default ComingSoonCard;
