import React, { useState } from "react";
import { Button, Container, Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProductDetails from "./ProductDetails";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../../../shared/api/ConstantesEndpoints";
import ScrapePopup from "../../../../shared/scrape/ScrapePopup";
import AddIcon from "@mui/icons-material/Add";
import ToastError from "../../../../shared/toast/ToastError";

const ProductForm = ({ product, onClose, siteConsulte, onSubmitted }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [scrapePopupOpen, setScrapePopupOpen] = useState(false);

  const [productDetails, setProductDetails] = useState({
    name: product ? product.name : "",
    price: product ? product.price : "",
    description: product ? product.description : "",
    images:
      product && product.images ? product.images.map((image) => image.src) : [],
    variations: product ? product.variations : [],
  });

  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const customerId = profil?.customerId;
  const [scrapedImage, setScrapedImage] = useState(null);

  const handleProductDetailsChange = (field, value) => {
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleVariationsChange = (variations) => {
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      variations: variations,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      let productData = new FormData();
      productData.append("siteNom", siteConsulte.nom);
      productData.append("name", productDetails.name);
      productData.append("price", productDetails.price);
      productData.append("description", productDetails.description);
      productData.append("customerId", customerId);

      if (scrapedImage) {
        // Si une image scrappée est présente, l'ajouter
        productData.append("imageUrls", scrapedImage);
      } else {
        // Sinon, ajouter les images existantes
        productDetails.images.forEach((image) => {
          if (typeof image === "string") {
            productData.append("imageUrls", image);
          } else {
            productData.append("imagesProduit", image);
          }
        });
      }

      processVariations(productDetails, productData);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let response;
      if (product) {
        response = await axios.put(
          `${API_SERVER_ENDPOINT}/products/${product.id}`,
          productData,
          config
        );
      } else {
        response = await axios.post(
          `${API_SERVER_ENDPOINT}/products`,
          productData,
          config
        );
      }

      if (response.status === 201 || response.status === 200) {
        if (onSubmitted) {
          onSubmitted(product ? "edit" : "add");
        }
        onClose();
      }
    } catch (error) {
      const erreurMessage = error.response?.data?.message || error.message;
      setError(erreurMessage);
      setTimeout(() => {
        setError(null);
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  const processVariations = (productDetails, productData) => {
    const sanitizedVariations = productDetails.variations.map((variation) => {
      const { id, ...rest } = variation;

      if (typeof id === "string") {
        return rest;
      }

      return variation;
    });

    sanitizedVariations.forEach((variation, index) => {
      if (variation.imageFile) {
        productData.append(`imagesVariation${index}`, variation.imageFile);
      }
    });

    if (sanitizedVariations.length > 0) {
      productData.append("variations", JSON.stringify(sanitizedVariations));
    }
  };

  const handleScrapeConfirm = (scrapedData) => {
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      name: scrapedData.title,
      description: scrapedData.description,
      price: scrapedData.price,
    }));

    setScrapedImage(scrapedData.image || null);

    setScrapePopupOpen(false);
  };

  const handleScrapedImageRemoved = () => {
    setScrapedImage(null);
  };

  const isSubmitDisabled = !productDetails.name || !productDetails.price;

  return (
    <Container maxWidth={false} sx={{ padding: "16px" }}>
      <Box sx={{ display: "flex", justifyContent: "right", mb: 2 }}>
        <div inert={scrapePopupOpen ? "true" : undefined}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setScrapePopupOpen(true)}
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: {
                lg: "14px",
                md: "14px",
                sm: "12px",
                xs: "12px",
              },
              mb: 3,
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
            }}
          >
            {t("produits.import_product")}
            <AddIcon sx={{ fontSize: "20px", ml: "3px" }} />
          </Button>
        </div>
      </Box>

      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={4}
          sx={{ paddingLeft: "10%", paddingRight: "10%" }}
        >
          <Grid item xs={12} md={12}>
            <ProductDetails
              product={productDetails}
              onProductDetailsChange={handleProductDetailsChange}
              onVariationsChange={handleVariationsChange}
              imageScrape={scrapedImage}
              onScrapedImageRemoved={handleScrapedImageRemoved}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            onClick={onClose}
            sx={{
              bgcolor: "#F3F4F6",
              color: "#303030",
              fontSize: {
                lg: "16px",
                md: "16px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Space Grotesk, sans-serif",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              mr: 2,
              pl: "3%",
              pr: "3%",
              boxShadow: "none",
              borderRadius: "10px",
              border: "1px solid #ECEFF2",
              outline: "1px solid #303030",
              "&:hover": {
                bgcolor: "#e2e2e2",
                color: "#303030",
                boxShadow: "none",
              },
            }}
          >
            {t("produits.cancel")}
          </Button>
          <LoadingButton
            type="submit"
            loading={loading}
            variant="contained"
            color="primary"
            disabled={isSubmitDisabled}
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: {
                lg: "16px",
                md: "16px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Space Grotesk, sans-serif",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
            }}
          >
            {product ? t("produits.modify") : t("produits.add")}
          </LoadingButton>
        </Box>
        <ToastError
          open={error !== null}
          onClose={() => setError(null)}
          message={error}
        />
      </form>

      <ScrapePopup
        open={scrapePopupOpen}
        onClose={() => setScrapePopupOpen(false)}
        onConfirm={handleScrapeConfirm}
        importOnConfirm={false}
        siteConsulte={siteConsulte}
      />
    </Container>
  );
};

export default ProductForm;
