import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { startOfDay, endOfDay, startOfWeek } from "date-fns";
import fr from "date-fns/locale/fr";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../../../shared/api/ConstantesEndpoints";
import CustomDateRangePicker from "../../../../shared/date/DatePicker";
import ToastError from "../../../../shared/toast/ToastError";

const ExportOrdersDialog = ({ open, onClose, siteNom }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [startDate, setStartDate] = useState(
    startOfDay(startOfWeek(new Date(), { weekStartsOn: 1 }))
  );
  const [endDate, setEndDate] = useState(endOfDay(new Date()));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleExport = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/commandes/export-csv`,
        {
          siteNom,
          startDate: startDate ? startDate.toISOString() : null,
          endDate: endDate ? endDate.toISOString() : null,
        },
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "commandes.csv");
      document.body.appendChild(link);
      link.click();
      onClose();
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setError(errorMessage);
      setTimeout(() => {
        setError(null);
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: "20px",
          width: { sm: "600px" },
          height: { sm: "400px" },
          maxHeight: { sm: "400px" },
          m: 0,
          p: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "1%",
          height: "100%",
          mt: "10%",
        }}
      >
        <DialogTitle sx={{ textAlign: "center", mb: "2%" }}>
          <Typography
            sx={{
              fontSize: { lg: "24px", md: "18px", sm: "14px", xs: "12px" },
              color: "black",
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textAlign: "center",
              mt: "1%",
            }}
          >
            {t("commande.exporterCommandes")}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ flexGrow: 1, overflowY: "auto" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
            <Box sx={{ my: { xs: "2rem", sm: "1rem" }, mx: "3rem" }}>
              <Grid
                container
                spacing={3}
                direction={isMobile ? "column" : "row"}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <CustomDateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onStartDateChange={handleStartDateChange}
                    onEndDateChange={handleEndDateChange}
                  />
                </Grid>
              </Grid>
            </Box>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Button
            onClick={onClose}
            sx={{
              bgcolor: "#F3F4F6",
              color: "#303030",
              fontSize: {
                lg: "16px",
                md: "16px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              mr: 1,
              pl: "3%",
              pr: "3%",
              boxShadow: "none",
              borderRadius: "10px",
              border: "1px solid #ECEFF2",
              outline: "1px solid #303030",
              "&:hover": {
                bgcolor: "#e2e2e2",
                color: "#303030",
                boxShadow: "none",
              },
            }}
          >
            {t("commande.annuler")}
          </Button>
          <LoadingButton
            onClick={handleExport}
            loading={isLoading}
            variant="contained"
            color="primary"
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: {
                lg: "16px",
                md: "16px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              mr: 2,
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
            }}
          >
            {t("commande.export")}
          </LoadingButton>
        </DialogActions>
        <ToastError
          open={error !== null}
          onClose={() => setError(null)}
          message={error}
        />
      </Box>
    </Dialog>
  );
};

export default ExportOrdersDialog;
