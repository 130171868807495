import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Paper,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuConnexion from "../../shared/menuConnexion/MenuConnexion";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../../shared/loader/LoadingScreen";

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          style: {
            fontFamily: "Inter Variable",
            fontWeight: "400",
            fontSize: "15px",
          },
        },
      },
    },
  },
});

const FelicitationInstallation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loadingBackground, setLoadingBackground] = useState(true);

  const navigateToHome = () => navigate("/");
  const navigateToAccountManagement = () => navigate("/gestion-compte");

  const handleBackgroundLoad = () => {
    setLoadingBackground(false);
  };

  return (
    <ThemeProvider theme={theme}>
      {loadingBackground && <LoadingScreen />}
      <div style={{ display: loadingBackground ? "none" : "block" }}>
        <img
          src={process.env.PUBLIC_URL + "/background/one-site-bg.png"}
          alt="Background"
          onLoad={handleBackgroundLoad}
          style={{ display: "none" }}
        />
        <Paper
          sx={{
            width: "100%",
            height: "100vh",
            overflowX: "auto",
            boxShadow: "none",
            backgroundImage: loadingBackground
              ? "none"
              : `url(${
                  process.env.PUBLIC_URL + "/background/one-site-bg.png"
                })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <MenuConnexion />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              sx={{
                mt: "7%",
                width: { xs: "80%", sm: "70%", md: "50%" },
                boxShadow: "0 0 100px 0 rgba(0, 150, 255, 0.2)",
                borderRadius: "20px",
                bgcolor: "white",
                p: 3,
                textAlign: "center",
                minHeight: "400px",
              }}
            >
              <CardContent>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    letterSpacing: "-1px",
                    fontSize: { xs: "1.5rem", sm: "2rem" },
                    mb: { xs: "1rem", sm: "3rem" },
                    fontFamily: "Inter Variable, sans-serif",
                  }}
                >
                  {t("felicitationInstallation.paymentValidated")}
                </Typography>

                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    paragraph
                    sx={{
                      width: "75%",
                      textAlign: "center",
                      fontWeight: "500",
                      letterSpacing: "-0.5px",
                      fontSize: "16px",
                      fontFamily: "Inter Variable, sans-serif",
                    }}
                  >
                    {t("felicitationInstallation.operationalSiteMessage")}
                  </Typography>
                </Box>
              </CardContent>

              <CardActions
                sx={{
                  justifyContent: "center",
                  gap: "20px",
                  mt: 3,
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Button
                  sx={{
                    width: "20%",
                    height: "100%",
                    bgcolor: "#F3F4F6",
                    color: "#303030",
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                    fontFamily: "Inter Variable",
                    fontWeight: "500",
                    letterSpacing: "-0.25px",
                    textTransform: "none",
                    boxShadow: "none",
                    borderRadius: "10px",
                    border: "1px solid #ECEFF2",
                    outline: "1px solid #303030",
                    "&:hover": {
                      bgcolor: "#e2e2e2",
                      color: "#303030",
                      boxShadow: "none",
                    },
                  }}
                  onClick={navigateToHome}
                >
                  {t("felicitationInstallation.returnHome")}
                </Button>
                <Button
                  sx={{
                    background: "#0042ff",
                    color: "white",
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                    width: "30%",
                    height: "100%",
                    fontFamily: "Inter Variable",
                    fontWeight: "500",
                    letterSpacing: "-0.25px",
                    textTransform: "none",
                    mr: 2,
                    borderRadius: "10px",
                    border: "1px solid #276CFA",
                    outline: "1px solid #0646C6",
                    "&:hover": {
                      backgroundColor: "#1459e2",
                      boxShadow: "none",
                    },
                  }}
                  onClick={navigateToAccountManagement}
                >
                  {t("felicitationInstallation.accountManagement")}
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Paper>
      </div>
    </ThemeProvider>
  );
};

export default FelicitationInstallation;
