import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Menu, MenuItem, IconButton } from "@mui/material";
import { FlagIcon } from "react-flag-kit";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [language, setLanguage] = useState(i18n.language);

  const getLanguageCode = (lng) => {
    if (lng.startsWith("fr")) {
      return "FR";
    }
    if (lng.startsWith("en")) {
      return "GB";
    }
    return "GB";
  };

  useEffect(() => {
    const handleLanguageChanged = (lng) => {
      setLanguage(lng);
    };

    i18n.on("languageChanged", handleLanguageChanged);

    return () => {
      i18n.off("languageChanged", handleLanguageChanged);
    };
  }, [i18n]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IconButton onClick={handleClick} color="inherit">
        <FlagIcon code={getLanguageCode(language)} size={20} />
        <ArrowDropDownIcon sx={{ color: "white" }} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => changeLanguage("fr")}>
          <FlagIcon code="FR" size={18} style={{ marginRight: 8 }} /> Français
        </MenuItem>
        <MenuItem onClick={() => changeLanguage("en")}>
          <FlagIcon code="GB" size={18} style={{ marginRight: 8 }} /> English
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default LanguageSwitcher;
