import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Loader from "../../shared/loader/Loader";
import {
  Box,
  TextField,
  Typography,
  Button,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import BoutonSuivant from "../../shared/boutons/BoutonSuivant";
import BoutonRetour from "../../shared/boutons/BoutonRetour";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_SERVER_ENDPOINT } from "../../shared/api/ConstantesEndpoints";
import SecteurSelector from "./SecteurSelector";
import ScrapePopup from "../../shared/scrape/ScrapePopup";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";
import { isValidUrl } from "../../shared/html/HtmlUtils";
import HelpIconButton from "../../shared/tooltip/HelpIconButton";
import ToastError from "../../shared/toast/ToastError";
import FullScreenLoader from "../../shared/loader/FullScreenLoader";

const FormInformations = ({
  currentStep,
  updateCurrentStep,
  abonnementId,
  siteNom,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isCompanyNameAvailable, setIsCompanyNameAvailable] = useState(false);
  const [isSectorValid, setIsSectorValid] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [sectorInputOrigin, setSectorInputOrigin] = useState("selector");
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    nomSite: "",
    secteurActivite: "",
    boutique: true,
    description: "",
    nomProduit: "",
    prixProduit: "",
    imageProduit: null,
    imageProduitAliexpress: null,
  });

  const imageUrl = formData.imageProduit
    ? URL.createObjectURL(formData.imageProduit)
    : formData.imageProduitAliexpress;

  const displayImage = imageUrl && isValidUrl(imageUrl);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "nomSite") {
      const debounceTimeout = 300;
      clearTimeout(window.debounceVerify);
      window.debounceVerify = setTimeout(
        () => verifyCompanyNameExists(value),
        debounceTimeout
      );
    }
  };

  const handleScrapeConfirm = (scrapedData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      nomProduit: scrapedData.title,
      description: scrapedData.description,
      prixProduit: scrapedData.price,
      imageProduit: scrapedData.image ? null : prevFormData.imageProduit,
      imageProduitAliexpress: scrapedData.image,
    }));
  };

  const verifyCompanyNameExists = async (companyName) => {
    try {
      const response = await axios.get(
        `${API_SERVER_ENDPOINT}/site/verifier-existence`,
        {
          params: {
            nomSite: companyName,
          },
        }
      );
      const data = response.data;
      setIsCompanyNameAvailable(data);
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleNextClick = () => {
    updateCurrentStep(currentStep + 1);
  };

  const handleBackClick = () => {
    updateCurrentStep(currentStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    sessionStorage.setItem("nomSite", formData.nomSite);
    sessionStorage.setItem("boutique", formData.boutique);

    await generatePreview(formData);
  };

  const handleApiResponse = (response) => {
    const urlPreview = response.pageUrl;
    const postId = response.postId;
    const templateId = response.templateId;

    sessionStorage.setItem("urlPreview", urlPreview);
    sessionStorage.setItem("postId", postId);
    sessionStorage.setItem("templateId", templateId);
    sessionStorage.setItem("accessToPreview", "true");

    setLoading(false);
    redirectionPreview();
  };

  const isNextButtonDisabled = () => {
    if (currentStep === 1) {
      return !formData.nomSite || !isCompanyNameAvailable.available;
    }

    if (currentStep === 3) {
      if (sectorInputOrigin === "selector") {
        return !formData.secteurActivite || !isSectorValid;
      } else {
        return !formData.secteurActivite;
      }
    }

    if (currentStep === 4) {
      return !formData.description;
    }

    return false;
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const handleImageUpload = (acceptedFiles) => {
    const validMimeTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/webp",
      "image/heif",
      "image/heic",
    ];

    const maxSize = 5 * 1024 * 1024;

    const file = acceptedFiles[0];

    if (file && validMimeTypes.includes(file.type)) {
      if (file.size > maxSize) {
        handleError("Le fichier est trop volumineux.");
        return;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        imageProduit: file,
      }));
    } else {
      handleError(t("formInformations.errorImageFileType"));
    }
  };

  const handleRemoveImage = (event) => {
    event.stopPropagation();
    setFormData((prevFormData) => ({
      ...prevFormData,
      imageProduit: null,
      imageProduitAliexpress: null,
    }));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleImageUpload,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
      "image/webp": [],
      "image/heif": [],
      "image/heic": [],
    },
  });

  async function generatePreview(formData) {
    try {
      setLoading(true);

      let choixSecteurExistant;
      if (sectorInputOrigin === "selector") {
        const responseMetiers = await axios.get(
          `${API_SERVER_ENDPOINT}/metiers?q=${formData.secteurActivite}`
        );
        const metiers = responseMetiers.data;
        choixSecteurExistant = metiers.find(
          (metier) => metier.label === formData.secteurActivite
        );

        if (!choixSecteurExistant) {
          throw new Error(t("formInformations.errorSectorNotFound"));
        }
      } else {
        choixSecteurExistant = {
          label: formData.secteurActivite,
          idSecteur: 15,
        };
      }

      const formDataToSend = new FormData();
      formDataToSend.append("nomSite", formData.nomSite);
      formDataToSend.append(
        "secteurActivite",
        JSON.stringify(choixSecteurExistant)
      );
      formDataToSend.append("boutique", formData.boutique);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("nomProduit", formData.nomProduit);
      formDataToSend.append("prixProduit", formData.prixProduit);

      if (formData.imageProduitAliexpress) {
        formDataToSend.append(
          "urlImageAliexpress",
          formData.imageProduitAliexpress
        );
      }

      if (formData.imageProduit) {
        formDataToSend.append("imageProduit", formData.imageProduit);
      }

      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/template/preview`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      sessionStorage.setItem(
        "secteurActivite",
        JSON.stringify(choixSecteurExistant)
      );

      handleApiResponse(response.data);

      return true;
    } catch (error) {
      handleError(error.message);
    } finally {
      setLoading(false);
    }
  }

  const handleSecteurValidation = (isValid) => {
    setIsSectorValid(isValid);
  };

  const redirectionPreview = () => {
    if (siteNom) {
      navigate(`/preview/${abonnementId}/${siteNom}`);
    } else if (abonnementId) {
      navigate(`/preview/${abonnementId}`);
    } else {
      navigate("/preview");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {currentStep === 1 && (
        <Box
          sx={{
            width: "100%",
            height: { lg: "300px", md: "300px", sm: "300px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={titreInformationsStyle}>
            {t("formInformations.info1")}
          </Typography>
          <TextField
            fullWidth
            type="text"
            placeholder={t("formInformations.info1Field")}
            name="nomSite"
            value={formData.nomSite}
            onChange={handleChange}
            variant="outlined"
            sx={inputStyle}
            autoComplete="off"
            helperText={
              !isCompanyNameAvailable.available
                ? isCompanyNameAvailable.message
                : ""
            }
            FormHelperTextProps={{
              sx: {
                fontSize: "16px",
                pt: 1,
                fontFamily: "Inter Variable",
                letterSpacing: "-0.5px",
                fontWeight: "500",
                color: "red",
              },
            }}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              mt: "auto",
              mb: 2,
            }}
          >
            <BoutonSuivant
              isNextButtonDisabled={isNextButtonDisabled}
              handleNextClick={handleNextClick}
              label={t("button.next")}
              sx={{
                alignSelf: "flex-end",
                mt: { lg: "10%", md: "10%", sm: "20%", xs: "20%" },
              }}
            />
          </Box>
        </Box>
      )}
      {currentStep === 2 && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box display="flex">
            <Typography sx={titreInformationsStyle}>
              {t("formInformations.productImport")}
            </Typography>
            <HelpIconButton
              helpText={t("formInformations.helpText")}
            ></HelpIconButton>
          </Box>
          <TextField
            fullWidth
            type="text"
            placeholder={t("formInformations.productName")}
            name="nomProduit"
            value={formData.nomProduit}
            onChange={handleChange}
            variant="outlined"
            sx={inputStyle}
            autoComplete="off"
          />
          <TextField
            fullWidth
            type="number"
            placeholder={t("formInformations.productPrice")}
            name="prixProduit"
            value={formData.prixProduit}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              handleChange({
                target: {
                  name: e.target.name,
                  value: isNaN(value) ? "" : value,
                },
              });
            }}
            variant="outlined"
            sx={{
              ...inputStyle,
              ".MuiInputBase-root": {
                paddingRight: 0,
              },
            }}
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
              inputProps: { min: 0, step: "1" },
            }}
            inputMode="decimal"
          />
          <Button
            onClick={() => setIsDialogOpen(true)}
            sx={{
              fontFamily: "Space Grotesk, sans-serif",
              fontWeight: 600,
              fontSize: { xs: "16px" },
              textAlign: "center",
              color: "#124ae5",
              mb: 3,
            }}
          >
            {t("formInformations.importFromAliexpress")}
          </Button>
          <Box
            {...getRootProps()}
            sx={{
              width: "100%",
              height: "200px",
              border: "2px dashed #ccc",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              margin: "1rem 0",
              position: "relative",
              mb: 5,
            }}
          >
            <input {...getInputProps()} />
            {displayImage ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  backgroundImage: `url(${imageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "10px",
                }}
              >
                <Button
                  onClick={handleRemoveImage}
                  sx={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    minWidth: "auto",
                    padding: "5px",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    borderRadius: "50%",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                    },
                  }}
                >
                  <DeleteIcon />
                </Button>
              </Box>
            ) : (
              <Typography
                sx={{
                  fontFamily: "Space Grotesk, sans-serif",
                  fontWeight: 600,
                  fontSize: { xs: "16px", md: "20px" },
                  textAlign: "center",
                  color: "#124ae5",
                }}
              >
                {t("formInformations.photoDrag")}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {currentStep === 3 && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={titreInformationsStyle}>
            {t("formInformations.chooseSector")}
          </Typography>
          <Box sx={{ width: "70%", mb: "15%" }}>
            <SecteurSelector
              value={formData.secteurActivite}
              onChange={(value, origin) => {
                handleChange({ target: { name: "secteurActivite", value } });
                setSectorInputOrigin(origin);
              }}
              onValidation={handleSecteurValidation}
            />
          </Box>
        </Box>
      )}
      {currentStep === 4 && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mb: "10%",
          }}
        >
          <Typography sx={titreInformationsStyle}>
            {t("formInformations.describeShopProduct")}
          </Typography>
          <TextField
            fullWidth
            type="text"
            placeholder={t("formInformations.shopProductDescription")}
            name="description"
            value={formData.description}
            onChange={handleChange}
            variant="outlined"
            sx={{
              ...inputStyle,
              minHeight: "100px",
            }}
            autoComplete="off"
            multiline
            minRows={4}
            maxRows={10}
          />
        </Box>
      )}

      {currentStep > 1 && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            mt: "auto",
            mb: 2,
          }}
        >
          {currentStep >= 2 && currentStep <= 4 && (
            <BoutonRetour
              handleBackClick={handleBackClick}
              sx={{
                alignSelf: "flex-start",
              }}
            />
          )}
          {currentStep >= 1 && currentStep < 4 && (
            <BoutonSuivant
              isNextButtonDisabled={isNextButtonDisabled}
              handleNextClick={handleNextClick}
              label={t("button.next")}
              sx={{
                alignSelf: "flex-end",
              }}
            />
          )}
          {currentStep === 4 && (
            <LoadingButton
              variant="contained"
              disabled={isNextButtonDisabled()}
              onClick={handleSubmit}
              loading={loading}
              sx={{
                fontFamily: "Inter Variable",
                borderRadius: "10px",
                background: "#1d35e5",
                color: "#ffffff",
                fontWeight: 650,
                fontSize: "1rem",
                textTransform: "none",
                border: "none",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  bgcolor: "#00258c",
                },
                "&.Mui-disabled": {
                  opacity: 0.5,
                  cursor: "not-allowed",
                },
                "@media (max-width: 600px)": {
                  fontSize: "0.8rem",
                  fontWeight: 600,
                  px: "1rem",
                  py: "0.5rem",
                  border: "none",
                  boxShadow: "none",
                },
              }}
              endIcon={!loading && <ArrowForwardIosIcon />}
            >
              {t("button.generer")}
            </LoadingButton>
          )}
        </Box>
      )}
      <Loader loading={loading}></Loader>
      <FullScreenLoader loading={loading} />
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
      <ScrapePopup
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={handleScrapeConfirm}
        importOnConfirm={false}
      />
    </Box>
  );
};

const titreInformationsStyle = {
  color: "black",
  fontFamily: "Inter Variable",
  fontWeight: "600",
  letterSpacing: "-1px",
  textAlign: "center",
  fontSize: {
    xs: "18px",
    sm: "22px",
    md: "22px",
    lg: "26px",
  },
  mt: { xs: "1rem", xl: "2rem" },
  mb: { xs: "1rem", xl: "2rem" },
  pl: "1rem",
  pr: "1rem",
};

const inputStyle = {
  fontFamily: "Inter Variable",
  borderRadius: "20px",
  backgroundColor: "white",
  mb: "3%",
  display: "flex",
  alignItems: "center",
  width: "80%",

  ".MuiInputBase-input": {
    fontSize: { xs: "14px", sm: "16px" },
    fontFamily: "Inter Variable",
    fontWeight: "600",
    letterSpacing: "-0.5px",
  },
  ".MuiInputBase-input::placeholder": {
    color: "rgba(0, 0, 0, 0.7)",
    fontSize: { xs: "14px", sm: "16px" },
    fontWeight: "400",
    fontFamily: "Inter Variable",
    opacity: 1,
    letterSpacing: "-0.5px",
  },
  ".MuiOutlinedInput-root": {
    backgroundColor: "#eef6ff",
    borderRadius: "20px",
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0)",
      boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "20px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0)",
    },
  },
  "@media (max-width: 768px)": {
    ".MuiInputBase-input": {
      fontSize: "16px",
    },
  },
};

export default FormInformations;
