import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Box, Grid } from "@mui/material";
import StatCard from "../../../../shared/statistique/StatCard";
import { eachDayOfInterval, eachMonthOfInterval, format } from "date-fns";
import { API_SERVER_ENDPOINT } from "../../../../shared/api/ConstantesEndpoints";
import { useTranslation } from "react-i18next";
import ToastError from "../../../../shared/toast/ToastError";

const WooCommerceStats = ({
  siteConsulte,
  startDate,
  endDate,
  granularity,
}) => {
  const { t } = useTranslation();
  const [totalSales, setTotalSales] = useState("0.00");
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [chartDataSales, setChartDataSales] = useState([]);
  const [chartDataOrders, setChartDataOrders] = useState([]);
  const [chartDataCustomers, setChartDataCustomers] = useState([]);
  const [salesPercentageChange, setSalesPercentageChange] = useState(0);
  const [ordersPercentageChange, setOrdersPercentageChange] = useState(0);
  const [customersPercentageChange, setCustomersPercentageChange] = useState(0);

  const [error, setError] = useState(null);

  const updatePercentageChanges = useCallback(
    (
      chartDataSales,
      chartDataOrders,
      chartDataCustomers,
      setSalesPercentageChange,
      setOrdersPercentageChange,
      setCustomersPercentageChange
    ) => {
      const previousTotalSales =
        chartDataSales[chartDataSales.length - 2]?.value || 0;
      const latestTotalSales =
        chartDataSales[chartDataSales.length - 1]?.value || 0;
      setSalesPercentageChange(
        calculatePercentageChange(latestTotalSales, previousTotalSales)
      );

      const previousTotalOrders =
        chartDataOrders[chartDataOrders.length - 2]?.value || 0;
      const latestTotalOrders =
        chartDataOrders[chartDataOrders.length - 1]?.value || 0;
      setOrdersPercentageChange(
        calculatePercentageChange(latestTotalOrders, previousTotalOrders)
      );

      const previousTotalCustomers =
        chartDataCustomers[chartDataCustomers.length - 2]?.value || 0;
      const latestTotalCustomers =
        chartDataCustomers[chartDataCustomers.length - 1]?.value || 0;
      setCustomersPercentageChange(
        calculatePercentageChange(latestTotalCustomers, previousTotalCustomers)
      );
    },
    []
  );

  const prepareChartData = (startDate, endDate, granularity) => {
    if (startDate > endDate) {
      throw new Error(
        "La date de début doit être antérieure à la date de fin."
      );
    }

    let dateRange;

    if (granularity === "annee") {
      dateRange = eachMonthOfInterval({ start: startDate, end: endDate });
    } else if (granularity === "mois") {
      dateRange = eachDayOfInterval({ start: startDate, end: endDate });
    } else {
      dateRange = eachDayOfInterval({ start: startDate, end: endDate });
    }

    return dateRange.map((date) => ({
      date: format(date, granularity === "annee" ? "yyyy-MM" : "yyyy-MM-dd"),
      value: 0,
    }));
  };

  const mergeChartData = (initialData, actualData) => {
    const dataMap = new Map(actualData.map((item) => [item.date, item.value]));
    return initialData.map((item) => ({
      date: item.date,
      value: dataMap.get(item.date) || 0,
    }));
  };

  const calculatePercentageChange = (current, previous) => {
    if (!previous || previous === "0.00") return 0;
    return (((current - previous) / previous) * 100).toFixed(2);
  };

  const loadWooCommerceStats = useCallback(() => {
    try {
      if (siteConsulte && siteConsulte.nom) {
        const formattedStart = format(startDate, "yyyy-MM-dd");
        const formattedEnd = format(endDate, "yyyy-MM-dd");

        const initialChartDataSales = prepareChartData(
          startDate,
          endDate,
          granularity
        );
        const initialChartDataOrders = prepareChartData(
          startDate,
          endDate,
          granularity
        );
        const initialChartDataCustomers = prepareChartData(
          startDate,
          endDate,
          granularity
        );

        const url = `${API_SERVER_ENDPOINT}/statistiques/site?siteNom=${encodeURIComponent(
          siteConsulte.nom
        )}&start=${formattedStart}&end=${formattedEnd}&granularity=${granularity}`;

        axios
          .get(url)
          .then((response) => {
            const {
              totalSales,
              totalOrders,
              totalCustomers,
              chartDataSales,
              chartDataOrders,
              chartDataCustomers,
            } = response.data;

            setTotalSales(totalSales);
            setTotalOrders(totalOrders);
            setTotalCustomers(totalCustomers);

            setChartDataSales(
              mergeChartData(initialChartDataSales, chartDataSales)
            );
            setChartDataOrders(
              mergeChartData(initialChartDataOrders, chartDataOrders)
            );
            setChartDataCustomers(
              mergeChartData(initialChartDataCustomers, chartDataCustomers)
            );

            updatePercentageChanges(
              chartDataSales,
              chartDataOrders,
              chartDataCustomers,
              setSalesPercentageChange,
              setOrdersPercentageChange,
              setCustomersPercentageChange
            );
          })
          .catch((error) => {
            handleError(error.message);
          });
      }
    } catch (error) {
      handleError(error.message);
    }
  }, [siteConsulte, startDate, endDate, granularity, updatePercentageChanges]);

  useEffect(() => {
    if (siteConsulte && startDate && endDate) {
      loadWooCommerceStats();
    }
  }, [siteConsulte, startDate, endDate, granularity, loadWooCommerceStats]);

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        spacing={0}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={12} md={4} sx={{ padding: "0 4px" }}>
          <StatCard
            title={t("statistique.total_sales")}
            value={`${totalSales} $`}
            percentageChange={salesPercentageChange}
            data={chartDataSales}
            dateRange={`${t("statistique.analyzed_period")} ${format(
              startDate,
              "MMM dd"
            )} à ${format(endDate, "MMM dd, yyyy")}`}
            comparisonInfo={t("statistique.sales_over_period")}
            tooltipContentLine="value"
            granularity={granularity}
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ padding: "0 4px" }}>
          <StatCard
            title={t("statistique.customers")}
            value={`${totalCustomers}`}
            percentageChange={customersPercentageChange}
            data={chartDataCustomers}
            dateRange={`${t("statistique.analyzed_period")} ${format(
              startDate,
              "MMM dd"
            )} à ${format(endDate, "MMM dd, yyyy")}`}
            comparisonInfo={t("statistique.customers_over_period")}
            tooltipContentLine="value"
            granularity={granularity}
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ padding: "0 4px" }}>
          <StatCard
            title={t("statistique.orders")}
            value={`${totalOrders}`}
            percentageChange={ordersPercentageChange}
            data={chartDataOrders}
            dateRange={`${t("statistique.analyzed_period")} ${format(
              startDate,
              "MMM dd"
            )} à ${format(endDate, "MMM dd, yyyy")}`}
            comparisonInfo={t("statistique.orders_over_period")}
            tooltipContentLine="value"
            granularity={granularity}
          />
        </Grid>
      </Grid>
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </Box>
  );
};

export default WooCommerceStats;
