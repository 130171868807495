import { Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";

function MenuConnexion() {
  return (
    <div>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src={process.env.PUBLIC_URL + "/logo/onesite.png"}
              alt="Presentation"
              className="logo"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
    </div>
  );
}
export default MenuConnexion;
