import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { API_SERVER_ENDPOINT } from "../../../shared/api/ConstantesEndpoints";
import { useTranslation } from "react-i18next";
import Loader from "../../../shared/loader/Loader";
import SiteDetails from "./SiteDetails";
import ConfirmationDialog from "../../../shared/popup/ConfirmationDialog";
import { isValidUrlDomaine } from "../../../shared/html/HtmlUtils";
import SuccessToast from "../../../shared/toast/ToastSuccess";
import ToastError from "../../../shared/toast/ToastError";

const GestionDomaine = ({ getUtilisateurSite }) => {
  const { t } = useTranslation();

  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const [customerId] = useState(profil?.customerId);

  const [loading, setLoading] = useState(false);
  const [loadingAjouterUrl, setLoadingAjouterUrl] = useState(false);
  const [loadingChangementUrl, setLoadingChangementUrl] = useState(false);

  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [newNomDomaine, setNewNomDomaine] = useState("");

  const [showSuccessAjouterNomDomaine, setShowSuccessAjouterNomDomaine] =
    useState(false);
  const [showSuccessValiderNomDomaine, setShowSuccessValiderNomDomaine] =
    useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [error, setError] = useState(null);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const scrollToReference = (encre) => {
    const element = document.getElementById(encre);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleError = useCallback((message) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 3000);
  }, []);

  const fetchDomains = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_SERVER_ENDPOINT}/utilisateur/${customerId}/sites`
      );
      const sites = response.data.sites;
      setSites(sites);
    } catch (error) {
      handleError(error.message);
    } finally {
      setLoading(false);
    }
  }, [customerId, handleError]);

  useEffect(() => {
    fetchDomains();
  }, [fetchDomains]);

  useEffect(() => {
    if (selectedSite) {
      const updatedSite = sites.find((site) => site.nom === selectedSite.nom);
      if (updatedSite && updatedSite !== selectedSite) {
        setSelectedSite(updatedSite);
      }
    }
  }, [sites, selectedSite]);

  useEffect(() => {
    if (selectedSite) {
      const urlToDisplay = selectedSite.urlPropose || selectedSite.url || "";
      setNewNomDomaine(urlToDisplay);
      scrollToReference("detailsCard");
    }
  }, [selectedSite]);

  const handleDetailsClick = (site) => {
    setSelectedSite(site);
  };

  const handleUrlChange = (event) => {
    setNewNomDomaine(event.target.value);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = () => {
    setOpenConfirmationDialog(true);
  };

  const handleConfirmChangeDomain = async () => {
    setOpenConfirmationDialog(false);
    setLoadingAjouterUrl(true);

    if (!isValidUrlDomaine(newNomDomaine)) {
      setError(t("error.invalidUrl"));
      setLoadingAjouterUrl(false);
      return;
    }

    try {
      const { nom } = selectedSite;

      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/site/ajouter-nom-domaine`,
        {
          nomSite: nom,
          nouveauNomDomaine: newNomDomaine,
        }
      );

      if (response.status === 202) {
        await fetchDomains();

        setToastMessage(
          "Nom de domaine ajouté avec succès. Veuillez maintenant configurer votre fournisseur."
        );

        setOpenSnackbar(true);
        setShowSuccessAjouterNomDomaine(true);
        setLoadingAjouterUrl(false);
      }

      setTimeout(() => {
        setShowSuccessAjouterNomDomaine(false);
      }, 3000);
    } catch (error) {
      setLoadingAjouterUrl(false);
      handleError(error.message);
    }
  };

  const handleValidateUrl = async () => {
    setLoadingChangementUrl(true);

    try {
      const { nom } = selectedSite;

      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/site/confirmer-nom-domaine`,
        {
          nomSite: nom,
          nouveauNomDomaine: newNomDomaine,
        }
      );

      await fetchDomains();

      if (response.status === 202) {
        const { operationId } = response.data;
        sessionStorage.setItem("operationIdDomaine", operationId);

        getUtilisateurSite();
        setToastMessage(
          "Confirmation validée. Changement du nom de domaine en cours."
        );

        setOpenSnackbar(true);
        setShowSuccessValiderNomDomaine(true);
        setLoadingChangementUrl(false);
      }

      setTimeout(() => {
        setShowSuccessValiderNomDomaine(false);
      }, 3000);
    } catch (error) {
      setLoadingChangementUrl(false);
      if (error.response && error.response.status === 400) {
        handleError(
          "Le DNS n'a pas encore été vérifié ou est mal configuré de votre côté. Veuillez réessayer plus tard."
        );
      } else {
        handleError(error.message);
      }
    }
  };

  return (
    <div
      style={{
        flexGrow: 1,
        padding: "2%",
        backgroundColor: "#f5f8fa",
        minHeight: "100vh",
      }}
    >
      <Paper
        sx={{
          margin: "20px",
          overflowX: "auto",
          boxShadow: "none",
          backgroundColor: "#f5f8fa",
        }}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: { xs: "26px", sm: "60px" },
            fontFamily: "Space Grotesk, sans serif",
            fontWeight: "1000",
            letterSpacing: { xs: "-2px", sm: "-5px", md: "-5px", lg: "-5px" },
            ml: "2%",
            mt: { xs: "5%", sm: 0 },
          }}
        >
          {t("domaine.nomsDeDomaine")}
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontSize: "20px",
            fontFamily: "Inter Variable",
            fontWeight: "500",
            letterSpacing: "-1.5px",
            mb: "3%",
            mt: "0%",
            ml: "2%",
          }}
        >
          {t("domaine.afficherGererTraiterNomsDeDomaine")}
        </Typography>
        <Paper
          sx={{
            flex: 1,
            overflowX: "auto",
            boxShadow: "none",
            padding: "20px",
            backgroundColor: "#f5f8fa",
          }}
        >
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Paper
                sx={{
                  flex: 1,
                  overflowX: "auto",
                  boxShadow: "none",
                  padding: "20px",
                  backgroundColor: "#f5f8fa",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: { xs: "20px", sm: "24px" },
                    fontFamily: "Inter Variable, sans serif",
                    letterSpacing: "-1px",
                    fontWeight: "600",
                    mb: "24px",
                  }}
                >
                  {t("domaine.explicationsNomsDeDomaine")}
                </Typography>
                <Typography
                  sx={{ fontFamily: "Inter Variable", textAlign: "left" }}
                >
                  {t("domaine.explicationsNomsDeDomaineDescription")}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter Variable",
                    textAlign: "left",
                    mt: 5,
                  }}
                >
                  {t("domaine.mettreAJourEnregistrementsDNS")}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper
                sx={{
                  flex: 1,
                  overflowX: "auto",
                  boxShadow: "none",
                  padding: "20px",
                  backgroundColor: "#f5f8fa",
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{
                    maxHeight: 600,
                    borderRadius: "20px",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    border: "1px solid #c6c7ce",
                  }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "20px",
                            fontFamily: "Inter Variable",
                            fontWeight: "500",
                            letterSpacing: "-1.5px",
                            textAlign: "center",
                          }}
                        >
                          {t("domaine.nomDuSite")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "20px",
                            fontFamily: "Inter Variable",
                            fontWeight: "500",
                            letterSpacing: "-1.5px",
                            textAlign: "center",
                          }}
                        >
                          {t("domaine.url")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "20px",
                            fontFamily: "Inter Variable",
                            fontWeight: "500",
                            letterSpacing: "-1.5px",
                            textAlign: "center",
                          }}
                        >
                          {t("domaine.urlEnAttente")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "20px",
                            fontFamily: "Inter Variable",
                            fontWeight: "500",
                            letterSpacing: "-1.5px",
                            textAlign: "center",
                          }}
                        >
                          {t("domaine.etat")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            fontFamily: "Inter Variable",
                            fontSize: "16px",
                          }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sites.length === 0 ? (
                        <TableRow>
                          <TableCell
                            colSpan={5}
                            sx={{
                              textAlign: "center",
                              fontFamily: "Inter Variable",
                            }}
                          >
                            {t("domaine.aucunNomDeDomaineDisponible")}
                          </TableCell>
                        </TableRow>
                      ) : (
                        sites.map((domain, index) => (
                          <TableRow key={index}>
                            <TableCell
                              sx={{
                                fontFamily: "Inter Variable",
                                textAlign: "center",
                              }}
                            >
                              {domain.nom}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "Inter Variable",
                                textAlign: "center",
                              }}
                            >
                              {domain.url}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "Inter Variable",
                                textAlign: "center",
                              }}
                            >
                              {domain.urlPropose &&
                              domain.urlPropose !== domain.url
                                ? domain.urlPropose
                                : t("domaine.aucuneUrl")}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "Inter Variable",
                                textAlign: "center",
                              }}
                            >
                              {domain.etat}
                            </TableCell>
                            <TableCell align="center">
                              {domain.etat === "actif" && (
                                <Button
                                  variant="outlined"
                                  align="center"
                                  sx={{
                                    background: "#0042ff",
                                    color: "white",
                                    fontSize: {
                                      lg: "14px",
                                      md: "14px",
                                      sm: "12px",
                                      xs: "12px",
                                    },
                                    width: "60%",
                                    fontFamily: "Inter Variable",
                                    fontWeight: "500",
                                    letterSpacing: "-0.25px",
                                    textTransform: "none",
                                    mr: 2,
                                    borderRadius: "10px",
                                    border: "1px solid #276CFA",
                                    outline: "1px solid #0646C6",
                                    "&:hover": {
                                      backgroundColor: "#1459e2",
                                      boxShadow: "none",
                                    },
                                  }}
                                  onClick={() => handleDetailsClick(domain)}
                                >
                                  {t("domaine.details")}
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
        {selectedSite && (
          <SiteDetails
            selectedSite={selectedSite}
            newNomDomaine={newNomDomaine}
            handleUrlChange={handleUrlChange}
            handleSubmit={handleSubmit}
            handleValidateUrl={handleValidateUrl}
            loadingAjouterUrl={loadingAjouterUrl}
            loadingChangementUrl={loadingChangementUrl}
            showSuccessAjouterNomDomaine={showSuccessAjouterNomDomaine}
            showSuccessValiderNomDomaine={showSuccessValiderNomDomaine}
          />
        )}
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={() => setOpenConfirmationDialog(false)}
          onConfirm={handleConfirmChangeDomain}
          message={t("domaine.confirmationMessage", {
            oldDomain: selectedSite?.url,
            newDomain: newNomDomaine,
          })}
        />
        <SuccessToast
          open={openSnackbar}
          onClose={handleSnackbarClose}
          message={toastMessage}
        />
        <Loader loading={loading} />
        <ToastError
          open={error !== null}
          onClose={() => setError(null)}
          message={error}
        />
      </Paper>
    </div>
  );
};

export default GestionDomaine;
