import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../components/authentification/Authentification";
import Loader from "../shared/loader/Loader";

function RouteRequireAuth({ children }) {
  const { userAuthenticated, isLoading } = useContext(AuthContext);
  let location = useLocation();

  if (isLoading) {
    return <Loader loading={isLoading} />;
  }

  if (!userAuthenticated) {
    return (
      <Navigate to="/connexion-compte" state={{ from: location }} replace />
    );
  }

  return children;
}

function GuestOnlyRoute({ children }) {
  const { userAuthenticated } = useContext(AuthContext);
  let location = useLocation();
  const pageAppelante = location.state?.pageAppelante;

  if (userAuthenticated && pageAppelante === "preview") {
    return <Navigate to="/paiement" replace />;
  }

  return !userAuthenticated ? (
    children
  ) : (
    <Navigate to="/gestion-compte" replace />
  );
}

export { RouteRequireAuth, GuestOnlyRoute };
