import React from "react";
import { Box, Typography, Card, CardContent, Link } from "@mui/material";
import { useTranslation } from "react-i18next";

const SocialMediaCard = () => {
  const { t } = useTranslation();

  return (
    <Card
      raised
      sx={{
        m: 2,
        p: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        boxShadow: "none",
        border: "2px solid #c6c7ce",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontFamily: "Space Grotesk, sans serif",
              fontWeight: "1000",
              letterSpacing: {
                xs: "-2px",
                sm: "-2px",
                md: "-3px",
                lg: "-5px",
              },
              fontSize: {
                xs: "25px",
                sm: "30px",
                md: "40px",
                lg: "60px",
              },
            }}
          >
            {t("socialMediaCard.followUs")}
          </Typography>
          <Typography color="text.secondary">
            {t("socialMediaCard.discussImprovementBug")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          <Link
            href="https://discord.com/invite/your-invite-code"
            target="_blank"
            rel="noopener"
            sx={{ mx: 1 }}
          >
            <img
              src={process.env.PUBLIC_URL + "/logo/discord.jpg"}
              alt="discord-lien"
              style={{ borderRadius: "20px", maxWidth: "75px" }}
            />
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SocialMediaCard;
