import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Button,
  Drawer,
  Box,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import CloseIcon from "@mui/icons-material/Close";
import { Container, Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../authentification/Authentification";
import { API_SERVER_ENDPOINT } from "../../../shared/api/ConstantesEndpoints";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../../shared/langue/LanguageSwitcher";
import ToastError from "../../../shared/toast/ToastError";

function MenuAccueil() {
  const { userAuthenticated, setUserAuthenticated } = useContext(AuthContext);
  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const customerName = profil?.customerName;
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const scrollToReference = (encre) => {
    const element = document.getElementById(encre);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    handleCloseMenu();

    try {
      await axios.get(`${API_SERVER_ENDPOINT}/logout`, {
        withCredentials: true,
      });

      setUserAuthenticated(false);
      sessionStorage.removeItem("profil");
      redirectionAccueil();
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const redirectionAccueil = () => {
    navigate("/");
  };

  const redirectionGestionCompte = () => {
    navigate("/gestion-compte");
  };

  const toggleDrawer = (isOpen) => {
    setDrawerOpen(isOpen);
    setMenuOpen(isOpen);
  };

  return (
    <div>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src={process.env.PUBLIC_URL + "/logo/onesite.png"}
              alt={t("menuAccueil.logoAlt")}
              className="logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle onClick={() => toggleDrawer(true)}>
            <FontAwesomeIcon
              icon={faBars}
              style={{ color: "white" }}
              size="lg"
            />
          </Navbar.Toggle>
          <Navbar.Collapse className={menuOpen ? "d-none" : "d-none"}>
            <Nav
              className="d-flex align-items-center ms-auto"
              style={{ gap: "24px" }}
            >
              <Nav.Link
                href="#"
                onClick={() => scrollToReference("fonctionnement")}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "15px",
                    fontFamily: "Inter Variable",
                    letterSpacing: "-0.5px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  {t("menuAccueil.commentCaMarche")}
                </Typography>
              </Nav.Link>
              <Nav.Link
                href="#"
                onClick={() => scrollToReference("visualbuilder")}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "15px",
                    fontFamily: "Inter Variable",
                    letterSpacing: "-0.5px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  {t("menuAccueil.editorVisuel")}
                </Typography>
              </Nav.Link>
              <Nav.Link href="#" onClick={() => scrollToReference("tarifs")}>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "15px",
                    fontFamily: "Inter Variable",
                    letterSpacing: "-0.5px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  {t("menuAccueil.tarifs")}
                </Typography>
              </Nav.Link>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                sx={{ ml: { xs: "-22px", sm: "0px" } }}
                onClick={handleOpenMenu}
              >
                {userAuthenticated ? (
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "16px",
                      fontFamily: "Inter Variable",
                      letterSpacing: "0px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    {customerName}
                  </Typography>
                ) : (
                  <Link
                    to="/connexion-compte"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "16px",
                        fontFamily: "Inter Variable",
                        letterSpacing: "-0.5px",
                        fontWeight: "600",
                        textAlign: "center",
                        textDecoration: "none",
                      }}
                    >
                      {t("menuAccueil.connexion")}
                    </Typography>
                  </Link>
                )}
              </IconButton>
              {userAuthenticated ? (
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={redirectionGestionCompte}>
                    <SettingsIcon />
                    <Typography sx={{ fontFamily: "Lexend Variable" }}>
                      {t("menuAccueil.monProfil")}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <LogoutIcon />
                    <Typography sx={{ fontFamily: "Lexend Variable" }}>
                      {t("menuAccueil.deconnexion")}
                    </Typography>
                  </MenuItem>
                </Menu>
              ) : null}
              <Button
                variant="contained"
                component={RouterLink}
                to="/informations"
                sx={{
                  backgroundColor: "#0042ff",
                  color: "white",
                  fontSize: "15px",
                  fontFamily: "Inter Variable",
                  letterSpacing: "-0.5px",
                  fontWeight: "600",
                  textTransform: "none",
                  borderRadius: "10px",
                  ml: "16px",
                  padding: "5px 10px",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#0033c1",
                    boxShadow: "none",
                  },
                }}
              >
                {t("menuAccueil.essayerGratuitement")}
              </Button>
              <LanguageSwitcher />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            backgroundColor: "rgba(2, 1, 14, 0.95)",
            color: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <Navbar.Brand as={Link} to="/">
          <img
            src={process.env.PUBLIC_URL + "/logo/onesite.png"}
            alt={t("menuAccueil.logoAlt")}
            className="logo"
          />
        </Navbar.Brand>
        <IconButton
          onClick={() => toggleDrawer(false)}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Nav
            className="d-flex flex-column align-items-center"
            style={{ gap: "24px", marginTop: "20px" }}
          >
            <Nav.Link
              href="#"
              onClick={() => {
                scrollToReference("fonctionnement");
                setDrawerOpen(false);
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "20px",
                  fontFamily: "Inter Variable",
                  letterSpacing: "-0.5px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {t("menuAccueil.commentCaMarche")}
              </Typography>
            </Nav.Link>
            <Nav.Link
              href="#"
              onClick={() => {
                scrollToReference("visualbuilder");
                setDrawerOpen(false);
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "20px",
                  fontFamily: "Inter Variable",
                  letterSpacing: "-0.5px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {t("menuAccueil.editorVisuel")}
              </Typography>
            </Nav.Link>
            <Nav.Link
              href="#"
              onClick={() => {
                scrollToReference("tarifs");
                setDrawerOpen(false);
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "20px",
                  fontFamily: "Inter Variable",
                  letterSpacing: "-0.5px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {t("menuAccueil.tarifs")}
              </Typography>
            </Nav.Link>
            {userAuthenticated ? (
              <Nav.Link
                href="#"
                onClick={() => {
                  redirectionGestionCompte();
                  setDrawerOpen(false);
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "20px",
                    fontFamily: "Inter Variable",
                    letterSpacing: "-0.5px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  {t("menuAccueil.monProfil")}
                </Typography>
              </Nav.Link>
            ) : (
              <Nav.Link
                href="/connexion-compte"
                onClick={() => setDrawerOpen(false)}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "20px",
                    fontFamily: "Inter Variable",
                    letterSpacing: "-0.5px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  {t("menuAccueil.connexion")}
                </Typography>
              </Nav.Link>
            )}
            {userAuthenticated && (
              <Nav.Link
                href="#"
                onClick={() => {
                  handleLogout();
                  setDrawerOpen(false);
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "20px",
                    fontFamily: "Inter Variable",
                    letterSpacing: "-0.5px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  {t("menuAccueil.deconnexion")}
                </Typography>
              </Nav.Link>
            )}
            <Button
              variant="contained"
              component={RouterLink}
              to="/informations"
              sx={{
                backgroundColor: "#0042ff",
                color: "white",
                fontSize: "20px",
                fontFamily: "Inter Variable",
                letterSpacing: "-0.5px",
                fontWeight: "600",
                textTransform: "none",
                borderRadius: "10px",
                padding: "10px 20px",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "#0033c1",
                  boxShadow: "none",
                },
              }}
              onClick={() => setDrawerOpen(false)}
            >
              {t("menuAccueil.essayerGratuitement")}
            </Button>
            <LanguageSwitcher />
          </Nav>
        </Box>
      </Drawer>
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </div>
  );
}

export default MenuAccueil;
